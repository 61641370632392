import React from "react";
import {Card, Col, Form, Row, Table} from "@themesberg/react-bootstrap";
import {connect} from "react-redux";



const PaylogDetails = ({paylogData, index}) => {
   
    console.log('paylogData', paylogData) 

    return (
        <Row className="mb-3">
            <h6>
                <b>Paylog-{index+1}</b>
            </h6>
            <Col md="12">
                <Card>
                    <Card.Body>
                        <Form onSubmit={() => {
                        }}>
                            <Table striped bordered>
                                <tr>
                                    <td>Msisdn</td>
                                    <td>
                                        <b>{paylogData?.fromMsisdn || "Customer Msisdn"}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date Paid</td>
                                    <td><b>{paylogData?.datePaid || "Date Paid"}</b></td>
                                </tr>
                                <tr>
                                    <td>Reference</td>
                                    <td><b>{paylogData?.reference || "Reference"}</b></td>
                                </tr>
                                <tr>
                                    <td>Loan ID</td>
                                    <td><b>{paylogData?.loanid || "Loan ID"}</b></td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td><b>{paylogData?.status || "Status"}</b></td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td><b>{paylogData?.totalAmount || "0"}</b></td>
                                </tr>
                                <tr>
                                    <td>Used Amount</td>
                                    <td><b>{paylogData?.usedAmount || "0"}</b></td>
                                </tr>
                                <tr>
                                    <td>Admin ID</td>
                                    <td><b>{paylogData?.userID || "Admin ID"}</b></td>
                                </tr>
                            </Table>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row> 
    );
}

const mapStateProps = (state) => {
    const {categories, category, error} = state.device;
    return {categories, category, error,};
};

export default connect(mapStateProps,)(PaylogDetails);
