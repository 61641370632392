import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Button, Card, Form, FormControl, InputGroup, Table, Dropdown, DropdownButton } from '@themesberg/react-bootstrap';
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { getAllDevices } from "../../actions/devices";
import { Routes } from "../../routes";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import authorization from "../../helpers/authorization";
import { getURLSearchParams } from "../../helpers/url";
import { fetchGraduatingCustomers } from '../../actions/customer_graduation';
import {
    FcApproval,
    FcDeleteDatabase
} from "react-icons/fc"
import ModalComponent from '../../components/modal/ModalComponent';
import ConfirmModalCustomerGrad from '..//customer_graduation/ConfirmCustomerGrad';
import { faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons";


// Table row component 
const TableRow = ({ customer, setShowModal, statusType, setActiveRow, index }) => {

    console.log('customer', customer)

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td>{customer?.fullname || "Full Name"}</td>
            <td>{customer?.msisdn || customer?.oldMsisdn || "msisdn"}</td>
            <td>{customer?.loanId}</td>
            <td>{customer?.totalLoanAmount}</td>
            <td>{customer?.totalPayment}</td>
            <td>{customer?.status === "REJECT" ? "REJECTED" : customer?.status}</td>
            <td>{customer?.emailsent}</td>
            <td>{customer?.adminUserId || "null"}</td>
            {
                statusType === "pending" &&
                <td>
                    {
                        authorization("DT_EDIT") ? (
                            <div>
                                <span
                                    title='GRADUATE'
                                    onClick={() => {
                                        setActiveRow(customer)
                                        setShowModal(true)
                                        // setActionType("approve")
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} className={`icon icon-xs text-info cursor-pointer color-white`} />
                                </span>
                            </div>
                        ) : (<></>)
                    }
                </td>
            }
        </tr>
    );
};

// Customer filter component 
const customerGradStatus = [
    {
        id: 1,
        name: 'PENDING',
        value: "pending"
    },
    {
        id: 2,
        name: 'APPROVED',
        value: "approved"
    },
];
const CustomerTypeFilter = (props) => {
    const { type, types, onTypeChange } = props;
    //
    return (
        <>
            <div className="">
                {/* <Dropdown style={{marginLeft: 1000, marginBottom: 30, marginTop: -50}}> */}
                <Dropdown>
                    <Dropdown.Toggle as={Button} variant="primary">
                        <FontAwesomeIcon icon={faFilter} className="me-2" /> Customer Status
                        <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                        {types && types.length > 0 ? types.map(t => (
                            <Dropdown.Item onClick={() => onTypeChange(t.value)} active={t.value === type}>
                                {t.name}
                            </Dropdown.Item>
                        )) : (<></>)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
};

function FetchGradCustomers({ getAllGraduatingCustomers, graduatingCustomers, store, loading }) {
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState("approve");
    const [activeRow, setActiveRow] = useState(null);
    const [searchValue, setSearchValue] = useState("")
    const [statusType, setStatusType] = useState("pending");

    // To handle search onChange 
    const handleSetStatusType = (e) => {
        setStatusType(e)
    }

    // To handle filter based on search Value 
    function filterArrayBySearchValue(arr, searchValue) {
        // Convert the search value to lowercase for case-insensitive matching
        const lowerSearchValue = searchValue.toLowerCase();

        // Use the filter method to create a new array with matching elements
        const filteredArray = arr.filter(item => {

            // Check if item.fromMsisdn is null or undefined
            if (item.fromMsisdn == null || item.status == null) {
                return false;
            }

            // Convert each item to lowercase and check if it includes the search value
            const lowerItem = item.fromMsisdn?.toLowerCase();
            const lowerItem2 = item.status?.toLowerCase();
            return lowerItem.includes(lowerSearchValue) || lowerItem2.includes(lowerSearchValue);
        });

        return filteredArray;
    }

    useEffect(() => {
        // Get All The Customer Logs Once Component Mounts 
        getAllGraduatingCustomers(statusType)
    }, [statusType])

    return (
        <div className='py-4'>
            {/* Header - starts  */}
            <Breadcrumb className="d-none d-md-inline-block"
                listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                    icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item active>Customers Graduation</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ marginTop: "2%" }}>
                <div style={{ marginBottom: "2%", display: "flex", justifyContent: 'space-between' }}>
                    <h5>Fetch Graduating Customers</h5>
                    {/* Filters */}
                    <>
                        <CustomerTypeFilter onTypeChange={handleSetStatusType} type={statusType} types={customerGradStatus} />
                    </>
                </div>

                {/* Search box  */}
                {/* <Form>
                    <InputGroup className="mb-3">
                        <FormControl
                            name={'search'}
                            onChange={handleChange}
                            placeholder="Search by msisdn or status"
                            aria-label="Search by msisdn"
                        />
                    </InputGroup>
                </Form> */}

                {/* Table section  */}
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                                <tr>
                                    <th className="">#</th>
                                    <th className="">Full Name</th>
                                    <th className="">Msisdn</th>
                                    <th className="">Loan ID</th>
                                    <th className="">Total Loan Amount</th>
                                    <th className="">Total Payment</th>
                                    <th className="">Status</th>
                                    <th className="">Email Sent</th>
                                    <th className="">Admin ID</th>
                                    {
                                        statusType === "pending" &&
                                        <th className="">Action</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? (
                                        <tr>
                                            <td colSpan={statusType === "pending" ? '11' : '10'} align="center"><BeatLoader color="#000" size="8" /></td>
                                        </tr>
                                    ) :
                                        graduatingCustomers?.payload?.length > 0 ?
                                            graduatingCustomers?.payload.map((customer, i) =>
                                            (
                                                <TableRow
                                                    index={++i}
                                                    key={`page-visit-${customer?.id}`}
                                                    setShowModal={setShowModal}
                                                    setActionType={setActionType}
                                                    setActiveRow={setActiveRow}
                                                    customer={customer}
                                                    statusType={statusType}
                                                />
                                            ))
                                            : (
                                                <tr>
                                                    <td colSpan="10" align="center">No {statusType?.toLowerCase()} customers available !</td>
                                                </tr>
                                            )
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>

            {
                showModal &&
                <ModalComponent
                    show={showModal}
                    setShow={setShowModal}
                    title={"Approve For Graduation"}
                    modalContent={
                        <ConfirmModalCustomerGrad
                            activeRow={activeRow}
                            setShow={setShowModal}
                        />
                    }
                />
            }
        </div>
    )
}


const mapDispatchToProps = dispatch => ({
    getAllGraduatingCustomers: (payload) => dispatch(fetchGraduatingCustomers(payload))
})

const mapStateToProps = (store) => {
    const { loading, graduatingCustomers } = store?.customerGrad;
    return { loading, graduatingCustomers, store };
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchGradCustomers);