import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Card, Table } from '@themesberg/react-bootstrap';
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Routes } from "../../routes";
import { FetchClosureLoans } from '../../actions/loans';
import authorization from '../../helpers/authorization';
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import {
    FcDeleteDatabase
} from "react-icons/fc"
import ModalComponent from '../../components/modal/ModalComponent';
import UpdateConfirmDialog from './components/UpdateConfirmDialog';

const TableRow = ({ intiatedLoan, setShowModal, setActionType, setActiveRow, index }) => {


    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td>
                {intiatedLoan?.msisdn}
            </td>
            <td>{intiatedLoan?.loan?.loanStatus}</td>
            <td>{intiatedLoan?.initiatedBy}</td>
            <td>{intiatedLoan?.initiatedOn}</td>
            <td>{intiatedLoan?.reason}</td>
            <td>{intiatedLoan?.status}</td>
            <td>
                {
                    authorization("DT_EDIT") && intiatedLoan?.status === "PENDING" ? (

                        <div>
                            <span
                                title='APPROVE'
                                onClick={() => {
                                    setActiveRow(intiatedLoan)
                                    setShowModal(true)
                                    setActionType("approve")
                                }}
                            >
                                <FontAwesomeIcon icon={faEdit} className={`icon icon-xs text-info cursor-pointer color-white`} />
                            </span>
                            <span
                                style={{ marginLeft: "10px", color: 'red !important' }}
                                title='REJECT'
                                onClick={() => {
                                    setActiveRow(intiatedLoan)
                                    setShowModal(true)
                                    setActionType("reject")
                                }}
                                type="button"
                            >
                                <FcDeleteDatabase size={18} />
                            </span>
                        </div>
                    ) : (<></>)
                }
            </td>
        </tr>
    );
};

const ClosureLoans = ({getInitiatedLoans, initiatedLoans, loading} ) => {
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState("approve");
    const [activeRow, setActiveRow] = useState(null);
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        getInitiatedLoans()
    }, [])

    console.log("initiatedLoans", initiatedLoans?.payload?.data);

    return (
        <div className='py-4'>
        {/* Header - starts  */}
        <Breadcrumb className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Closure Loans</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ marginTop: "2%" }}>
            <h5 style={{ marginBottom: "2%" }}>Loans initiated for closure</h5>


            {/* Table section  */}
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="">
                    <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                        <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Msisdn</th>
                                <th className="">Loan Status</th>
                                <th className="">Initiated By</th>
                                <th className="">Initiated On</th>
                                <th className="">Reason</th>
                                <th className="">Initiate Status</th>
                                <th className="">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="11" align="center"><BeatLoader color="#000" size="8" /></td>
                                    </tr>
                                ) :
                                    initiatedLoans?.payload?.data?.length > 0 ?
                                    initiatedLoans?.payload?.data?.map((intiatedLoan, i) =>
                                        (
                                            <TableRow
                                                index={++i}
                                                key={intiatedLoan?.id}
                                                setShowModal={setShowModal}
                                                setActionType={setActionType}
                                                setActiveRow={setActiveRow}
                                                intiatedLoan={intiatedLoan}
                                            />
                                        ))
                                        : (
                                            <tr>
                                                <td colSpan="8" align="center">No initiated loan for cancellation</td>
                                            </tr>
                                        )
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>


        {
                showModal &&
                <ModalComponent
                    show={showModal}
                    setShow={setShowModal}
                    title={actionType === "approve" ? "APPROVE" : "REJECT"}
                    modalContent={
                        <UpdateConfirmDialog
                            type={actionType}
                            activeRow={activeRow}
                            setShow={setShowModal}
                        />
                    }
                />
            }

    </div>
    );
};

const mapDispatchToProps = dispatch => ({
    getInitiatedLoans: () => dispatch(FetchClosureLoans())
})

const mapStateToProps = (store) => {
    const { loading, initiatedLoans } = store?.loans;
    return { loading, initiatedLoans };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClosureLoans);