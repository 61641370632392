import {action} from "../index";
import {ERROR_TICKETS, GET_TICKETS, REPLY_TICKETS} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCallT} from "../../utils/apiCall";

/**
 * Get tickets
 *
 * @param query
 * @returns {(function(*): Promise<void>)|*}
 */
export const getTickets = (query = null) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const _q = query ? `?${query}` : '';
        const req = await apiCallT.get(`/tickets${_q}`, {
            headers,
        });
        dispatch(action(GET_TICKETS, req.data.data ?? []));
    } catch (e) {
        dispatch(action(ERROR_TICKETS, e.message));
    }
}

/**
 * Reply tickets
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const replyTickets = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCallT.post(`/messages`, data, {
            headers,
        });
        console.log(req.data);
        dispatch(action(REPLY_TICKETS, req.data.data ?? []));
        return true;
    } catch (e) {
        dispatch(action(ERROR_TICKETS, e.message));
    }
    return false;
}
