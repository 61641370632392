import {ERROR_NOTIFICATION, NOTIFICATION_DEVICE, SEND_NOTIFICATION} from "../../actions/types";

const initials = {
    error: null,
}

const notificationReducer = (state = initials, action) => {
    switch (action.type) {
        case NOTIFICATION_DEVICE:
            return {
                ...state,
                error: null,
                devices: action.payload,
            }
        case SEND_NOTIFICATION:
            return {
                ...state,
                error: null,
                notification: action.payload,
            }
        case ERROR_NOTIFICATION:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default notificationReducer;
