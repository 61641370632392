import {action} from "../index";
import {CURRENT_PAGE, DASHBOARD_DATA, DASHBOARD_DATE, DASHBOARD_ERROR, LOADING_STATE} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";

/**
 * Set current page
 *
 * @param route
 * @returns {(function(*): void)|*}
 */
export const setCurrentPage = (route) => (dispatch) => {
    dispatch(action(CURRENT_PAGE, route));
}

/**
 * Set loading state
 *
 * @param isLoading
 * @returns {(function(*): void)|*}
 */
export const setLoadingSate = (isLoading) => (dispatch) => {
    dispatch(action(LOADING_STATE, isLoading));
}

/**
 * Dashboard Data
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const getDashboardData = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        // const req = await apiCall.post(`/intelligrapi/dashboardcard/getdashdata`, data, {
        //     headers,
        // });
        dispatch(action(DASHBOARD_DATA, []));
    } catch (e) {
        dispatch(action(DASHBOARD_ERROR, e.message));
    }
}

/**
 * Set Dashboard dates
 *
 * @param date
 * @returns {(function(*): Promise<void>)|*}
 */
export const setDashboardDate = (date) => async (dispatch) => {
    dispatch(action(DASHBOARD_DATE, date));
}
