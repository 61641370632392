import { Button } from "@themesberg/react-bootstrap";
import { BeatLoader } from "react-spinners";
import { connect } from 'react-redux';
import { useState } from 'react';
import { fetchGraduatingCustomers, verifyGraduateCustomer } from "../../actions/customer_graduation";
import { getUser } from "../../helpers/tokenHelper";



function ConfirmModalCustomerGrad({
    setShow,
    loading,
    activeRow,
    verifyGraduateCustomerNow,
    getAllGraduatingCustomers
}) {
    let content = activeRow;
    console.log('activeRow', activeRow)

    const handleVerifyGradCustomer = async (data) => {
        const payLoadToSend = {
            customerID: data?.customerId,
            msisdn: `${data?.oldMsisdn}`,
            adminUser: `${getUser()?.id}`
        }

        let response = await verifyGraduateCustomerNow(payLoadToSend)
        console.log('modal-response', response)

        if (response?.data?.status === 200) {
            setShow(false);

            // Fetch records again 
            getAllGraduatingCustomers()
        }
    }

    return (
        <>
            <>
                <>
                    <h5>Are you sure you want to approve this customer for graduation???</h5>
                </>


                {/* Action buttons  */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "30px"
                    }}
                >
                    <Button
                        variant={"dark"}
                        size={"sm"}
                        color="white"
                        onClick={() => handleVerifyGradCustomer(content)}
                        disabled={loading}
                    >
                        {loading ? <BeatLoader color="#fff" /> : "APPROVE"}
                    </Button>
                </div>
            </>
        </>
    )
}


const mapDispatchToProps = dispatch => ({
    verifyGraduateCustomerNow: (payload) => dispatch(verifyGraduateCustomer(payload)),
    getAllGraduatingCustomers: (payload) => dispatch(fetchGraduatingCustomers(payload))
})

const mapStateToProps = (store) => {
    const { loading } = store?.customerGrad;
    return { loading };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalCustomerGrad);