import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, ButtonGroup, Card, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import {DevicesModelDialog} from "../../../loans/components/DevicesModelDialog";
import {SchedulesModelDialog} from "../../../loans/components/SchedulesModelDialog";
import {getBankAllocatedLoans} from "../../../../actions/partners";

const TableRow = (props) => {
    const {
        index, loanId, bank, downPayment, totalLoanAmount, graceTime, minScore, paymentFrequency, devices,
        offeringDate, loanStatus, loanBalance, paidAmount, paymentSchedules, showDevices, setDevices,
        showSchedules, setSchedules, customer,
    } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{(index + 1)}</Card.Link>
            </td>
            <td className="fw-bold">{`${customer.customerName} ${customer.customerSurname}`}</td>
            <td className="fw-bold">{customer.msisdn}</td>
            <td className="fw-bold">
                {bank ? bank.name ?? bank.bankName : ""}
            </td>
            <td className="fw-bold">{minScore}</td>
            <td className="fw-bold">
                <Button size={'sm'} onClick={() => {
                    showDevices();
                    setDevices(devices);
                }}
                        variant={'light'}>Device(s): {devices && devices.length}</Button>
            </td>
            <td className="fw-bold">
                <Button size={'sm'} variant={'light'} onClick={() => {
                    showSchedules();
                    setSchedules(paymentSchedules);
                }}>Schedules</Button>
            </td>
            <td className="fw-bold">{paymentFrequency}</td>
            <td className="fw-bold">{downPayment.toLocaleString()}</td>
            <td className="fw-bold">{totalLoanAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanBalance.toLocaleString()}</td>
            <td className="fw-bold">{paidAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanStatus}</td>
            <td className="fw-bold">{
                (new Date(offeringDate)).toLocaleString()
            }</td>
        </tr>
    );
};

class InsuranceLoans extends Component {
    state = {
        loading: false,
        showDevices: false,
        showSchedules: false,
        getDevices: [],
        getSchedules: [],
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});

        this.setState({loading: false});
    }
    //
    showDevices = () => {
        this.setState({showDevices: true,});
    }
    //
    showSchedule = () => {
        this.setState({showSchedules: true});
    }
    //
    hideSchedule = () => {
        this.setState({showSchedules: false, getSchedules: [],});
    }
    //
    hideDevices = () => {
        this.setState({showDevices: false, getDevices: [],});
    }
    //
    setDevices = (devices) => {
        this.setState({
            getDevices: devices,
        });
    }
    //
    setPaymentSchedules = (schedules) => {
        this.setState({
            getSchedules: schedules,
        });
    }

    //
    render() {
        const {loading, showDevices, getDevices, showSchedules, getSchedules} = this.state;
        const {loans, bank} = this.props;
        //
        return (
            <>
                <Card>
                    <Card.Body className={'overflow-auto'}>
                        <div className="btn-toolbar" style={{marginBottom: 10}}>
                            <ButtonGroup>
                                <Button variant="outline-primary" size="sm">Export</Button>
                            </ButtonGroup>
                        </div>
                        <Table responsive className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Name</th>
                                <th className="border-0">National ID</th>
                                <th className="border-0">Nationality</th>
                                <th className="border-0">MSISDN</th>
                                <th className="border-0">Email address</th>
                                <th className="border-0">Gender</th>
                                <th className="border-0">Date of birth</th>
                                <th className="border-0">Place of birth</th>
                                <th className="border-0">Device's serial number</th>
                                <th className="border-0">Modal number</th>
                                <th className="border-0">Customer onboarded date</th>
                                <th className="border-0">Device release date</th>
                                <th className="border-0">Policy</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan={14} className="text-center">No Loans Available !!</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                    <DevicesModelDialog show={showDevices} close={this.hideDevices} devices={getDevices}/>
                    <SchedulesModelDialog show={showSchedules} close={this.hideSchedule} schedules={getSchedules}/>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, loans} = state.partners;
    return {error, loans,};
}
//
export default connect(mapStateProps, {})(InsuranceLoans);
