import {
    CORPORATE_UPLOAD_TEMPLATE,
    CREATE_CORPORATE,
    GET_CORPORATE,
    GET_CORPORATE_MEMBERS,
    GET_CORPORATE_TRANSACTIONS,
    GET_CORPORATE_USERS,
    GET_CORPORATES,
    UPLOAD_CORPORATE_ERROR
} from "../../actions/types";

const init = {
    error: null,
}

const corporateReducer = (state = init, action) => {
    switch (action.type) {
        case GET_CORPORATES:
            return {
                ...state,
                error: null,
                corporates: action.payload,
            }
        case GET_CORPORATE:
            return {
                ...state,
                error: null,
                corporate: action.payload,
            }
        case GET_CORPORATE_MEMBERS:
            return {
                ...state,
                error: null,
                members: action.payload,
            }
        case GET_CORPORATE_TRANSACTIONS:
            return {
                ...state,
                error: null,
                transactions: action.payload,
            }
        case GET_CORPORATE_USERS:
            return {
                ...state,
                error: null,
                users: action.payload,
            }
        case CORPORATE_UPLOAD_TEMPLATE:
            return {
                ...state,
                error: null,
                upload: action.payload,
            }
        case CREATE_CORPORATE:
            return {
                ...state,
                error: null,
                corporate: action.payload,
            }
        case UPLOAD_CORPORATE_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default corporateReducer;
