import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Card, Col, ProgressBar, Row, Table} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
//
const TableRow = (props) => {
    const {id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare,} = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{id}</Card.Link>
            </td>
            <td className="fw-bold">
                <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`}/>
                {source}
            </td>
            <td>{sourceType}</td>
            <td>{category ? category : "--"}</td>
            <td>{rank ? rank : "--"}</td>
            <td>
                <Row className="d-flex align-items-center">
                    <Col xs={12} xl={2} className="px-0">
                        <small className="fw-bold">{trafficShare}%</small>
                    </Col>
                    <Col xs={12} xl={10} className="px-0 px-xl-1">
                        <ProgressBar variant="primary" className="progress-lg mb-0" now={trafficShare} min={0}
                                     max={100}/>
                    </Col>
                </Row>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
    );
};

class LoansPartners extends Component {
    state = {
        loading: true,
    }
    //
    componentDidMount = async () => {
    }

    //
    render() {
        const {loading} = this.state;
        const {data} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Partners & Plans</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Partners & Plans</h4>
                    </div>
                </div>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Lender</th>
                                <th className="border-0">Plan</th>
                                <th className="border-0">Score</th>
                                <th className="border-0">Total Amount</th>
                                <th className="border-0">Down-Payment</th>
                                <th className={"border-0"}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="8" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : data != null && data.length > 0 ?
                                    data.map((loan, i) => <TableRow {...loan}
                                                                    index={i}
                                                                    key={`page-visit-${loan.id}`}/>) : (
                                        <tr>
                                            <td colSpan="8" align="center">No loans & partners yet !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    return {};
}

export default connect(mapStateProps, {})(LoansPartners);
