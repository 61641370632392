import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Breadcrumb, Button, Card, Form, Modal} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {apiCall} from "../../utils/apiCall";
import {getToken} from "../../helpers/tokenHelper";

class PaymentTransaction extends Component {
    state = {
        loading: false,
        validated: false,
        file: null,
        errorMessage: null,
        successMessage: null,
        showModal: false,
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        const form = event.currentTarget;
        if (form.checkValidity() === false || !this.state.file) {
            event.stopPropagation();
            this.setState({ loading: false });
            return;
        }

        const formData = new FormData();
        formData.append("file", this.state.file);
        const token = getToken();
        apiCall.post(`/intelligrapi/uploaddailytransactions`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                this.setState({
                    errorMessage: null,
                    file: null,
                    validated: false,
                    loading: false,
                });
                alert("File uploaded and processing started.");
            })
            .catch(error => {
                this.setState({
                    errorMessage: "An error occurred while uploading the file.",
                    file: null,
                    validated: false,
                    loading: false,
                });
                alert("An error occurred while uploading the file.");
            });
    };

    isValidExcelFile = (file) => {
        return file.name.toLowerCase().endsWith(".xlsx");
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!this.isValidExcelFile(file)) {
            this.setState({ errorMessage: "Please upload a xlsx file.", file: null, validated: false });
            return;
        }
        this.setState({file: file, validated: true});
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { loading, validated, errorMessage, csvContent, showModal, autoSweep } = this.state;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item href="#"><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                            <Breadcrumb.Item active>Upload Transaction</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Upload Transaction</h4>
                    </div>
                </div>
                <Card border="light" className="table-wrapper shadow-sm mb-4">
                    <Card.Body>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <div>
                                <Form.Group controlId="formFile" className="mb-3 has-error">
                                    <Form.Label>Payment Transaction File (xlsx)</Form.Label>
                                    <Form.Control type="file" onChange={this.handleFileChange} required />
                                    <Form.Control.Feedback type="invalid">Please choose a XLSX file.</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <Button variant="primary" disabled={validated !== true || loading === true} type="submit">
                                {loading ? 'Uploading ...' : 'Upload'}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <Modal as="div" centered show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview CSV</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "85vh", overflowY: "auto" }}>
                        <pre>{csvContent}</pre>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        loading: store.loading,
    };
}
export default connect(mapStateToProps, {})(PaymentTransaction);
