import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Image, Row} from '@themesberg/react-bootstrap';

import {Link} from 'react-router-dom';

import {Routes} from "../../routes";
import NotAuthorizedImage from "../../assets/img/illustrations/401.svg";
import {connect} from "react-redux";
import {profileAction} from "../../actions/user";
import {logout} from "../../actions/auth";

const NotAuthorized = (props) => {
    const logout = async () => {
        const {logout} = props;
        await logout();
        window.location = Routes.Login.path;
    }

    return (
        <main>
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                            <div>
                                <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                                    <Image src={NotAuthorizedImage} className="img-fluid w-75"/>
                                </Card.Link>
                                <h1 className="text-primary mt-5">
                                    Page not <span className="fw-bolder">authorized</span>
                                </h1>
                                <p className="lead my-4">
                                    Oops! Looks like you followed an authorized link.
                                </p>
                                <Button as={Link} variant="primary" className="animate-hover"
                                        to={Routes.DashboardOverview.path}>
                                    <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2"/>
                                    Go back home
                                </Button>
                                <Button variant={'secondary'}
                                        onClick={logout}
                                        className="animate-hover mx-3">
                                    <FontAwesomeIcon icon={faSignOutAlt} className="animate-left-3 me-3 ms-2"/>
                                    Logout
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

const mapStateToProps = (state) => {
    const {isLoggedIn} = state.authentication;
    const {user, error} = state.profile;
    const {currentPage, loadingState,} = state.app;
    return {
        isLoggedIn, user, error, currentPage, loadingState,
    };
}
export default connect(mapStateToProps, {
    profileAction,
    logout,
})(NotAuthorized);