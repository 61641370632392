import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";
import {action} from "../index";
import {
    ASSIGN_DEVICE_TO_STORE,
    ATTACH_REPRESENTATIVE_TO_STORE,
    CREATE_STORES,
    ERROR_ASSIGN_DEVICE_TO_STORE,
    ERROR_REPRESENTATIVE_STORE,
    ERROR_STORES,
    GET_DEVICE_IN_STORES,
    GET_DEVICE_NOT_IN_STORE,
    GET_REPRESENTATIVE_IN_STORES,
    GET_STORES,
    REMOVE_DEVICE_FROM_STORE,
    UPDATE_STORE, UPDATE_STORE_PRICE,
    VIEW_STORE_DETAILS
} from "../types";

/**
 * Get all stores type from the backend
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllStores = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get('/intelligrapi/allactivestores', {
            headers,
        });
        dispatch(action(GET_STORES, req.data));
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Create a store
 *
 * @param data
 * @returns {(function(*): Promise<boolean|undefined>)|*}
 */
export const createStores = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/registermerchantstore', data, {
            headers,
        });
        //
        if (req.data && req.data.storeId) {
            dispatch(action(CREATE_STORES, req.data));
            return true;
        } else {
            dispatch(action(ERROR_STORES, req.data.error));
        }
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Create a store
 *
 * @param data
 * @returns {(function(*): Promise<boolean|undefined>)|*}
 */
export const updateStores = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/updatemarchantstore', data, {
            headers,
        });
        //
        if (req.data && req.data.storeId) {
            dispatch(action(UPDATE_STORE, req.data));
            return true;
        } else {
            dispatch(action(ERROR_STORES, req.data.error));
        }
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Get device type in the store
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getStoreDevices = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/devicetypesinthestore?store=${id}`, {
            headers,
        });
        dispatch(action(GET_DEVICE_IN_STORES, req.data));
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Get devices type not in selected store
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getDevicesNotInStore = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/devicetypesnotinthestore?store=${id}`, {
            headers,
        });
        dispatch(action(GET_DEVICE_NOT_IN_STORE, req.data));
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Assigning device type to store
 *
 * @param data
 * @returns {(function(*): Promise<boolean|undefined>)|*}
 */
export const assignDeviceToStore = (data) => async (dispatch) => {
    try {
        try {
            const token = getToken();
            const headers = {
                Authorization: "Bearer ".concat(token),
            };
            const req = await apiCall.post('/intelligrapi/attachdevicetypetostore', data, {
                headers,
            });
            //
            if (req.data && req.data.length > 0) {
                dispatch(action(ASSIGN_DEVICE_TO_STORE, req.data));
                return true;
            } else {
                dispatch(action(ERROR_ASSIGN_DEVICE_TO_STORE, req.data.error));
            }
        } catch (e) {
            dispatch(action(ERROR_ASSIGN_DEVICE_TO_STORE, e.message));
        }
    } catch (e) {

    }
}

/**
 * Remove device from a store
 *
 * @param storeId
 * @param deviceId
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const removeDeviceFromStore = (storeId, deviceId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/stopsellingdevicetypeinthestore?store=${storeId}&devicetypeId=${deviceId}`, {
            headers,
        });
        //
        dispatch(action(REMOVE_DEVICE_FROM_STORE, req.data));
        return true;
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
    return false;
}

/**
 *  Get store details
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getStoreDetails = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/storebyid?id=${id}`, {
            headers,
        });
        dispatch(action(VIEW_STORE_DETAILS, req.data));
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Get representatives in the selected store
 *
 * @param storeId
 * @returns {(function(*): Promise<*>)|*}
 */
export const getRepresentativesInStore = (storeId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/repsinthestore?storeid=${storeId}`, {
            headers,
        });
        dispatch(action(GET_REPRESENTATIVE_IN_STORES, req.data));
    } catch (e) {
        dispatch(action(ERROR_STORES, e.message));
    }
}

/**
 * Attach representative to a store
 *
 * @param storeId
 * @param repId
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const attacheRepToStore = (storeId, repId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.get(`/intelligrapi/attachreptostore?store=${storeId}&representative=${repId}`, {
            headers,
        });
        //
        if (req.data && req.data.error === undefined) {
            dispatch(action(ATTACH_REPRESENTATIVE_TO_STORE, req.data));
            return true;
        }
        //
        if (req.data.error) {
            dispatch(action(ERROR_REPRESENTATIVE_STORE, req.data.error));
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_REPRESENTATIVE_STORE, e.response.data.error));
        } else {
            dispatch(action(ERROR_REPRESENTATIVE_STORE, e.message));
        }
    }
    return false;
}

/**
 * Remove representative from a store
 *
 * @param storeId
 * @param repId
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const removeRepFromStore = (storeId, repId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/deattachreptostore?store=${storeId}&rep=${repId}`, {
            headers,
        });
        //
        if (req.data) {
            dispatch(action(REMOVE_DEVICE_FROM_STORE, req.data));
            return true;
        } else {
            dispatch(action(ERROR_REPRESENTATIVE_STORE, req.data));
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_REPRESENTATIVE_STORE, e.response.data.error));
        } else {
            dispatch(action(ERROR_REPRESENTATIVE_STORE, e.message));
        }
    }
    return false;
}

export const updateDevicePrice = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/devicetypestorepricechange', data, {
            headers,
        });
        // dispatch(action(UPDATE_STORE_PRICE, data));
    } catch (e) {
        console.log(e);
    }
}

