import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumb, Button} from '@themesberg/react-bootstrap';


import {TransactionsTable} from "../../components/Tables";


export default () => {
    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>stores</Breadcrumb.Item>
                        <Breadcrumb.Item active>List of all users</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4> All users</h4>
                </div>
            </div>
            <Button variant="primary" href="form" className="m-1">Add device

            </Button>
            <TransactionsTable/>
        </>
    );
};