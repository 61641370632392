import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import {DevicesModelDialog} from "../../../loans/components/DevicesModelDialog";
import {SchedulesModelDialog} from "../../../loans/components/SchedulesModelDialog";
import {getBankAllocatedLoans} from "../../../../actions/partners";

const TableRow = (props) => {
    const {
        index, bank, downPayment, totalLoanAmount, minScore, paymentFrequency, devices,
        offeringDate, loanStatus, loanBalance, paidAmount, paymentSchedules, showDevices, setDevices,
        showSchedules, setSchedules, customer,
    } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{(index + 1)}</Card.Link>
            </td>
            <td className="fw-bold">{`${customer.customerName} ${customer.customerSurname}`}</td>
            <td className="fw-bold">{customer.msisdn}</td>
            <td className="fw-bold">
                {bank ? bank.name ?? bank.bankName : ""}
            </td>
            <td className="fw-bold">{minScore}</td>
            <td className="fw-bold">
                <Button size={'sm'} onClick={() => {
                    showDevices();
                    setDevices(devices);
                }}
                        variant={'light'}>Device(s): {devices && devices.length}</Button>
            </td>
            <td className="fw-bold">
                <Button size={'sm'} variant={'light'} onClick={() => {
                    showSchedules();
                    setSchedules(paymentSchedules);
                }}>Schedules</Button>
            </td>
            <td className="fw-bold">{paymentFrequency}</td>
            <td className="fw-bold">{downPayment.toLocaleString()}</td>
            <td className="fw-bold">{totalLoanAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanBalance.toLocaleString()}</td>
            <td className="fw-bold">{paidAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanStatus}</td>
            <td className="fw-bold">{
                (new Date(offeringDate)).toLocaleString()
            }</td>
        </tr>
    );
};

class BankLoans extends Component {
    state = {
        loading: false,
        showDevices: false,
        showSchedules: false,
        getDevices: [],
        getSchedules: [],
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getBankAllocatedLoans, bank} = this.props;
        await getBankAllocatedLoans(bank.id);
        this.setState({loading: false});
    }
    //
    showDevices = () => {
        this.setState({showDevices: true,});
    }
    //
    showSchedule = () => {
        this.setState({showSchedules: true});
    }
    //
    hideSchedule = () => {
        this.setState({showSchedules: false, getSchedules: [],});
    }
    //
    hideDevices = () => {
        this.setState({showDevices: false, getDevices: [],});
    }
    //
    setDevices = (devices) => {
        this.setState({
            getDevices: devices,
        });
    }
    //
    setPaymentSchedules = (schedules) => {
        this.setState({
            getSchedules: schedules,
        });
    }

    //
    render() {
        const {loading, showDevices, getDevices, showSchedules, getSchedules} = this.state;
        const {loans} = this.props;
        //
        return (
            <>
                <Card>
                    <Card.Body className={'overflow-auto'}>
                        <Table responsive className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Lender</th>
                                <th className="border-0">Min Score</th>
                                <th className="border-0">Device(s)</th>
                                <th className="border-0">Schedules</th>
                                <th className="border-0">Payment Frequency</th>
                                <th className="border-0">Down Payment</th>
                                <th className="border-0">Total Loan Amount</th>
                                <th className="border-0">Loan Balance</th>
                                <th className="border-0">Paid Amount</th>
                                <th className="border-0">Loan Status</th>
                                <th className="border-0">Offering Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="12" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : loans != null && loans.length > 0 ?
                                    loans.map((loan, i) => <TableRow {...loan}
                                                                     index={i}
                                                                     showDevices={this.showDevices}
                                                                     showSchedules={this.showSchedule}
                                                                     hideDevices={this.hideDevices}
                                                                     openDevicesModel={showDevices}
                                                                     openSchedulesModel={showSchedules}
                                                                     setDevices={this.setDevices}
                                                                     setSchedules={this.setPaymentSchedules}
                                                                     key={`page-visit-${i}`}/>) : (
                                        <tr>
                                            <td colSpan="12" align="center">No loans yet !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                    <DevicesModelDialog show={showDevices} close={this.hideDevices} devices={getDevices}/>
                    <SchedulesModelDialog show={showSchedules} close={this.hideSchedule} schedules={getSchedules}/>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, loans} = state.partners;
    return {error, loans,};
}
//
export default connect(mapStateProps, {getBankAllocatedLoans})(BankLoans);
