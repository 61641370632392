import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import ViewTelco from "./pages/telcos/ViewTelco";
import ViewInsurance from "./pages/insurances/ViewInsurance";
import ViewBank from "./pages/banks/ViewBank";
import {Breadcrumb, Button, Card} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import authorization from "../../helpers/authorization";
import useRoute from "../../helpers/useRoute";

const renderComponents = (code, id) => {
    switch (code) {
        case 'banks':
            return (<ViewBank id={id} partnerType={code} code={'B'}/>);
        case 'insurances':
            return (<ViewInsurance id={id} partnerType={code} code={'IN'}/>);
        case 'telcos':
            return (<ViewTelco id={id} partnerType={code} code={'T'}/>);
        default:
            return (<></>);
    }
}

const PartnerView =  (props) => {
    const { id, code: partnerType } = props.match.params;
    const url = useRoute(Routes.AddPartner.path, {code: partnerType});

    //
    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item href={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome}/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>View of {partnerType}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>View {partnerType !== "" ? ` ${partnerType}` : ""}</h4>
                </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="">
                    {renderComponents(partnerType, id)}
                </Card.Body>
            </Card>
        </>
    );
}

const mapStateProps = (state) => {
    return {};
}

export default connect(mapStateProps, {})(PartnerView);