import React from 'react';
import { Breadcrumb, Button, Card, Form, Row } from "@themesberg/react-bootstrap";
import { MDBRow } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Routes } from '../../routes';
import authorization from '../../helpers/authorization';
import { useState } from 'react';
import { BeatLoader } from "react-spinners";
import { connect } from 'react-redux';
import { InsertMultipleManualPaynent } from '../../actions/manual_repayments';
import { getUser } from '../../helpers/tokenHelper';


function CustomerPaymentLogMultiple({ postMultipleManualPaynentPayload, loading }) {
    const [selectedFile, setSelectedFile] = useState(null);

    // To handle file chnage 
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0])
    }

    // To handle form submission 
    const handleMultipleUpload = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('excelData', selectedFile);

        const payload = {
            formData,
            'AdminUserID': `${getUser()?.id}`
        }


        postMultipleManualPaynentPayload(payload)
        console.log("Submitting form...payload:", payload)
    }

    return (
        <div className='py-4'>
            {/* Header - starts  */}
            <Breadcrumb className="d-none d-md-inline-block"
                listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                    icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item active>Customer Payment Log</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ marginTop: "2%" }}>
                <h5 style={{ marginBottom: "2%" }}>Multiple Payment Upload</h5>

                <Card border="light" className="custom-class">
                    <Card.Body>
                        <Form onSubmit={handleMultipleUpload}>
                            <Row className="align-items-center col ">
                                <Form.Group id="file">
                                    <Form.Label>Kindly Select An Excel File With Cells In The Name Format Below:</Form.Label>
                                    <h6 style={{margin: "10px 0"}}>serialNumber | from_msisdn | totalAmount | datepaid | reference | paymentMethod</h6>
                                    <Form.Control
                                        required
                                        type="file"
                                        name="file"
                                        onChange={handleFileChange}
                                        placeholder="Upload excel file"

                                    />
                                </Form.Group>
                            </Row>
                            <div className="mt-3">
                                <Button variant="primary"
                                    type="submit"
                                    disabled={loading || !authorization("MANUAL_REPAYMENT")}>
                                    {loading ? <BeatLoader color="#fff" /> : "Upload"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => ({
    postMultipleManualPaynentPayload: (payload) => dispatch(InsertMultipleManualPaynent(payload))
})

const mapStateToProps = (store) => {
    const { loading } = store?.manualRepayments;
    return { loading };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentLogMultiple);