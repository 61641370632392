import {
    CREATE_CLIENT,
    ERROR_CLIENTS,
    EXTEND_CLIENT_DEVICE,
    GET_CLIENT,
    GET_CLIENT_TRANSACTION_LOG,
    GET_CLIENT_DEVICES,
    GET_CLIENT_FILE,
    GET_CLIENT_LOANS,
    GET_CLIENTS,
    GET_CLIENTS_TYPES,
    LEGIBILITY_CHECK,
    LOCK_CLIENT_DEVICE,
    OPEN_CLIENT_DEVICE, PROVISIONING,
    STATUS_CLIENT_DEVICE,
    UNLOCK_CLIENT_DEVICE
} from "../../actions/types";

const initState = {
    error: null,
    clients: null,
    size: 0,
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
}
const clientsReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_CLIENTS:
            return {
                ...state,
                error: null,
                clients: action.payload.content ?? action.payload,
                total: action.payload.length ?? 0,
                size: action.payload.size ?? 100,
                totalPages: action.payload.totalPages ?? 1,
                totalElements: action.payload.totalElements ?? 0,
                numberOfElements: action.payload.numberOfElements ?? 0,
                currentPage: action.payload.number,
                first: action.payload.first ?? false,
                last: action.payload.last ?? false,
            }
        case GET_CLIENT:
            return {
                ...state,
                error: null,
                client: action.payload,
            }
        case UNLOCK_CLIENT_DEVICE:
        case LOCK_CLIENT_DEVICE:
        case OPEN_CLIENT_DEVICE:
        case STATUS_CLIENT_DEVICE:
        case EXTEND_CLIENT_DEVICE:
            return {
                ...state,
                error: null,
                deviceAction: action.payload,
            }
        case GET_CLIENT_TRANSACTION_LOG:
            return {
                ...state,
                error: null,
                transactions: action.payload,
            }
        case GET_CLIENT_DEVICES:
            return {
                ...state,
                clientDevices: action.payload,
                error: null,
            }
        case GET_CLIENT_FILE:
            return {
                ...state,
                clientFile: action.payload,
                error: null,
            }
        case GET_CLIENT_LOANS:
            return {
                ...state,
                clientLoans: action.payload,
                error: null,
            }
        case CREATE_CLIENT:
            return {
                ...state,
                error: null,
                client: action.payload,
            }
        case GET_CLIENTS_TYPES:
            return {
                ...state,
                types: action.payload,
                error: null,
            }
        case LEGIBILITY_CHECK:
            return {
                ...state,
                legibility: action.payload,
            }
        case PROVISIONING:
            return {
                ...state,
                provisioning: action.payload,
            }
        case ERROR_CLIENTS:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}
export default clientsReducer;
