import React, {Component} from "react";
import {Accordion, Badge, Button, Card, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";

const MessageItem = (props) => {
    return (
        <>
            <div className={'mb-2 shadow-1'}>
                <Badge bg={'primary'} className={'rounded-0 rounded-top py-2'}>
                    November 30, 2021 07:00 AM
                </Badge>
                <p id={'message-11'} className={'border p-2 rounded-bottom'}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat.
                </p>
            </div>
        </>
    );
}

class DevicesNotification extends Component {
    state = {
        loading: false,
        showModal: false,
    }

    handleClose = () => {
        this.setState({showModal: false,});
    };
    handleShow = () => {
        this.setState({showModal: true,});
    };

    render() {
        const {showModal,} = this.state;
        //
        return (
            <>
                <Card border="light" className="table-wrapper shadow-sm">
                    <Card.Body className="">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Row>
                                        Device #1: SUMSANG S10
                                    </Row>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12} className={'d-flex justify-content-end align-items-center'}>
                                            <Button className={'pull-right'}
                                                    onClick={this.handleShow}
                                                    size={'sm'}>Send Message</Button>
                                        </Col>
                                        <Col className={'mt-4'}>
                                            {
                                                [1, 2,].map((i, v) => <MessageItem/>)
                                            }
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Body>
                </Card>
                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="notificationForm.subject">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type="text" placeholder="Subject"/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="notificationForm.content">
                                <Form.Label>Content</Form.Label>
                                <Form.Control as="textarea" rows={3}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
                        <Button variant="primary" onClick={this.handleClose}>Send Notification</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default DevicesNotification;
