import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import {createPermission} from "../../actions/roles";
import authorization from "../../helpers/authorization";

class CreatePermission extends Component {
    state = {
        loading: false,
        roleCode: "",
        roleName: "",
        description: "",
        rolestatus: "",
    }

    componentDidMount = async () => {

    }

    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }

    submit = async (e) => {
        e.preventDefault();
        const {roleCode, roleName, description, rolestatus,} = this.state;
        const {createPermission,} = this.props;
        this.setState({loading: true,});
        const create = await createPermission({
            roleCode,
            roleName,
            description,
            rolestatus: parseInt(rolestatus),
        });
        if (create !== false) {
            // history.push({
            //     pathname: Routes.GroupsRoles.path,
            //     state: {
            //         selectedTab: "roles",
            //     },
            // });
        }
        this.setState({loading: false,});
    }

    render() {
        const {roleCode, roleName, description, rolestatus, loading,} = this.state;
        const {error} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.GroupsRoles.path}>Roles</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Roles</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Create Role</h4>
                    </div>
                </div>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">Create a role</h5>
                        {error ? (<div className="text-danger mb-2">{error}</div>) : ""}
                        <Form onSubmit={this.submit}>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="name">
                                        <Form.Label>Role Code</Form.Label>
                                        <Form.Control required type="text" name="roleCode" onChange={this.handleChange}
                                                      value={roleCode} placeholder="Role Code"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="surname">
                                        <Form.Label>Role Name</Form.Label>
                                        <Form.Control required type="text" name="roleName" onChange={this.handleChange}
                                                      value={roleName} placeholder="Role Name"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="name">
                                        <Form.Label>Role Description</Form.Label>
                                        <Form.Control required as={'textarea'} type={'text'} name="description"
                                                      onChange={this.handleChange}
                                                      value={description} placeholder="Description"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className={'align-items-center col '}>
                                    <Form.Group id="rolestatus">
                                        <Form.Label>Role Status</Form.Label>
                                        <Form.Select name={'rolestatus'}
                                                     onChange={this.handleChange}
                                                     aria-label="Select role">
                                            <option value="" disabled={true} selected={true}>Select:.</option>
                                            <option value="1" selected={rolestatus === "1"}>Active</option>
                                            <option value="0" selected={rolestatus === "0"}>Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <div className="mt-3">
                                <Button variant="primary" type="submit"
                                        disabled={loading || !authorization("RO_CREATE")}>
                                    {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {role, error} = state.roles;
    return {role, error,};
}
//
export default connect(mapStateProps, {createPermission})(CreatePermission);
