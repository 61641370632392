import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumb, Button, Card, Dropdown, Form, FormControl, InputGroup, Table} from '@themesberg/react-bootstrap';
import {connect} from "react-redux";
import getAllUsersAction from "../../actions/user/getAllUsersAction";
import {BeatLoader} from "react-spinners";
import {Routes} from "../../routes";
import FilterUsers from "./components/FilterUsers";
import authorization from "../../helpers/authorization";
import {faGripVertical} from "@fortawesome/free-solid-svg-icons/faGripVertical";

export class Users extends Component {
    state = {
        loading: false,
        items: null,
    };
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getAllUsersAction,} = this.props;
        await getAllUsersAction();
        this.setState({loading: false});
    }
    //
    onLoading = (state = false) => {
        this.setState({loading: state});
    }

    //
    onChanges = async (e, isType = false) => {
        if (isType === false) {
            e.preventDefault();
        }
        const {data} = this.props;
        if (e.target.value) {
            const items = data?.filter((item, i) => {
                if (isType === false) {
                    const _s = `${item.username} ${item.surname} ${item.msisdn} ${item.name} ${item.email}`.toLowerCase();
                    return _s.includes(`${e.target.value}`.toLowerCase());
                } else {
                    const byName = `${item.userpartnertyype?.toLowerCase()}` === `${e.target.value.name}`.toLowerCase();
                    const byCode = `${item.userpartnertyype?.toLowerCase()}` === `${e.target.value.code}`.toLowerCase();
                    return byCode || byName;
                }
            });
            this.setState({items: items,});
        } else {
            this.setState({items: data,});
        }
    }

    //
    render() {
        const {loading, items} = this.state;
        const {data,} = this.props;
        //
        let _items = items ?? data;
        //
        const TableRow = (props) => {
            const {userid, username, name, surname, msisdn, email, userStatus, userpartnertyype,} = props.user;
            const {index} = props;
            const bounceTxtColor = userStatus !== 'active' ? "text-danger" : "text-success";
            const isRep = userpartnertyype === 'representative';
            return (
                <tr>
                    <th className={'text-center'}>{index}</th>
                    <th scope="row">
                        <a href={authorization("US_READ") ? `/users/${userid}/details` : ""}>
                            {name} {surname}
                        </a>
                    </th>
                    <td>{username}</td>
                    <td>{msisdn}</td>
                    <td>{email}</td>
                    <td className={bounceTxtColor}>
                        {userStatus}
                    </td>
                    <td>
                        <Dropdown className="float-right">
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className="btn-sm" bsPrefix>
                                <FontAwesomeIcon icon={faGripVertical} title="More action"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    authorization("US_EDIT") ? (
                                        <>
                                            <Dropdown.Item href={`/users/${userid}/edit`}>Edit</Dropdown.Item>
                                            {
                                                !isRep && (
                                                    <>
                                                        <Dropdown.Item href={`/users/${userid}/mark-as-representative`}
                                                                       disabled={isRep}>
                                                            Mark as Rep
                                                        </Dropdown.Item>
                                                    </>
                                                )
                                            }
                                        </>
                                    ) : (<></>)
                                }
                                {
                                    authorization("US_DELETE") ? (
                                        <>
                                            <Dropdown.Item>Delete</Dropdown.Item>
                                        </>
                                    ) : (<></>)
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        };

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Users</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>All users</h4>
                    </div>
                </div>
                {
                    authorization("US_CREATE") ? (
                        <>
                            <Button variant="primary" href={Routes.CreateUser.path} className="mb-3">Add User</Button>
                        </>
                    ) : (<></>)
                }
                <FilterUsers loading={loading} data={_items} onChange={this.onChanges} onLoading={this.onLoading}/>
                <Card border="light" className="shadow-sm">
                    <Card.Body>
                        <Form>
                            <InputGroup className="mb-3">
                                <FormControl
                                    name={'search'}
                                    onChange={this.onChanges}
                                    placeholder="Search"
                                    aria-label="Search"/>
                            </InputGroup>
                        </Form>
                        <Table responsive className="align-items-center" bordered striped>
                            <thead className="thead-light">
                            <tr>
                                <th className={'text-center'}>#</th>
                                <th scope="col">Names</th>
                                <th scope="col">Username</th>
                                <th scope="col">MSISDN</th>
                                <th scope="col">Email</th>
                                <th scope="col">User Status</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="7" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : _items != null && _items.length > 0 ?
                                    _items.map((user, i) => <TableRow user={user}
                                                                      key={`page-visit-${i}`}
                                                                      index={++i}/>) : (
                                        <tr>
                                            <td colSpan="7" align="center">No users available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapState = (state) => {
    const {data, error, total} = state.users;
    return {
        data, error, total,
    };
}
export default connect(mapState, {getAllUsersAction})(Users);
