import {GET_USER_ACTIVITY_LOGS, GET_USER_DETAILS, GET_USERS, USER_ERROR} from "../../actions/types";

const initials = {
    data: null,
    error: null,
    total: 0,
};
const getUsersReducer = (state = initials, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                data: action.payload,
                error: null,
                total: action.payload.length ?? 0,
            }
        case GET_USER_DETAILS:
            return {
                ...state,
                user: action.payload,
                error: null,
            }
        case GET_USER_ACTIVITY_LOGS:
            return {
                ...state,
                logs: action.payload,
                error: null,
            }
        case USER_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state;
    }
}
export default getUsersReducer;