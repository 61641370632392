import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFilter} from "@fortawesome/free-solid-svg-icons";
import {Button, Dropdown} from '@themesberg/react-bootstrap';

export default () => {
    return (
        <>
            <div className="pull-right">
                <Dropdown style={{marginLeft: 1000, marginBottom: 30, marginTop: -50}}>
                    <Dropdown.Toggle as={Button} variant="primary">
                        <FontAwesomeIcon icon={faFilter} className="me-2"/> Status Filter
                        <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                        <Dropdown.Item>
                            Urgent
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Open
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Closed
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Resolved
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Pending
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
};