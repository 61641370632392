import React, {Component} from "react";
import {Card, Table} from "@themesberg/react-bootstrap";

class DetailsInfo extends Component {

    render() {
        const {client} = this.props;
        return (
            <>
                <Card border="light" className="table-wrapper shadow-sm">
                    <Card.Body className="">
                        <Table striped bordered>
                            <tbody>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Customer Type</td>
                                <td className={'py-1'}><b>{client && `${client.customerType}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Name</td>
                                <td className={'py-1'}><b>{client && `${client.name ?? ""} ${client.surname ?? ""}`}</b>
                                </td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>MSISDN</td>
                                <td className={'py-1'}><b>{client && `${client.msisdn ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Email</td>
                                <td className={'py-1'}><b>{client && `${client.email ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Address</td>
                                <td className={'py-1'}><b>{client && `${client.address ?? ""}`}</b></td>
                            </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default DetailsInfo;