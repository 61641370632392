import React from "react";
import {Button, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import {connect} from "react-redux";
import createUserAction from "../actions/user/createUserAction";


export class GeneralInfoForm extends React.Component {
    state = {
        error: false,
        loading: false,
        username: '',
        password: '',
        names: '',
        email: '',
        phone: '',
    };

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    };

    createUser = async (e) => {
        this.setState({loading: true});
        e.preventDefault();

    }

    componentDidMount() {
        console.log(this.props);
    }

    render() {
        return (
            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                    <h5 className="mb-4">User information</h5>
                    <Form>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group id="username">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter username"/>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group id="password">
                                    <Form.Label>password</Form.Label>
                                    <Form.Control required type="password" placeholder="Enter password"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Form.Group id="password">
                                <Form.Label>Names</Form.Label>
                                <Form.Control required type="text" placeholder="Enter Names"/>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group id="emal">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="name@intelligra.io"/>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group id="phone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control required type="number" placeholder="+250 788 327 447"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <Button variant="primary" type="submit">Submit</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        )
    }
}

//
const mapStateToProps = (state) => {
    const {isLoggedIn} = state.authentication;
    const {user} = state.profile;
    const {data, error} = state.createUser;
    return {
        isLoggedIn,
        user,
        data, error,
    };
};

export default connect(mapStateToProps, {createUserAction})(GeneralInfoForm);
