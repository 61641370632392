import React, {Component} from "react";
import {Routes} from "../../routes";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import {connect} from "react-redux";
import {createClientAction} from "../../actions/clients";

export class CreateClient extends Component {

    state = {
        loading: false,
        submitted: false,
        hasError: false,
    };
    //
    componentDidMount = () => {
        //
    }
    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    submit = async (e) => {
        e.preventDefault();
        this.setState({loading: true, submitted: true,});
        //
        const {createClientAction, history} = this.props;
        const {
            surname, name, msisdn, address, gender, martial_status, national_id_number,
            identification_type, province, district, sector, cell, village, email, customerType,
            dob, language,
        } = this.state;
        //
        const create = await createClientAction({
            surname, name, msisdn, address, gender, martial_status, national_id_number,
            identification_type, province, district, sector, cell, village, email, customerType,
            dob, language,
        });
        //
        this.setState({loading: true});
        if (create) {
            history.push(Routes.Clients.path);
        }
        this.setState({hasError: true});
    }

    render() {
        const {
            surname, name, msisdn, address, gender, martial_status, national_id_number,
            identification_type, province, district, sector, cell, village, email, customerType,
            dob, language, loading, submitted,
        } = this.state;
        //
        const {
            error,
        } = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Clients.path}>Clients</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Client</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Add client</h4>
                    </div>
                </div>
                <Button variant="primary" href={Routes.Clients.path} className="m-1">All Clients</Button>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">Add client information</h5>
                        {error ? (<div className="text-danger">{error}</div>) : ""}
                        <Form onSubmit={this.submit}>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="text" name="name" onChange={this.handleChange}
                                                      value={name} placeholder="Name"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="surname">
                                        <Form.Label>Surname</Form.Label>
                                        <Form.Control required type="text" name="surname" onChange={this.handleChange}
                                                      value={surname} placeholder="Surname"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="msisdn">
                                        <Form.Label>MSISDN</Form.Label>
                                        <Form.Control required type="text" name="msisdn" onChange={this.handleChange}
                                                      value={msisdn} placeholder="MSISDN"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="national_id_number">
                                        <Form.Label>National ID</Form.Label>
                                        <Form.Control type="text" name="national_id_number"
                                                      onChange={this.handleChange}
                                                      value={national_id_number} placeholder="National ID"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="identification_type">
                                        <Form.Label>Identification Type</Form.Label>
                                        <Form.Control type="text" name="identification_type"
                                                      onChange={this.handleChange}
                                                      value={identification_type} placeholder="Identification Type"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" onChange={this.handleChange}
                                                      value={email} placeholder="Email"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="surname">
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Select name={'gender'} readOnly={loading || submitted}
                                                     onChange={this.handleChange}
                                                     aria-label="Select gender">
                                            <option value="" disabled={true} selected={true}>Select:.</option>
                                            <option value="M" selected={gender === 'M'}>Male</option>
                                            <option value="F" selected={gender === 'F'}>Female</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id={'martial_status'}>
                                        <Form.Label>Marital Status</Form.Label>
                                        <Form.Select aria-label="Select marital status" name={"martial_status"}
                                                     readOnly={loading || submitted} onChange={this.handleChange}>
                                            <option value="" disabled={true} selected={true}>Select:.</option>
                                            <option value="M" selected={martial_status === 'M'}>Married</option>
                                            <option value="S" selected={martial_status === 'S'}>Single</option>
                                            <option value="W" selected={martial_status === 'W'}>Widower/Window</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="address">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" name="address" onChange={this.handleChange}
                                                      value={address} placeholder="Address"/>
                                    </Form.Group>
                                </Row>

                                <Row className="align-items-center col ">
                                    <Form.Group id="customerType">
                                        <Form.Label>Customer Type</Form.Label>
                                        <Form.Control type="text" name="customerType"
                                                      onChange={this.handleChange}
                                                      value={customerType} placeholder="Customer Type"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="dob">
                                        <Form.Label>DOB</Form.Label>
                                        <Form.Control type="date" name="dob"
                                                      onChange={this.handleChange}
                                                      value={dob} placeholder="DOB"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="language">
                                        <Form.Label>Language</Form.Label>
                                        <Form.Control type="text" name="language"
                                                      onChange={this.handleChange}
                                                      value={language} placeholder="language"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="province">
                                        <Form.Label>Province</Form.Label>
                                        <Form.Control required type="text" name="province" onChange={this.handleChange}
                                                      value={province} placeholder="Province"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="district">
                                        <Form.Label>District</Form.Label>
                                        <Form.Control required type="text" name="district"
                                                      onChange={this.handleChange}
                                                      value={district} placeholder="District"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="sector">
                                        <Form.Label>Sector</Form.Label>
                                        <Form.Control required type="text" name="sector"
                                                      onChange={this.handleChange}
                                                      value={sector} placeholder="Sector"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="cell">
                                        <Form.Label>Cell</Form.Label>
                                        <Form.Control required type="text" name="cell"
                                                      onChange={this.handleChange}
                                                      value={cell} placeholder="Cell"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="village">
                                        <Form.Label>Village</Form.Label>
                                        <Form.Control required type="text" name="village"
                                                      onChange={this.handleChange}
                                                      value={village} placeholder="Village"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <div className="mt-3">
                                <Button variant="primary" type="submit" disabled={loading || submitted}>
                                    {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {error, client} = state.clients;
    return {error, client};
}
export default connect(mapStateProps, {createClientAction})(CreateClient);
