import {action} from "../index";
import {GET_CELL, GET_DISTRICT, GET_PROVINCES, GET_SECTOR, GET_VILLAGE, PLACES_ERROR} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";

/**
 * Get country provinces
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getProvinces = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/regulator/provinces`, {
            headers,
        });
        dispatch(action(GET_PROVINCES, req.data?.body));
    } catch (e) {
        dispatch(action(PLACES_ERROR, e.message));
    }
}

/**
 * Get districts
 *
 * @param provincecode
 * @returns {(function(*): Promise<void>)|*}
 */
export const getDistricts = (provincecode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.post(`/intelligrapi/regulator/districts`, {
            provincecode,
        }, {
            headers,
        });
        dispatch(action(GET_DISTRICT, req.data?.body));
    } catch (e) {
        dispatch(action(PLACES_ERROR, e.message));
    }
}

/**
 * Get sectors
 *
 * @param districtcode
 * @returns {(function(*): Promise<void>)|*}
 */
export const getSectors = (districtcode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.post(`/intelligrapi/regulator/sectors`, {
            districtcode,
        }, {
            headers,
        });
        dispatch(action(GET_SECTOR, req.data?.body));
    } catch (e) {
        dispatch(action(PLACES_ERROR, e.message));
    }
}

/**
 * Get cells
 * @param sectorcode
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCells = (sectorcode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.post(`/intelligrapi/regulator/cells`, {
            sectorcode,
        }, {
            headers,
        });
        dispatch(action(GET_CELL, req.data?.body));
    } catch (e) {
        dispatch(action(PLACES_ERROR, e.message));
    }
}

/**
 * Get villages
 *
 * @param cellcode
 * @returns {(function(*): Promise<void>)|*}
 */
export const getVillages = (cellcode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.post(`/intelligrapi/regulator/villages`, {
            cellcode,
        }, {
            headers,
        });
        dispatch(action(GET_VILLAGE, req.data?.body));
    } catch (e) {
        dispatch(action(PLACES_ERROR, e.message));
    }
}
