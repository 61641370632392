import React, {Component} from "react";
import {Card, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import {connect} from "react-redux";

const TableRow = (props) => {
    const {
        index,
        surname,
        name,
        msisdn,
        address,
        gender,
        martial_status,
        national_id_number,
        memberEmail,
        status,
    } = props;
    return (
        <>
            <tr>
                <td>
                    <Card.Link href="#" className="text-primary fw-bold">{(index)}</Card.Link>
                </td>
                <td>{`${name} ${surname}`}</td>
                <td>{msisdn}</td>
                <td>{national_id_number}</td>
                <td>{memberEmail}</td>
                <td>{address}</td>
                <td>{gender}</td>
                <td>{martial_status}</td>
                <td>{status}</td>
                <td>&nbsp;</td>
            </tr>
        </>
    );
}

class Members extends Component {
    state = {
        loading: false,
    }
    componentDidMount = async () => {
        this.setState({loading: true,});
        const {corporateId, getCorporateMembers} = this.props;
        await getCorporateMembers(corporateId);
        this.setState({loading: false,});
    }

    render() {
        const {loading} = this.state;
        const {members} = this.props;
        return (
            <>
                <Card>
                    <Card.Body className={'overflow-auto'}>
                        <Table responsive className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Name</th>
                                <th className="border-0">MSISDN</th>
                                <th className="border-0">NationalID/Passport</th>
                                <th className="border-0">E-Mail</th>
                                <th className="border-0">Address</th>
                                <th className="border-0">Gender</th>
                                <th className="border-0">Martial Status</th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : members != null && members.length > 0 ?
                                    members.map(
                                        (member, i) => <TableRow {...member} key={`page-visit-${i}`} index={++i}/>
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">No member yet !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, members} = state.corporates;
    return {error, members};
}
//
export default connect(mapStateProps, {})(Members);
