import React, {Component} from "react";
import {connect} from "react-redux";
import {getCorporateUsers} from "../../../actions/corporates";
import {Card, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";

const TableRow = (props) => {
    const {
        index, surname, name, msisdn, username, userstatus, email,
    } = props;
    return (
        <>
            <tr>
                <td>
                    <Card.Link href="#" className="text-primary fw-bold">{(index)}</Card.Link>
                </td>
                <td>{`${name} ${surname}`}</td>
                <td>{username}</td>
                <td>{msisdn}</td>
                <td>{email}</td>
                <td>{userstatus}</td>
                <td>&nbsp;</td>
            </tr>
        </>
    );
}

class Users extends Component {
    state = {
        loading: false,
    }
    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getCorporateUsers, corporateId} = this.props;
        await getCorporateUsers(corporateId);
        this.setState({loading: false,});
    }

    render() {
        const {loading} = this.state;
        const {users} = this.props;

        return (
            <>
                <Card>
                    <Card.Body className={'overflow-auto'}>
                        <Table responsive className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Name</th>
                                <th className="border-0">Username</th>
                                <th className="border-0">MSISDN</th>
                                <th className="border-0">E-Mail</th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : users != null && users.length > 0 ?
                                    users.map(
                                        (user, i) => <TableRow {...user} key={`page-visit-${i}`} index={++i}/>
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">No users yet !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, users} = state.corporates;
    return {error, users};
}
//
export default connect(mapStateProps, {getCorporateUsers})(Users);
