import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumb, Button, Card, Col, Form, Row} from '@themesberg/react-bootstrap';
import {connect} from "react-redux";
import {Routes} from "../../routes";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import {createStores, getStoreDetails, updateStores} from "../../actions/stores";
import authorization from "../../helpers/authorization";
import RwandaBorders from "../components/forms/RwandaBorders";
import Preloader from "../../components/Preloader";

export class CreateStore extends Component {
    state = {
        storeId: this.props.match.params.id,
        loading: true,
        name: null,
        fulladress: null,
        location: null,
        owner_name: null,
        owner_tin: null,
        mobileMoneyAccount: null,
        msisdn: null,
        email: null,
        representattives: null,
        province: null,
        district: null,
        sector: null,
        cell: null,
        village: null,
        is_mtn: false,
        provinceId: null,
        districtId: null,
        sectorId: null,
        cellId: null,
        villageId: null,
    };

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {storeId} = this.state;
        if (storeId) {
            const {getStoreDetails, data} = this.props;
            await getStoreDetails(storeId);
            if (data) {
                this.setState({
                    ...data,
                });
            }
        }
        this.setState({loading: false,});
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const {storeId, owner_name} = prevState;
        const {data} = this.props;
        if (storeId && owner_name == null) {
            if (data) {
                this.setState({
                    ...data,
                });
            }
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        const _value = value.toString().split(':');
        if (_value.length > 1) {
            const _name = _value[1] ?? null;
            const _id = _value[0] ?? value;
            this.setState(() => ({
                [name]: _name,
                [`${name}Id`]: _id,
            }));
        } else {
            this.setState(() => ({
                [name]: value,
            }));
        }
    }

    submit = async (e) => {
        this.setState({loading: true});
        e.preventDefault();
        const {
            name, fulladress, location,
            owner_name, owner_tin, mobileMoneyAccount,
            msisdn, email, representattives, storeId,
            province, district, sector, cell, village,
            is_mtn, provinceId, districtId, sectorId, villageId,
        } = this.state;
        const {createStores, updateStores,} = this.props;
        let save = false;
        if (storeId) {
            save = await updateStores({
                name, fulladress, location,
                owner_name, owner_tin, mobileMoneyAccount,
                msisdn, email, representattives, storeId,
                province, district, sector, cell, village,
                is_mtn, provinceId, districtId, sectorId, villageId,
            });
        } else {
            save = await createStores({
                name, fulladress, location,
                owner_name, owner_tin, mobileMoneyAccount,
                msisdn, email, representattives,
                province, district, sector, cell, village,
                is_mtn, provinceId, districtId, sectorId, villageId,
            });
        }
        this.setState({loading: false});
        if (save) {
            const {history} = this.props;
            history.push(Routes.Stores.path);
        }
    }

    render() {
        const {error} = this.props;
        const {
            loading, name, fulladress, location, owner_name, owner_tin, mobileMoneyAccount, msisdn, email,
            storeId, province, district, sector, cell, village,
        } = this.state;
        return (
            <>
                {loading ? (
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Preloader show={loading}/>
                        </Col>
                    </Row>
                ) : (<></>)
                }
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>stores</Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all stores</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4> {storeId ? "Update" : "Add"} stores</h4>
                    </div>
                </div>
                <Button variant="primary" href={Routes.Stores.path} className="m-1">All store</Button>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">{storeId ? "Update" : "Add"} store information</h5>
                        {error ? (<div className="text-danger">{error}</div>) : ""}
                        <Form onSubmit={this.submit}>
                            <MDBRow>
                                <Row className="align-items-center col mb-2">
                                    <Form.Group id="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="text" name="name" onChange={this.handleChange}
                                                      value={name} placeholder="Name"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col mb-2">
                                    <Form.Group id="owner_name">
                                        <Form.Label>Owner Name</Form.Label>
                                        <Form.Control type="text" name="owner_name"
                                                      onChange={this.handleChange}
                                                      value={owner_name} placeholder="Owner Name"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={"mb-2"}>
                                <Row className="align-items-center col">
                                    <Form.Group id="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" onChange={this.handleChange}
                                                      value={email} placeholder="E-Mail"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="fulladress">
                                        <Form.Label>Full Address</Form.Label>
                                        <Form.Control type="text" name="fulladress" onChange={this.handleChange}
                                                      value={fulladress} placeholder="Full address"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={"mb-2"}>
                                <Row className="align-items-center col">
                                    <Form.Group id="owner_tin">
                                        <Form.Label>Owner Tin</Form.Label>
                                        <Form.Control type="number" name="owner_tin"
                                                      onChange={this.handleChange}
                                                      value={owner_tin} placeholder="Owner Tin"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="msisdn">
                                        <Form.Label>MSISDN</Form.Label>
                                        <Form.Control type="text" name="msisdn" onChange={this.handleChange}
                                                      value={msisdn} placeholder="MSISDN"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="mobileMoneyAccount">
                                        <Form.Label>Mobile Money Account</Form.Label>
                                        <Form.Control type="text" name="mobileMoneyAccount" onChange={this.handleChange}
                                                      value={mobileMoneyAccount} placeholder="Mobile Money Account"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={"mb-2"}>
                                <Row className="align-items-center col">
                                    <Form.Group id="location">
                                        <Form.Label>Location</Form.Label>
                                        <Form.Control type="text" name="location" onChange={this.handleChange}
                                                      value={location} placeholder="Location"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            {
                                !loading ? (
                                    <>
                                        <RwandaBorders province={province} district={district} sector={sector}
                                                       cell={cell} village={village} handleChange={this.handleChange}/>
                                    </>
                                ) : (<></>)
                            }
                            <div className="mt-3">
                                <Button variant="primary"
                                        type="submit"
                                        disabled={loading || (!authorization(`ST_${storeId ? 'EDIT' : 'CREATE'}`))}>
                                    {loading ? <BeatLoader color="#fff"/> : storeId ? "Update" : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {data, error} = state.store;
    return {data, error};
}
//
export default connect(mapStateProps, {createStores, updateStores, getStoreDetails})(CreateStore);