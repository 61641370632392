import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumb, Button, Card, Form, FormControl, InputGroup, Table} from '@themesberg/react-bootstrap';
import {Routes} from "../../routes";
import {BeatLoader} from "react-spinners";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import authorization from "../../helpers/authorization";
import {connect} from "react-redux";
import {getAllStores} from "../../actions/stores";

export class Stores extends Component {
    state = {
        loading: false,
        show: false,
        modalTitle: null,
        selectedItem: null,
        modalAction: null,
        items: null,
    };

    componentDidMount = async () => {
        this.setState({loading: true});
        const {getAllStores} = this.props;
        await getAllStores();
        this.setState({loading: false});
    }
    //
    onChanges = async (e) => {
        e.preventDefault();
        const {data} = this.props;
        if (e.target.value) {
            const items = data?.filter((item, i) => {
                const _s = `${item.name} ${item.owner_name} ${item.msisdn} ${item.mobileMoneyAccount} ${item.msisdn} ${item.email} ${item.province} ${item.district} ${item.sector} ${item.cell} ${item.village}`.toLowerCase();
                return _s.includes(`${e.target.value}`.toLowerCase());
            });
            this.setState({items: items,});
        } else {
            this.setState({items: data,});
        }
    }
    //
    handleClose = () =>
        this.setState({show: false, modalTitle: null, selectedItem: null, modalAction: null, item: null});
    handleShow = (modalTitle, selectedItem, modalAction, items) =>
        this.setState({show: true, modalTitle, selectedItem, modalAction, items});

    render() {
        const {loading, items,} = this.state;
        //
        const {data,} = this.props;
        //
        let _items = items ?? data;
        //
        const TableRow = (props) => {
            const {
                storeId, name, owner_name, msisdn,
                province, district,
            } = props;

            return (
                <tr>
                    <td>
                        <Card.Link href="#" className="text-primary fw-bold">{storeId}</Card.Link>
                    </td>
                    <td className="fw-bold">
                        <a href={authorization("ST_READ") ? `/partners/stores/${storeId}/details` : "#"}>
                            {name}
                        </a>
                    </td>
                    <td>{owner_name}</td>
                    {/*<td>{owner_tin}</td>*/}
                    <td>{`${province ?? ""} - ${district ?? ""}`}</td>
                    {/*<td>{mobileMoneyAccount}</td>*/}
                    <td>{msisdn}</td>
                    {/*<td>{email}</td>*/}
                    <td>
                        <Button variant="light" size="sm"
                                disabled={!authorization("ST_READ")}
                                href={`/partners/stores/${storeId}/details`}>
                            <FontAwesomeIcon icon={faEye} className={`icon icon-xs text-success cursor-pointer`}/>
                        </Button>&nbsp;&nbsp;&nbsp;
                        {
                            authorization("ST_EDIT") ? (
                                <>
                                    <Button variant="light" size="sm" href={`/partners/stores/${storeId}/edit`}>
                                        <FontAwesomeIcon icon={faEdit}
                                                         className={`icon icon-xs text-info cursor-pointer`}/>
                                    </Button>
                                </>
                            ) : (<></>)
                        }
                    </td>
                </tr>
            );
        };
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>Stores</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4> All stores</h4>
                    </div>
                </div>
                {
                    authorization("ST_CREATE") ? (
                        <>
                            <Button variant="primary" href={Routes.CreateStore.path} className="m-1">Add
                                Store</Button>
                        </>
                    ) : (<></>)
                }
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Form>
                            <InputGroup className="mb-3">
                                <FormControl
                                    name={'search'}
                                    onChange={this.onChanges}
                                    placeholder="Search"
                                    aria-label="Search"/>
                            </InputGroup>
                        </Form>
                        <Table responsive
                               striped
                               className="table-centered table-striped table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Name</th>
                                <th className="">Owner Name</th>
                                {/*<th className="">Owner Tin</th>*/}
                                <th className="">Province/District</th>
                                {/*<th className="">Mobile Money Account</th>*/}
                                <th className="">MSISDN</th>
                                {/*<th className="">email</th>*/}
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : _items != null && _items.length > 0 ?
                                    _items.map(store => <TableRow {...store}
                                                                  key={`page-visit-${store.storeId}`}/>) : (
                                        <tr>
                                            <td colSpan="10" align="center">No store available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const
    mapStateProps = (state) => {
        const {data, error, total} = state.store;
        return {
            data, error, total,
        };
    }
export default connect(mapStateProps, {getAllStores})(Stores);