import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";
import {action} from "../index";
import {ACCOUNTING_ERROR, GET_ACCOUNTING_HISTORY, GET_ACCOUNTING_NUMBER} from "../types";


/**
 * Get account number
 *
 * @param partnerId
 * @param accountTypeCode
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAccountingNumber = (partnerId, accountTypeCode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/financials/partneraccount?partnerid=${partnerId}&&accounttypecode=${accountTypeCode}`, {
            headers,
        });
        const _dt = req.data[0];
        dispatch(action(GET_ACCOUNTING_NUMBER, _dt ? _dt.account : null));
    } catch (e) {
        dispatch(action(GET_ACCOUNTING_NUMBER, null));
        dispatch(action(ACCOUNTING_ERROR, e.message));
    }
}

/**
 * Get accounting history for a particular account
 *
 * @param accountNumber
 * @returns {(function(*): Promise<*>)|*}
 */
export const getAccountingHistory = (accountNumber) => async (dispatch) => {
    try {
        // const token = getToken();
        // const headers = {
        //     Authorization: "Bearer ".concat(token),
        // };
        // const req = await apiCall.get(`/intelligrapi/financials/partneraccount`, {
        //     headers,
        // });
        dispatch(action(GET_ACCOUNTING_HISTORY, []));
    } catch (e) {
        dispatch(action(ACCOUNTING_ERROR, e.message));
    }
}