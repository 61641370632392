import {
    GET_CUSTOMER_DETAILS_START,
    GET_CUSTOMER_DETAILS_SUCCESS,
    GET_CUSTOMER_DETAILS_ENDS,

    PROCESS_CUSTOMER_GRADUATION_START,
    PROCESS_CUSTOMER_GRADUATION_SUCCESS,
    PROCESS_CUSTOMER_GRADUATION_ENDS,

    FETCH_GRADUATING_CUSTOMER_START,
    FETCH_GRADUATING_CUSTOMER_SUCCESS,
    FETCH_GRADUATING_CUSTOMER_ENDS,

    VERIFY_GRADUATE_CUSTOMER_START,
    VERIFY_GRADUATE_CUSTOMER_SUCCESS,
    VERIFY_GRADUATE_CUSTOMER_ENDS
} from "../../actions/types";

const initiate = {
    error: null,
    loading: false,
    graduatingCustomers: [],
    singleCustomerDetails: {}
}

export const CustomerGradReducer = (state = initiate, action) => {
    console.log('reducer-action', action)
    switch (action.type) {
        // Get customer details 
        case GET_CUSTOMER_DETAILS_START:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                singleCustomerDetails: action.payload
            }
        case GET_CUSTOMER_DETAILS_ENDS:
            return {
                ...state,
                loading: false
            }

        // Process customer graduation  
        case PROCESS_CUSTOMER_GRADUATION_START:
            return {
                ...state,
                loading: true
            }
        case PROCESS_CUSTOMER_GRADUATION_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case PROCESS_CUSTOMER_GRADUATION_ENDS:
            return {
                ...state,
                loading: false
            }

        // Fetch graduating customers 
        case FETCH_GRADUATING_CUSTOMER_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_GRADUATING_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                graduatingCustomers: action.payload
            }
        case FETCH_GRADUATING_CUSTOMER_ENDS:
            return {
                ...state,
                loading: false
            }

        // Verify/Graduate customer 
        case VERIFY_GRADUATE_CUSTOMER_START:
            return {
                ...state,
                loading: true
            }
        case VERIFY_GRADUATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case VERIFY_GRADUATE_CUSTOMER_ENDS:
            return {
                ...state,
                loading: false,
            }

        default:
            return {
                ...state,
            }
    }
}
