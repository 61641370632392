import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Form, Image, Row, Table} from "@themesberg/react-bootstrap";
import ImageHolder from "../../../assets/img/loading.gif";

class CustomerDetails extends Component {
    state = {
        image: ImageHolder,
    }

    render() {
        const {image} = this.state;
        const {client, imageLoading, images} = this.props;
        let profile = image;
        let nationId = image;
        //
        if (!imageLoading) {
            if (images) {
                images.map((i) => {
                    if (i && i.type === "profile") {
                        profile = i;
                    }
                    //
                    if (i && i.type === "nationalid") {
                        nationId = i;
                    }
                });
            }
        }
        //
        return (<Card>
            <Card.Body>
                <Row>
                    <Col className={'md-6'}>
                        <Table bordered striped className={'table-compacted'}>
                            <tbody>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Customer Type</td>
                                <td className={'py-1'}><b>{client && `${client.customerType}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Name</td>
                                <td className={'py-1'}><b>{client && `${client.name ?? ""} ${client.surname ?? ""}`}</b>
                                </td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>MSISDN</td>
                                <td className={'py-1'}><b>{client && `${client.msisdn ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Email</td>
                                <td className={'py-1'}><b>{client && `${client.email ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Address</td>
                                <td className={'py-1'}><b>{client && `${client.address ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Martial Status</td>
                                <td className={'py-1'}><b>{client && `${client.martial_status ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Identification Type</td>
                                <td className={'py-1'}><b>{client && `${client.identification_type ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>National ID Number</td>
                                <td className={'py-1'}><b>{client && `${client.national_id_number ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>DOB</td>
                                <td className={'py-1'}><b>{client && `${client.dob ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>BankId</td>
                                <td className={'py-1'}><b>{client && `${client.bankId ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Language</td>
                                <td className={'py-1'}><b>{client && `${client.language ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Province</td>
                                <td className={'py-1'}><b>{client && `${client.province ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>District</td>
                                <td className={'py-1'}><b>{client && `${client.district ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Sector</td>
                                <td className={'py-1'}><b>{client && `${client.sector ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Cell</td>
                                <td className={'py-1'}><b>{client && `${client.cell ?? ""}`}</b></td>
                            </tr>
                            <tr className={'py-1'}>
                                <td className={'py-1'}>Village</td>
                                <td className={'py-1'}><b>{client && `${client.village ?? ""}`}</b></td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col className={'md-6'}>
                        <Row>
                            <Col xs={6} md={4}>
                                <Image src={imageLoading ? profile : profile.file ?? ""} thumbnail fluid={true}
                                       className="w-75 h-75" title={"Profile Picture"}/>
                            </Col>
                            <Col xs={6} md={4}>
                                <Image src={imageLoading ? nationId : nationId.file ?? ""} thumbnail fluid={true}
                                       className="w-75 h-75" title={"Nation ID"}/>
                            </Col>
                        </Row>
                        <Col className={'md-12 mt-4'}>
                            <Form.Group id={'blocked'}>
                                <Form.Switch name={'blocked'} onChange={() => {
                                }} defaultValue={client && client.blocked} title={'Status'} size={8}
                                             label={"Active Status"}/>
                            </Form.Group>
                        </Col>
                    </Col>
                </Row>
            </Card.Body>
        </Card>);
    }
}

//
const mapStateProps = (state) => {
    return {};
}
//
export default connect(mapStateProps, {})(CustomerDetails);
