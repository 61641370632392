import {Button, Modal, Table} from "@themesberg/react-bootstrap";
import React from "react";

export const SchedulesModelDialog = (props) => {
    const {show, close, schedules} = props;
    const TableRow = (props) => {
        const {schedule, index,} = props;
        return (
            <tr>
                <td>{index}</td>
                <td>{schedule.scheduleStatus.toLocaleString()}</td>
                <td>{schedule.dueAmount.toLocaleString()}</td>
                <td>{schedule.paidAmount.toLocaleString()}</td>
                <td>{schedule.deferredAmount.toLocaleString()}</td>
                <td>{schedule.startingDate ? (new Date(schedule.startingDate)).toDateString() : '-'}</td>
                <td>{schedule.endingDate ? (new Date(schedule.endingDate)).toDateString() : '-'}</td>
                <td>{schedule.dueDate ? (new Date(schedule.dueDate)).toDateString() : '-'}</td>
                <td>{schedule.paymentDate ? (new Date(schedule.paymentDate)).toDateString() : '-'}</td>
            </tr>
        );
    }
    //
    return (
        <Modal show={show} onHide={close} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Schedules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive striped={true} className="table-centered table-bordered table-nowrap rounded">
                    <thead className="thead-light">
                    <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Schedule Status</th>
                        <th className="border-0">Due Amount</th>
                        <th className="border-0">Paid Amount</th>
                        <th className="border-0">Deferred Amount</th>
                        <th className="border-0">Starting Date</th>
                        <th className="border-0">Ending Date</th>
                        <th className="border-0">Due Date</th>
                        <th className="border-0">Payment Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {schedules && schedules.length ? (
                        schedules.map((schedule, i) => <TableRow schedule={schedule} index={++i}/>)
                    ) : (
                        <></>
                    )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}