import {action} from "../index";
import {CREATE_FAQ, FAQ_ERROR, GET_FAQS,} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";

/**
 * Get all faq
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getFAQs = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get('/intelligrapi/faq/all', {
            headers,
        });
        //
        dispatch(action(GET_FAQS, JSON.parse(req.data.data)));
    } catch (e) {
        dispatch(action(FAQ_ERROR, e.message));
    }
}

/**
 * View a single faq
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const viewFAQ = () => async (dispatch) => {
    try {

    } catch (e) {
        dispatch(action(FAQ_ERROR, e.message));
    }
}

/**
 * Create a new faq
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const createFAQ = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get('/intelligrapi/faq/add', {
            headers,
        });
        dispatch(action(CREATE_FAQ, req.data));
    } catch (e) {
        dispatch(action(FAQ_ERROR, e.message));
    }
}

/**
 * Update faq
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const updateFAQ = (data) => async (dispatch) => {
    try {

    } catch (e) {
        dispatch(action(FAQ_ERROR, e.message));
    }
}
