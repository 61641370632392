import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {getPartnerData} from "../../../../actions/partners";
import {Button, Table} from "@themesberg/react-bootstrap";
import useRoute from "../../../../helpers/useRoute";
import {Routes} from "../../../../routes";
import authorization from "../../../../helpers/authorization";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
//
const TableRow = ({index, id, description, code, partnerType}) => {
    const urlEdit = useRoute(Routes.EditPartner.path, {code: partnerType, id});
    const urlView = useRoute(Routes.ViewPartner.path, {code: partnerType, id});

    return (
        <tr valign={'middle'}>
            <td className={'text-center'}>{index}</td>
            <td className={'text-center'}>
                <a href={authorization("PA_BROWSE") ? `${urlView}` : ""}>{description}</a>
            </td>
            <td className={'text-center'}>{code}</td>
            <td className={'text-center'}>
                {
                    authorization("PA_EDIT") && (
                        <>
                            <Button variant="light" size="sm" href={urlEdit}>
                                <FontAwesomeIcon icon={faEdit} className={`icon icon-xs text-success cursor-pointer`}/>
                            </Button> &nbsp;&nbsp;
                        </>
                    )
                }
            </td>
        </tr>
    );
}
//
const BrowseInsurances = (
    {
        partnerType,
        code,
        error,
        partnerData: insurances,
        freelancerLoading: loading,
        getPartnerData
    }
) => {
    //
    const [data, setData] = useState(null);
    //
    useEffect(() => {
        if (data === null && !loading) {
            getPartnerData(code);
            setData(insurances);
        }
    }, [data, loading, insurances]);
    //
    return (
        <>
            <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                <thead className="thead-light">
                <tr>
                    <th className={'text-center'}>#</th>
                    <th className={'text-center'}>Name</th>
                    <th className={'text-center'}>Code</th>
                    <th className={'text-center'}>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    loading ? (
                        <tr>
                            <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                        </tr>
                    ) : insurances != null && insurances.length > 0 ?
                        insurances.map((partner, i) => <TableRow {...partner}
                                                                 index={++i}
                                                                 partnerType={partnerType}
                                                                 key={`page-visit-${partner.id}`}/>) : (
                            <tr>
                                <td colSpan="10" align="center">No insurances available !</td>
                            </tr>
                        )
                }
                </tbody>
            </Table>
        </>
    );
}
const mapStateToProps = (store) => {
    const {error, partnerData, freelancerLoading} = store.partners;
    return {error, partnerData, freelancerLoading};
}
export default connect(mapStateToProps, {getPartnerData})(BrowseInsurances);
