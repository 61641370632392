import React from "react";
import {Nav} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import authorization from "../helpers/authorization";
import {connect} from "react-redux";

class TabNavItem extends React.Component {

    render() {
        const {eventKey, onSelect, icon, role, title,} = this.props;
        return (
            <>
                {
                    authorization(role) ? (
                        <>
                            <Nav.Item>
                                <Nav.Link eventKey={eventKey} className="mb-sm-3 mb-md-0"
                                          onSelect={onSelect}>
                                    <FontAwesomeIcon icon={icon} className="me-2"/> {title}
                                </Nav.Link>
                            </Nav.Item>
                        </>
                    ) : (<></>)
                }
            </>
        );
    }
}

const mapStateProps = (state) => {
    return {};
}
export default connect(mapStateProps, {})(TabNavItem);
