import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome, faMobile, faUsers} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import Preloader from "../../components/Preloader";
import {getStoreDetails} from "../../actions/stores";
import StoreDetail from "./components/StoreDetails";
import StoreDevices from "./components/StoreDevices";
import StoreRepresentative from "./components/StoreRepresentative";
import TabNavItem from "../../components/TabNavItem";
import authorization from "../../helpers/authorization";
import {faEdit} from "@fortawesome/free-regular-svg-icons";

export class StoreView extends Component {
    state = {
        loading: true,
        storeId: this.props.match.params.id,
        selectedTab: 'store_details',
    };
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {storeId} = this.state;
        const {getStoreDetails} = this.props;
        await getStoreDetails(storeId);
        this.setState({loading: false});
    }

    selectTab = (tab) => {
        this.setState({selectedTab: tab});
    }

    //
    render() {
        const {data} = this.props;
        const {loading, selectedTab} = this.state;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Stores.path}>Stores</Breadcrumb.Item>
                            <Breadcrumb.Item active>Detail</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>Store {!loading && data ? (<span>: <b><u>{data.name}</u></b></span>) : ("")}</h3>
                        {
                            !loading && authorization("ST_EDIT") ? (
                                <>
                                    <Button href={`/partners/stores/${data?.storeId}/edit`}>
                                        <FontAwesomeIcon icon={faEdit}
                                                         className={`icon icon-xs text-success cursor-pointer`}/> Edit
                                    </Button>
                                </>
                            ) : (<></>)
                        }
                    </div>
                </div>
                {loading || !data ? (
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Preloader show={loading}/>
                        </Col>
                    </Row>
                ) : (
                    <Row className="">
                        <Col md="12">
                            <Tab.Container title={data && data.name} defaultActiveKey="store_details">
                                <Row>
                                    <Col lg={12}>
                                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                                            <TabNavItem title={'Details'}
                                                        eventKey="store_details"
                                                        icon={faEye}
                                                        role={'ST_READ'}
                                                        onSelect={() => this.selectTab('store_details')}/>
                                            <TabNavItem title={'Devices'}
                                                        eventKey="store_devices"
                                                        icon={faMobile}
                                                        role={'SD_BROWSE'}
                                                        onSelect={() => this.selectTab('store_devices')}/>
                                            <TabNavItem title={'Representative'}
                                                        eventKey="store_representative"
                                                        icon={faUsers}
                                                        role={'SR_BROWSE'}
                                                        onSelect={() => this.selectTab('store_representative')}/>
                                        </Nav>
                                        <Tab.Content>
                                            {
                                                authorization("ST_READ") ? (
                                                    <>
                                                        <Tab.Pane eventKey="store_details" className="py-4">
                                                            {
                                                                selectedTab === 'store_details' ? (
                                                                    <StoreDetail {...data} />) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("SD_BROWSE") ? (
                                                    <>
                                                        <Tab.Pane eventKey="store_devices" className="py-4">
                                                            {
                                                                selectedTab === 'store_devices' ? (
                                                                    <StoreDevices {...data} />) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("SR_BROWSE") ? (
                                                    <>
                                                        <Tab.Pane eventKey="store_representative" className="py-4">
                                                            {
                                                                selectedTab === 'store_representative' ? (
                                                                    <StoreRepresentative {...data} />) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                )
                }
            </>
        );
    }
}

/**
 * Map State vars to component props
 * @param state
 * @returns {{}}
 */
const mapStateProps = (state) => {
    const {data, error} = state.store;
    return {data, error};
}
export default connect(mapStateProps, {getStoreDetails})(StoreView);