import {combineReducers} from 'redux';
import Authentication from "./auth/authReducer";
import profile from "./user/profileReducer";
import createUserReducer from "./user/createUserReducer";
import getUsersReducer from "./user/getUsersReducers";
import devicesReducer from "./devices/devicesReducer";
import storeReducer from "./stores/storesReducer";
import clientsReducer from "./clients/clientsReducer";
import rolesReducer from "./roles/rolesReducer";
import corporateReducer from "./corporates/corporateReducer";
import plansReducer from "./plans/planReducer";
import partnersReducer from "./partners/partnersReducer";
import {loansReducer} from "./loans/loansReducers";
import {ticketsReducer} from "./tickets/ticketsReducer";
import notificationReducer from "./notifications/notificationReducer";
import {faqReducer} from "./faq/faqReducer";
import placesReducer from "./places/placesReducer";
import appReducer from "./app/appReducer";
import {accountingReducer} from "./accounting/accountingReducer";
import repUsersReducer from "./user/repUsersReducer";
import {reportsReducer} from "./reports/reportsReducer";
import {ManualRepaymentReducer} from "./manual_repayments/ManualRepaymentsReducer";
import alertReducer from './apiresponse/AlertReducer';
import { CustomerGradReducer } from './customer_graduation/CustomerGradReducer';
import {  SupportReducer } from './supports/supportReducer';

const allReducers = combineReducers({
    app: appReducer,
    authentication: Authentication,
    profile: profile,
    createUser: createUserReducer,
    users: getUsersReducer,
    device: devicesReducer,
    store: storeReducer,
    clients: clientsReducer,
    roles: rolesReducer,
    corporates: corporateReducer,
    plans: plansReducer,
    partners: partnersReducer,
    loans: loansReducer,
    tickets: ticketsReducer,
    notification: notificationReducer,
    faq: faqReducer,
    places: placesReducer,
    financials: accountingReducer, 
    reps: repUsersReducer,
    reports: reportsReducer,
    manualRepayments: ManualRepaymentReducer,
    supports: SupportReducer,
    alerts: alertReducer,
    customerGrad: CustomerGradReducer
});

export default allReducers;