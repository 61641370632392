import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Table} from "@themesberg/react-bootstrap";
import {getSinglePartnerData} from "../../../../actions/partners";
import Preloader from "../../../../components/Preloader";

class InsuranceDetails extends Component {
    state = {
        loading: false,
    }
    //
    componentDidMount = async () => {
        const {id, code, getSinglePartnerData} = this.props;
        this.setState({loading: true});
        await getSinglePartnerData(code, id);
        this.setState({loading: false});
    }

    //
    render() {
        const {loading} = this.state;
        const {partner} = this.props;
        console.log(partner);
        //
        return (
            <>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col className={'md-6'}>
                                {loading && (
                                    <Row className="justify-content-md-center">
                                        <Col md="auto">
                                            <Preloader show={loading}/>
                                        </Col>
                                    </Row>
                                )}
                                {partner && (
                                    <Table bordered striped className={'table-compacted'}>
                                        <tbody>
                                        <tr className={'py-1'}>
                                            <td className={'py-1'}>Insurance</td>
                                            <td className={'py-1'}><b>{partner ? partner.description : ''}</b></td>
                                        </tr>
                                        <tr className={'py-1'}>
                                            <td className={'py-1'}>Insurance Code</td>
                                            <td className={'py-1'}><b>{partner && partner.code ? partner.code : '-'}</b></td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {partner, error,} = state.partners;
    return {partner, error};
}
export default connect(mapStateProps, {getSinglePartnerData})(InsuranceDetails);
