import {
  RESOLVE_COMMON_ISSUES_START,
  RESOLVE_COMMON_ISSUES_ENDS,
  RESOLVE_MISSING_RECORDS_START,
  RESOLVE_MISSING_RECORDS_ENDS,
  READ_TRANSACTION_STATUS_START,
  READ_TRANSACTION_STATUS_ENDS,
  READ_PENDING_TRANSACTIONS_START,
  READ_PENDING_TRANSACTIONS_ENDS,
  RESOLVE_PAYMENT_FETCH_START,
  RESOLVE_PAYMENT_FETCH_ENDS,
  RESOLVE_DEVICE_DETAILS_START,
  RESOLVE_DEVICE_DETAILS_ENDS,
  TEMPORARY_EXTENSION_START,
  TEMPORARY_EXTENSION_ENDS,
} from "../../actions/types";

const initiate = {
  error: null,
  loading: false,
};

export const SupportReducer = (state = initiate, action) => {
  console.log("reducer-action", action);
  switch (action.type) {
    // Resolve Active Plan Issue
    case RESOLVE_COMMON_ISSUES_START:
      return {
        ...state,
        loading: true,
      };
    case RESOLVE_COMMON_ISSUES_ENDS:
      return {
        ...state,
        loading: false,
      };

    //Read Transaction status
    case READ_TRANSACTION_STATUS_START:
      return {
        ...state,
        loading: true,
      };
    case READ_TRANSACTION_STATUS_ENDS:
      return {
        ...state,
        loading: false,
      };

    //Resolve Payment Fetch
    case RESOLVE_PAYMENT_FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case RESOLVE_PAYMENT_FETCH_ENDS:
      return {
        ...state,
        loading: false,
      };

          //Resolve Device Details
    case RESOLVE_DEVICE_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case RESOLVE_DEVICE_DETAILS_ENDS:
      return {
        ...state,
        loading: false,
      };

    //Read Pending transaction
    case READ_PENDING_TRANSACTIONS_START:
      return {
        ...state,
        loading: true,
      };
    case READ_PENDING_TRANSACTIONS_ENDS:
      return {
        ...state,
        loading: false,
      };

    //Resolve Missing Records
    case RESOLVE_MISSING_RECORDS_START:
      return {
        ...state,
        loading: true,
      };
    case RESOLVE_MISSING_RECORDS_ENDS:
      return {
        ...state,
        loading: false,
      };

          //Read Pending transaction
    case TEMPORARY_EXTENSION_START:
      return {
        ...state,
        loading: true,
      };
    case TEMPORARY_EXTENSION_ENDS:
    default:
      return {
        ...state,
      };
  }
};
