import React, {Component} from "react";
import {Button, Card, Col, Form, Image, Row, Table} from "@themesberg/react-bootstrap";
import Profile3 from "../../../assets/img/team/profile-picture-10.png";
import {StartCase} from "react-lodash";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {addUserIntoTheGroup, getGroupsByUser, getRolesGroups, removeUserFromGroup} from "../../../actions/roles";
import {getUserDetails} from "../../../actions/user";
import {BeatLoader} from "react-spinners";
import authorization from "../../../helpers/authorization";

//
const GroupDetails = (props) => {
    const {
        groupCode,
        groupName,
        roles,
        handleRemoveUserFromGroup,
        username,
    } = props;
    //
    return (
        <>
            <tr className={'py-1'}>
                <td rowSpan={2} width={'10em'} valign={'middle'} align={'center'} className={'py-1'}>
                    <Button variant={'danger'}
                            className={'px-2 py-2'}
                            size={'sm'}
                            disabled={!authorization("US_EDIT")}
                            onClick={() => {
                                handleRemoveUserFromGroup(username, groupCode);
                            }}>
                        <FontAwesomeIcon icon={faMinusCircle} title={'Remove from a group'}
                                         className={`icon icon-xs cursor-pointer`}/>
                    </Button>
                </td>
                <th className={'py-1'} width={'10em'}>Group Name</th>
                <td className={'py-1'}><b><a href={`/users/roles/${groupCode}/groups/`} rel="noreferrer"
                                             target={'_blank'}>{groupName}</a></b></td>
            </tr>
            <tr className={'py-1'}>
                <th className={'py-1'}>
                    <a href={`/users/roles/${groupCode}/groups/`} rel="noreferrer"
                       target={'_blank'}>Roles</a>
                </th>
                <td className={'py-1'}><b><a href={`/users/roles/${groupCode}/groups/`} rel="noreferrer"
                                             target={'_blank'}>{roles?.length ?? 0}</a></b></td>
            </tr>
        </>
    );
}

//
class UserDetails extends Component {
    state = {
        loading: false,
        loadingUserGroups: false,
        group: null,
    }
    //
    componentDidMount = () => {
        const {getRolesGroups, getGroupsByUser, usr,} = this.props;
        this.setState({loading: true, loadingUserGroups: true,});
        getRolesGroups().then(() => {
            this.setState({loading: false});
        });
        getGroupsByUser(usr.username).then(() => {
            this.setState({loadingUserGroups: false,});
        });
    }
    //
    handleOnchange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    //
    handleOnClick = async (e) => {
        e.preventDefault();
        const {group,} = this.state;
        if (group) {
            this.setState({loading: true,});
            const {addUserIntoTheGroup, getGroupsByUser, usr,} = this.props;
            const add = await addUserIntoTheGroup({userId: usr.userid, groupCode: group,});
            if (add) {
                this.setState({loadingUserGroups: true, loading: false});
                getGroupsByUser(usr.username).then(() => {
                    this.setState({loadingUserGroups: false,});
                });
            }
            this.setState({loading: false});
        } else {
            window.alert("Please select group name !!");
        }
    }

    handleRemoveUserFromGroup = async (username, groupCode) => {
        if (window.confirm("Are you sure !") === true) {
            const {usr, removeUserFromGroup, getGroupsByUser} = this.props;
            this.setState({loading: true, loadingUserGroups: true,});
            await removeUserFromGroup(username, groupCode);
            getGroupsByUser(usr.username).then(() => {
                this.setState({loadingUserGroups: false,});
            });
            this.setState({loading: false,});
        }
    }

    render() {
        const {username, name, surname, msisdn, email, userStatus,} = this.props.usr;
        const {groups, error, userGroups,} = this.props;
        const {loading, loadingUserGroups,} = this.state;
        return (
            <>
                <Card>
                    <Card.Body>
                        <Table striped bordered className={'mb-3'}>
                            <tbody>
                            <tr>
                                <td className={'py-1'}>Name</td>
                                <td className={'py-1'}><b><StartCase string={`${name} ${surname}`}/></b></td>
                                <td rowSpan={5} colSpan={2} width={'10em'} align={'center'} valign={'middle'}>
                                    <Image src={Profile3} width={100} className=""/>
                                </td>
                            </tr>
                            <tr>
                                <td className={'py-1'}>Username</td>
                                <td className={'py-1'}><b>{`${username}`}</b></td>
                            </tr>
                            <tr>
                                <td className={'py-1'}>MSISDN</td>
                                <td className={'py-1'}><b>{`${msisdn}`}</b></td>
                            </tr>
                            <tr>
                                <td className={'py-1'}>Email</td>
                                <td className={'py-1'}>
                                    <a href={`mail:${email}`} rel="noreferrer" target="_blank"><b>{email}</b></a>
                                </td>
                            </tr>
                            <tr>
                                <td className={'py-1'}>User Status</td>
                                <td className={'py-1'}><b>{`${userStatus}`}</b></td>
                            </tr>
                            </tbody>
                        </Table>
                        <h5>Roles & Permissions</h5>
                        <Row>
                            <Col md={8}>
                                {
                                    !loadingUserGroups && userGroups && userGroups.length > 0 ? (
                                        <>
                                            <Table striped bordered rowSpace={''}>
                                                <tbody>
                                                {
                                                    userGroups.map(
                                                        (group, i) => <GroupDetails {...group}
                                                                                    username={username}
                                                                                    handleRemoveUserFromGroup={this.handleRemoveUserFromGroup}/>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </>
                                    ) : (
                                        <center>
                                            {
                                                loadingUserGroups ? (<BeatLoader color="#000" size="8"/>) :
                                                    ("Not Yet assigned to any group")
                                            }
                                        </center>
                                    )
                                }
                            </Col>
                            <Col md={4}>
                                {
                                    groups && authorization("GR_EDIT") ? (
                                        <>
                                            {error && (<p className={'text-warning'}>{error}</p>)}
                                            {/* --- */}
                                            <Form.Select aria-label="" name={'group'} onChange={this.handleOnchange}>
                                                <option selected disabled>Select :.</option>
                                                {
                                                    groups.map((group, i) => (
                                                        <option value={group.groupCode}>{group.groupName}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            <Row>
                                                <Col md={12} className={'justify-content-end align-items-end'}>
                                                    <Button className={'mt-3'}
                                                            onClick={this.handleOnClick}
                                                            disabled={loading || !authorization("GR_EDIT")}>
                                                        Assign to group
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <center>
                                            {
                                                loading ? (<BeatLoader color="#000" size="8"/>) :
                                                    ("No groups available !!")
                                            }
                                        </center>
                                    )
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {groups, error, userGroups,} = state.roles;
    const {user} = state.authentication;
    return {groups, error, user, userGroups,}
}
//
export default connect(mapStateProps, {
    getRolesGroups,
    removeUserFromGroup,
    addUserIntoTheGroup,
    getUserDetails,
    getGroupsByUser,
})(UserDetails);
