import React, {Component} from "react";
import {Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {connect} from "react-redux";
import {getUndeployedRepresentatives} from "../../../actions/user";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare} from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import {BeatLoader} from "react-spinners";
import {attacheRepToStore, getRepresentativesInStore} from "../../../actions/stores";
import authorization from "../../../helpers/authorization";

const TableRow = (props) => {
    //
    const {
        index, username, name, surname, email, msisdn, nid, gender, startingDate,
        attach, repId,
    } = props;
    //
    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className="fw-bold">
                {`${name} ${surname}`}
            </td>
            <td>{`${username}`}</td>
            <td>{`${email}`}</td>
            <td>{`${gender}`}</td>
            <td>{`${msisdn}`}</td>
            <td>{`${nid}`}</td>
            <td>{`${startingDate}`}</td>
            <td>
                <Button variant="light"
                        size="sm"
                        disabled={!authorization("SR_CREATE")}
                        onClick={() => attach(repId)}>
                    <FontAwesomeIcon icon={faPlusSquare} className={`icon icon-xs text-success cursor-pointer`}/>
                </Button>
            </td>
        </tr>
    );
};

class ModalRep extends Component {
    state = {
        loading: true,
        storeId: this.props.storeId,
    }
    //
    attacheRepToStore = async (rep) => {
        if (window.confirm("Are you sure?") === true) {
            this.setState({loading: true});
            const {storeId} = this.state;
            const {attacheRepToStore, getRepresentativesInStore, handleClose} = this.props;
            const attach = await attacheRepToStore(storeId, rep);
            if (attach) {
                await getRepresentativesInStore(storeId);
                this.setState({loading: false});
                handleClose();
            }
            this.setState({loading: false});
        }
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getUndeployedRepresentatives,} = this.props;
        await getUndeployedRepresentatives();
        this.setState({loading: false});
    }

    //
    render() {
        const {show, handleClose, repUsers, error} = this.props;
        const {loading} = this.state;
        //
        if (error != null) {
            window.alert(error);
        }
        //
        return (<>
            <Modal show={show} onHide={handleClose} keyboard={false} backdrop={'static'} size={'lg'}
                   className="custom-class">
                <Modal.Header closeButton>
                    <Modal.Title>Available Representative</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                        <thead className="thead-light">
                        <tr>
                            <th className="">#</th>
                            <th className="">Name</th>
                            <th className="">Username</th>
                            <th className="">Email</th>
                            <th className="">Gender</th>
                            <th className="">MSISDN</th>
                            <th className="">NID</th>
                            <th className="">Starting Date</th>
                            <th className="">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="9" align="center"><BeatLoader color="#000" size="8"/></td>
                            </tr>
                        ) : (
                            repUsers && repUsers.length > 0 ? (
                                repUsers.map((representative, index) =>
                                    <TableRow {...representative} index={++index} attach={this.attacheRepToStore}/>)
                            ) : (
                                <tr>
                                    <td colSpan={9} className={"text-center"}>
                                        There is no representative in this store !
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>);
    }
}

const mapStateProps = (state) => {
    const {repUsers, error} = state.store;
    return {repUsers, error}
}
//
export default connect(mapStateProps, {
    getUndeployedRepresentatives,
    attacheRepToStore,
    getRepresentativesInStore
})(ModalRep);