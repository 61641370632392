import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFilter} from "@fortawesome/free-solid-svg-icons";
import {StartCase} from "react-lodash";
import {getAllBanks} from "../../../actions/partners";
import {BeatLoader} from "react-spinners";

class FilterRender extends Component {
    state = {
        loading: true,
        selectRender: null,
    };
    //
    selectRender = (status) => {
        // const {banks,error} = this.props;
        // selectStatus(status);
        this.setState({selectRender: status,});
    }

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getAllBanks,} = this.props;
        await getAllBanks();
        this.setState({loading: false,});
    }

    render() {
        const {loading, selectRender,} = this.state;
        const {banks} = this.props;
        return (
            <>
                <div className="">
                    <Dropdown>
                        <Dropdown.Toggle as={Button} variant="primary" className={'py-1'}>
                            <FontAwesomeIcon icon={faFilter} className="me-2"/> <StartCase
                            string={selectRender?.bankName ?? 'Select Render'}/>
                            <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                            {
                                loading ? (
                                    <>
                                        <center>
                                            <BeatLoader color="#000" size="2"/>
                                        </center>
                                    </>
                                ) : (
                                    banks && banks.length > 0 ? banks.map((t, i) => (
                                        <Dropdown.Item onClick={() => {
                                            this.selectRender(t)
                                        }}
                                                       active={t?.id === selectRender?.id}>
                                            <StartCase string={t?.bankName}/>
                                        </Dropdown.Item>
                                    )) : (<></>)
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }
}

const mapPropsState = (store) => {
    const {banks, error,} = store.partners;
    return {banks, error,};
}
export default connect(mapPropsState, {getAllBanks,})(FilterRender);
