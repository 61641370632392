import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Card, Col, Image, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {getFAQs} from "../../actions/faq";
import ReactLogo from "../../assets/loader/Loader.svg";
import FaqCard from "./components/FaqCard";

class Faq extends Component {
    state = {
        loading: true,
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getFAQs} = this.props;
        await getFAQs();
        this.setState({loading: false,});
    }

    //
    render() {
        const {loading} = this.state;
        const {faqs,} = this.props;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all faq</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>FAQ</h4>
                    </div>
                </div>

                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="">
                        {
                            loading ? (
                                <>
                                    <center>
                                        <Image
                                            className="loader-element animate__animated animate__jackInTheBox"
                                            src={ReactLogo} height={200} width={200}/>
                                    </center>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        {
                                            !loading && faqs.length > 0 ? (
                                                faqs.map((faq, i) => <FaqCard key={i} faq={faq} index={i}/>)
                                            ) : (
                                                <>
                                                    <Col md={12} className={'text-center'}>
                                                        <h5>No FAQ available yet !</h5>
                                                    </Col>
                                                </>
                                            )
                                        }
                                    </Row>
                                </>
                            )
                        }
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, faqs,} = state.faq;
    return {error, faqs,};
}

export default connect(mapStateProps, {getFAQs})(Faq);
