import {action} from "../index";
import {
    CREATE_REPRESENTATIVE_ERROR_USER,
    CREATE_REPRESENTATIVE_USER,
    EDIT_USER,
    ERROR_EDITING_USER,
    GET_UNDEPLOYED_REPRESENTATIVE,
    GET_USER_ACTIVITY_LOGS,
    GET_USER_DETAILS,
    GET_USERS_DEVICES,
    USER_DATA,
    USER_ERROR
} from "../types";
import {getToken, getUser} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";
import clientLogs from "../../data/clientLogs";

/**
 * Get user profile action
 * @returns {(function(*): Promise<void>)|*}
 */
export const profileAction = () => async (dispatch) => {
    try {
        const user = getUser();
        dispatch(action(USER_DATA, user));
    } catch (e) {
        dispatch(action(USER_ERROR, e.message));
    }
}

/**
 * Get user details
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getUserDetails = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/userbyuserid?userid=${id}`, {
            headers,
        });
        dispatch(action(GET_USER_DETAILS, req.data));
        return req.data;
    } catch (e) {
        dispatch(action(USER_ERROR, e.message));
    }
    return null;
}

/**
 * Mark user as representative
 * @param data
 * @returns {(function(*): Promise<boolean|undefined>)|*}
 */
export const markUserAsRepresentative = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/addrepresentative', data, {
            headers,
        });
        //
        if (req.data && !req.data.information) {
            dispatch(action(CREATE_REPRESENTATIVE_USER, req.data));
            return true;
        } else {
            dispatch(action(CREATE_REPRESENTATIVE_ERROR_USER, req.data.information));
        }
    } catch (e) {
        dispatch(action(CREATE_REPRESENTATIVE_ERROR_USER, e.message));
    }
    return false;
}

/**
 * Get undeployed representative
 *
 * @returns {(function(*): Promise<*>)|*}
 */
export const getUndeployedRepresentatives = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/undeployedreps`, {
            headers,
        });
        dispatch(action(GET_UNDEPLOYED_REPRESENTATIVE, req.data));
    } catch (e) {
        dispatch(action(USER_ERROR, e.message));
    }
}

/**
 * Edit user action
 *
 * @param userData
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const updateUserAction = (userData) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/updateuser', userData, {
            headers,
        });
        if (req.data.userid !== undefined) {
            const user = req.data;
            dispatch(action(EDIT_USER, user));
            return true;
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_EDITING_USER, e.response.data.error));
        } else {
            dispatch(action(ERROR_EDITING_USER, e.message));
        }
    }
    return false;
}

/**
 * Get user activities
 *
 * @param userId
 * @returns {(function(*): void)|*}
 */
export const getUserActivityLogs = (userId) => (dispatch) => {
    try {
        // const token = getToken();
        // const headers = {
        //     Authorization: "Bearer ".concat(token),
        // };
        //
        // const req = await apiCall.get(`/intelligrapi/deviceextendingaion?imei=${imei}&msisdn=${msisdn}`, {
        //     headers,
        // });
        //
        dispatch(action(GET_USER_ACTIVITY_LOGS, clientLogs));
    } catch (e) {
        dispatch(action(USER_ERROR, e.message));
    }
}

/**
 * Get users devices
 *
 * @param username
 * @returns {(function(*): void)|*}
 */
export const getUsersDevices = (username) => async dispatch => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/reps/representativedevices?rep=${username}`, {
            headers,
        });
        //
        dispatch(action(GET_USERS_DEVICES, req.data?.data ?? []));
    } catch (e) {
        dispatch(action(USER_ERROR, e.message));
    }
}
