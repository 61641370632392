import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Container, Dropdown, Form, Image, ListGroup, Nav, Navbar, Row} from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-10.png";
import {Routes} from "../routes";
import {connect, useDispatch, useSelector} from "react-redux";
import {profileAction} from "../actions/user";
import {logout} from "../actions/auth";
import DatePicker from "react-datepicker";
import {setDashboardDate} from "../actions/app";
import {getCurrentMonth} from "../helpers/dateHelper";
import {action} from "../actions";
import {DASHBOARD_DATE} from "../actions/types";

export const NavBar = ({component: Component, ...rest}) => {
    // const history = useHistory();
    const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
    const [username, setUsername] = useState("");
    const [startDate, setStartDate] = useState((getCurrentMonth()[0].getTime()));
    const [endDate, setEndDate] = useState((getCurrentMonth()[1].getTime()));
    const dispatch = useDispatch();
    const {dates} = useSelector((store) => store.app);
    //
    const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

    const markNotificationsAsRead = () => {
        setTimeout(() => {
            setNotifications(notifications.map(n => ({...n, read: true})));
        }, 300);
    };

    const onChangeDates = () => {
        dispatch(action(DASHBOARD_DATE, {
            from: startDate,
            to: endDate,
        }));
    }

    const logout = async () => {
        const {logout} = rest;
        await logout();
        window.location = Routes.Login.path;
    }
    //
    const setDates = (date, r = 'f') => {
        switch (r) {
            case 'f':
                setStartDate(() => date)
                break;
            case 't':
                setEndDate(() => date)
                break;
        }
        onChangeDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let mounted = useRef().current;
    useEffect(() => {
        const username = rest.user ? rest.user.name + " " : "";
        setUsername(username);
        if (!mounted && dates === undefined) {
            dispatch(action(DASHBOARD_DATE, {
                from: startDate,
                to: endDate,
            }));
            mounted = true;
        }
    }, [mounted, startDate, endDate, dates]);

    const Notification = (props) => {
        const {link, sender, image, time, message, read = false} = props;
        const readClassName = read ? "" : "text-danger";

        return (
            <ListGroup.Item action href={link} className="border-bottom border-light">
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <Image src={image} className="user-avatar lg-avatar rounded-circle"/>
                    </Col>
                    <Col className="ps-0 ms--2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h4 className="h6 mb-0 text-small">{sender}</h4>
                            </div>
                            <div className="text-end">
                                <small className={readClassName}>{time}</small>
                            </div>
                        </div>
                        <p className="font-small mt-1 mb-0">{message}</p>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    };

    return (
        <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
            <Container fluid className="px-0">
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <Form className="d-inline-flex align-items-center">
                            {
                                (rest.currentPage === Routes.DashboardOverview.path) ? (
                                    <>
                                        <Form.Group className={'me-4'} controlId="formStartDate">
                                            <DatePicker
                                                selected={startDate}
                                                className={'form-control'}
                                                name={'_s'}
                                                onChange={(date) => {
                                                    setDates(date.getTime())
                                                }}/>
                                        </Form.Group>
                                        <Form.Group className={'me-4'} controlId="formEndDate">
                                            <DatePicker
                                                className={'form-control'}
                                                name={'_e'}
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setDates(date.getTime(), 't')
                                                }}/>
                                        </Form.Group>
                                        <Button variant="primary" type="button" onClick={onChangeDates}>Search</Button>
                                    </>
                                ) : (<></>)
                            }
                        </Form>
                    </div>
                    <Nav className="align-items-center">
                        <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
                            <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                              <span className="icon icon-sm">
                                <FontAwesomeIcon icon={faBell} className="bell-shake"/>
                                  {areNotificationsRead ? null :
                                      <span className="icon-badge rounded-circle unread-notifications"/>}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                                <ListGroup className="list-group-flush">
                                    <Nav.Link href="#"
                                              className="text-center text-primary fw-bold border-bottom border-light py-3">
                                        Notifications
                                    </Nav.Link>

                                    {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                                    <Dropdown.Item className="text-center text-primary fw-bold py-3">
                                        View all
                                    </Dropdown.Item>
                                </ListGroup>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                                <div className="media d-flex align-items-center">
                                    <Image src={Profile3} className="user-avatar md-avatar rounded-circle"/>
                                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                                        <span className="mb-0 font-small fw-bold">{username}</span>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                                {/*<Dropdown.Item className="fw-bold">*/}
                                {/*    <FontAwesomeIcon icon={faUserCircle} className="me-2"/> My Profile*/}
                                {/*</Dropdown.Item>*/}
                                {/*<Dropdown.Divider/>*/}
                                <Dropdown.Item className="fw-bold" onClick={logout}>
                                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/> Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </div>
            </Container>
        </Navbar>
    );
};

const mapStateToProps = (state) => {
    const {isLoggedIn} = state.authentication;
    const {user, error} = state.profile;
    const {currentPage, loadingState, dates, dashboard} = state.app;
    return {
        isLoggedIn, user, error, currentPage, loadingState, dates,
        dashboard,
    };
}

export default connect(mapStateToProps, {
    profileAction,
    logout,
    setDashboardDate,
})(NavBar);
