import React from "react";
import {connect} from "react-redux";

class DeviceTypeCategory extends React.Component {
    state = {
        loading: false,
    };

    //
    render() {
        return (<></>);
    }
}

const mapStateProps = (store) => {
    return {};
}
export default connect(mapStateProps, {})(DeviceTypeCategory);