import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {BeatLoader} from "react-spinners";
import {getCorporates} from "../../actions/corporates";
import authorization from "../../helpers/authorization";

const TableRow = (props) => {
    const {
        index, corporateId, name, tinNumber, msisdn, status,
    } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td>
                <a href={authorization('CC_BROWSE') ? `/corporates/${corporateId}/details` : ''}>
                    {name}
                </a>
            </td>
            <td>{tinNumber}</td>
            <td>{msisdn}</td>
            <td>{status === true ? "Active" : "Inactive"}</td>
            <td>
                <Button variant="light"
                        size="sm"
                        disabled={!authorization("CC_BROWSE")}
                        href={`/corporates/${corporateId}/details`}>
                    <FontAwesomeIcon icon={faEye} className={`icon icon-xs text-success cursor-pointer`}/>
                </Button>
            </td>
        </tr>
    );
};

class Corporates extends Component {
    state = {
        loading: true,
    };
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getCorporates} = this.props;
        await getCorporates();
        this.setState({loading: false});
    }

    //
    render() {
        const {loading,} = this.state;
        const {corporates} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>Corporates</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>{'Corporates'}</h4>
                    </div>
                </div>
                {
                    authorization("CC_CREATE") ? (
                        <>
                            <Button variant="primary" href={Routes.CreateCorporate.path} className="m-1">
                                Add Corporate
                            </Button>
                        </>
                    ) : (<></>)
                }
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive striped
                               className="table-centered table-striped table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Name</th>
                                <th className="">Tin Number</th>
                                <th className="">MSISDN</th>
                                <th className="">Status</th>
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : (
                                    corporates && corporates.length > 0 ? corporates.map((corporate, i) =>
                                        <TableRow {...corporate} index={++i} key={`page-visit-${i}`}/>
                                    ) : (
                                        <tr>
                                            <td colSpan="9" align="center">No registered corporates yet!</td>
                                        </tr>
                                    )
                                )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {error, corporates} = state.corporates;
    return {error, corporates,};
}
export default connect(mapStateProps, {getCorporates})(Corporates);