import React, {Component} from "react";
import {connect} from "react-redux";
import {getUserActivityLogs} from "../../../actions/user";
import {Accordion, Alert, Card, Col, Image, Row} from "@themesberg/react-bootstrap";
import {StartCase} from "react-lodash";
import ReactLogo from "../../../assets/loader/Loader.svg";

const AccordionItem = (props) => {
    const {eventKey, data} = props;
    return (
        <Accordion.Item eventKey={eventKey}>
            <Accordion.Header><StartCase string={eventKey}/></Accordion.Header>
            <Accordion.Body>
                {data ? (
                    data.map((log, i) => (
                        <>
                            <Row className={'justify-content-between'}>
                                <Col md={6}>#{log.ref}</Col>
                                <Col md={6} className={'text-right'}>{'By Admin'}</Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <p>{log.createdAt}</p>
                                    <Alert variant={'dark'}>
                                        <pre>{`${log.content}`}</pre>
                                    </Alert>
                                </Col>
                            </Row>
                        </>
                    ))
                ) : ('')}
            </Accordion.Body>
        </Accordion.Item>
    );
}

class UserActivityLog extends Component {
    state = {
        loading: false,
    }
    componentDidMount = async () => {
        this.setState({loading: true,});
        const {user, getUserActivityLogs} = this.props;
        await getUserActivityLogs(user.userid);
        this.setState({loading: false,});
    }

    render() {
        const {loading} = this.state;
        //
        const {logs,} = this.props;
        //
        return (
            <>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body>
                        {
                            loading ? (
                                <center>
                                    <Image
                                        className="loader-element animate__animated animate__jackInTheBox"
                                        src={ReactLogo} height={100} width={100}/>
                                </center>
                            ) : (
                                <Accordion>
                                    {
                                        logs ? (
                                            Object.entries(logs).map(([k, log]) => <AccordionItem eventKey={k}
                                                                                                  data={log}/>)
                                        ) : (<><p className={'text-danger'}>No logs</p></>)
                                    }
                                </Accordion>
                            )
                        }
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapPropsState = (state) => {
    const {error, logs,} = state.users;
    return {error, logs,};
}
export default connect(mapPropsState, {getUserActivityLogs})(UserActivityLog);
