import React, {Component} from "react";
import {Button, Card, Form, FormControl, Image, InputGroup, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight, faCloud, faExpand, faEye, faLock, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {faUnlock} from "@fortawesome/free-solid-svg-icons/faUnlock";
import {connect} from "react-redux";
import ReactLogo from "../../../assets/loader/Loader.svg";
import {StartCase} from "react-lodash";
import authorization from "../../../helpers/authorization";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import {faSave} from "@fortawesome/free-regular-svg-icons";

const ShowResponse = (props) => {
    const {k, value} = props; 
    //
    if (k === "code") return ("");
    let key = <span><StartCase string={k}/></span>;
    let response = <span><StartCase string={value}/></span>;
    if (value && typeof value === 'object') {
        return Object.entries(value).map(([kk, res]) => <ShowResponse k={kk} value={res}/>);
    }
    //
    return (
        <tr className={'py-1'}>
            <th className={'py-1'} width={'10em'}>{key}</th>
            <td className={'py-1'}><b>{response}</b></td>
        </tr>
    );
}

class DeviceActions extends Component {
    state = {
        loading: this.props.loading,
        deviceAction: null,
        enableIMEIChange: false,
        enableMSISDN: false,
        newMSISDN: "",
        newImei: "",
        reason: "",
    }

    action = async (/**action**/) => {
        this.setState({loading: true, deviceAction: null,});
        const {deviceAction} = this.props;
        this.setState({loading: false, deviceAction: deviceAction,});
    }

    onReasonChange = ({target: {value}}) => {
        if (value.toString().trim() !== "") {
            this.setState({reason: value});
        } else {
            this.setState({reason: ""});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {deviceAction} = prevState;
        if (deviceAction === null) {
            const {deviceAction} = this.props;
            this.setState({deviceAction: deviceAction,});
        }
    }

    enableChangeDevice = (state) => {
        if (window.confirm("Are you sure?") === true) {
            const _state = this.state[state];
            this.setState(() => ({
                [state]: !_state
            }))
        }
    }

    savingNewMSISDN = (imei, oldPhone) => {
        if (window.confirm(`Are you sure you want to change ${oldPhone} to ${this.state.newMSISDN}`) === true) {
            //
        }
    }

    savingNewImei = (imei) => {
        if (window.confirm(`Are you sure you want to change ${imei} to ${this.state.newImei}`) === true) {
            //
        }
    }

    render() {
        const {show, handleClose, device, error,} = this.props;
        const {loading, deviceAction, enableIMEIChange, enableMSISDN, reason} = this.state;
        return (
            <>
                <Modal show={show} onHide={handleClose} size={'xl'} backdrop={'static'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Device Actions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="" width='400px'>IMEI</th>
                                <th className="" width='300px'>MSISDN</th>
                                <th className="">Device status</th>
                                <th className="">Sideloading</th>
                                <th className="">Device type</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td valign={'middle'}>
                                    <InputGroup size={'sm'}>
                                        <FormControl
                                            type="text"
                                            disabled={!authorization('CAN_SWAP_PHONE') || !enableIMEIChange}
                                            id="device_imei"
                                            defaultValue={device.imei}
                                            className={'py-2'}
                                            aria-describedby="deviceImeiHelpBlock"
                                        />
                                        {
                                            authorization('CAN_SWAP_PHONE') ? (
                                                <>
                                                    <Button size={"sm"}
                                                            variant={enableIMEIChange ? 'primary' : 'outline-secondary'}
                                                            className={'px-4'}
                                                            onClick={() => {
                                                                if (enableIMEIChange) {
                                                                    this.savingNewImei(device.imei);
                                                                } else {
                                                                    this.enableChangeDevice('enableIMEIChange')
                                                                }
                                                            }}
                                                    >
                                                        {
                                                            enableIMEIChange ? (
                                                                <>
                                                                    <FontAwesomeIcon icon={faSave}/>
                                                                </>) : (
                                                                <>
                                                                    <FontAwesomeIcon icon={faExchangeAlt}/>
                                                                </>
                                                            )
                                                        }
                                                    </Button>
                                                </>
                                            ) : (<></>)
                                        }
                                    </InputGroup>
                                </td>
                                <td valign={'middle'}>
                                    <InputGroup size={'sm'}>
                                        <FormControl
                                            type="text"
                                            disabled={!authorization('CAN_SWAP_SIM') || !enableMSISDN}
                                            id="device_imei"
                                            defaultValue={device.msisdn}
                                            className={'py-2'}
                                            aria-describedby="deviceMsisdnHelpBlock"
                                        />
                                        {
                                            authorization('CAN_SWAP_SIM') ? (
                                                <>
                                                    <Button size={"sm"}
                                                            variant={enableMSISDN ? 'primary' : 'outline-secondary'}
                                                            className={'px-4'}
                                                            onClick={() => {
                                                                if (enableMSISDN) {
                                                                    this.savingNewMSISDN(device.imei, device.msisdn);
                                                                } else {
                                                                    this.enableChangeDevice('enableMSISDN')
                                                                }
                                                            }}
                                                    >
                                                        {
                                                            enableMSISDN ? (
                                                                <>
                                                                    <FontAwesomeIcon icon={faSave}/>
                                                                </>) : (
                                                                <>
                                                                    <FontAwesomeIcon icon={faExchangeAlt}/>
                                                                </>
                                                            )
                                                        }
                                                    </Button>
                                                </>
                                            ) : (<></>)
                                        }
                                    </InputGroup>
                                </td>
                                <td valign={'middle'}><b>{device.devicestatus}</b></td>
                                <td valign={'middle'}><b>{device.sideloaded === true ? 'YES' : 'NO'}</b></td>
                                <td valign={'middle'}><b>{device.deviceType.type_name}</b></td>
                            </tr>
                            </tbody>
                        </Table>
                        <Card className={'mb-4'}>
                            <Card.Body>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>The Reason</Form.Label>
                                    <Form.Control as="textarea" rows={2} name="reason" onChange={this.onReasonChange}/>
                                </Form.Group>
                                {
                                    authorization("IC_C_LOCK") && device.devicestatus === 'Limited' && (
                                        <>
                                            <Button variant={"light"} size={"sm"}
                                                    onClick={async () => {
                                                        if (window.confirm("Are you sure,you want to lock this phone ?") === true) {
                                                            await this.action(device.lockDevice(device.imei, reason));
                                                        }
                                                    }} className={'mr-3'} disabled={loading || reason === ""}>
                                                <FontAwesomeIcon icon={faLock} title={'Lock the phone'}
                                                                 className={`icon icon-xs text-warning cursor-pointer`}/> Lock
                                            </Button>&emsp;
                                        </>
                                    )
                                }
                                {
                                    authorization("IC_C_UNLOCK") && device.devicestatus !== 'Limited' && (
                                        <>
                                            <Button variant={"light"} size={"sm"} onClick={async () => {
                                                if (window.confirm("Are you sure,you want to unlock this phone ?") === true) {
                                                    await this.action(device.unlockDevice(device.imei, reason));
                                                }
                                            }} className={'mr-3'} disabled={loading || reason === ""}>
                                                <FontAwesomeIcon icon={faUnlock} title={'Unlock the phone'}
                                                                 className={`icon icon-xs text-info cursor-pointer`}/> Unlock
                                            </Button>&emsp;
                                        </>
                                    )
                                }
                                {
                                    authorization("IC_D_OPEN") ? (
                                        <>
                                            <Button variant={"light"} size={"sm"} className={'mr-3'}
                                                    disabled={loading || reason === ""}
                                                    onClick={async () => {
                                                        if (window.confirm("Are you sure,you want to open this phone ?") === true) {
                                                            await this.action(device.openDevice(device.imei, reason));
                                                        }
                                                    }}>
                                                <FontAwesomeIcon icon={faExpand} title={'Open the phone'}
                                                                 className={`icon icon-xs text-success cursor-pointer`}/> Open
                                            </Button>&emsp;
                                        </>
                                    ) : (<></>)
                                }
                                {
                                    authorization("IC_D_STATUS") ? (
                                        <>
                                            <Button variant={"light"} size={"sm"} className={'mr-3'} disabled={loading}
                                                    onClick={async () => {
                                                        await this.action(device.statusDevice(device.imei, reason));
                                                    }}>
                                                <FontAwesomeIcon icon={faEye} title={'Status the phone'}
                                                                 className={`icon icon-xs text-dark cursor-pointer`}/> Status
                                            </Button>&emsp;
                                        </>
                                    ) : (<></>)
                                }
                                {
                                    authorization("IC_D_EXTEND") ? (
                                        <>
                                            <Button variant={"light"} size={"sm"} className={'mr-3'}
                                                    disabled={loading || reason === ""}
                                                    onClick={async () => {
                                                        if (window.confirm("Are you sure,you want to extend this phone ?") === true) {
                                                            await this.action(device.extendDevice(device.imei, device.msisdn, reason));
                                                        }
                                                    }}>
                                                <FontAwesomeIcon icon={faPlusCircle} title={'Extend the phone'}
                                                                 className={`icon icon-xs text-primary cursor-pointer`}/> Extend
                                            </Button>&emsp;
                                        </>
                                    ) : (<></>)
                                }
                            </Card.Body>
                        </Card>
                        {
                            loading ? (
                                <Card>
                                    <Card.Body className={'d-flex justify-content-center align-items-center'}>
                                        <Image
                                            className="loader-element animate__animated animate__jackInTheBox"
                                            src={ReactLogo} height={100} width={100}/>
                                    </Card.Body>
                                </Card>
                            ) : (<></>)
                        }
                        {
                            (deviceAction || error) && !loading ? (
                                <Card>
                                    <Card.Body className={error ? 'text-danger' : ''}>
                                        {deviceAction ? (
                                            <Table responsive striped
                                                   className="table-centered table-bordered table-nowrap rounded">
                                                <tbody>
                                                {
                                                    Object.entries(deviceAction)
                                                        .map(([k, v]) => <ShowResponse key={k} k={k} value={v}/>)
                                                }
                                                </tbody>
                                            </Table>
                                        ) : (<></>)}
                                        {error && error.toString()}
                                    </Card.Body>
                                </Card>
                            ) : (<></>)
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateProps = (state) => {
    const {error, deviceAction} = state.clients;
    return {
        error, deviceAction,
    };
}
export default connect(mapStateProps, {})(DeviceActions);
