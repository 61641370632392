import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {Button, Card, Form, FormControl, InputGroup, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {assignDeviceToStore, getDevicesNotInStore, getStoreDevices} from "../../../actions/stores";
import authorization from "../../../helpers/authorization";

const TableRow = (props) => {
    //
    const [deviceType, setDeviceType, deviceTypePrice] = useState(0);
    //
    const resetDeviceType = () => {
        setDeviceType(0);
        selectingDevice(0, 0)
    };
    //
    const {
        deviceTypeId, type_name, full_price, submitDevice, selectingDevice, index,
    } = props;
    //
    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{(index + 1)}</Card.Link>
            </td>
            <td className="fw-bold">
                {type_name}
            </td>
            <td align={'center'}>
                {
                    deviceType === deviceTypeId ?
                        (<>
                            <Form onSubmit={submitDevice}>
                                <InputGroup className="">
                                    <Form.Control type="number"
                                                  required
                                                  placeholder={deviceTypePrice ?? full_price}
                                                  onChange={(e) => selectingDevice(deviceTypeId, parseInt(e.target.value))}/>
                                    <Button variant="outline-secondary"
                                            id="assignDevice"
                                            disabled={!authorization("SD_CREATE")}
                                            type={"submit"}>
                                        Assign
                                    </Button>
                                </InputGroup>
                            </Form>
                        </>)
                        :
                        (<span>{full_price ? full_price.toLocaleString() : "-"}</span>)
                }
            </td>
            <td>
                {
                    deviceType === deviceTypeId ?
                        (
                            <>
                                <Button variant="light" size="sm">
                                    <FontAwesomeIcon icon={faMinusCircle}
                                                     onClick={resetDeviceType}
                                                     className={`icon icon-xs text-danger cursor-pointer`}/>
                                </Button>&nbsp;&nbsp;
                            </>
                        )
                        :
                        (
                            <>
                                <Button variant="light" size="sm" onClick={() => setDeviceType(deviceTypeId)}
                                        disabled={deviceType !== 0}>
                                    <FontAwesomeIcon icon={faPlusCircle}
                                                     className={`icon icon-xs text-success cursor-pointer`}/>
                                </Button>
                            </>
                        )
                }
            </td>
        </tr>
    );
};

export class DeviceNotInStore extends Component {
    //
    state = {
        loading: false,
        assigningDevice: 0,
        assigningPrice: 0,
        deviceTypes: null,
    };
    //
    selectingDevice = (deviceId, price) => {
        this.setState({assigningDevice: deviceId, assigningPrice: price});
    }
    //
    componentDidMount = async () => {
        const {getDevicesNotInStore, storeId} = this.props;
        this.setState({loading: true,});
        await getDevicesNotInStore(storeId);
        this.setState({loading: false,});
    }
    //
    submitDevice = async (e) => {
        e.preventDefault();
        const {assignDeviceToStore, storeId, getStoreDevices, getDevicesNotInStore, user} = this.props;
        const {assigningDevice, assigningPrice} = this.state;
        //
        if (assigningDevice && assigningPrice) {
            if (window.confirm(`Are sure you want to assign device to the store ?`) === true) {
                this.setState({loading: true,});
                await assignDeviceToStore({
                    storeId,
                    deviceTypeId: assigningDevice,
                    price: parseFloat(assigningPrice.toString()),
                    username: user.username,
                });
                await getDevicesNotInStore(storeId);
                await getStoreDevices(storeId);
                //
                this.setState({loading: false,});
            }
        } else {
            window.alert('Please make sure you selected a device and set price!');
        }

    }
    //
    onChanges = async (e) => {
        e.preventDefault();
        if (e.target.value) {
            const {devicesNotInTheStore} = this.props;
            const devices = devicesNotInTheStore?.filter((item, i) => `${item?.type_name}`.toLowerCase().includes(`${e.target.value}`.toLowerCase()));
            this.setState({deviceTypes: devices,});
        }
    }

    //
    render() {
        const {showModal, handleClose, devicesNotInTheStore} = this.props;
        const {loading, assigningDevice, deviceTypes,} = this.state;
        //
        let _deviceTypes = deviceTypes ?? devicesNotInTheStore;
        //
        return (
            <>
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    size={"lg"}
                    backdrop="static"
                    keyboard={false}
                    fullscreen={"lg-down"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Device type not in the store</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup className="mb-3">
                                <FormControl
                                    name={'search'}
                                    onChange={this.onChanges}
                                    placeholder="Search by device type"
                                    aria-label="Search by device type"/>
                            </InputGroup>
                        </Form>
                        <Table responsive className="table-centered table-bordered table-nowrap rounded mb-0"
                               style={{verticalAlign: 'middle'}}>
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Device type</th>
                                <th className="" style={{width: "25em"}}>Price</th>
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                    <tr>
                                        <td colSpan="5" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) :
                                _deviceTypes != null && _deviceTypes.length > 0 ?
                                    _deviceTypes?.map((device, i) => <TableRow {...device}
                                                                               index={i}
                                                                               submitDevice={this.submitDevice}
                                                                               selectedDevice={assigningDevice}
                                                                               selectingDevice={this.selectingDevice}
                                                                               key={`page-visit-${device.deviceTypeId}`}/>) : (
                                        <tr>
                                            <td colSpan="5" align="center">No device available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

/**
 * Adding reducer state to props
 *
 * @param state
 * @returns {{devicesNotInTheStore, error}}
 */
const mapStateProps = (state) => {
    const {devicesNotInTheStore, error} = state.store;
    const {user} = state.authentication;
    //
    return {
        devicesNotInTheStore, error, user,
    };
}

export default connect(mapStateProps, {getDevicesNotInStore, assignDeviceToStore, getStoreDevices})(DeviceNotInStore);