import React from "react";
import {faCashRegister, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {Col, Row} from '@themesberg/react-bootstrap';
import {
    BankStatsWidget,
    CircleChartWidget,
    CounterWidget,
    ProgressRepWidget,
    ProgressTrackWidget,
} from "../../components/Widgets";
import {connect} from "react-redux";
import {getDashboardData} from "../../actions/app";
import {numberWithCommas} from "../../helpers/dateHelper";

class DashboardOverview extends React.Component {
    state = {
        loading: false,
    }

    componentDidMount = async () => {
        const {getDashboardData, dates,} = this.props;
        if (dates) {
            await getDashboardData(dates);
        }
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const {dates, getDashboardData, dashboard} = this.props;
        if (dates && !dashboard) {
            await getDashboardData(dates);
        }
    }

    render() {
        const {dashboard,} = this.props;
        return (
            <>
                <Row className={'mt-3'}>
                    <Col xs={12} sm={6} xl={4} className="mb-4">
                        <CounterWidget
                            category="Active Customers"
                            title={numberWithCommas((`${dashboard?.activeCustomers ?? ''}`) ?? 0)}
                            period=""
                            percentage={numberWithCommas(`${dashboard?.activeCustomers ?? ''}` ?? 0)}
                            icon={faChartLine}
                            iconColor="shape-secondary"
                        />
                    </Col>

                    <Col xs={12} sm={6} xl={4} className="mb-4">
                        <CounterWidget
                            category="Turnover"
                            title={numberWithCommas((`${dashboard?.turnover ?? ""}`) ?? 0)}
                            period=""
                            percentage={numberWithCommas((`${dashboard?.turnover ?? ""}`.toLocaleString()))}
                            icon={faCashRegister}
                            iconColor="shape-tertiary"
                        />
                    </Col>

                    <Col xs={12} sm={6} xl={4} className="mb-4">
                        <CircleChartWidget
                            title="Top devices"
                            data={dashboard?.deviceCategories}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} xl={12} className="mb-4">
                        <Row>
                            <Col xs={12} xl={8} className="mb-4">
                                <Row>
                                    <Col xs={12} lg={6} className="mb-4">
                                        <BankStatsWidget banks={dashboard?.banks ?? []}/>
                                    </Col>
                                    <Col xs={12} lg={6} className="mb-4">
                                        <ProgressTrackWidget stores={dashboard?.stores ?? []}/>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} xl={4}>
                                <Row>
                                    <Col xs={12} className="mb-4">
                                        <Col xs={12} lg={12} className="mb-4">
                                            <ProgressRepWidget reps={dashboard?.dashreps ?? []}/>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateProps = (store) => {
    const {dashboard, error, dates} = store.app;
    return {dashboard, error, dates};
} 
export default connect(mapStateProps, {
    getDashboardData,
})(DashboardOverview);
