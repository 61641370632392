import { Button } from "@themesberg/react-bootstrap";
import { BeatLoader } from "react-spinners";
import { connect } from 'react-redux';
import { FetchClosureLoans, UpdateLoanInitiation } from "../../../actions/loans";


const UpdateConfirmDialog = ({
    type,
    setShow,
    actionTxt,
    loading,
    activeRow,
    updateInitiatedLoan,
    getInitiatedLoans
}) => {
    let content = activeRow;

    const handleUpdateRecord = async (data) => {
        const payLoadToSend = {
            id: data?.id,
            reviewerComment: data?.fromMsisdn,
            status: type === "approve" ? "APPROVED" : "REJECT"
        }
        let response = await updateInitiatedLoan(payLoadToSend)
        console.log('modal-response', response)

        if (response?.data?.message === "Customer record was updated successfully") {
            setShow(false);

            // Fetch records again 
            getInitiatedLoans()
        }
    }

    return (
        <>
        <>
            <>
                <h5>Are you sure you want to {type} this Loan???</h5>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Msisdn:</b>
                    <span style={{ fontSize: "17px" }}>{content?.msisdn}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Customer Loan Status:</b>
                    <span style={{ fontSize: "17px" }}>{content?.loan?.loanStatus}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Loan Initiation Status:</b>
                    <span style={{ fontSize: "17px" }}>{content?.status}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Initiated By:</b>
                    <span style={{ fontSize: "17px" }}>{content?.initiatedBy}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Initiated On:</b>
                    <span style={{ fontSize: "17px" }}>{content?.initiatedOn}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Reason:</b>
                    <span style={{ fontSize: "17px" }}>{content?.reason}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Review By:</b>
                    <span style={{ fontSize: "17px" }}>{content?.reviewBy}</span>
                </p>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Review On:</b>
                    <span style={{ fontSize: "17px" }}>{content?.reviewOn}</span>
                </p>
            
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Review Comment:</b>
                    <span style={{ fontSize: "17px" }}>{content?.reviewComment}</span>
                </p>
            </>


            {/* Action buttons  */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "30px"
                }}
            >
                <Button
                    variant={"dark"}
                    size={"sm"}
                    color="white"
                    onClick={() => handleUpdateRecord(content)}
                    disabled={loading}
                >
                    {loading ? <BeatLoader color="#fff" /> : (actionTxt || "Proceed")}
                </Button>
            </div>
        </>
    </>
    );
};

const mapDispatchToProps = dispatch => ({
    updateInitiatedLoan: (payload) => dispatch(UpdateLoanInitiation(payload)),
    getInitiatedLoans: () => dispatch(FetchClosureLoans())
})

const mapStateToProps = (store) => {
    const { loading } = store?.loans;
    return { loading };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateConfirmDialog );