import {Routes} from "../../routes";
import {CURRENT_PAGE, DASHBOARD_DATA, DASHBOARD_DATE, DASHBOARD_ERROR, LOADING_STATE} from "../../actions/types";
import {addDaysToNow, dateNow} from "../../helpers/dateHelper";

const init = {
    currentPage: Routes.DashboardOverview.path,
    loadingState: false,
};
const appReducer = (state = init, action) => {
    switch (action.type) {
        case CURRENT_PAGE:
            return {
                currentPage: action.payload,
            }
        case LOADING_STATE:
            return {
                loadingState: action.payload,
            }
        case DASHBOARD_DATA:
            return {
                ...state,
                dashboard: action.payload,
            }
        case DASHBOARD_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case DASHBOARD_DATE:
            return {
                ...state,
                dates: {
                    from: action.payload.from ?? (dateNow().getTime()),
                    to: action.payload.to ?? (addDaysToNow(30).getTime()),
                }
            }
        default:
            return {
                ...state,
            }
    }
}

export default appReducer;