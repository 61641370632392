/**
 * App State type
 */
export const CURRENT_PAGE = "CURRENT_PAGE";
export const LOADING_STATE = "LOADING_STATE";

/**
 * Auth actions type
 */
export const AUTHENTICATED = "AUTHENTICATED";
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILURE = "LOGIN_FAILURE";
/**
 * Get user data
 */
export const USER_DATA = 'USER_DATA';
export const USER_ERROR = 'USER_ERROR';
export const USER_CREATED = 'USER_CREATED';
export const USER_CREATE_FAILED = 'USER_CREATE';
export const GET_USERS = 'GET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const ERROR_EDITING_USER = 'ERROR_EDITING_USER';
export const GET_REPRESENTATIVE_USERS = 'GET_REPRESENTATIVE_USERS';
export const GET_UNDEPLOYED_REPRESENTATIVE = 'GET_UNDEPLOYED_REPRESENTATIVE';
export const CREATE_REPRESENTATIVE_USER = 'CREATE_REPRESENTATIVE_USER';
export const CREATE_REPRESENTATIVE_ERROR_USER = 'CREATE_REPRESENTATIVE_ERROR_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_ACTIVITY_LOGS = 'GET_USER_ACTIVITY_LOGS';
export const GET_USERS_DEVICES = 'GET_USERS_DEVICES';

/**
 * ROLES && GROUPS
 */
export const ROLE_GROUPS = 'ROLE_GROUPS';
export const USER_GROUPS = 'USER_GROUPS';
export const USER_ROLES = 'USER_ROLES';
export const ROLE_GROUP = 'ROLE_GROUP';
export const GET_ROLES = 'GET_ROLES';
export const ROLE_ERROR = 'ROLE_ERROR';
export const ROLE_GROUPS_CREATE = 'ROLE_GROUPS_CREATE';
export const GET_ROLES_NOT_IN_GROUP = 'GET_ROLES_NOT_IN_GROUP';
export const GET_ROLES_IN_GROUP = 'GET_ROLES_IN_GROUP';
export const ASSIGN_USER_TO_GROUP = 'ASSIGN_USER_TO_GROUP';
export const REVOKE_USER_FROM_GROUP = "REVOKE_USER_FROM_GROUP";
export const ADD_ROLE_TO_GROUP = 'ADD_ROLE_TO_GROUP';
export const REMOVE_ROLE_TO_GROUP = 'REMOVE_ROLE_TO_GROUP';
export const CREATE_ROLE = "CREATE_ROLE";
export const REMOVE_USER_ROLE = "REMOVE_USER_ROLE";
export const REMOVE_ROLES_GROUP = "REMOVE_ROLES_GROUP";

/**
 * Get Devices data
 */
export const GET_DEVICES = 'GET_DEVICES';
export const ERROR_DEVICES = 'ERROR_DEVICES';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const GET_DEVICES_CATEGORY = 'GET_DEVICES_CATEGORY';
export const ERROR_DEVICES_CATEGORY = 'ERROR_DEVICES_CATEGORY';
export const GET_DEVICE_DETAILS = 'GET_DEVICE_DETAILS';
export const UPLOAD_DEVICE_IMAGE = 'UPLOAD_DEVICE_IMAGE';
export const UPLOAD_DEVICE_IMAGE_ERROR = 'UPLOAD_DEVICE_IMAGE_ERROR';

/**
 * Get Stores data
 */
export const GET_STORES = 'GET_STORES';
export const ERROR_STORES = 'ERROR_STORES';
export const CREATE_STORES = 'CREATE_STORE';
export const GET_DEVICE_IN_STORES = 'GET_DEVICE_IN_STORES';
export const GET_REPRESENTATIVE_IN_STORES = 'GET_REPRESENTATIVE_IN_STORES';
export const VIEW_STORE_DETAILS = 'VIEW_STORE_DETAILS';
export const GET_DEVICE_NOT_IN_STORE = 'GET_DEVICE_NOT_IN_STORE';
export const REMOVE_DEVICE_FROM_STORE = 'REMOVE_DEVICE_FROM_STORE';
export const ASSIGN_DEVICE_TO_STORE = 'ASSIGN_DEVICE_TO_STORE';
export const ERROR_ASSIGN_DEVICE_TO_STORE = 'ERROR_ASSIGN_DEVICE_TO_STORE';
export const ATTACH_REPRESENTATIVE_TO_STORE = 'ATTACH_REPRESENTATIVE_TO_STORE';
export const REMOVE_REPRESENTATIVE_FROM_STORE = 'REMOVE_REPRESENTATIVE_FROM_STORE';
export const ERROR_REPRESENTATIVE_STORE = 'ERROR_REPRESENTATIVE_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_STORE_PRICE = 'UPDATE_STORE_PRICE';

/**
 * Get clients types
 */
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENT_DEVICES = 'GET_CLIENT_DEVICES';
export const GET_CLIENT_LOANS = 'GET_CLIENT_LOANS';
export const GET_CLIENT_TRANSACTIONS = 'GET_CLIENT_TRANSACTIONS';
export const GET_CLIENT_FILE = 'GET_CLIENT_FILE';
export const GET_CLIENTS_TYPES = 'GET_CLIENTS_TYPES';
export const ERROR_CLIENTS = 'ERROR_CLIENTS';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const LEGIBILITY_CHECK = 'LEGIBILITY_CHECK';
export const PROVISIONING = 'PROVISIONING';
//
export const CHANGE_DEVICE_SIM_NUMBER = "CHANGE_DEVICE_SIM_NUMBER";
export const ERROR_CHANGE_SIM_NUMBER = "ERROR_CHANGE_SIM_NUMBER";
export const CHANGE_DEVICE = "CHANGE_DEVICE";
export const ERROR_CHANGE_DEVICE = "ERROR_CHANGE_DEVICE";
//
export const LOCK_CLIENT_DEVICE = 'LOCK_CLIENT_DEVICE';
export const UNLOCK_CLIENT_DEVICE = 'UNLOCK_CLIENT_DEVICE';
export const OPEN_CLIENT_DEVICE = "OPEN_CLIENT_DEVICE";
export const STATUS_CLIENT_DEVICE = "STATUS_CLIENT_DEVICE";
export const EXTEND_CLIENT_DEVICE = "EXTEND_CLIENT_DEVICE";
export const GET_CLIENT_TRANSACTION_LOG = "GET_CLIENT_TRANSACTION_LOG";

/**
 * Corporates
 */
export const GET_CORPORATES = "GET_CORPORATES";
export const CREATE_CORPORATE = "CREATE_CORPORATE";
export const ERROR_CORPORATE = "ERROR_CORPORATE";
export const CORPORATE_UPLOAD_TEMPLATE = "CORPORATE_UPLOAD_TEMPLATE";
export const UPLOAD_CORPORATE_ERROR = "UPLOAD_CORPORATE_ERROR";
export const GET_CORPORATE = "GET_CORPORATE";
export const GET_CORPORATE_MEMBERS = "GET_CORPORATE_MEMBERS";
export const GET_CORPORATE_USERS = "GET_CORPORATE_USERS";
export const GET_CORPORATE_TRANSACTIONS = "GET_CORPORATE_TRANSACTIONS";

/**
 * Plans
 */
export const GET_PLANS = "GET_PLANS";
export const EDIT_PLAN = "EDIT_PLAN";
export const VIEW_PLAN = "VIEW_PLAN";
export const ERROR_PLAN = "ERROR_PLAN";

/**
 * Partners
 */
export const GET_PARTNERS = "GET_PARTNERS";
export const VIEW_PARTNER = "VIEW_PARTNER";
export const ERROR_PARTNER = "ERROR_PARTNER";
export const EDIT_PARTNER = "EDIT_PARTNER";
export const CREATE_PARTNER = "CREATE_PARTNER";
export const GET_PARTNERS_PLANS = "GET_PARTNERS_PLANS";
export const ERROR_PARTNERS_PLANS = "ERROR_PARTNERS_PLANS";
export const GET_ALL_BANKS = 'GET_ALL_BANKS';
export const EDIT_BANKS = "EDIT_BANKS";
export const CREATE_BANKS = "CREATE_BANKS";
export const VIEW_BANK = "VIEW_BANK";
export const GET_BANK_LOANS = "GET_BANK_LOANS";
export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const GET_BANK_FINANCING_CARD = "GET_BANK_FINANCING_CARD";
export const UPDATE_BANK_FINANCING_CARD = "UPDATE_BANK_FINANCING_CARD";
export const PARTNER_TYPES = 'PARTNER_TYPES';
export const PARTNER_DATA = 'PARTNER_DATA';
export const PARTNER_SINGLE_DATA = 'PARTNER_SINGLE_DATA';
export const PARTNER_CREATE_FREELANCER = 'PARTNER_CREATE_FREELANCER';
export const PARTNER_GET_FREELANCERS = 'PARTNER_GET_FREELANCERS';
export const PARTNER_GET_FREELANCE = 'PARTNER_GET_FREELANCE';
export const PARTNER_LOADING_FREELANCE = 'PARTNER_LOADING_FREELANCE';

/**
 * Loan Allocation
 */
export const GET_ALLOCATED_LOANS = "GET_ALLOCATED_LOANS";
export const ERROR_ALLOCATED_LOANS = "ERROR_ALLOCATED_LOANS";
export const GET_LOAN_REPAYMENT = "GET_LOAN_REPAYMENT";
export const POST_LOAN_REPAYMENT = "POST_LOAN_REPAYMENT";
export const UPDATE_LOAN_REPAYMENT = "UPDATE_LOAN_REPAYMENT";
export const ERROR_LOAN_REPAYMENT = "ERROR_LOAN_REPAYMENT";

/**
 * Tickets
 */
export const GET_TICKETS = "GET_TICKETS";
export const ERROR_TICKETS = "ERROR_TICKETS";
export const CREATE_TICKETS = "CREATE_TICKETS";
export const CLOSE_TICKETS = "CLOSE_TICKETS";
export const DELETE_TICKETS = "DELETE_TICKETS";
export const REPLY_TICKETS = "REPLY_TICKETS";

/**
 * Notifications
 */
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const NOTIFICATION_DEVICE = "NOTIFICATION_DEVICE";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";

/**
 * FAQ
 */
export const GET_FAQS = "GET_FAQS";
export const CREATE_FAQ = "CREATE_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const VIEW_FAQ = "VIEW_FAQ";
export const FAQ_ERROR = "FAQ_ERROR";

/**
 * Utilities
 */
export const SIM_SWAP = "SIM_SWAP";
export const ERROR_SIM_SWAP = "ERROR_SIM_SWAP";

/**
 * Places
 */

export const PLACES_ERROR = "PLACES_ERROR";
export const GET_PROVINCES = "GET_PROVINCES";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_SECTOR = "GET_SECTOR";
export const GET_CELL = "GET_CELL";
export const GET_VILLAGE = "GET_VILLAGE";

/**
 * Dashboard
 */

export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const DASHBOARD_DATE = 'DASHBOARD_DATE';

/**
 * Accounting
 */
export const GET_ACCOUNTING_NUMBER = 'GET_ACCOUNTING_NUMBER';
export const GET_ACCOUNTING_HISTORY = 'GET_ACCOUNTING_HISTORY';
export const ACCOUNTING_ERROR = 'ACCOUNTING_ERROR';

/**
 * Reports
 */
export const REPORT_GET_TELCO_LOANS = 'REPORT_GET_TELCO_LOANS'; 
export const REPORT_ERROR = 'REPORT_ERROR';
export const REPORT_LOADING = 'REPORT_LOADING';

/**
 * Newly added - Starts
 */
// Single Manual Repayment 
export const SINGLE_MANUAL_REPAYMENT_STARTS = 'SINGLE_MANUAL_REPAYMENT_STARTS';
export const SINGLE_MANUAL_REPAYMENT_ENDS = 'SINGLE_MANUAL_REPAYMENT_ENDS';

// Multiple manual repayment 
export const MULTIPLE_MANUAL_REPAYMENT_STARTS = 'MULTIPLE_MANUAL_REPAYMENT_STARTS';
export const MULTIPLE_MANUAL_REPAYMENT_ENDS = 'MULTIPLE_MANUAL_REPAYMENT_ENDS';

// Check manual repayment 
export const CHECK_MANUAL_REPAYMENT_STATUS_STARTS = 'CHECK_MANUAL_REPAYMENT_STATUS_STARTS';
export const CHECK_MANUAL_REPAYMENT_STATUS_ENDS = 'CHECK_MANUAL_REPAYMENT_STATUS_ENDS';

// Single customer paylog 
export const SINGLE_CUSTOMER_PAYMENT_STARTS = 'SINGLE_CUSTOMER_PAYMENT_STARTS';
export const SINGLE_CUSTOMER_PAYMENT_ENDS = 'SINGLE_CUSTOMER_PAYMENT_ENDS';

// Multiple customer paylog 
export const MULTIPLE_CUSTOMER_PAYMENT_STARTS = 'MULTIPLE_CUSTOMER_PAYMENT_STARTS';
export const MULTIPLE_CUSTOMER_PAYMENT_ENDS = 'MULTIPLE_CUSTOMER_PAYMENT_ENDS';

// Fetch customer paylog 
export const FETCH_CUSTOMER_PAYMENT_LOGS_STARTS = 'FETCH_CUSTOMER_PAYMENT_LOGS_STARTS';
export const FETCH_CUSTOMER_PAYMENT_LOGS_SUCCESS = 'FETCH_CUSTOMER_PAYMENT_LOGS_SUCCESS';
export const FETCH_CUSTOMER_PAYMENT_LOGS_ENDS = 'FETCH_CUSTOMER_PAYMENT_LOGS_ENDS';

// Update customer paylog 
export const UPDATE_CUSTOMER_PAYMENT_LOGS_STARTS = 'UPDATE_CUSTOMER_PAYMENT_LOGS_STARTS';
export const UPDATE_CUSTOMER_PAYMENT_LOGS_SUCCESS = 'UPDATE_CUSTOMER_PAYMENT_LOGS_SUCCESS';
export const UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS = 'UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS';

// Check customer paylog 
export const CHECK_CUSTOMER_PAYMENT_LOG_STARTS = 'CHECK_CUSTOMER_PAYMENT_LOG_STARTS';
export const CHECK_CUSTOMER_PAYMENT_LOG_SUCCESS = 'CHECK_CUSTOMER_PAYMENT_LOG_SUCCESS';
export const CHECK_CUSTOMER_PAYMENT_LOG_ENDS = 'CHECK_CUSTOMER_PAYMENT_LOG_ENDS';

// Get customer details 
export const GET_CUSTOMER_DETAILS_START = 'GET_CUSTOMER_DETAILS_START';
export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS';
export const GET_CUSTOMER_DETAILS_ENDS = 'GET_CUSTOMER_DETAILS_ENDS';

// Process customer graduation 
export const PROCESS_CUSTOMER_GRADUATION_START = 'PROCESS_CUSTOMER_GRADUATION_START';
export const PROCESS_CUSTOMER_GRADUATION_SUCCESS = 'PROCESS_CUSTOMER_GRADUATION_SUCCESS';
export const PROCESS_CUSTOMER_GRADUATION_ENDS = 'PROCESS_CUSTOMER_GRADUATION_ENDS';

// Process customer graduation 
export const FETCH_GRADUATING_CUSTOMER_START = 'FETCH_GRADUATING_CUSTOMER_START';
export const FETCH_GRADUATING_CUSTOMER_SUCCESS = 'FETCH_GRADUATING_CUSTOMER_SUCCESS';
export const FETCH_GRADUATING_CUSTOMER_ENDS = 'FETCH_GRADUATING_CUSTOMER_ENDS';

// Verify & graduate customer graduation 
export const VERIFY_GRADUATE_CUSTOMER_START = 'VERIFY_GRADUATE_CUSTOMER_START';
export const VERIFY_GRADUATE_CUSTOMER_SUCCESS = 'VERIFY_GRADUATE_CUSTOMER_SUCCESS';
export const VERIFY_GRADUATE_CUSTOMER_ENDS = 'VERIFY_GRADUATE_CUSTOMER_ENDS';

// Resove Active Plan Issues
export const RESOLVE_COMMON_ISSUES_START = 'RESOLVE_COMMON_ISSUES_START';
export const RESOLVE_COMMON_ISSUES_SUCCESS = 'RESOLVE_COMMON_ISSUES_SUCCESS';
export const RESOLVE_COMMON_ISSUES_ENDS = 'RESOLVE_COMMON_ISSUES_ENDS';

export const READ_TRANSACTION_STATUS_START = 'READ_TRANSACTION_STATUS_START';
export const READ_TRANSACTION_STATUS_SUCCESS = 'READ_TRANSACTION_STATUS_SUCCESS';
export const READ_TRANSACTION_STATUS_ENDS = 'READ_TRANSACTION_STATUS_ENDS';

export const READ_PENDING_TRANSACTIONS_START = 'READ_PENDING_TRANSACTIONS_START';
export const READ_PENDING_TRANSACTIONS_SUCCESS = 'READ_PENDING_TRANSACTIONS_SUCCESS';
export const READ_PENDING_TRANSACTIONS_ENDS = 'READ_PENDING_TRANSACTIONS_ENDS';

export const RESOLVE_MISSING_RECORDS_START = 'RESOLVE_MISSING_RECORDS_START';
export const RESOLVE_MISSING_RECORDS_SUCCESS = 'RESOLVE_MISSING_RECORDS_SUCCESS';
export const RESOLVE_MISSING_RECORDS_ENDS = 'RESOLVE_MISSING_RECORDS_ENDS';

export const RESOLVE_PAYMENT_FETCH_START = 'RESOLVE_PAYMENT_FETCH_START';
export const RESOLVE_PAYMENT_FETCH_SUCCESS = 'RESOLVE_PAYMENT_FETCH_SUCCESS';
export const RESOLVE_PAYMENT_FETCH_ENDS = 'RESOLVE_PAYMENT_FETCH_ENDS';

export const RESOLVE_DEVICE_DETAILS_START = 'RESOLVE_DEVICE_DETAILS_START';
export const RESOLVE_DEVICE_DETAILS_SUCCESS = 'RESOLVE_DEVICE_DETAILS_SUCCESS';
export const RESOLVE_DEVICE_DETAILS_ENDS = 'RESOLVE_DEVICE_DETAILS_ENDS';

export const INITIATE_LOAN_CANCELLATION_START = 'INITIATE_LOAN_CANCELLATION_START';
export const INITIATE_LOAN_CANCELLATION_SUCCESS = 'INITIATE_LOAN_CANCELLATION_SUCCESS';
export const INITIATE_LOAN_CANCELLATION_ENDS = 'INITIATE_LOAN_CANCELLATION_ENDS';

export const FETCH_CLOSURE_LOANS_START = 'FETCH_CLOSURE_LOANS_START';
export const FETCH_CLOSURE_LOANS_SUCCESS = 'FETCH_CLOSURE_LOANS_SUCCESS';
export const FETCH_CLOSURE_LOANS_ENDS = 'FETCH_CLOSURE_LOANS_ENDS';

export const UPDATE_LOAN_CLOSURE_START = 'UPDATE_LOAN_CLOSURE_START';
export const UPDATE_LOAN_CLOSURE_SUCCESS = 'UPDATE_LOAN_CLOSURE_SUCCESS';
export const UPDATE_LOAN_CLOSURE_ENDS = 'UPDATE_LOAN_CLOSURE_ENDS';

export const TEMPORARY_EXTENSION_START = 'TEMPORARY_EXTENSION_START';
export const TEMPORARY_EXTENSION_SUCCESS = 'TEMPORARY_EXTENSION_SUCCESS';
export const TEMPORARY_EXTENSION_ENDS = 'TEMPORARY_EXTENSION_ENDS';

/**
 * Newly added - Ends
 */