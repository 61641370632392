import React, {Component} from "react";
import {Button, Form, FormControl, InputGroup, Modal} from "@themesberg/react-bootstrap";
import {getUser} from "../../../helpers/tokenHelper";

export class ChangeStorePrice extends Component {
    state = {
        loading: false,
        price: null,
        device: this.props.device,
    }

    onSubmit = async () => {
        const {updateDevicePrice, getStoreDevices, store, onClose} = this.props;
        const {device: {deviceTypeId}, price} = this.state;
        this.setState({loading: true});
        if (price) {
            try {
                const data = {
                    storeId: store,
                    deviceTypeId: deviceTypeId,
                    price: parseFloat(price),
                    username: getUser()?.username,
                };
                await updateDevicePrice(data);
            } catch (e) {
            }
            await getStoreDevices(store);
            this.setState({loading: false});
            onClose();
        } else {
            alert('New Price required!');
        }
    }

    render() {
        const {onOpen, onClose, initialPrice, device, error, updatedPrice} = this.props;
        const {price, loading} = this.state;
        //
        return (
            <>
                <Modal show={onOpen} onHide={onClose} size={'lg'} backdrop={'static'} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4>Change Device Price</h4>
                            <small><u>{device.typeName}</u></small>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>RWF</InputGroup.Text>
                                <FormControl
                                    type={'number'}
                                    name={'price'}
                                    min={initialPrice ?? 1}
                                    onChange={e => {
                                        this.setState({price: e.target.value});
                                    }}
                                    placeholder={initialPrice}
                                    aria-label="Price"/>
                                <Button type={'button'}
                                        disabled={!price || price === '' || loading}
                                        onClick={!loading ? this.onSubmit : null}>
                                    {loading ? 'Submitting...' : 'Save'}
                                </Button>
                            </InputGroup>
                            {
                                (error) && (<Form.Text id="priceHelpBlock" muted>{error}</Form.Text>)
                            }

                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
