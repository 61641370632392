import {action} from "../index";
import {
    CREATE_BANKS,
    EDIT_BANKS,
    ERROR_PARTNER,
    GET_ALL_BANKS,
    GET_BANK_ACCOUNTS,
    GET_BANK_FINANCING_CARD,
    GET_BANK_LOANS,
    GET_PARTNERS,
    GET_PARTNERS_PLANS, PARTNER_CREATE_FREELANCER,
    PARTNER_DATA, PARTNER_GET_FREELANCERS, PARTNER_LOADING_FREELANCE, PARTNER_SINGLE_DATA,
    PARTNER_TYPES,
    UPDATE_BANK_FINANCING_CARD,
    VIEW_BANK
} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";
import {partners} from "../../data/tables";
import {allocatedLoans} from "../../data/loans";

/**
 * Get partners
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getPartners = () => async (dispatch) => {
    try {
        // const token = getToken();
        // const headers = {
        //     Authorization: "Bearer ".concat(token),
        // };
        // const req = await apiCall.get(`/intelligrapi/`, {
        //     headers,
        // });
        dispatch(action(GET_PARTNERS, partners));

    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}

/**
 * Get partners & plans
 * @returns {(function(*): Promise<void>)|*}
 */
export const getPartnersPlans = () => async (dispatch) => {
    try {
        // const token = getToken();
        // const headers = {
        //     Authorization: "Bearer ".concat(token),
        // };
        // const req = await apiCall.get(`/intelligrapi/`, {
        //     headers,
        // });
        dispatch(action(GET_PARTNERS_PLANS, []));
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}
/**
 * Get all available banks
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllBanks = () => async (dispatch) => {
    dispatch(action(PARTNER_LOADING_FREELANCE, true));
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get('/intelligrapi/financials/allbanks', {
            headers,
        });
        if (req.data.data) {
            dispatch(action(GET_ALL_BANKS, JSON.parse(req.data.data)));
        }
    } catch (e) {
        dispatch(action(GET_ALL_BANKS, []));
        dispatch(action(ERROR_PARTNER, e.message));
    }
    dispatch(action(PARTNER_LOADING_FREELANCE, false));
}

/**
 * Create a new bank
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const createBank = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/financials/createbank', data, {
            headers,
        });
        //
        if (req.data.data) {
            dispatch(action(CREATE_BANKS, JSON.parse(req.data.data)));
            return true;
        } else {
            dispatch(action(ERROR_PARTNER, "No Data provided."));
        }
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
    return false;
}

/**
 * Update a bank
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const updateBank = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/financials/editingabank', data, {
            headers,
        });
        //
        dispatch(action(EDIT_BANKS, JSON.parse(req.data.data)));
        return true;
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
    return false;
}

/**
 * View  a single bank
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const viewBank = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/financials/bankbyid?bankid=${id}`, {
            headers,
        });
        //
        dispatch(action(VIEW_BANK, JSON.parse(req.data.data)));
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}

/**
 * Get allocated url
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getBankAllocatedLoans = (id) => async (dispatch) => {
    try {
        // const token = getToken();
        // const headers = {
        //     Authorization: "Bearer ".concat(token),
        // };
        // const req = await apiCallF.get(`/intelligrapi/financials/bankbyid?bankid=${id}`, {
        //     headers,
        // });
        //
        dispatch(action(GET_BANK_LOANS, allocatedLoans));
        //
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}
/**
 * Get banks accounts
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getBankAccounts = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/financials/openaccount?bankid=${id}`, {
            headers,
        });
        //
        dispatch(action(GET_BANK_ACCOUNTS, req.data));
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}
/**
 * Get bank financing card
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getBankFinancingCard = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/financials/bankscorefinancingcard?bankid=${id}`, {
            headers,
        });
        //
        dispatch(action(GET_BANK_FINANCING_CARD, JSON.parse((req.data?.data ?? '[]'))));
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}

/**
 * Update bank financing card
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const updateBankFinancingCard = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.post(`/intelligrapi/financials/addbankscorefinancing`, data, {
            headers,
        });
        //
        dispatch(action(UPDATE_BANK_FINANCING_CARD, JSON.parse(req.data.data)));
        if (req.data.data) {
            return true;
        }
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
        return e.message;
    }
}

/**
 * Get partner types
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getPartnerTypes = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.get("/intelligrapi/partnertypes", {headers});
        const data = req.data;
        const _data = data.filter(item => {
            return item.typeName && item.typeName.toString().toLowerCase().includes('intelligra') ? null : item;
        });
        dispatch(action(PARTNER_TYPES, _data));
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}

/**
 * Get partner data
 *
 * @param code
 * @returns {(function(*): Promise<void>)|*}
 */
export const getPartnerData = (code) => async (dispatch) => {
    dispatch(action(PARTNER_LOADING_FREELANCE, true));
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.get(`/intelligrapi/partnersbycode?code=${code.toString()}`, {headers});
        dispatch(action(PARTNER_DATA, req.data ?? []));
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
        dispatch(action(PARTNER_DATA, []));
    }
    dispatch(action(PARTNER_LOADING_FREELANCE, false));
}

/**
 * Get single partner data
 *
 * @param code
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getSinglePartnerData = (code, id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.get(`/intelligrapi/partnersbycodeid?code=${code.toString()}&id=${id}`, {headers});
        dispatch(action(PARTNER_SINGLE_DATA, req.data));
        console.log(req.data);
        //
    } catch (e) {
        dispatch(action(ERROR_PARTNER, e.message));
    }
}

/**
 * Save freelancer for partner
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const createPartnerFreelancer = (data) => async (dispatch) => {
    dispatch(action(PARTNER_LOADING_FREELANCE, true));
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.post('/intelligrapi/freelancer/freelancer', data, {
            headers,
        });
        dispatch(action(PARTNER_CREATE_FREELANCER, req.data));
    } catch (e) {
        if (e.response && e.response.data?.message) {
            let message = e.response.data?.message;
            if (message instanceof String) {
                message = [message];
            }
            dispatch(action(ERROR_PARTNER, message));
        } else {
            dispatch(action(ERROR_PARTNER, e.message));
        }
    }
    dispatch(action(PARTNER_LOADING_FREELANCE, false));
}

/**
 * Get freelancers for partner
 *
 * @param partner
 * @returns {(function(*): Promise<void>)|*}
 */
export const getFreelancersPartner = (partner) => async (dispatch) => {
    dispatch(action(PARTNER_LOADING_FREELANCE, true));
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.get(`/intelligrapi/freelancer/freelancersbypartner?partnerid=${partner}`, {
            headers,
        });
        console.log(req.data);
        dispatch(action(PARTNER_GET_FREELANCERS, (req.data?.data ?? [])));
    } catch (e) {
        if (e.response && e.response.data?.message) {
            dispatch(action(ERROR_PARTNER, e.response.data.message));
        } else {
            dispatch(action(ERROR_PARTNER, e.message));
        }
    }
    dispatch(action(PARTNER_LOADING_FREELANCE, false));
}
