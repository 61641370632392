import React, {Fragment} from "react";
import {connect} from "react-redux";
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Nav,
    Pagination,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCog, faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import {getPartnerData, getPartnerTypes} from "../../actions/partners";
import {getAccountingHistory, getAccountingNumber} from "../../actions/accounting";

const TableRow = (props) => {

    return (
        <tr className={'text-center text-border px-1 py-0'}>
            <td>1</td>
            <td>IP</td>
            <td>0005</td>
            <td>42,000</td>
            <td className={'px-1 py-1'}>
                <Fragment>
                    <Table responsive striped bordered>
                        <thead className={'thead-light'}>
                        <tr className={'py-1'}>
                            <th colSpan={2}>Credited Account</th>
                            <th>Credited Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width={'4em'}>1</td>
                            <td>0002</td>
                            <td>20,000</td>
                        </tr>
                        <tr>
                            <td width={'4em'}>2</td>
                            <td>0003</td>
                            <td>22,000</td>
                        </tr>
                        </tbody>
                    </Table>
                </Fragment>
            </td>
            <td>June 20, 2022</td>
        </tr>
    )
}

class Transactions extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        loading: false,
        loadingPartnerData: false,
        loadingAccountNumber: false,
        userpartnertyype: "",
        accountTypeCode: null,
        partnerId: null,
        accountNumber: "",
    }
    //
    componentDidMount = async () => {
        const {getPartnerTypes} = this.props;
        this.setState({loading: true});
        await getPartnerTypes();
        this.setState({loading: false});
    }
    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    };
    //
    handlePartnerChange = async (e) => {
        e.preventDefault();
        const {value} = e.target;
        const {getPartnerData,} = this.props;
        const splitValue = value.toString().split(":");
        if (splitValue.length > 0) {
            const _name = splitValue[0];
            const _code = splitValue[1];
            this.setState({loadingPartnerData: true, loading: true, userpartnertyype: _name, accountTypeCode: _code,});
            await getPartnerData(_code);
            this.setState({loadingPartnerData: false, loading: false,});
        }
    }
    //
    handlePartnerDataChange = async (e) => {
        e.preventDefault();
        const {value} = e.target;
        if (value !== "") {
            this.setState({partnerId: value, loadingAccountNumber: true});
            const {accountTypeCode,} = this.state;
            const {getAccountingNumber,} = this.props;
            await getAccountingNumber(value, accountTypeCode);
            this.setState({loadingAccountNumber: false});
        }
    }
    //
    handleSearchHistory = async (e) => {
        e.preventDefault();
    }
    //
    setStartDate = (date) => {
        this.setState({startDate: date,});
    }
    //
    setEndState = (date) => {
        this.setState({endDate: date,});
    }

    //
    render() {
        const {
            startDate,
            endDate,
            userpartnertyype,
            loadingPartnerData,
            userpartnertyypeId,
            loadingAccountNumber,
        } = this.state;
        const {partnerTypes, partnerData, accountNumber} = this.props;
        //
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Accounting</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Accounting</h4>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <ButtonGroup>
                            <Button variant="outline-primary" size="sm">Export</Button>
                        </ButtonGroup>
                    </div>
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-between align-items-center">
                        <Col md={10} className={''}>
                            <Row>
                                <Col md={2} className="mb-3">
                                    <Form.Group id="email">
                                        <Form.Select name={'userpartnertyype'}
                                                     onChange={this.handlePartnerChange}
                                                     aria-label="Select user type"
                                                     required={true}>
                                            <option value={""}>Types :.</option>
                                            {
                                                (partnerTypes ?? []).map((item, i) => (
                                                    <option
                                                        value={`${item.typeName}:${item.typeCode}`}
                                                        selected={userpartnertyype === item.typeName}>{item.typeName}</option>)
                                                )
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {
                                    partnerData && !loadingPartnerData ? (
                                        <>
                                            <Col md={2} className="mb-3">
                                                <Form.Group id="userpartnertyypeId">
                                                    <Form.Select name={'userpartnertyypeId'}
                                                                 onChange={this.handlePartnerDataChange}
                                                                 aria-label="Select partner data"
                                                                 required={true}>
                                                        <option value={""}>Partner List :.</option>
                                                        {
                                                            (partnerData ?? []).map((item, i) => (
                                                                <option
                                                                    value={item.id}
                                                                    selected={userpartnertyypeId === item.id}>{item.description}</option>)
                                                            )
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </>
                                    ) : (<></>)
                                }
                                <Col md={2}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faSearch}/>
                                        </InputGroup.Text>
                                        <Form.Control type="text"
                                                      name={'accountNumber'}
                                                      disabled={loadingAccountNumber}
                                                      onChange={this.handleChange}
                                                      value={accountNumber}
                                                      placeholder="Account Number"/>
                                    </InputGroup>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-0" controlId="formStartDate">
                                        <DatePicker
                                            selected={startDate}
                                            className={'form-control'}
                                            name={'_s'}
                                            onChange={(date) => this.setStartDate(date)}/>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-0" controlId="formEndDate">
                                        <DatePicker
                                            className={'form-control'}
                                            name={'_e'}
                                            selected={endDate}
                                            onChange={(date) => this.setEndState(date)}/>
                                    </Form.Group>
                                </Col>
                                <Col md={1}>
                                    <Button className={'btn-block'}
                                            disabled={loadingAccountNumber || loadingPartnerData}
                                            onClick={this.handleSearchHistory}
                                            type={'button'}>Search</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} className="ps-md-0 text-end">
                            <Dropdown as={ButtonGroup}>
                                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm icon-gray">
                                  <FontAwesomeIcon icon={faCog}/>
                                </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                    <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                    <Dropdown.Item className="d-flex fw-bold">
                                        10 <span className="icon icon-small ms-auto"><FontAwesomeIcon
                                        icon={faCheck}/></span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                    <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>

                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="">
                        <Table responsive striped={false} bordered className="align-items-center">
                            <thead className={'thead-light'}>
                            <tr>
                                <th className="border-bottom text-center" width={'3em'}>Transaction ID</th>
                                <th className="border-bottom text-center">Transaction Type</th>
                                <th className="border-bottom text-center">Debited Account</th>
                                <th className="border-bottom text-center">Debited Amount</th>
                                <th className="border-bottom text-center">Credited Accounts</th>
                                <th className="border-bottom text-center">Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <TableRow/>
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer className="px-3 d-lg-flex align-items-center justify-content-between">
                        <Nav>
                            <Pagination className="mb-2 mb-lg-0">
                                <Pagination.Prev>
                                    Previous
                                </Pagination.Prev>
                                <Pagination.Item active>1</Pagination.Item>
                                <Pagination.Item>2</Pagination.Item>
                                <Pagination.Item>3</Pagination.Item>
                                <Pagination.Item>4</Pagination.Item>
                                <Pagination.Item>5</Pagination.Item>
                                <Pagination.Next>
                                    Next
                                </Pagination.Next>
                            </Pagination>
                        </Nav>
                        <small className="fw-bold">
                            Showing <b>{1}</b> out of <b>25</b> entries
                        </small>
                    </Card.Footer>
                </Card>
            </>
        );
    }
}

const mapStateProps = (store) => {
    const {partnerTypes, partnerData} = store.partners;
    const {accountNumber, accountHistory, accountingError} = store.financials;
    return {partnerTypes, partnerData, accountNumber, accountHistory, accountingError};
}

export default connect(mapStateProps, {
    getPartnerTypes,
    getPartnerData,
    getAccountingNumber,
    getAccountingHistory,
})(Transactions);
