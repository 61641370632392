import {action} from "../index";
import {
    ERROR_ALLOCATED_LOANS, ERROR_LOAN_REPAYMENT,
    FETCH_CLOSURE_LOANS_ENDS,
    FETCH_CLOSURE_LOANS_START,
    FETCH_CLOSURE_LOANS_SUCCESS,
    GET_ALLOCATED_LOANS,
    GET_LOAN_REPAYMENT,
    INITIATE_LOAN_CANCELLATION_ENDS,
    INITIATE_LOAN_CANCELLATION_START,
    INITIATE_LOAN_CANCELLATION_SUCCESS, POST_LOAN_REPAYMENT,
    UPDATE_LOAN_CLOSURE_ENDS,
    UPDATE_LOAN_CLOSURE_START,
    UPDATE_LOAN_CLOSURE_SUCCESS, UPDATE_LOAN_REPAYMENT
} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCall, apiCallRwUtils} from "../../utils/apiCall";
import { showAlert } from "../apiresponse/AlertAction";
import axios from "axios";

/**
 * Get all allocated loans
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllocatedLoans = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        // const req = await apiCall.get(`/intelligrapi/allactiveloans`, {
        //     headers,
        // });
        dispatch(action(GET_ALLOCATED_LOANS, []));
    } catch (e) {
        dispatch(action(ERROR_ALLOCATED_LOANS, e.message));
    }
}

export const ResolveLoanInitiation = (payload) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const data = payload;

        dispatch(action(INITIATE_LOAN_CANCELLATION_START))

        const req = await apiCall.post(`intelligrapi/customerloanclosure`, data, {
            headers,
        });
        dispatch(action(INITIATE_LOAN_CANCELLATION_SUCCESS));

        dispatch(action(INITIATE_LOAN_CANCELLATION_ENDS));
    
        return req;
    } catch (e) {
       
       
        dispatch(action(INITIATE_LOAN_CANCELLATION_ENDS));
        dispatch(showAlert("Error initiating loan cancellation", "error"));
        return e.response
        // return req
    }
}

export const FetchClosureLoans = (payload) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const data = payload;

        dispatch(action(FETCH_CLOSURE_LOANS_START))

        const req = await apiCall.get(`intelligrapi/customerloanclosure`, {
            headers,
        });

        // console.log('loanreg', req);
        dispatch(action(FETCH_CLOSURE_LOANS_SUCCESS, {payload: req?.data}))

        dispatch(action(FETCH_CLOSURE_LOANS_ENDS));
    
        return req;
    } catch (e) { 
       
       
        dispatch(action(FETCH_CLOSURE_LOANS_ENDS));
        dispatch(showAlert("Error fetching closure loans", "error"));
        return e.response
        // return req
    }
}

export const UpdateLoanInitiation = (payload) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const data = payload;

        dispatch(action(UPDATE_LOAN_CLOSURE_START))

        const req = await apiCall.post(`intelligrapi/customerloanclosure/${data.id}`, data, {
            headers,
        });
        dispatch(action(UPDATE_LOAN_CLOSURE_SUCCESS));

        dispatch(action(UPDATE_LOAN_CLOSURE_ENDS));
    
        return req;
    } catch (e) {
       
       
        dispatch(action(UPDATE_LOAN_CLOSURE_ENDS));
        dispatch(showAlert("Error updating this closure loan", "error"));
        return e.response
        // return req
    }
}

export const getLoanRepayments = () => async (dispatch) => {
    try {
        const req = await apiCallRwUtils.get("/support/loan-list");
        dispatch(action(GET_LOAN_REPAYMENT, req.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                dispatch(action(ERROR_LOAN_REPAYMENT, `Server error: ${error.response.status} - ${error.response.data.message || 'Unknown error'}`));
            } else if (error.request) {
                dispatch(action(ERROR_LOAN_REPAYMENT, 'No response received from server'));
            } else {
                dispatch(action(ERROR_LOAN_REPAYMENT, `Request setup error: ${error.message}`));
            }
        } else {
            dispatch(action(ERROR_LOAN_REPAYMENT, `Unexpected error: ${error.message}`));
        }
    }
}

export const submitLoanRepayment = (payload) => async (dispatch) => {
    try {
        const req = await apiCallRwUtils.post("/support/request-loan-repayment", payload);
        dispatch(action(POST_LOAN_REPAYMENT, req.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                dispatch(action(ERROR_LOAN_REPAYMENT, `Server error: ${error.response.status} - ${error.response.data.message || 'Unknown error'}`));
            } else if (error.request) {
                dispatch(action(ERROR_LOAN_REPAYMENT, 'No response received from server'));
            } else {
                dispatch(action(ERROR_LOAN_REPAYMENT, `Request setup error: ${error.message}`));
            }
        } else {
            dispatch(action(ERROR_LOAN_REPAYMENT, `Unexpected error: ${error.message}`));
        }
    }
}

export const updateLoanRepayment = (payload) => async (dispatch) => {
    try {
        const req = await apiCallRwUtils.post("/support/process-loans", payload);
        dispatch(action(UPDATE_LOAN_REPAYMENT, req.data))
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                dispatch(action(ERROR_LOAN_REPAYMENT, `Server error: ${error.response.status} - ${error.response.data.message || 'Unknown error'}`));
            } else if (error.request) {
                dispatch(action(ERROR_LOAN_REPAYMENT, 'No response received from server'));
            } else {
                dispatch(action(ERROR_LOAN_REPAYMENT, `Request setup error: ${error.message}`));
            }
        } else {
            dispatch(action(ERROR_LOAN_REPAYMENT, `Unexpected error: ${error.message}`));
        }
    }
}
