import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage"; 
import ScrollToTop from "./components/ScrollToTop";
import Reducers from "./reducers";
import {createStore} from "redux"; 
import {Provider} from "react-redux";
import Middlewares from "./middlewares";

const store = createStore(Reducers, Middlewares);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop/>
            <HomePage/>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
