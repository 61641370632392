import React from "react";

export default (props) => {
    // const currentYear = moment().get("year");
    // const showSettings = props.showSettings;
    //
    // const toggleSettings = (toggle) => {
    //     props.toggleSettings(toggle);
    // }

    return (
        <>
            <div className={'my-3'}>&nbsp;</div>
        </>
    );
};
