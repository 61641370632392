import React, { useState } from "react";
import { Routes } from "../../routes";
import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Table,
  Alert
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { BeatLoader } from "react-spinners";
import { connect } from "react-redux";
import { ReadTransactionStatus } from "../../actions/supports";
import { formatDate } from "../../utils/format";

const TransactionStatus = ({ getTransactionStatus, loading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [response, setResponse] = useState(null);

  const [hiddenAlerts, setHiddenAlerts] = React.useState([]);
  const onClose = (alertId) => {
    const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
    setHiddenAlerts(hiddenAlertsUpdated);
  };
  const shouldShowAlert = (alertId) => hiddenAlerts.indexOf(alertId) === -1;

  const fetchTransactionStatus = async (e) => {
    e.preventDefault();
    setResponse(null);
    const res = await getTransactionStatus(startDate, endDate);
    setResponse(res?.data);
  };

  console.log(loading);

  return (
    <div>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href={Routes.DashboardOverview.path}>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Transaction Status</Breadcrumb.Item>
          </Breadcrumb>
          <h5>Transaction Status</h5>

          <Card border="light" className="table-wrapper shadow-sm mb-4">
            <Card.Body className="">
              <Form className="d-inline-flex align-items-center">
                <>
                  <Form.Group className={"me-4"} controlId="formStartDate">
                    <DatePicker
                      selected={startDate}
                      className={"form-control"}
                      name={"_s"}
                      onChange={(date) => {
                        setStartDate(date);
                        setResponse(null);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className={"me-4"} controlId="formEndDate">
                    <DatePicker
                      className={"form-control"}
                      name={"_e"}
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setResponse(null);
                      }}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={fetchTransactionStatus}
                  >
                    {loading ? <BeatLoader color="#fff" /> : "Fetch"}
                  </Button>
                </>
              </Form>
            </Card.Body>
          </Card>

          {response?.status && response?.data && (
            <Card>
              <Card.Body>
                <p className="text-center text-info">
                  {" "}
                  <strong>
                    Transaction Status ({formatDate(startDate)} -{" "}
                    {formatDate(endDate)})
                  </strong>
                </p>
                <Table striped bordered>
                  {response?.data?.map((res, index) => (
                    <tr key={index}>
                      <td>{res?.bankApplicationStatus}</td>
                      <td>
                        <b>{res?.count}</b>
                      </td>
                    </tr>
                  ))}
                </Table>
              </Card.Body>
            </Card>
          )}

          {response && !response?.data && (
            <Alert
              variant="primary"
              show={shouldShowAlert("primary")}
              onClose={() => onClose("primary")}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <p>
                    <strong>
                    Transaction Status ({formatDate(startDate)} -{" "}
                    {formatDate(endDate)})
                    </strong>
                  </p>
                  <p>{response?.message}</p>
                </div>
                <Button
                  variant="close"
                  size="xs"
                  onClick={() => onClose("primary")}
                />
              </div>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTransactionStatus: (startDate, endDate) =>
    dispatch(ReadTransactionStatus({ startDate, endDate })),
});

const mapStateToProps = (store) => {
  const { loading } = store?.supports;
  return { loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionStatus);
