import {action} from "../index";
import {REPORT_ERROR, REPORT_GET_TELCO_LOANS, REPORT_LOADING} from "../types";
import {apiCall} from "../../utils/apiCall";
import {bearerHeader, getToken} from "../../helpers/tokenHelper";

const baseUrl = '/intelligrapi/reports';
export const getTelcoRequestedLoans = (from, to) => async (dispatch) => {
    try {
        const token = getToken() ?? "";
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        dispatch(action(REPORT_LOADING, true));
        const  req = await apiCall.get(`${baseUrl}/mtnloanrequests?from=${from}&to=${to}`, {
            headers,
        });
        dispatch(action(REPORT_GET_TELCO_LOANS, req.data ?? []));
        dispatch(action(REPORT_LOADING, false));
    } catch (e) {
        dispatch(action(REPORT_GET_TELCO_LOANS, []));
        dispatch(action(REPORT_ERROR, e.message));
    }
}