import {EDIT_PLAN, ERROR_PLAN, GET_PLANS, VIEW_PLAN} from "../../actions/types";

const init = {
    error: null,
}
//
const plansReducer = (state = init, action) => {
    switch (action.type) {
        case GET_PLANS:
            return {
                ...state,
                plans: action.payload,
                error: null,
            }
        case VIEW_PLAN:
            return {
                ...state,
                plan: action.payload,
                error: null,
            }
        case ERROR_PLAN:
            return {
                ...state,
                error: action.payload,
                data: null,
            }
        case EDIT_PLAN:
            return {
                ...state,
                error: null,
                plan: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}
//
export default plansReducer;
