import React, {Component} from "react";
import {connect} from "react-redux";
import {viewBank} from "../../../../actions/partners";
import {Breadcrumb, Button, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {Routes} from "../../../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnchor, faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../../../components/Preloader";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {faListOl} from "@fortawesome/free-solid-svg-icons/faListOl";
import BankDetails from "../../components/banks/BankDetails";
import BankLoans from "../../components/banks/BankLoans";
import BankAccounts from "../../components/banks/BankAccounts";
import BankFinancingCard from "../../components/banks/BankFinancingCard";
import authorization from "../../../../helpers/authorization";
import TabNavItem from "../../../../components/TabNavItem";
import Freelancers from "../../components/banks/Freelancers";
import useRoute from "../../../../helpers/useRoute";

class ViewBank extends Component {
    state = {
        loading: false,
        selectedTab: 'bank_details',
        bankId: this.props.id,
        partnerType: this.partnerType,
    }

    componentDidMount = async () => {
        this.setState({partnerType: this.partnerType});
        this.setState({loading: true,});
        const {bankId} = this.state;
        const {viewBank} = this.props;
        await viewBank(bankId);
        this.setState({loading: false,});
    }

    selectTab = (tab) => {
        this.setState({selectedTab: tab,});
    }

    render() {
        const {loading, selectedTab, partnerType} = this.state;
        const {bank,} = this.props;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Partners.path}>Banks list</Breadcrumb.Item>
                            <Breadcrumb.Item active>Details</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>{
                            !loading && bank ? (
                                <span><b>{bank.bankName ?? ""}</b></span>
                            ) : ("")
                        }</h3>
                    </div>
                </div>
                {
                    !loading && bank && authorization('PA_EDIT') ? (
                        <Button variant="primary"
                                href={useRoute(Routes.EditPartner, {code: '', id:bank.id})}
                                className="mb-3">
                            Edit Bank
                        </Button>
                    ) : (<></>)
                }
                {
                    loading ? (
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Preloader show={loading}/>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row className={''}>
                                <Col md={12}>
                                    <Tab.Container title={''} defaultActiveKey={'bank_details'}>
                                        <Row>
                                            <Col md={12}>
                                                <Nav fill variant="pills" className="flex-column flex-sm-row">
                                                    <TabNavItem eventKey="bank_details"
                                                                onSelect={() => this.selectTab('bank_details')}
                                                                icon={faEye}
                                                                title={'Details'}
                                                                role={"PA_BROWSE"}/>
                                                    <TabNavItem eventKey="bank_loans"
                                                                onSelect={() => this.selectTab('bank_loans')}
                                                                icon={faMoneyBill}
                                                                title={'Allocated Loans'}
                                                                role={"LO_BROWSE"}/>
                                                    <TabNavItem eventKey="bank_accounts"
                                                                onSelect={() => this.selectTab('bank_accounts')}
                                                                icon={faListOl}
                                                                title={'Accounts'}
                                                                role={"TR_BROWSE"}/>
                                                    <TabNavItem eventKey="freelancers"
                                                                onSelect={() => this.selectTab('freelancers')}
                                                                icon={faAnchor}
                                                                title={'Freelancers'}
                                                                role={"PP_BROWSE"}/>
                                                </Nav>
                                                <Tab.Content>
                                                    {
                                                        authorization("PA_BROWSE") ? (
                                                            <>
                                                                <Tab.Pane eventKey={'bank_details'} className={'py-4'}>
                                                                    {
                                                                        selectedTab === "bank_details" ? (
                                                                            <BankDetails bank={bank}/>
                                                                        ) : (<></>)
                                                                    }
                                                                </Tab.Pane>
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        authorization("LO_BROWSE") ? (
                                                            <>
                                                                <Tab.Pane eventKey={'bank_loans'} className={'py-4'}>
                                                                    {
                                                                        selectedTab === "bank_loans" ? (
                                                                            <BankLoans bank={bank}/>
                                                                        ) : (<></>)
                                                                    }
                                                                </Tab.Pane>
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        authorization("TR_BROWSE") ? (
                                                            <>
                                                                <Tab.Pane eventKey={'bank_accounts'} className={'py-4'}>
                                                                    {
                                                                        selectedTab === "bank_accounts" ? (
                                                                            <BankAccounts bank={bank}/>
                                                                        ) : (<></>)
                                                                    }
                                                                </Tab.Pane>
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        authorization("PP_BROWSE") && (
                                                            <>
                                                                <Tab.Pane eventKey={'freelancers'}
                                                                          className={'py-4'}>
                                                                    {
                                                                        selectedTab === "freelancers" &&
                                                                        <Freelancers bank={bank}/>
                                                                    }
                                                                </Tab.Pane>
                                                            </>
                                                        )
                                                    }
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </>
                    )
                }
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {bank, error,} = state.partners;
    return {bank, error,};
};
//
export default connect(mapStateProps, {viewBank})(ViewBank);
