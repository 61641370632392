import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {Button, Card, Col, FormControl, InputGroup, Modal, Row, Table, Toast} from "@themesberg/react-bootstrap";
import {getBankFinancingCard, updateBankFinancingCard} from "../../../../actions/partners";
import {BeatLoader} from "react-spinners";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

const TableRow = (props) => {
    const {id, bankId, score, maxfinancingAmount, index, handleShow} = props;

    return (
        <tr valign={'middle'}>
            <td className={'text-center'}>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className={'text-center'}>{score}</td>
            <td className={'text-center cursor-pointer'} onClick={() => {
                handleShow({id, bankId, score, maxfinancingAmount,})
            }}>{maxfinancingAmount.toLocaleString()}</td>
            <td align={'center'}>
                <Button variant="light" size="sm" onClick={() => {
                    handleShow({id, bankId, score, maxfinancingAmount,})
                }}>
                    <FontAwesomeIcon icon={faEdit}
                                     className={`icon icon-xs text-success cursor-pointer`}/>
                </Button>
            </td>
        </tr>
    );
}
//
const UpdateBankFinancingCardModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(() => {
        return {
            success: null,
            hasError: null,
            errorMessage: null,
        }
    });
    const {show, updateBankFinancingCard, handleClose, card, getBankFinancingCard} = props;
    const [maxFinancingAmount, setMaxFinancingAmount] = useState((card.maxfinancingAmount ?? 0));
    const handleSubmit = async (e) => {
        setLoading(true);
        const update = await updateBankFinancingCard({
            id: card.id,
            bankId: card.bankId,
            score: card.score,
            maxfinancingAmount: maxFinancingAmount,
        });
        if (update) {
            setResponse({
                success: true,
                hasError: null,
                errorMessage: null,
            });
            await getBankFinancingCard(card.bankId);
            handleClose();
        } else {
            setResponse({
                success: null,
                hasError: true,
                errorMessage: update,
            });
        }
        setLoading(false);
    }
    //
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton={!loading}>
                    <Modal.Title>Score : {card.score}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            {
                                response.hasError ? (
                                    <>
                                        <Toast className="d-inline-block m-1" bg={"Danger".toLowerCase()} key={0}>
                                            <Toast.Header>
                                                <strong className="me-auto">Error Occur</strong>
                                            </Toast.Header>
                                            <Toast.Body className={''}>
                                                {response.errorMessage ?? "Update failed"}
                                            </Toast.Body>
                                        </Toast>
                                    </>
                                ) : (<></>)
                            }
                            <InputGroup id={'maxfinancingAmount'}
                                        size={'lg'}>
                                <FormControl required type="text" name="maxfinancingAmount"
                                             aria-label="Large"
                                             aria-describedby="inputGroup-sizing-sm"
                                             className={`border ${response.success === true ? 'border-success' : ''} ${response.hasError === true ? 'border-danger' : ''}`}
                                             id={'maxfinancingAmount'}
                                             readOnly={loading}
                                             value={maxFinancingAmount ?? 0}
                                             onChange={e => setMaxFinancingAmount(e.target.value)}
                                             placeholder="Score"/>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={loading}>Close</Button>
                    <Button variant="primary" disabled={loading} onClick={handleSubmit}>Updat{loading ? (
                        <>ing&nbsp;<BeatLoader color="#fff" size={4}/></>
                    ) : (<>e</>)}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

//
class BankFinancingCard extends Component {
    state = {
        loading: false,
        showModal: false,
        selectedCard: null,
        maxFinancingAmount: null,
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getBankFinancingCard, bank} = this.props;
        if (bank && bank.id) {
            await getBankFinancingCard(bank.id);
        }
        this.setState({loading: false,});
    }
    //
    getBankFinancingCard = async (id) => {
        this.setState({loading: true,});
        const {getBankFinancingCard, bank} = this.props;
        await getBankFinancingCard(bank.id);
        this.setState({loading: false,});
    }
    //
    handleMaxFinancingAmountChange = (amount = 0.0) => {
        this.setState({maxFinancingAmount: amount,});
    }
    //
    handleClose = () => {
        this.setState({showModal: false, selectedCard: null,});
    }
    //
    handleShow = (card) => {
        this.setState({showModal: true, selectedCard: card,});
    }

    //
    render() {
        const {loading, showModal, selectedCard,} = this.state;
        const {cards, bank, updateBankFinancingCard,} = this.props;
        //
        return (
            <>
                <Card>
                    <Card.Body>
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className={'text-center'}>#</th>
                                <th className={'text-center'}>Score</th>
                                <th className={'text-center'}>Max financing Amount</th>
                                <th className={'text-center'}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="4" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : cards != null && cards.length > 0 ? cards.map((card, i) =>
                                        <TableRow {...card}
                                                  index={++i}
                                                  bank={bank}
                                                  handleShow={this.handleShow}
                                                  loading={loading}
                                                  key={`page-visit-${card.id}`}/>
                                    ) :
                                    (
                                        <tr>
                                            <td colSpan="4" align="center">No cards available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                {
                    showModal && selectedCard ? (
                        (<UpdateBankFinancingCardModal bank={bank}
                                                       show={showModal}
                                                       handleClose={this.handleClose}
                                                       card={selectedCard}
                                                       getBankFinancingCard={this.getBankFinancingCard}
                                                       updateBankFinancingCard={updateBankFinancingCard}/>)
                    ) : (<></>)
                }
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, cards,} = state.partners;
    return {error, cards,};
}
//
export default connect(mapStateProps, {
    getBankFinancingCard,
    updateBankFinancingCard,
})(BankFinancingCard);
