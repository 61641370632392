import {
    CREATE_BANKS,
    CREATE_PARTNER,
    EDIT_BANKS,
    EDIT_PARTNER,
    ERROR_PARTNER,
    GET_ALL_BANKS,
    GET_BANK_ACCOUNTS,
    GET_BANK_FINANCING_CARD,
    GET_BANK_LOANS,
    GET_PARTNERS, PARTNER_CREATE_FREELANCER,
    PARTNER_DATA, PARTNER_GET_FREELANCERS, PARTNER_LOADING_FREELANCE, PARTNER_SINGLE_DATA,
    PARTNER_TYPES,
    UPDATE_BANK_FINANCING_CARD,
    VIEW_BANK,
    VIEW_PARTNER
} from "../../actions/types";

const initiate = {
    error: null,
}

const partnersReducer = (state = initiate, action) => {
    switch (action.type) {
        case GET_PARTNERS:
            return {
                ...state,
                partners: action.payload,
                error: null,
            }
        case ERROR_PARTNER:
            return {
                ...state,
                error: action.payload,
            }
        case CREATE_PARTNER:
            return {
                ...state,
                partner: action.payload,
                error: null,
            }
        case VIEW_PARTNER:
            return {
                ...state,
                partner: action.payload,
                error: null,
            }
        case PARTNER_SINGLE_DATA:
            return {
                ...state,
                partner: action.payload,
                error: null,
            }
        case EDIT_PARTNER:
            return {
                ...state,
                partner: action.payload,
                error: null,
            }
        case GET_ALL_BANKS:
            return {
                ...state,
                banks: action.payload,
                error: null,
            }
        case CREATE_BANKS:
        case VIEW_BANK:
        case EDIT_BANKS:
            return {
                ...state,
                bank: action.payload,
                error: null,
            }
        case GET_BANK_LOANS:
            return {
                ...state,
                loans: action.payload,
                error: null,
            }
        case GET_BANK_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload,
                error: null,
            }
        case GET_BANK_FINANCING_CARD:
            return {
                ...state,
                cards: action.payload,
                error: null,
            }
        case UPDATE_BANK_FINANCING_CARD:
            return {
                ...state,
                cards: action.payload,
            }
        case PARTNER_TYPES:
            return {
                ...state,
                partnerTypes: action.payload,
            }
        case PARTNER_DATA:
            return {
                ...state,
                partnerData: action.payload,
            }
        case PARTNER_CREATE_FREELANCER:
            return {
                ...state,
                freelancer: action.payload,
            }
        case PARTNER_LOADING_FREELANCE:
            return {
                ...state,
                freelancerLoading: action.payload,
            }
        case PARTNER_GET_FREELANCERS:
            return {
                ...state,
                freelancers: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default partnersReducer;