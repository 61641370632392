import React, {Component} from "react";
import {Button, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFilter} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {getPartnerTypes} from "../../../actions/partners";
import {StartCase} from "react-lodash";
import getAllUsersAction, {getAllRepUsers} from "../../../actions/user/getAllUsersAction";

class FilterUsers extends Component {
    state = {
        loading: this.props.loading,
        selectedType: null,
        selectedName: null,
        getReps: false,
    }

    setSelectedType = (item, name) => {
        this.setState({selectedType: item, selectedName: name,});
        const {onChange} = this.props;
        const _d = {
            target: {
                value: {
                    code: item,
                    name: name,
                },
            }
        }
        onChange(_d, true);
    }

    componentDidMount = async () => {
        this.setState({loading: true});
        const {getPartnerTypes} = this.props;
        await getPartnerTypes();
        this.setState({loading: false});
    }

    _getAllRepUsers = async () => {
        const {getAllRepUsers, getAllUsersAction, onLoading} = this.props;
        const {getReps} = this.state;
        onLoading(true);
        if (getReps === false) {
            this.setState({getReps: true});
            await getAllRepUsers();
        } else {
            this.setState({getReps: false});
            await getAllUsersAction();
        }
        onLoading(false);
    }

    render() {

        const {selectedType, getReps, loading, selectedName} = this.state;
        const {partnerTypes} = this.props;

        return (
            <>
                <div className="mb-3">
                    <div className={'row'}>
                        <div className={'col-3'}>
                            <Dropdown>
                                <Dropdown.Toggle as={Button} variant="primary">
                                    <FontAwesomeIcon icon={faFilter} className="me-2"/> {
                                    selectedName ?
                                        <StartCase string={`${selectedName}`.toLowerCase()}/> : 'All Users'
                                }
                                    <span className="icon icon-small ms-1"><FontAwesomeIcon
                                        icon={faChevronDown}/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                                    <Dropdown.Item active={selectedType === null}
                                                   onClick={() => {
                                                       this.setSelectedType(null, null)
                                                   }}>
                                        {'All Users'}
                                    </Dropdown.Item>
                                    {
                                        partnerTypes && partnerTypes.map((item, i) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    this.setSelectedType(item.typeCode, item.typeName)
                                                }}
                                                active={`${item.typeCode}` === `${selectedType}`}>
                                                <StartCase string={`${item.typeName}`.toLowerCase()}/>
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className={'col-3'}>
                            <Button
                                disabled={loading || this.props.loading}
                                onClick={this._getAllRepUsers}>
                                All {getReps === false ? ' reps' : ' users'}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {partnerTypes, error} = state.partners;
    return {partnerTypes, error,};
}
export default connect(mapStateToProps, {getPartnerTypes, getAllRepUsers, getAllUsersAction})(FilterUsers);
