import {CREATE_FAQ, FAQ_ERROR, GET_FAQS, UPDATE_FAQ, VIEW_FAQ} from "../../actions/types";

const initial = {
    error: null,
}

export const faqReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_FAQS:
            return {
                ...state,
                faqs: action.payload,
                error: null,
            }
        case FAQ_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case VIEW_FAQ:
        case CREATE_FAQ:
        case UPDATE_FAQ:
            return {
                ...state,
                faq: action.payload,
                error: null,
            }
        default:
            return {
                ...state,
            }
    }
}
