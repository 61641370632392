import jwtDecode from "jwt-decode";

export const setToken = (token) => {
    return localStorage.setItem("userToken", token);
};

export const getToken = () => {
    return localStorage.getItem("userToken");
};

export const setUser = (user) => {
    return localStorage.setItem('userData', user);
}

export const getUser = () => {
    try { 
        const s = localStorage.getItem('userData');
        return JSON.parse(s.toString());
    } catch (e) {
        return null;
    }
}

export const bearerHeader = () => {
    const token = getToken() ?? "";
    return {
        Authorization: "Bearer ".concat(token),
    };
}

export const decodeToken = () => {
    const token = getToken();
    if (token !== undefined || token !== null) {
        return jwtDecode(token);
    }
    return false;
};

export const removeToken = () => {
    localStorage.clear();
    return localStorage.removeItem("userToken");
};
export const removeUserData = () => {
    return localStorage.removeItem('userData');
}

export const isLoggedIn = () => {
    const token = getToken();
    let currentDate = new Date();
    if (token) {
        const decodedToken = decodeToken();
        if (decodedToken) {
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                removeToken();
                return false;
            }
        }
        return !!decodedToken;
    }
    return false;
};