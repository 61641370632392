import {connect, useDispatch, useStore} from "react-redux";
import {Breadcrumb, Button, ButtonGroup, Card, Col, Form, Row, Table} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import React, {Component, useMemo, useState} from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {getTelcoRequestedLoans} from "../../actions/reports";
import {render} from "react-dom";
import {BeatLoader} from "react-spinners";

const ReportTableRow = (props) => {
    const {
        loanrequestDate, customerphonenumber, customernames, gender, dob,
        brandName, modelName, imei, bandledOffer, devicePlan, firstMonthPayment,
        nextMonthPayment, accumulatedPayment, customerSelectedPlan, onboardingStatus,
        devicePlanStatus, riskGroup, province, district, sector, cell, village, agentCode,
        agentName, shopName, categoryChannel, index
    } = props;
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{moment(loanrequestDate).format('YYYY-MM-DD H:mm:ss')}</td>
            <td>{customerphonenumber}</td>
            <td>{customernames}</td>
            <td>{gender}</td>
            <td>{dob}</td>
            <td>{brandName}</td>
            <td>{modelName}</td>
            <td>{imei}</td>
            <td>{bandledOffer}</td>
            <td>{devicePlan}</td>
            <td>{firstMonthPayment}</td>
            <td>{nextMonthPayment}</td>
            <td>{accumulatedPayment}</td>
            <td>{customerSelectedPlan}</td>
            <td>{onboardingStatus}</td>
            <td>{devicePlanStatus}</td>
            <td>{riskGroup}</td>
            <td>{province}</td>
            <td>{district}</td>
            <td>{sector}</td>
            <td>{cell}</td>
            <td>{village}</td>
            <td>{agentCode}</td>
            <td>{agentName}</td>
            <td>{shopName}</td>
            <td>{categoryChannel}</td>
        </tr>
    )
}

class TelcosLoanRequested extends Component {
    state = {
        fromDate: moment().subtract(1, "days").toDate(),
        toDate: moment().toDate(),
    };
    formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    }

    componentDidMount = async () => {
        const {getTelcoRequestedLoans,} = this.props;
        const {fromDate, toDate} = this.state;
        getTelcoRequestedLoans(this.formatDate(fromDate), this.formatDate(toDate));
    }


    handleSearch = async () => {
        const {getTelcoRequestedLoans,} = this.props;
        const {fromDate, toDate} = this.state;
        if (fromDate && toDate) {
            getTelcoRequestedLoans(this.formatDate(fromDate), this.formatDate(toDate));
        }
    }

    render() {
        const {error, reports, loading} = this.props;
        const {fromDate, toDate} = this.state;
        console.log([error, reports, loading]);
        return (<>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item href={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item active>MTN loans</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Telcos loans report
                        from {fromDate && toDate && (`${this.formatDate(fromDate)} to ${this.formatDate(toDate)}`)}</h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Excel Export</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div className="table-settings mb-4">
                <Row className="">
                    <Col md={2}>
                        <Form.Group className="mb-0" controlId="formStartDate">
                            <DatePicker
                                selected={fromDate}
                                className={'form-control'}
                                name={'_s'}
                                onChange={(date) => this.setState({fromDate: date})}/>
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group className="mb-0" controlId="formEndDate">
                            <DatePicker
                                className={'form-control'}
                                name={'_e'}
                                selected={toDate}
                                onChange={(date) => this.setState({toDate: date})}/>
                        </Form.Group>
                    </Col>
                    <Col md={1}>
                        <Button className={'btn-block'}
                                onClick={this.handleSearch}
                                disabled={loading}
                                type={'button'}>Search {loading && (<BeatLoader color="#000" size="8" />)}</Button>
                    </Col>
                </Row>
            </div>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="">
                    <Table responsive striped={false} bordered className="align-items-center">
                        <thead className={'thead-light'}>
                        <tr>
                            <th className="border-bottom text-center" width={'3em'}>#</th>
                            <th className="border-bottom text-center">Requested Date</th>
                            <th className="border-bottom text-center">Customer Phone Number</th>
                            <th className="border-bottom text-center">Customer Names</th>
                            <th className="border-bottom text-center">Gender</th>
                            <th className="border-bottom text-center">DOB</th>
                            <th className="border-bottom text-center">Brand Name</th>
                            <th className="border-bottom text-center">Model Name</th>
                            <th className="border-bottom text-center">IMEI</th>
                            <th className="border-bottom text-center">Bundled Offer</th>
                            <th className="border-bottom text-center">Device Plan</th>
                            <th className="border-bottom text-center">First Month Payment</th>
                            <th className="border-bottom text-center">Next Month Payment</th>
                            <th className="border-bottom text-center">Accumulated Payment</th>
                            <th className="border-bottom text-center">Customer Selected Plan</th>
                            <th className="border-bottom text-center">Onboarding Status</th>
                            <th className="border-bottom text-center">Device Plan Status</th>
                            <th className="border-bottom text-center">Risk Group</th>
                            <th className="border-bottom text-center">Province</th>
                            <th className="border-bottom text-center">District</th>
                            <th className="border-bottom text-center">Sector</th>
                            <th className="border-bottom text-center">Cell</th>
                            <th className="border-bottom text-center">Village</th>
                            <th className="border-bottom text-center">Agent Code</th>
                            <th className="border-bottom text-center">Agent Name</th>
                            <th className="border-bottom text-center">Shop Name</th>
                            <th className="border-bottom text-center">Category Channel</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading === true && (<tr>
                            <td colSpan={27} align="center">
                                <BeatLoader color="#000" size="8"/>
                            </td>
                        </tr>)}
                        {reports.length > 0 && reports.map((report, i) => (<ReportTableRow {...report} index={i} />))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>);
    }
}

const mapStateProps = (state) => {
    const {reports, loading, error,} = state.reports;
    return {reports, loading, error};
}
export default connect(mapStateProps, {getTelcoRequestedLoans})(TelcosLoanRequested);