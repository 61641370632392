import {
    CREATE_DEVICE,
    ERROR_DEVICES,
    ERROR_DEVICES_CATEGORY,
    GET_DEVICE_DETAILS,
    GET_DEVICES,
    GET_DEVICES_CATEGORY,
    UPDATE_DEVICE,
    UPLOAD_DEVICE_IMAGE,
    UPLOAD_DEVICE_IMAGE_ERROR
} from "../../actions/types";

const initiate = {
    data: null,
    error: null,
    categories: null,
    total: 0,
}

const devicesReducer = (state = initiate, action) => {
    switch (action.type) {
        case GET_DEVICES:
            return {
                ...state,
                devices: action.payload,
                error: null,
                total: action.payload.length ?? 0,
            };
        case ERROR_DEVICES:
            return {
                ...state,
                data: null,
                error: action.payload,
                total: 0,
            };
        case CREATE_DEVICE:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case UPDATE_DEVICE:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case GET_DEVICES_CATEGORY:
            return {
                ...state,
                categories: action.payload,
                error: null,
                total: action.payload.length,
            }
        case GET_DEVICE_DETAILS:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case UPLOAD_DEVICE_IMAGE:
            return {
                ...state,
                upload: action.payload,
                error: null,
            }
        case UPLOAD_DEVICE_IMAGE_ERROR:
            return {
                ...state,
                upload: null,
                uploadError: action.payload,
            }
        case ERROR_DEVICES_CATEGORY:
            return {
                ...state,
                categories: null,
                error: action.payload,
                total: 0,
            }
        default:
            return state;
    }
}

export default devicesReducer;
