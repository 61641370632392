import {apiCall} from "../../utils/apiCall";
import {removeToken, removeUserData, setToken, setUser} from "../../helpers/tokenHelper";
import {action} from "../index";
import {AUTHENTICATED, LOGIN_FAILURE, LOGOUT, USER_DATA} from "../types";

/**
 * Login
 *
 * @param credentials
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const login = (credentials) => async (dispatch) => { 
    try {
        const res = await apiCall.post(`/intelligrapi/authenticate`, credentials);
        //
        if (res.data.token) {
            if (res.data.store) {
                throw new Error("Incorrect credentials !!");
            }
            await setToken(res.data.token); 
            let user = res.data;
            dispatch(action(AUTHENTICATED, {...user, roles: [...user.roles, 'MANUAL_REPAYMENT', 'CUSTOMER_PAYMENT_LOG', 'CUSTOMERS_GRADUATION']}));
            //
            const usr = {
                id: user.userid,
                name: user.name,
                surname: user.surname,
                status: user.userStatus,
                roles: [...user.roles, 'MANUAL_REPAYMENT', 'CUSTOMER_PAYMENT_LOG', 'CUSTOMERS_GRADUATION'],
                username: user.username,
            };
            await setUser(JSON.stringify(usr));
            dispatch(action(USER_DATA, usr));
            return true;
        }
    } catch (error) {
        dispatch(action(LOGIN_FAILURE, error.message));
    }
    return false;
};

/**
 * Logout auth
 *
 * @returns {function(*): boolean}
 */
export const logout = () => async (dispatch) => {
    try {
        removeToken();
        removeUserData();
        dispatch(action(LOGOUT, null));
    } catch (e) {
        dispatch(action(LOGIN_FAILURE, e.message));
    }
    return false;
}

export default login;