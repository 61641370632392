import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import authorization from "../../helpers/authorization";

class CreateCategory extends Component {
    state = {
        loading: false,
    };

    componentDidMount = async () => {
    }

    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    submit = async (e) => {
        e.preventDefault();
    }

    render() {
        const {loading, name, description} = this.state;
        const {error} = this.props;

        return (<>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Categories</Breadcrumb.Item>
                        <Breadcrumb.Item active>List of all categories</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Create Category</h4>
                </div>
            </div>
            <Button variant="primary" href={Routes.DeviceCategory.path} className="m-1">All categories</Button>
            <Card border="light" className="custom-class">
                <Card.Body>
                    <h5 className="mb-4">Add Category information</h5>
                    {error ? (<div className="text-danger">{error}</div>) : ""}
                    <Form onSubmit={this.submit}>
                        <MDBRow>
                            <Row className="align-items-center col ">
                                <Form.Group id="type_name">
                                    <Form.Label>Category Name</Form.Label>
                                    <Form.Control required type="text" name="name" onChange={this.handleChange}
                                                  value={name} placeholder="Category Name"/>
                                </Form.Group>
                            </Row>
                        </MDBRow>
                        <MDBRow>
                            <Row className="custom">
                                <Form.Group id="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control required as="textarea" type="text" name="description"
                                                  onChange={this.handleChange}
                                                  value={description} placeholder="Description"/>
                                </Form.Group>
                            </Row>
                        </MDBRow>
                        <div className="mt-3">
                            <Button variant="primary" type="submit"
                                    disabled={loading || !authorization("DC_CREATE")}>
                                {loading ? <BeatLoader color="#fff"/> : "Submit"}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>);
    }
}

const mapStateProps = (state) => {
    return {};
}
export default connect(mapStateProps, {})(CreateCategory);
