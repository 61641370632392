import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import Preloader from "../../../../components/Preloader";
import TabNavItem from "../../../../components/TabNavItem";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import authorization from "../../../../helpers/authorization";
import InsuranceDetails from "../../components/insurances/InsuranceDetails";
import InsuranceLoans from "../../components/insurances/InsuranceLoans";

class ViewInsurance extends Component {
    state = {
        loading: false,
        selectedTab: 'insurance_details',
        insuranceId: this.props.id,
        partnerType: this.props.partnerType,
    }

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {insuranceId} = this.state;
        this.setState({loading: false,});
    }

    selectTab = (tab) => {
        this.setState({selectedTab: tab,});
    }

    render() {
        const {loading, selectedTab, partnerType, insuranceId} = this.state;

        return (
            <>
                {
                    loading ? (
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Preloader show={loading}/>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row className={''}>
                                <Col md={12}>
                                    <Tab.Container title={''} defaultActiveKey={'insurance_details'}>
                                        <Row>
                                            <Col md={12}>
                                                <Nav fill variant="pills" className="flex-column flex-sm-row">
                                                    <TabNavItem eventKey="insurance_details"
                                                                onSelect={() => this.selectTab('insurance_details')}
                                                                icon={faEye}
                                                                title={'Details'}
                                                                role={"PA_BROWSE"}/>
                                                    <TabNavItem eventKey="insurance_loans"
                                                                onSelect={() => this.selectTab('insurance_loans')}
                                                                icon={faMoneyBill}
                                                                title={'Allocated Loans'}
                                                                role={"LO_BROWSE"}/>
                                                </Nav>
                                                <Tab.Content>
                                                    {
                                                        authorization("PA_BROWSE") && (
                                                            <>
                                                                <Tab.Pane eventKey={'insurance_details'} className={'py-4'}>
                                                                    {
                                                                        selectedTab === "insurance_details" && (
                                                                            <InsuranceDetails
                                                                                code={this.props.code}
                                                                                id={this.props.id} />
                                                                        )
                                                                    }
                                                                </Tab.Pane>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        authorization("LO_BROWSE") && (
                                                            <>
                                                                <Tab.Pane eventKey={'insurance_loans'} className={'py-4'}>
                                                                    {
                                                                        selectedTab === "insurance_loans" && (
                                                                            <InsuranceLoans
                                                                                code={this.props.code}
                                                                                id={this.props.id}/>
                                                                        )
                                                                    }
                                                                </Tab.Pane>
                                                            </>
                                                        )
                                                    }
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </>
                    )
                }
            </>
        );
    }
}
const mapStateToProps = store => {
    const {partner, error} = store.partners;
    return {partner, error};
}
//
export default connect(mapStateToProps, {})(ViewInsurance);