import React, {Component} from "react";
import {Button, Card, Form, FormControl, InputGroup, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {connect} from "react-redux";
import {getStoreDevices, removeDeviceFromStore, updateDevicePrice} from "../../../actions/stores";
import DeviceNotInStore from "./DeviceNotInStore";
import authorization from "../../../helpers/authorization";
import {ChangeStorePrice} from "./ChangeStorePrice";

const TableRow = (props) => {
    //
    const {
        deviceTypeId, type_name, description, network, display,
        internal_storage, full_price, store, removeDevice, index, changePrice
    } = props;
    //
    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index + 1}</Card.Link>
            </td>
            <td className="fw-bold">
                {type_name}
            </td>
            <td>{description}</td>
            <td>{network}</td>
            <td>{display}</td>
            <td>{internal_storage}</td>
            <td>{full_price.toLocaleString() ?? "-"}</td>
            <td>
                {
                    authorization("SD_EDIT") ? (
                        <>
                            <Button variant="light" size="sm"
                                    onClick={() => {
                                        changePrice({deviceTypeId, typeName: type_name, fullPrice: full_price});
                                    }}>
                                <FontAwesomeIcon icon={faMoneyBill}
                                                 className={`icon icon-xs text-success cursor-pointer`}/>
                            </Button>
                            &nbsp;&nbsp;
                        </>
                    ) : (<></>)
                }
                {
                    authorization("SD_DELETE") ? (
                        <>
                            <Button variant="light" size="sm" onClick={() => removeDevice(store, deviceTypeId)}>
                                <FontAwesomeIcon icon={faMinusCircle}
                                                 className={`icon icon-xs text-danger cursor-pointer`}/>
                            </Button>
                        </>
                    ) : (<></>)
                }
            </td>
        </tr>
    );
};

export class StoreDevices extends Component {
    state = {
        loading: false,
        items: null,
        showModal: false,
        changePrice: false,
        selectedDevice: null,
    };
    //
    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getStoreDevices, storeId} = this.props;
        await getStoreDevices(storeId);
        this.setState({loading: false,});
    }
    //
    handleClose = () => {
        this.setState({showModal: false,});
    }
    //
    handleShow = () => {
        this.setState({showModal: true,});
    }
    //
    handleOpenPriceChange = (selectedDevice) => {
        this.setState({changePrice: true, selectedDevice: selectedDevice});
    }
    //
    handleClosePriceChange = () => {
        this.setState({changePrice: false, selectedDevice: null});
    }
    //
    onChanges = async (e) => {
        e.preventDefault();
        const {storeDevices} = this.props;
        if (e.target.value) {
            const devices = storeDevices?.filter((item, i) => `${item?.type_name}`.toLowerCase().includes(`${e.target.value}`.toLowerCase()));
            this.setState({deviceTypes: devices,});
        } else {
            this.setState({deviceTypes: storeDevices,});
        }
    }
    //
    removeDevice = async (store, device) => {
        if (window.confirm("Are you sure, you want to remove the device?") === true) {
            this.setState({loading: true,});
            const {getStoreDevices, removeDeviceFromStore} = this.props;
            const remove = await removeDeviceFromStore(store, device);
            if (remove) {
                await getStoreDevices(store);
                this.setState({loading: false,});
            } else {
                window.alert("Failed to remove the device from the store !!");
            }
        }
    }

    //
    render() {
        const {storeDevices, storeId, updateDevicePrice, getStoreDevices, error} = this.props;
        const {loading, showModal, deviceTypes, changePrice, selectedDevice} = this.state;
        //
        let _deviceTypes = deviceTypes ?? storeDevices;
        //
        return (
            <>
                {
                    authorization("SD_CREATE") ? (
                        <>
                            <div className="mb-4">
                                <Button variant="primary" onClick={this.handleShow} size="sm">Assign Devices</Button>
                            </div>
                        </>
                    ) : (<></>)
                }
                <Form>
                    <InputGroup className="mb-3">
                        <FormControl
                            name={'search'}
                            onChange={this.onChanges}
                            placeholder="Search by device type"
                            aria-label="Search by device type"/>
                    </InputGroup>
                </Form>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table striped responsive className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Device type</th>
                                <th className="">Description</th>
                                <th className="">Network</th>
                                <th className="">Display</th>
                                <th className="">Internal Storage</th>
                                <th className="">Price</th>
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                    <tr>
                                        <td colSpan="8" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) :
                                _deviceTypes != null && _deviceTypes.length > 0 ?
                                    _deviceTypes.map((device, i) => <TableRow {...device}
                                                                              store={storeId}
                                                                              getStoreDevices={getStoreDevices}
                                                                              removeDevice={this.removeDevice}
                                                                              changePrice={this.handleOpenPriceChange}
                                                                              index={i}
                                                                              key={`page-visit-${i}`}/>) : (
                                        <tr>
                                            <td colSpan="8" align="center">No device available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                {
                    showModal && (
                        <DeviceNotInStore showModal={showModal}
                                          handleClose={this.handleClose}
                                          getStoreDevices={getStoreDevices}
                                          storeId={storeId}
                                          handleOpen={this.handleShow}/>
                    )
                }
                {
                    changePrice && selectedDevice && (
                        <ChangeStorePrice
                            store={storeId}
                            onOpen={changePrice}
                            onClose={this.handleClosePriceChange}
                            initialPrice={selectedDevice?.fullPrice}
                            device={selectedDevice}
                            updateDevicePrice={updateDevicePrice}
                            getStoreDevices={getStoreDevices}
                            error={error}
                        />
                    )
                }
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {storeDevices, data, error} = state.store;
    return {
        storeDevices, error, data,
    };
};

export default connect(mapStateProps, {getStoreDevices, removeDeviceFromStore, updateDevicePrice})(StoreDevices);
