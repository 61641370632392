import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import {getUsersDevices} from "../../../actions/user";
import {BeatLoader} from "react-spinners";
import authorization from "../../../helpers/authorization";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {faUnlock} from "@fortawesome/free-solid-svg-icons/faUnlock";

const TableTr = (props) => {
    const {loading} = props;
    return (
        <>
            <tr valign={'middle'}>
                <td>{props.index}</td>
                <td>{props.appName}</td>
                <td>{props.carrier}</td>
                <td>{props.devicename}</td>
                <td>{props.imei}</td>
                <td>{props.brand}</td>
                <td>{props.msisdn}</td>
                <td>{props.shopname}</td>
                <td>
                    {
                        authorization("IC_C_LOCK") && props.devicestatus === 'Limited' ? (
                            <>
                                <Button variant={"light"} size={"sm"}
                                        onClick={async () => {
                                            if (window.confirm("Are you sure,you want to lock this phone ?") === true) {
                                                //
                                            }
                                        }} className={'mr-3'} disabled={loading}>
                                    <FontAwesomeIcon icon={faLock} title={'Lock the phone'}
                                                     className={`icon icon-xs text-warning cursor-pointer`}/> Lock
                                </Button>&emsp;
                            </>
                        ) : (<></>)
                    }
                    {
                        authorization("IC_C_UNLOCK") && props.devicestatus !== 'Limited' ? (
                            <>
                                <Button variant={"light"} size={"sm"} onClick={async () => {
                                    if (window.confirm("Are you sure,you want to unlock this phone ?") === true) {
                                        //
                                    }
                                }} className={'mr-3'} disabled={loading}>
                                    <FontAwesomeIcon icon={faUnlock} title={'Unlock the phone'}
                                                     className={`icon icon-xs text-info cursor-pointer`}/> Unlock
                                </Button>&emsp;
                            </>
                        ) : (<></>
                        )
                    }
                </td>
            </tr>
        </>
    );
}

const UserDevices = (props) => {
    const [loading, setLoading] = useState(false);
    const {repDevices: devices, usr, getUsersDevices} = props;
    const dispatch = useDispatch();
    useEffect(() => {
        if (!devices) {
            setLoading(true);
            getUsersDevices(usr.username).then((re) => {
                setLoading(false);
            });
        }
    }, [devices, loading, setLoading, usr, getUsersDevices, dispatch]);
    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                        <thead className="thead-light">
                        <tr>
                            <th className="">#</th>
                            <th className="">App Name</th>
                            <th className="">Carrier</th>
                            <th className="">Device Name</th>
                            <th className="">Device IMEI</th>
                            <th className="">Brand</th>
                            <th className="">MSISDN</th>
                            <th className="">Shop Name</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loading ? (
                                <tr>
                                    <td colSpan="8" align="center"><BeatLoader color="#000" size="8"/></td>
                                </tr>
                            ) : devices && devices.length > 0 ? (
                                devices.map((device, i) => <TableTr {...device} index={++i}/>)
                            ) : (
                                <>
                                    <tr>
                                        <td colSpan="8" align="center">No devices available !</td>
                                    </tr>
                                </>
                            )
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}
const mapStateToProps = (store) => {
    const {repDevices} = store.reps;
    return {repDevices};
}
export default connect(mapStateToProps, {getUsersDevices,})(UserDevices);