import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import authorization from "../../helpers/authorization";
import useRoute from "../../helpers/useRoute";
import BrowseBanks from "./pages/banks/BrowseBanks";
import BrowseInsurances from "./pages/insurances/BrowseInsurances";
import BrowseTelco from "./pages/telcos/BrowseTelco";

class Partners extends Component {
    state = {
        loading: true,
        selectedFilterType: "",
        partnerType: "",
    };

    componentDidMount = async () => {
        const param = this.props.match.params;
        this.setState({partnerType: param['code']});
        this.setState({loading: true,});
        // const {getAllBanks} = this.props;
        // await getAllBanks();
        this.setState({loading: false,});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const param = this.props.match.params;
        const {partnerType} = prevState;
        if (partnerType !== param['code']) {
            this.setState({partnerType: param['code']});
        }
    }

    filterByType = (type) => {
        this.setState({selectedFilterType: type});
    }

    renderComponents = (code) => {
        switch (code) {
            case 'banks':
                return (<BrowseBanks partnerType={code} code={'B'}/>);
            case 'insurances':
                return (<BrowseInsurances partnerType={code} code={'IN'}/>);
            case 'telcos':
                return (<BrowseTelco partnerType={code} code={'T'}/>);
            default:
                return (<></>);
        }
    }

    render() {
        const {partnerType} = this.state;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}>
                                <FontAwesomeIcon icon={faHome}/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>List of {partnerType}</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>All {partnerType !== "" ? ` ${partnerType}` : ""}</h4>
                    </div>
                </div>
                {/*<TypeFilter onSelected={this.filterByType}/>*/}
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        {this.renderComponents(partnerType)}
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    return {};
}
//
export default connect(mapStateProps, {})(Partners);
