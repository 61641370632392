import React, {Component} from "react";
import {Card, Col, Form, Row, Table} from "@themesberg/react-bootstrap";
import {connect} from "react-redux";
import {BeatLoader} from "react-spinners";

export class Details extends Component {

    state = {
        loadingCategory: false,
    };

    componentDidMount = async () => {
        this.setState({loadingCategory: true});
        const {devicetypecategory, getDeviceTypeCategoryById} = this.props;
        await getDeviceTypeCategoryById(devicetypecategory);
        this.setState({loadingCategory: false});
    }

    render() {
        const {loadingCategory} = this.state;
        const {
            type_name, description,
            dimension, chipset, os_ui, network, display, screen_size, memory,
            internal_storage, camera, battery, specs, categories,
        } = this.props;

        return (
            <Row className="">
                <Col md="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={() => {
                            }}>
                                <Table striped bordered>
                                    <tr>
                                        <td>Category</td>
                                        <td>
                                            <b>
                                                {
                                                    loadingCategory ? (
                                                        <BeatLoader color="#000" size="8"/>
                                                    ) : (
                                                        categories ? categories.categoryName : ""
                                                    )
                                                }
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Type Name</td>
                                        <td>
                                            <b>{type_name}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td><b>{description}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Dimension</td>
                                        <td><b>{dimension}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Chipset</td>
                                        <td><b>{chipset}</b></td>
                                    </tr>
                                    <tr>
                                        <td>OS UI</td>
                                        <td><b>{os_ui}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Network</td>
                                        <td><b>{network}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Display</td>
                                        <td><b>{display}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Memory</td>
                                        <td><b>{memory}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Internal Storage</td>
                                        <td><b>{internal_storage}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Screen Size</td>
                                        <td><b>{screen_size}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Camera</td>
                                        <td><b>{camera}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Battery</td>
                                        <td><b>{battery}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Camera</td>
                                        <td><b>{camera}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Specs</td>
                                        <td><b>{specs}</b></td>
                                    </tr>
                                </Table>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateProps = (state) => {
    const {categories, category, error} = state.device;
    return {categories, category, error,};
};

export default connect(mapStateProps,)(Details);
