import React from 'react';
import { Modal } from "@themesberg/react-bootstrap";


function ModalComponent({ show, setShow, title, modalContent }) {

    const handleClose = () => {
        setShow(false);
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} size={'md'} backdrop={'static'}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "#262b40", fontSize: "18px", fontWeight: "800" }}>{title || "Title"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalContent ? modalContent : "Modal Content..."
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalComponent