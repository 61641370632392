import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFilter} from "@fortawesome/free-solid-svg-icons";
import {StartCase} from "react-lodash";

class FilterLoanStatus extends Component {
    state = {
        loading: false,
        selectStatus: null,
        status: [
            'Active', 'Declined', 'Applied', 'Graduated', 'In-Default',
        ],
    }
    //
    selectStatus = (status) => {
        const {selectStatus} = this.props;
        selectStatus(status);
        this.setState({selectStatus: status,});
    }

    //
    render() {
        const {status, selectStatus,} = this.state;
        return (
            <>
                <div className="">
                    <Dropdown>
                        <Dropdown.Toggle as={Button} variant="primary" className={'py-1'}>
                            <FontAwesomeIcon icon={faFilter} className="me-2"/><StartCase
                            string={selectStatus ?? 'Loan Status'}/>
                            <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                            {
                                status && status.length > 0 ? status.map((t, i) => (
                                    <Dropdown.Item onClick={() => this.selectStatus(t)} active={t === selectStatus}>
                                        <StartCase string={t.toLowerCase()}/>
                                    </Dropdown.Item>
                                )) : (<></>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }
}

const mapPropsState = (state) => {
    const {status, error,} = state.loans;
    return {status, error};
}
//
export default connect(mapPropsState, {})(FilterLoanStatus);
