import React, {Component} from "react";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {connect} from "react-redux";
import {createDevice, getDeviceTypeCategory} from "../../actions/devices";
import {BeatLoader} from "react-spinners";
import {MDBRow} from 'mdb-react-ui-kit';
import authorization from "../../helpers/authorization";


export class NewDevice extends Component {
    state = {
        loading: false,
        type_name: null,
        description: null,
        device_photo: null,
        dimension: null,
        chipset: null,
        os_ui: "Android",
        network: null,
        display: null,
        screen_size: null,
        memory: null,
        internal_storage: null,
        camera: null,
        battery: null,
        specs: null,
        full_price: null,
        devicetypecategory: null,
    };
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getDeviceTypeCategory} = this.props;
        await getDeviceTypeCategory();
        this.setState({loading: false});
    }

    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    addDeviceType = async (e) => {
        this.setState({loading: true});
        e.preventDefault();
        const {
            type_name, description, device_photo, dimension, chipset, os_ui, network, display, screen_size, memory,
            internal_storage, camera, battery, specs, full_price, devicetypecategory
        } = this.state;
        const {createDevice,} = this.props;
        const create = await createDevice({
            type_name, description, device_photo, dimension, chipset, os_ui, network, display, screen_size, memory,
            internal_storage, camera, battery, specs, full_price, devicetypecategory
        });
        this.setState({loading: false});
        if (create) {
            const {history} = this.props;
            history.push(Routes.Devices.path);
        }
    };

    //
    render() {
        const {error, categories} = this.props;
        //
        const {
            loading, type_name, description,
            dimension, chipset, network, display, screen_size, memory,
            internal_storage, camera, battery, specs, full_price, devicetypecategory
        } = this.state;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>Devices</Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all devices</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4> Add device</h4>
                    </div>
                </div>
                <Button variant="primary" href={Routes.Devices.path} className="m-1">All device</Button>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">Add Device type information</h5>
                        {error ? (<div className="text-danger">{error}</div>) : ""}
                        <Form onSubmit={this.addDeviceType}>
                            <MDBRow>
                                <Row className="align-items-center col">
                                    <Form.Group id="devicetypecategory">
                                        <Form.Label>Select Category</Form.Label>
                                        <Form.Select className="form-control" required name="devicetypecategory"
                                                     onChange={this.handleChange}>
                                            <option>Select Category</option>
                                            {
                                                categories && categories.map(category => (
                                                        <option value={category.id}
                                                                selected={devicetypecategory === category.id}>{category.categoryName}</option>
                                                    )
                                                )
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="type_name">
                                        <Form.Label>Type Name</Form.Label>
                                        <Form.Control required type="text" name="type_name" onChange={this.handleChange}
                                                      value={type_name} placeholder="Type Name"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow>
                                <Row className=" custom">
                                    <Form.Group id="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control required as="textarea" type="text" name="description"
                                                      onChange={this.handleChange}
                                                      value={description} placeholder="Description"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow>
                                <Row className="align-items-center col">
                                    <Form.Group id="full_price">
                                        <Form.Label>Full Price</Form.Label>
                                        <Form.Control required type="number" name="full_price"
                                                      onChange={this.handleChange}
                                                      value={full_price} placeholder="Full Price"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="dimension">
                                        <Form.Label>Dimension</Form.Label>
                                        <Form.Control type="text" name="dimension" onChange={this.handleChange}
                                                      value={dimension} placeholder="Dimension"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="chipset">
                                        <Form.Label>Chipset</Form.Label>
                                        <Form.Control type="text" name="chipset" onChange={this.handleChange}
                                                      value={chipset} placeholder="Chipset"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow>
                                <Row className="align-items-center col">
                                    <Form.Group id="os_ui">
                                        <Form.Label>OS UI</Form.Label>
                                        <Form.Control type="text" name="os_ui" onChange={this.handleChange}
                                                      value="Android" placeholder="Android" readOnly={true}/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="network">
                                        <Form.Label>Network</Form.Label>
                                        <Form.Control required type="text" name="network" onChange={this.handleChange}
                                                      value={network} placeholder="network"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="display">
                                        <Form.Label>Display</Form.Label>
                                        <Form.Control type="text" name="display" onChange={this.handleChange}
                                                      value={display} placeholder="Display"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow>
                                <Row className="align-items-center col">
                                    <Form.Group id="screen_size">
                                        <Form.Label>Screen Size</Form.Label>
                                        <Form.Control type="text" name="screen_size" onChange={this.handleChange}
                                                      value={screen_size} placeholder="Screen Size"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="memory">
                                        <Form.Label>Memory</Form.Label>
                                        <Form.Control type="text" name="memory" onChange={this.handleChange}
                                                      value={memory} placeholder="Memory"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="internal_storage">
                                        <Form.Label>Internal Storage</Form.Label>
                                        <Form.Control required type="text" name="internal_storage"
                                                      onChange={this.handleChange}
                                                      value={internal_storage} placeholder="Internal Storage"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow>
                                <Row className="align-items-center col">
                                    <Form.Group id="camera">
                                        <Form.Label>Camera</Form.Label>
                                        <Form.Control type="text" name="camera" onChange={this.handleChange}
                                                      value={camera} placeholder="Camera"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="battery">
                                        <Form.Label>Battery</Form.Label>
                                        <Form.Control type="text" name="battery" onChange={this.handleChange}
                                                      value={battery} placeholder="Battery"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col">
                                    <Form.Group id="specs">
                                        <Form.Label>Specs</Form.Label>
                                        <Form.Control type="text" name="specs" onChange={this.handleChange}
                                                      value={specs} placeholder="Specs"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <div className="mt-3">
                                <Button variant="primary" type="submit"
                                        disabled={loading || !authorization("DT_CREATE")}>
                                    {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateToProps = (state) => {
    const {data, error, categories} = state.device;
    return {
        data, error, categories,
    };
};
//
export default connect(mapStateToProps, {createDevice, getDeviceTypeCategory})(NewDevice);
