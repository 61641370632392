import React, {Component} from "react";
import {Button, Card, Col, Container, Form, Image, Row} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import authorization from "../../../helpers/authorization";

const ImageCol = (props) => {
    const {photo} = props;
    return (
        <>
            <Col xs={6} md={3} className={"mb-1"}>
                <Image
                    src={`data:image/jpeg;base64,${photo.encodedImage}`}
                    thumbnail
                    style={{cursor: 'pointer'}}
                />
            </Col>
        </>
    );
}

//
export class Images extends Component {
    //
    state = {
        files: null,
        uploading: false,
        error: null,
        successUploaded: false,
    };
    //
    handleOnChange = (e) => {
        e.preventDefault();
        this.setState({
            files: e.target.files[0],
        });
    }
    //
    handleOnSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        this.setState({uploading: true});
        const {data, uploadDeviceImage, getDeviceTypeDetails, uploadError} = this.props;
        const {deviceTypeId,} = data;
        const {files} = this.state;
        formData.append('files', files);
        formData.append('deviceId', deviceTypeId);
        const uploaded = await uploadDeviceImage(formData);
        //
        if (uploaded) {
            await getDeviceTypeDetails(deviceTypeId);
            this.setState({
                successUploaded: true,
            });
        } else {
            this.setState({error: uploadError});
        }
        //
        this.setState({uploading: false});
    }
    //
    componentDidMount = async () => {

    }

    //
    render() {
        const {data} = this.props;
        const {photos} = data || {};
        const {uploading, error, successUploaded} = this.state;
        //
        return (
            <>
                <Row className="mb-3">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                {
                                    authorization('DT_EDIT') ? (
                                        <>
                                            <Form onSubmit={this.handleOnSubmit} validated={successUploaded}
                                                  enctype="multipart/form-data" ref={(el) => this.myFormRef = el}>
                                                <Form.Group as={Row} controlId="formFile" className="mb-0">
                                                    <Form.Label column sm="2">Upload new Image</Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control required type="file" name="files"
                                                                      onChange={this.handleOnChange}
                                                                      disabled={uploading}/>
                                                        {error ? (<Form.Text muted
                                                                             className="text-danger">{error}</Form.Text>) : ""}
                                                    </Col>
                                                    <Col sm="2">
                                                        <Button variant="primary" className="btn-block"
                                                                type="submit" disabled={uploading === true}>
                                                            {uploading ?
                                                                <BeatLoader color="#fff" className="mt-1"/> : "Upload"}
                                                        </Button>
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </>
                                    ) : (<></>)
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row className="clearfix">
                                        {
                                            photos && (photos.length > 0) ? (
                                                photos.map(photo => <ImageCol {...this.props} photo={photo}/>)
                                            ) : (
                                                <Col md={12}>
                                                    <p className="text-center"><b>There is no images for the device
                                                        !!</b></p>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

//
export default Images;