import React, { useState } from "react";
import { Routes } from "../../routes";
import { Breadcrumb, Button, Card, Col, Form, InputGroup, Row, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import authorization from "../../helpers/authorization";
import { manualRepayment } from "../../actions/manual_repayments";
import { connect } from "react-redux";
import ModalComponent from "../../components/modal/ModalComponent";
import ConfirmModalContent from "./ConfirmModalContent";


const ManualRepaymentUpload = ({ tiggerManualRepayment }) => {
    const [inputValue, setInputValue] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [repaymentType, setRepaymentType] = useState("single")

    const handleOnChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleSingleRepayment = (e) => {
        e.preventDefault();
        if (inputValue?.trim() !== "") {
            setRepaymentType("single")
            setShowModal(true)
        }
        return;
    }

    const handleMultipleUpload = async (e) => {
        e.preventDefault();
        setRepaymentType("all")
        setShowModal(true)
    }

    return (
        <div>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                        listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                            icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Manual Repayments Recognition</Breadcrumb.Item>
                    </Breadcrumb>
                    <h5>Single Repayment</h5>

                    <Card border="light" className="table-wrapper shadow-sm mb-4">
                        <Card.Body className="">
                            <Row className="">
                                <Col md={12}>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            name="inputValue"
                                            onChange={handleOnChange}
                                            value={inputValue}
                                            placeholder="Type Msisdn..."
                                            required
                                        />
                                        <Button onClick={handleSingleRepayment}>
                                            Make Repayment
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <div style={{ marginTop: "20%" }}>
                        <hr />
                        <h5>Multiple Repayments</h5>
                        <div className="mt-1">
                            <p>This section to to trigger multiple repayments.</p>
                            <Form onSubmit={handleMultipleUpload}>
                                <Button variant="primary"
                                    type="submit"
                                    disabled={!authorization("MANUAL_REPAYMENT")}
                                >
                                    Make Multiple Repayment
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            {
                showModal &&
                <ModalComponent
                    show={showModal}
                    setShow={setShowModal}
                    modalContent={
                        <ConfirmModalContent
                            type={repaymentType}
                            action={repaymentType === "single" ? tiggerManualRepayment : tiggerManualRepayment}
                            actionPayload={repaymentType === "single" ? inputValue : "all"}
                            content={repaymentType === "single" ? inputValue : "all"}
                        />
                    }
                    title={repaymentType === "single" ? "Single Manual Repayment" : "Multiple Manual Repayment"}
                />
            }
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    tiggerManualRepayment: (msisdn) => dispatch(manualRepayment(msisdn))
})

const mapStateToProps = (store) => {

}

export default connect(mapStateToProps, mapDispatchToProps)(ManualRepaymentUpload);
