import {getToken, getUser} from "../../helpers/tokenHelper";
import {AUTHENTICATED, LOGIN_FAILURE, LOGOUT} from "../../actions/types";

// Get auth token
const token = getToken();
// Init state
const auth = {
    isLoggedIn: token !== null,
    authError: null,
    user: getUser(),
    role: null,
    token,
};
// Implement Authentication reducer
const Authentication = (state = auth, action) => {
    switch (action.type) { 
        case AUTHENTICATED:
            const user = action.payload.user ?? getUser();
            return {
                ...state,
                user: user,
                roles: user ? user.roles ?? [] : [],
                token: action.payload.token,
                isLoggedIn: true,
                authError: null,
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                user: null,
                roles: [],
                token: null,
                isLoggedIn: false,
                authError: action.payload,
            }
        case LOGOUT:
            return {
                ...state,
                user: null,
                roles: [],
                token: null,
                isLoggedIn: false,
                authError: null,
            }
        default:
            return {
                ...state,
            };
    }
}
//
export default Authentication;