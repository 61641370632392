import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Table} from "@themesberg/react-bootstrap";

export class StoreDetail extends Component {

    state = {
        loading: false,
    };

    render() {
        const {
            name,
            fulladress,
            owner_name,
            owner_tin,
            mobileMoneyAccount,
            msisdn,
            email,
            province,
            district,
            sector,
            cell,
            village
        } = this.props;
        return (
            <>
                <Card>
                    <Card.Body>
                        <Table striped bordered>
                            <tbody>
                            <tr>
                                <td>Name</td>
                                <td><b>{name}</b></td>
                            </tr>
                            <tr>
                                <td>Full address</td>
                                <td><b>{fulladress}</b></td>
                            </tr>
                            <tr>
                                <td>Owner Name</td>
                                <td><b>{owner_name}</b></td>
                            </tr>
                            <tr>
                                <td>Owner Tin</td>
                                <td><b>{owner_tin}</b></td>
                            </tr>
                            <tr>
                                <td>Mobile Money Account</td>
                                <td><b>{mobileMoneyAccount}</b></td>
                            </tr>
                            <tr>
                                <td>MSISDN</td>
                                <td><b>{msisdn}</b></td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>
                                    <a href={`mail:${email}`} rel="noreferrer" target="_blank"><b>{email}</b></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Province</td>
                                <td>
                                    <b>{province}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>District</td>
                                <td>
                                    <b>{district}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Sector</td>
                                <td>
                                    <b>{sector}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Cell</td>
                                <td>
                                    <b>{cell}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Village</td>
                                <td>
                                    <b>{village}</b>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    return {};
}

export default connect(mapStateProps, {})(StoreDetail);