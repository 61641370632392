import React, { useState } from "react";
import { Routes } from "../../routes";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import { ResolveDeviceDetails } from "../../actions/supports";
import { connect } from "react-redux";

const IMEIDeviceDetails = ({ deviceDetails, loading }) => {  
    const [imei, setImei] = useState("");
    const [response, setResponse] = useState(null);
  
    const getDeviceDetails = async (e) => {
      e.preventDefault();
      setResponse(null);
      const res = await deviceDetails(imei);
      setResponse(res?.data?.data);
    };
  
    console.log("response", response);
    return (
        <div>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
            >
              <Breadcrumb.Item href={Routes.DashboardOverview.path}>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Device Details</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Support Issues - Device Details</h5>
  
            <Card border="light" className="table-wrapper shadow-sm mb-4">
              <Card.Body className="">
                <Form onSubmit={getDeviceDetails}>
                  <Row className="">
                    <Col>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="msisdn"
                          onChange={(e) => {
                            setImei(e.target.value);
                            setResponse(null);
                          }}
                          value={imei}
                          placeholder="Enter IMEI..."
                          required
                        />
  
                        <Button type="submit">
                          {loading ? <BeatLoader color="#fff" /> : "Resolve"}
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>

            {response && (
            <Card>
              <Card.Body>
                <p className="text-center text-info">
                  {" "}
                  <strong>
                    Device Details - {imei}
                  </strong>
                </p>
                <Table striped bordered>
             
                    <tr>
                      <td>MSISDN</td>
                      <td>
                        <b>{response?.msisdn}</b>
                      </td>
                    </tr>
                 
                    <tr>
                      <td>IMEI</td>
                      <td>
                        <b>{response?.imei}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Customer Name</td>
                      <td>
                        <b>{response?.customerName}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Rep. Name</td>
                      <td>
                        <b>{response?.repName}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Rep. Phone</td>
                      <td>
                        <b>{response?.repPhone}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Rep Email</td>
                      <td>
                        <b>{response?.repEmail}</b>
                      </td>
                    </tr>
        
                </Table>
              </Card.Body>
            </Card>
          )}
          </div>
        </div>
      </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    deviceDetails: (imei) =>
      dispatch(ResolveDeviceDetails({ imei})),
  });
  
  const mapStateToProps = (store) => {
    const { loading } = store?.supports;
    return { loading };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(IMEIDeviceDetails);