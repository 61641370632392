import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";
import {action} from "../index";
import {
    CREATE_CORPORATE,
    ERROR_CORPORATE,
    GET_CORPORATE,
    GET_CORPORATE_MEMBERS,
    GET_CORPORATE_TRANSACTIONS,
    GET_CORPORATE_USERS,
    GET_CORPORATES
} from "../types";

/**
 * Get corporate
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCorporates = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/corporate/allcoporates`, {
            headers,
        });
        //
        dispatch(action(GET_CORPORATES, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CORPORATE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CORPORATE, e.message));
        }
    }
}

/**
 * Get single corporate
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCorporate = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/corporate/corporatebyid?corporateid=${id}`, {
            headers,
        });
        //
        dispatch(action(GET_CORPORATE, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CORPORATE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CORPORATE, e.message));
        }
    }
}

/**
 * Create corporate
 *
 * @param data
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const createCorporate = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/corporate/corporate', data, {
            headers,
        });
        if (req.data && req.data.corporateId) {
            dispatch(action(CREATE_CORPORATE, req.data));
            return true;
        } else {
            dispatch(action(ERROR_CORPORATE, req.data.error));
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CORPORATE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CORPORATE, e.message));
        }
    }
    return false;
}

/**
 * Get corporate members
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCorporateMembers = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/corporate/corporatemembers?corporateid=${id}`, {
            headers,
        });
        dispatch(action(GET_CORPORATE_MEMBERS, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CORPORATE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CORPORATE, e.message));
        }
    }
}

/**
 * Get corporate users
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCorporateUsers = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/corporate/corporateusers?coporateid=${id}`, {
            headers,
        });
        dispatch(action(GET_CORPORATE_USERS, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CORPORATE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CORPORATE, e.message));
        }
    }
}

/**
 * Get corporate transactions
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCorporateTransactions = (id) => async (dispatch) => {
    try {
        // const token = getToken();
        // const headers = {
        //     Authorization: "Bearer ".concat(token),
        // };

        dispatch(action(GET_CORPORATE_TRANSACTIONS, []));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CORPORATE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CORPORATE, e.message));
        }
    }
}
