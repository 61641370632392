import {faGoogle, faTwitter, faYahoo, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {faGlobeEurope,} from '@fortawesome/free-solid-svg-icons';

import USAFlag from '../assets/img/flags/united-states-of-america.svg';
import CanadaFlag from '../assets/img/flags/canada.svg';
import GermanyFlag from '../assets/img/flags/germany.svg';
import FranceFlag from '../assets/img/flags/france.svg';
import JapanFlag from '../assets/img/flags/japan.svg';
import ItalyFlag from '../assets/img/flags/italy.svg';


const pageVisits = [
    {id: 1, views: "+250788327447", returnValue: "Samsung A01", bounceRate: "30 May, 2021", pageName: "Copain Fabrice"},
    {id: 1, views: "+250788327447", returnValue: "Samsung A01", bounceRate: "1 Jun, 2021", pageName: "Jean Paul"},
    {
        id: 1,
        views: "+250788327447",
        returnValue: "Samsung A01",
        bounceRate: "27 May, 2021",
        pageName: "Pacifique Musigwa"
    },
    {id: 1, views: "+250788327447", returnValue: "Samsung A01", bounceRate: "1 Jun, 2021", pageName: "Isabelle Noella"},
    {id: 1, views: "+250788327447", returnValue: "Samsung A01", bounceRate: "1 Jun, 2021", pageName: "Aline Umwali"},
    {id: 1, views: "+250788327447", returnValue: "Samsung A01", bounceRate: "13 May, 2021", pageName: "Theodor Makuke"},
    {id: 1, views: "+250788327447", returnValue: "Samsung A01", bounceRate: "1 Jun, 2021", pageName: "Steven Steven"},


];

const pageTraffic = [
    {
        id: 1,
        source: "Direct",
        sourceType: "Direct",
        trafficShare: 51,
        change: 2.45,
        sourceIcon: faGlobeEurope,
        sourceIconColor: "gray"
    },
    {
        id: 2,
        source: "Google Search",
        sourceType: "Search / Organic",
        trafficShare: 18,
        change: 17.67,
        sourceIcon: faGoogle,
        sourceIconColor: "info"
    },
    {
        id: 3,
        source: "youtube.com",
        sourceType: "Social",
        category: "Arts and Entertainment",
        rank: 2,
        trafficShare: 27,
        sourceIcon: faYoutube,
        sourceIconColor: "danger"
    },
    {
        id: 4,
        source: "yahoo.com",
        sourceType: "Referral",
        category: "News and Media",
        rank: 11,
        trafficShare: 8,
        change: -9.30,
        sourceIcon: faYahoo,
        sourceIconColor: "purple"
    },
    {
        id: 5,
        source: "twitter.com",
        sourceType: "Social",
        category: "Social Networks",
        rank: 4,
        trafficShare: 4,
        sourceIcon: faTwitter,
        sourceIconColor: "info"
    }
];
const deviceList = [
    {
        id: 1,
        source: "Fabrice",
        sourceType: "Fabrice",
        trafficShare: 51,
        change: 2.45,
        sourceIcon: faGlobeEurope,
        sourceIconColor: "gray"
    },
    {
        id: 2,
        source: "Google Search",
        sourceType: "Search / Organic",
        trafficShare: 18,
        change: 17.67,
        sourceIcon: faGoogle,
        sourceIconColor: "info"
    },
    {
        id: 3,
        source: "youtube.com",
        sourceType: "Social",
        category: "Arts and Entertainment",
        rank: 2,
        trafficShare: 27,
        sourceIcon: faYoutube,
        sourceIconColor: "danger"
    },
    {
        id: 4,
        source: "yahoo.com",
        sourceType: "Referral",
        category: "News and Media",
        rank: 11,
        trafficShare: 8,
        change: -9.30,
        sourceIcon: faYahoo,
        sourceIconColor: "purple"
    },
    {
        id: 5,
        source: "twitter.com",
        sourceType: "Social",
        category: "Social Networks",
        rank: 4,
        trafficShare: 4,
        sourceIcon: faTwitter,
        sourceIconColor: "info"
    }
];

const pageRanking = [
    {
        id: 1,
        country: "United States",
        countryImage: USAFlag,
        overallRank: 76,
        overallRankChange: -5,
        travelRank: 3,
        widgetsRank: 32,
        widgetsRankChange: 3
    },
    {
        id: 2,
        country: "Canada",
        countryImage: CanadaFlag,
        overallRank: 106,
        overallRankChange: 17,
        travelRank: 4,
        widgetsRank: 30,
        widgetsRankChange: 3
    },
    {
        id: 4,
        country: "France",
        countryImage: FranceFlag,
        overallRank: 112,
        overallRankChange: 10,
        travelRank: 5,
        widgetsRank: 34,
        widgetsRankChange: 7
    },
    {
        id: 5,
        country: "Japan",
        countryImage: JapanFlag,
        overallRank: 115,
        overallRankChange: 3,
        travelRank: 7,
        travelRankChange: 1,
        widgetsRank: 39,
        widgetsRankChange: -2
    },
    {
        id: 3,
        country: "Germany",
        countryImage: GermanyFlag,
        overallRank: 147,
        overallRankChange: -12,
        travelRank: 10,
        travelRankChange: -1,
        widgetsRank: 12,
        widgetsRankChange: -5
    },
    {
        id: 6,
        country: "Italy",
        countryImage: ItalyFlag,
        overallRank: 220,
        overallRankChange: -56,
        travelRank: 11,
        travelRankChange: -3,
        widgetsRank: 89,
        widgetsRankChange: 2
    }
];

const invoiceItems = [
    {id: 1, item: "Origin License", description: "Extended License", price: "999,00", quantity: 1},
    {
        id: 2,
        item: "Custom Services",
        description: "Instalation and Customization (cost per hour)",
        price: "150,00",
        quantity: 20
    },
    {id: 3, item: "Hosting", description: "1 year subcription", price: "499,00", quantity: 1},
    {id: 4, item: "Platinum Support", description: "1 year subcription 24/7", price: "3999,00", quantity: 1},
];

const plans = [
    {
        id: 1,
        plane_tile: "BANDLE1",
        bandleMinutes: 25,
        bandleSms: 100,
        bandleData: 1000,
        financedAmount: 60500.0,
        interestRate: 2.5,
        weeklyPayment: 1750.0,
    },
    {
        id: 2,
        plane_tile: "BANDLE13",
        bandleMinutes: 50,
        bandleSms: 200,
        bandleData: 2000,
        financedAmount: 334800,
        interestRate: 2.5,
        weeklyPayment: 5225,
    },
];

const partners = [
    {id: 1, type: "Lender", name: "Equity", email: "info@equit.rw", telephone: "0788112311", clients: 11,},
    {id: 2, type: "Retailer", name: "CogeBank", email: "info@cogebank.rw", telephone: "0788112312", clients: 32,},
    {id: 3, type: "insurance", name: "SORAS", email: "info@soras.rw", telephone: "0788112313", clients: 20,},
];

const PARTNER_TYPES = [
    {
        id: 1,
        name: "Retailer",
    },
    {
        id: 2,
        name: "Lender",
    },
    {
        id: 3,
        name: "Distributor",
    },
    {
        id: 5,
        name: "Support",
    },
    {
        id: 7,
        name: "Telco",
    },
    {
        id: 8,
        name: "OEM",
    },
    {
        id: 9,
        name: "Insurance",
    },
];

export {
    pageVisits,
    pageTraffic,
    pageRanking,
    invoiceItems,
    deviceList,
    plans,
    partners,
    PARTNER_TYPES,
};