import React, {Component} from "react";
import {connect} from "react-redux";
import {getCorporateTransactions} from "../../../actions/corporates";
import {TransactionsTable} from "../../../components/Tables";

class Transactions extends Component {
    state = {
        loading: false,
    };

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getCorporateTransactions, corporateId} = this.props;
        await getCorporateTransactions(corporateId);
        this.setState({loading: false});
    }

    render() {

        return (
            <>
                <TransactionsTable/>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {error, transactions} = state.corporates;
    return {error, transactions};
}
//
export default connect(mapStateProps, {getCorporateTransactions})(Transactions);
