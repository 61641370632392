import {ACCOUNTING_ERROR, GET_ACCOUNTING_HISTORY, GET_ACCOUNTING_NUMBER} from "../../actions/types";

const init = {
    accountingError: null,
}

export const accountingReducer = (state = init, action) => {
    switch (action.type) {
        case GET_ACCOUNTING_NUMBER:
            return {
                ...state,
                accountNumber: action.payload,
            }
        case GET_ACCOUNTING_HISTORY:
            return {
                ...state,
                accountHistory: action.payload,
            }
        case ACCOUNTING_ERROR:
            return {
                ...state,
                accountingError: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}