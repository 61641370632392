import React from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom"
import { useEffect } from 'react';


function Alert({message, variant, redirectUrl}) {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    useEffect(() => {
        if (message && variant) {
            enqueueSnackbar(message, {variant})

            if (redirectUrl != null && redirectUrl !== "undefined") {
                setTimeout(() => {
                    history.push(redirectUrl)
                }, [2000])
            } else {
                console.log("Not redirecting...")
            }
        }
    }, [enqueueSnackbar, message, variant])

    return null;
}

export default Alert