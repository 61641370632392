import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Breadcrumb, Button, Card, Form, Modal} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

class PaymentCollection extends Component {
    state = {
        loading: false,
        validated: false,
        file: null,
        autoSweep: '1',
        errorMessage: null,
        successMessage: null,
        showModal: false,
        csvContent: "",
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const form = event.currentTarget;
        if (form.checkValidity() === false || !this.state.file) {
            event.stopPropagation();
            return;
        }
        const formData = new FormData();
        formData.append("file", this.state.file);
        formData.append("autoSweep", this.state.autoSweep);
        const url = process.env.REACT_APP_URL_RW_UTILS;

        axios.post(`${url}/rw-utils/payment/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                // Handle success response
                console.log("Response:", response.data);
                if (response.data) {
                    this.setState({
                        errorMessage: null,
                        file: null,
                        validated: false,
                        csvContent: "",
                        loading: false
                    });
                    alert("File uploaded and processing started.");
                }
            })
            .catch(error => {
                // Handle error response
                if (error) {
                    console.error("Error:", error);
                    this.setState({
                        errorMessage: "An error occurred while uploading the file.",
                        file: null,
                        validated: false,
                        csvContent: "",
                        loading: false
                    });
                    alert("An error occurred while uploading the file.");
                }
            });
    };

    isValidCSVFile = (file) => {
        return file.type === "text/csv" || file.name.toLowerCase().endsWith(".csv");
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!this.isValidCSVFile(file)) {
            this.setState({errorMessage: "Please upload a CSV file.", file: null, validated: false});
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const contents = e.target.result;
            if (!this.isValidCSV(contents)) {
                this.setState({errorMessage: "Invalid content!", file: null, validated: false});
                return;
            }
            this.setState({errorMessage: null, file, showModal: true, csvContent: contents, validated: true});
        };
        reader.readAsText(file);
    };

    handleAutoSweepChange = (event) => {
        this.setState({autoSweep: event.target.checked ? '1' : '0'});
    }

    isValidCSV = (contents) => {
        // Split the CSV content into lines
        const lines = contents.trim().split(/\r?\n/);

        // Iterate over each line
        for (const line of lines) {
            // Split the line by comma
            const parts = line.split(',');
            // Ensure there are exactly 2 parts and both are non-empty
            if (parts.length !== 2 || parts[0].trim() === '' || parts[1].trim() === '') {
                return false;
            }
            // Ensure the first part starts with "250" pattern
            if (!/^250\d+$/.test(parts[0].trim())) {
                return false;
            }
            // Ensure the second part is a valid amount (e.g., numeric)
            if (!/^\d+$/.test(parts[1].trim())) {
                return false;
            }
        }

        return true;
    };

    handleCloseModal = () => {
        this.setState({showModal: false});
    };

    render() {
        const {loading, validated, errorMessage, csvContent, showModal, autoSweep } = this.state;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Payment Collection</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Payment Collection</h4>
                    </div>
                </div>
                <Card border="light" className="table-wrapper shadow-sm mb-4">
                    <Card.Body className="">
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <div>
                                <Form.Group controlId="formFile" className="mb-3 has-error">
                                    <Form.Label>Payment Collection File (csv)</Form.Label>
                                    <Form.Control type="file" onChange={this.handleFileChange} required/>
                                    <Form.Control.Feedback type="invalid">Please choose a CSV
                                        file.</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className={'my-4'}>
                                <Form.Check
                                    type="switch"
                                    id="switch-button"
                                    label={`Auto Sweep ${autoSweep === '1' ? 'Enabled' : 'Disabled'}`}
                                    defaultChecked={true}
                                    defaultValue={'1'}
                                    onChange={this.handleAutoSweepChange}
                                />
                            </div>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <Button variant="primary" disabled={validated !== true || loading === true} type="submit">
                                {loading ? 'Uploading ...' : 'Upload'}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <Modal as="div" centered show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview CSV</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: "85vh", overflowY: "auto"}}>
                        <pre>{csvContent}</pre>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        loading: store.loading,
    };
}
export default connect(mapStateToProps, {})(PaymentCollection);
