import React, {Component} from "react";
import {Card, Col} from "@themesberg/react-bootstrap";

class FaqCard extends Component {
    state = {}

    //
    render() {
        const {faq,} = this.props;

        return (
            <>
                <Col md={6} className={'mb-1'}>
                    <Card className={'cursor-pointer'} onClick={() => {
                    }}>
                        <Card.Header className={'py-2'}>
                            <b>{faq.question ?? ""}</b>
                        </Card.Header>
                        <Card.Body>
                            {faq.answers ?? ""}
                        </Card.Body>
                    </Card>
                </Col>
            </>
        );
    }
}

export default FaqCard;