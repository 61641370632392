import React, { useState } from 'react';
import { Breadcrumb, Button, Card, Form, Row } from "@themesberg/react-bootstrap";
import { MDBRow } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Routes } from '../../routes';
import authorization from '../../helpers/authorization';
import { connect } from 'react-redux';
import { InsertSingleManualPaynent } from '../../actions/manual_repayments';
import { BeatLoader } from "react-spinners";
import { getUser } from '../../helpers/tokenHelper';
import { useEffect } from 'react';


function CustomerPaymentUploadLog({postSingleManualPaynentPayload, loading, store}) {
  const [admin, setAdmin] = useState(null);
  const [singleCustomerLog, setSingleCustomerLog] = useState({
    serialNumber: "",
    from_msisdn: "",
    totalAmount: "",
    datePaid: "",
    reference: "",
    paymentMethod: "",
    userId: `${getUser()?.id}`
  })

  const handleChange = (e) => {
    setSingleCustomerLog({
      ...singleCustomerLog, [e.target.name]: e.target.value
    })
  }

  const handleSingleAdd = (e) => {
    e.preventDefault()
    console.log("singleCustomerLog", singleCustomerLog)
    postSingleManualPaynentPayload(singleCustomerLog)

    // Clear All Inputs After 1sec 
    setTimeout(() => {
      setSingleCustomerLog({
        serialNumber: "",
        from_msisdn: "",
        totalAmount: "",
        datePaid: "",
        reference: "",
        paymentMethod: "",
        userId: getUser()?.id
      })
    }, 1000)
  }
  
  useEffect(() => {
    let adminUser = getUser()
    setAdmin(adminUser)
  }, [])

 console.log('store', store)

  return (
    <div className='py-4'>
      {/* Header - starts  */}
      <Breadcrumb className="d-none d-md-inline-block"
        listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
        <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
          icon={faHome} /></Breadcrumb.Item>
        <Breadcrumb.Item active>Customer Payment Log</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ marginTop: "2%" }}>
        <h5 style={{ marginBottom: "2%" }}>Insert Single Payment</h5>
        {/* Header - ends */}

        <Form onSubmit={handleSingleAdd}>
          {/* First row - starts  */}
          <MDBRow>
            <Row className="align-items-center col mb-4">
              <Form.Group id="serialNumber">
                <Form.Label>Serial Number(Optional)</Form.Label>
                <Form.Control
                  // required
                  type="text"
                  name="serialNumber"
                  onChange={handleChange}
                  value={singleCustomerLog.serialNumber}
                  placeholder="Enter Serial Number..."
                />
              </Form.Group>
            </Row>
            <Row className="align-items-center col mb-4">
              <Form.Group id="msisdn">
                <Form.Label>Msisdn</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="from_msisdn"
                  onChange={handleChange}
                  value={singleCustomerLog.from_msisdn}
                  placeholder="Enter Msisdn..."
                />
              </Form.Group>
            </Row>
          </MDBRow>
          {/* First row - ends  */}

          {/* Second row - starts  */}
          <MDBRow>
            <Row className="align-items-center col mb-4">
              <Form.Group id="totalAmount">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="totalAmount"
                  onChange={handleChange}
                  value={singleCustomerLog.totalAmount}
                  placeholder="Enter Total Amount..."
                />
              </Form.Group>
            </Row>
            <Row className="align-items-center col mb-4">
              <Form.Group id="datePaid">
                <Form.Label>Date Paid</Form.Label>
                <Form.Control
                  required
                  type="date"
                  name="datePaid"
                  onChange={handleChange}
                  value={singleCustomerLog.datePaid}
                // placeholder="Enter Msisdn..."
                />
              </Form.Group>
            </Row>
          </MDBRow>
          {/* Second row - ends  */}

          {/* Third row - starts  */}
          <MDBRow>
            <Row className="align-items-center col mb-4">
              <Form.Group id="reference">
                <Form.Label>Reference</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="reference"
                  onChange={handleChange}
                  value={singleCustomerLog.reference}
                  placeholder="Enter Reference..."
                />
              </Form.Group>
            </Row>
            <Row className="align-items-center col mb-4">
              <Form.Group id="paymentMethod">
                <Form.Label>Payment Method (Eg: Bank Transfer)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="paymentMethod"
                  onChange={handleChange}
                  value={singleCustomerLog.paymentMethod}
                  placeholder="Enter Payment Method..."
                />
              </Form.Group>
            </Row>
          </MDBRow>
          {/* Third row - ends  */}

          <Button
            style={{ width: "18.75rem" }}
            size='md'
            variant="dark"
            type="submit"
            // disabled={loading || !authorization("MANUAL_REPAYMENT")}
            disabled={!authorization("MANUAL_REPAYMENT")}
          >
            {loading ? <BeatLoader color="#fff" /> : "ADD"}
          </Button>
        </Form>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  postSingleManualPaynentPayload: (payload) => dispatch(InsertSingleManualPaynent(payload))
})

const mapStateToProps = (store) => {
  const {loading} = store?.manualRepayments;
  return {loading, store};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentUploadLog);