import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import useRoute from "../../../../helpers/useRoute";
import {Routes} from "../../../../routes";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import authorization from "../../../../helpers/authorization";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {getAllBanks} from "../../../../actions/partners";
import {action} from "../../../../actions";
import {PARTNER_DATA} from "../../../../actions/types";

const TableRow = (props) => {
    //
    const {
        index, id, bankCode, address, bankName, bankShort, tinNumber,
        phoneNumber, customerServicePhone, email, website, partnerType: code,
    } = props;
    //
    const urlEdit = useRoute(Routes.EditPartner.path, {code, id});
    const urlView = useRoute(Routes.ViewPartner.path, {code, id});

    return (
        <tr valign={'middle'}>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className={"fw-bold text-center"}>
                <a href={authorization("PA_BROWSE") ? `${urlView}` : ""}>{bankName}</a>
            </td>
            <td className={'text-center'}>{bankCode}</td>
            <td className={'text-center'}>{bankShort}</td>
            <td className={'text-center'}>{phoneNumber}</td>
            <td className={'text-center'}>{tinNumber}</td>
            <td className={'text-center'}>{address}</td>
            <td className={'text-center'}>{email}</td>
            <td>
                {
                    authorization("PA_EDIT") ? (
                        <>
                            <Button variant="light" size="sm" href={urlEdit}>
                                <FontAwesomeIcon icon={faEdit} className={`icon icon-xs text-success cursor-pointer`}/>
                            </Button> &nbsp;&nbsp;
                        </>
                    ) : (<></>)
                }
                <Button variant="light"
                        size="sm" disabled={!authorization("PA_BROWSE")}
                        href={urlView}>
                    <FontAwesomeIcon icon={faEye} className={`icon icon-xs text-info cursor-pointer`}/>
                </Button> &nbsp;&nbsp;
            </td>
        </tr>
    );
};

const BrowseBanks = (props) => {

    const {banks, error, getAllBanks, partnerType, freelancerLoading: loading} = props;
    const dispatch = useDispatch();
    //
    useEffect(() => {
      if (banks == null && !loading) {
          getAllBanks();
      }
    }, [banks, error, loading,]);
    //
    return (
        <>
            <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                <thead className="thead-light">
                <tr>
                    <th className={'text-center'}>#</th>
                    <th className={'text-center'}>Bank Name</th>
                    <th className={'text-center'}>Bank Code</th>
                    <th className={'text-center'}>Bank Short</th>
                    <th className={'text-center'}>Phone Number</th>
                    <th className={'text-center'}>Tin Number</th>
                    <th className={'text-center'}>Address</th>
                    <th className={'text-center'}>Email</th>
                    <th className={'text-center'}>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    loading ? (
                        <tr>
                            <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                        </tr>
                    ) : banks != null && banks.length > 0 ?
                        banks.map((partner, i) => <TableRow {...partner}
                                                            index={++i}
                                                            partnerType={partnerType}
                                                            key={`page-visit-${partner.id}`}/>) : (
                            <tr>
                                <td colSpan="10" align="center">No bank available !</td>
                            </tr>
                        )
                }
                </tbody>
            </Table>
        </>
    );
}
const mapStateToProps = (store) => {
    const {error, banks, freelancerLoading} = store.partners;
    return {error, banks, freelancerLoading};
}
export default connect(mapStateToProps, {getAllBanks})(BrowseBanks);
