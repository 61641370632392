import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Col,
  InputGroup,
  Row,
  Table,
  FormCheck,
} from "@themesberg/react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import authorization from "../../helpers/authorization";
import { connect } from "react-redux";
import { InsertSingleManualPaynent } from "../../actions/manual_repayments";
import { BeatLoader } from "react-spinners";
import { getUser } from "../../helpers/tokenHelper";
import { useEffect } from "react";
import {
  getCustomerDetails,
  processCustomerGraduation,
} from "../../actions/customer_graduation";

function ProcessCustomerGraduation({
  fetchCustomerDetails,
  loading,
  processCustomerNow,
  singleCustomerDetails,
}) {
  const [admin, setAdmin] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({
    fromMsisdn: "",
    customerId: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [byPassUnpaidCheck, setByPassUnpaidCheck] = useState(false)

  // To handle input change
  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  // Fetch Customer Details
  const handleFetchCustomerDetails = async (e) => {
    e.preventDefault();

    let response = await fetchCustomerDetails(inputValue);

    if (response?.status === 200) {
      setCustomerDetails({
        fromMsisdn: response.data?.data[0]?.msisdn,
        customerId: response.data?.data[0]?.customerId,
      });
      setBtnDisabled(false);
      setShowCustomerDetails(true);
    }
  };

  // TO handle Input Change
  const handleChange = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };

  // To process customer for graduation now
  const handleProcessGraduation = async (e) => {
    e.preventDefault();
    console.log("customerDetails", customerDetails);
    let payloadToSend = {
      customerId: customerDetails.customerId,
      msisdn: `${customerDetails.fromMsisdn}`,
      adminUser: `${getUser().id}`,
      byPassUnpaidCheck
    };
    let response = await processCustomerNow(payloadToSend);

    if (response.data.status) {
      setBtnDisabled(true);
      setInputValue("");
      setCustomerDetails({
        fromMsisdn: "",
        customerId: "",
      });
      setShowCustomerDetails(false);
      // setBtnDisabled(false)
    }
  };

  // Function to check if singleCustomerDetails obj is empty or not
  const isObjectEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    let adminUser = getUser();
    setAdmin(adminUser);
  }, [customerDetails]);

  console.log("byPassUnpaidCheck:", byPassUnpaidCheck);

  return (
    <div className="py-4">
      {/* Header - starts  */}
      <Breadcrumb
        className="d-none d-md-inline-block"
        listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
      >
        <Breadcrumb.Item href={Routes.DashboardOverview.path}>
          <FontAwesomeIcon icon={faHome} />
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Customers Graduation</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ marginTop: "2%" }}>
        <h5 style={{ marginBottom: "1%" }}>Process Graduation</h5>
        {/* Header - ends */}

        <>
          {/* <p>
                        Firstly fetch the details of the customer in other to get the "customer ID"
                        which is needed to process customer for graduation
                    </p> */}

          <Form onSubmit={handleFetchCustomerDetails}>
            <Card border="light" className="table-wrapper shadow-sm mb-4">
              <Card.Body className="">
                <Row className="">
                  <Col md={12}>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="inputValue"
                        onChange={handleOnChange}
                        value={inputValue}
                        placeholder="Type Msisdn..."
                        required
                      />
                      <Button type="submit">
                        {loading ? (
                          <BeatLoader color="#fff" />
                        ) : (
                          "Fetch Details"
                        )}
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </>

        {/* Display This when customer details have been fetched  */}
        {showCustomerDetails && (
          <Row className="mb-2">
            <h6>
              <b>Customer Details</b>
            </h6>
            <Col md="5">
              <Card>
                <Card.Body>
               
                    <Table striped bordered>
                      <tr>
                        <td>Msisdn</td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {singleCustomerDetails?.payload?.msisdn || "null"}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Customer Id</td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {singleCustomerDetails?.payload?.customerId ||
                              "null"}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {singleCustomerDetails?.payload?.name || "null"}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {singleCustomerDetails?.payload?.email || "null"}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {singleCustomerDetails?.payload?.address || "null"}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Date Of Birth</td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            {singleCustomerDetails?.payload?.dob || "CellId"}
                          </b>
                        </td>
                      </tr>
                    </Table>
            
                    <div className="mt-4">
                      {/* <Form.Check type="checkbox" onChange={() => setByPassUnpaidCheck(!byPassUnpaidCheck)}>
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Bypass Pending Payments</FormCheck.Label>
                      </Form.Check> */}
                      <input class="form-check-input" type="checkbox" value={byPassUnpaidCheck} onChange={() => setByPassUnpaidCheck(!byPassUnpaidCheck)} checked={byPassUnpaidCheck}/><label style={{marginLeft: 10}}>Bypass Pending Payments</label>

                    </div>
                              
                  <Button
                 
                    size="md"
                    variant="dark"
                    type="submit"
                    className="mt-3"
                    onClick={handleProcessGraduation}
                    disabled={!authorization("MANUAL_REPAYMENT") || btnDisabled}
                  >
                    {loading && !btnDisabled ? (
                      <BeatLoader color="#fff" />
                    ) : (
                      "PROCESS"
                    )}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {/* <Form onSubmit={handleProcessGraduation} style={{ marginTop: "60px" }}>
                    {
                        showCustomerDetails &&
                        <p>
                            <b>
                                You can now PROCESS CUSTOMER FOR GRADUATION.
                            </b>
                        </p>
                    }

             
                    <MDBRow>
                        <Row className="align-items-center col mb-4">
                            <Form.Group id="fromMsisdn">
                                <Form.Label>Msisdn *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="fromMsisdn"
                                    onChange={handleChange}
                                    value={customerDetails.fromMsisdn}
                                    placeholder="Enter Msisdn..."
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                        <Row className="align-items-center col mb-4">
                            <Form.Group id="customerId">
                                <Form.Label>Customer ID *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="customerId"
                                    onChange={handleChange}
                                    value={customerDetails.customerId}
                                    placeholder="Enter Customer Id.."
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                    </MDBRow>
            

                    <Button
                        style={{ width: "18.75rem" }}
                        size='md'
                        variant="dark"
                        type="submit"
                        disabled={!authorization("MANUAL_REPAYMENT") || btnDisabled}
                    >
                        {(loading && !btnDisabled) ? <BeatLoader color="#fff" /> : "PROCESS"}
                    </Button>
                </Form> */}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerDetails: (payload) => dispatch(getCustomerDetails(payload)),
  processCustomerNow: (payload) => dispatch(processCustomerGraduation(payload)),
});

const mapStateToProps = (store) => {
  const { loading, graduatingCustomers, singleCustomerDetails } =
    store?.customerGrad;
  return { loading, graduatingCustomers, singleCustomerDetails };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessCustomerGraduation);
