import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Card, Col, Image, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import TicketsFilter from "./components/TicketsFilter";
import TicketCard from "./components/TicketCard";
import {getTickets, replyTickets} from "../../actions/tickets";
import ReactLogo from "../../assets/loader/Loader.svg";
import ViewTicket from "./components/ViewTicket";

class Tickets extends Component {
    state = {
        loading: true,
        showModal: false,
        selectedTicket: null,
    };

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getTickets} = this.props;
        await getTickets();
        this.setState({loading: false,});
    }

    handleClose = () => {
        this.setState({showModal: false, selectedTicket: null,});
    };
    //
    handleShow = (data) => {
        this.setState({showModal: true, selectedTicket: data,});
    }

    render() {
        const {loading, selectedTicket, showModal,} = this.state;
        const { tickets, replyTickets, user} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all tickets</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Tickets</h4>
                    </div>
                </div>
                <div className={'d-flex justify-content-end'}><TicketsFilter/></div>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="">
                        {
                            loading ? (
                                <>
                                    <center>
                                        <Image
                                            className="loader-element animate__animated animate__jackInTheBox"
                                            src={ReactLogo} height={200} width={200}/>
                                    </center>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        {
                                            !loading && tickets?.length > 0 ? (
                                                tickets.map((ticket, i) => <TicketCard ticket={ticket}
                                                                                       handleClose={this.handleClose}
                                                                                       onClick={() => {
                                                                                           this.handleShow(ticket)
                                                                                       }}/>)
                                            ) : (
                                                <>
                                                    <Col md={12} className={'text-center'}>
                                                        <h5>No tickets available yet !</h5>
                                                    </Col>
                                                </>
                                            )
                                        }
                                    </Row>
                                </>
                            )
                        }
                    </Card.Body>
                    <Card.Footer className=" d-lg-flex align-items-center justify-content-between">
                        {/*<Nav>*/}
                        {/*    <Pagination className="mb-2 mb-lg-0">*/}
                        {/*        <Pagination.Prev>*/}
                        {/*            Previous*/}
                        {/*        </Pagination.Prev>*/}
                        {/*        <Pagination.Item active>1</Pagination.Item>*/}
                        {/*        <Pagination.Item>2</Pagination.Item>*/}
                        {/*        <Pagination.Next>*/}
                        {/*            Next*/}
                        {/*        </Pagination.Next>*/}
                        {/*    </Pagination>*/}
                        {/*</Nav>*/}
                        {/*<small className="fw-bold">*/}
                        {/*    Showing <b>1</b> out of <b>25</b> entries*/}
                        {/*</small>*/}
                    </Card.Footer>
                </Card>
                {
                    showModal ? <ViewTicket ticket={selectedTicket} handleClose={this.handleClose} show={showModal}
                                            user={user}
                                            replyTickets={replyTickets}/> : (<></>)
                }
            </>
        );
    };
}

//
const mapStateProps = (state) => {
    const {error, tickets} = state.tickets;
    const {user} = state.authentication;
    return {error, tickets, user};
}
//
export default connect(mapStateProps, {getTickets, replyTickets})(Tickets);
