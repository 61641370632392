import React from "react";
import {connect} from "react-redux";

const ViewTelco = (props) => {
    return (<></>);
}
const mapStateToProps = store => {
    const {} = store.partners;
    return {}
}
//
export default connect(mapStateToProps, {})(ViewTelco);