import {
    ADD_ROLE_TO_GROUP,
    ASSIGN_USER_TO_GROUP,
    CREATE_ROLE,
    GET_ROLES,
    GET_ROLES_IN_GROUP,
    GET_ROLES_NOT_IN_GROUP,
    REMOVE_ROLE_TO_GROUP,
    REMOVE_ROLES_GROUP,
    REMOVE_USER_ROLE,
    ROLE_ERROR,
    ROLE_GROUP,
    ROLE_GROUPS,
    ROLE_GROUPS_CREATE,
    USER_GROUPS,
    USER_ROLES
} from "../../actions/types";

const initiate = {
    groups: null,
    roles: null,
    error: null,
}
//
const rolesReducer = (state = initiate, action) => {
    switch (action.type) {
        case ROLE_GROUPS:
        case ADD_ROLE_TO_GROUP:
        case REMOVE_ROLE_TO_GROUP:
            return {
                ...state,
                groups: action.payload,
                error: null,
            }
        case USER_GROUPS:
            return {
                ...state,
                userGroups: action.payload,
                error: null,
            }
        case ROLE_GROUP:
        case REMOVE_ROLES_GROUP:
        case ASSIGN_USER_TO_GROUP:
        case ROLE_GROUPS_CREATE:
            return {
                ...state,
                group: action.payload,
                error: null,
            }
        case GET_ROLES_IN_GROUP:
            return {
                ...state,
                rolesInGroup: action.payload,
                error: null,
            }
        case GET_ROLES_NOT_IN_GROUP:
            return {
                ...state,
                rolesNotInGroup: action.payload,
                error: null,
            }
        case GET_ROLES:
            return {
                ...state,
                roles: action.payload,
                error: null,
            }
        case USER_ROLES:
            return {
                ...state,
                userRoles: action.payload,
                error: null,
            }
        case CREATE_ROLE:
        case REMOVE_USER_ROLE:
            return {
                ...state,
                role: action.payload,
                error: null,
            }
        case ROLE_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}
//
export default rolesReducer;
