import { Button } from "@themesberg/react-bootstrap";
import { BeatLoader } from "react-spinners";
import { connect } from 'react-redux';
import { useState } from 'react';
import { UpdateCustomerPaymentLogs, fecthCustomerPaymentLogs } from "../../actions/manual_repayments";



function ConfirmModalCustomerLog({
    type,
    setShow,
    actionTxt,
    loading,
    activeRow,
    UpdateCustomerPaymentLogsFunc,
    getCustomerPaymentLogs
}) {
    let content = activeRow;
    console.log('activeRow', activeRow)
    console.log('type', type)

    const handleUpdateRecord = async (data) => {
        const payLoadToSend = {
            id: data?.id,
            fromMsisdn: data?.fromMsisdn,
            status: type === "approve" ? "APPROVED" : "REJECT"
        }
        let response = await UpdateCustomerPaymentLogsFunc(payLoadToSend)
        console.log('modal-response', response)

        if (response?.data?.message === "Customer record was updated successfully") {
            setShow(false);

            // Fetch records again 
            getCustomerPaymentLogs()
        }
    }

    return (
        <>
            <>
                <>
                    <h5>Are you sure you want to {type} this customer payment log???</h5>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Msisdn:</b>
                        <span style={{ fontSize: "17px" }}>{content?.fromMsisdn || "Msisdn"}</span>
                    </p>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Loan Id:</b>
                        <span style={{ fontSize: "17px" }}>{content?.loanid || "Loadn Id"}</span>
                    </p>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Status:</b>
                        <span style={{ fontSize: "17px" }}>{content?.status || "Status"}</span>
                    </p>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Total Amount:</b>
                        <span style={{ fontSize: "17px" }}>{content?.totalAmount || "0"}</span>
                    </p>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Used Amount:</b>
                        <span style={{ fontSize: "17px" }}>{content?.usedAmount || "0"}</span>
                    </p>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Date Paid:</b>
                        <span style={{ fontSize: "17px" }}>{content?.datePaid || "null"}</span>
                    </p>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <b>Payment Method:</b>
                        <span style={{ fontSize: "17px" }}>{content?.paymentMethod || "null"}</span>
                    </p>
                </>


                {/* Action buttons  */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "30px"
                    }}
                >
                    <Button
                        variant={"dark"}
                        size={"sm"}
                        color="white"
                        onClick={() => handleUpdateRecord(content)}
                        disabled={loading}
                    >
                        {loading ? <BeatLoader color="#fff" /> : (actionTxt || "Proceed")}
                    </Button>
                </div>
            </>
        </>
    )
}


const mapDispatchToProps = dispatch => ({
    UpdateCustomerPaymentLogsFunc: (payload) => dispatch(UpdateCustomerPaymentLogs(payload)),
    getCustomerPaymentLogs: (payload) => dispatch(fecthCustomerPaymentLogs(payload))
})

const mapStateToProps = (store) => {
    const { loading } = store?.manualRepayments;
    return { loading };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalCustomerLog);