import React, {Component} from "react";
import {connect} from "react-redux";
import {getUserDetails} from "../../actions/user";
import {Breadcrumb, Col, Nav, Row, Tab, Toast} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faEye, faHome, faMobile} from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/Preloader";
import UserDetails from "./components/UserDetails";
import {StartCase} from "react-lodash";
import UserActivityLog from "./components/UserActivityLog";
import TabNavItem from "../../components/TabNavItem";
import authorization from "../../helpers/authorization";
import UserDevices from "./components/UserDevices";

class UserDetail extends Component {
    state = {
        loading: true,
        username: this.props.match.params.username,
        selectedTab: 'user_details',
        showToast: true,
    }

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {username} = this.state;
        const {getUserDetails} = this.props;
        await getUserDetails(username);
        this.setState({loading: false,});
    }

    closeToast = () => {
        this.setState({showToast: false,});
    }

    selectTab = (tab) => {
        this.setState({selectedTab: tab,});
    }

    render() {
        const {loading, showToast} = this.state;
        const {user, error} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Users.path}>Users</Breadcrumb.Item>
                            <Breadcrumb.Item active={true}>Details</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>User {!loading && user ? (
                                <span>: <b><StartCase string={`${user.name ?? ""} ${user.surname ?? ""}`}/></b></span>) :
                            ("")}
                        </h3>
                    </div>
                </div>
                {
                    loading ? (
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Preloader show={loading}/>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row className={''}>
                                <Col md={12}>
                                    {
                                        error ? (
                                            <Toast className="d-inline-block m-1"
                                                   autohide
                                                   delay={3} onClose={() => this.closeToast()} show={showToast}
                                                   bg={'danger'} key={'idx-toast'}>
                                                <Toast.Header>Error Occur</Toast.Header>
                                                <Toast.Body>{error}</Toast.Body>
                                            </Toast>
                                        ) : (<></>)
                                    }
                                    <Tab.Container title={''} defaultActiveKey={'user_details'}>
                                        <Row>
                                            <Col md={12}>
                                                <Nav fill variant="pills" className="flex-column flex-sm-row">
                                                    <TabNavItem title={'Details'}
                                                                eventKey="user_details"
                                                                icon={faEye}
                                                                role={'US_READ'}
                                                                onSelect={() => this.selectTab('user_details')}/>
                                                    <TabNavItem title={'Devices'}
                                                                eventKey="user_devices"
                                                                icon={faMobile}
                                                                role={'US_READ'}
                                                                onSelect={() => this.selectTab('user_devices')}/>
                                                    <TabNavItem title={'Activity Logs'}
                                                                eventKey="user_logs"
                                                                icon={faArrowDown}
                                                                role={'US_READ'}
                                                                onSelect={() => this.selectTab('user_logs')}/>
                                                </Nav>
                                                <Tab.Content>
                                                    {
                                                        authorization("US_READ") ? (
                                                            <>
                                                                <Tab.Pane eventKey="user_details" className="py-4">
                                                                    <UserDetails usr={user}/>
                                                                </Tab.Pane>
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        authorization('US_READ') ? (
                                                            <>
                                                                <Tab.Pane eventKey="user_devices" className="py-4">
                                                                    <UserDevices usr={user}/>
                                                                </Tab.Pane>
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        authorization('US_READ') ? (
                                                            <>
                                                                <Tab.Pane eventKey="user_logs" className="py-4">
                                                                    <UserActivityLog user={user}/>
                                                                </Tab.Pane>
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </>
                    )
                }
            </>
        );
    }
}

//
const mapPropsState = (state) => {
    const {error, user,} = state.users;
    return {error, user,};
}
//
export default connect(mapPropsState, {getUserDetails})(UserDetail);
