import React, {Component} from "react";
import {connect} from "react-redux";
import {checkLegibilityCheck} from "../../actions/clients";
import {Breadcrumb, Button, Card, Col, Form, InputGroup, Row, Table} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {StartCase} from "react-lodash";

const Trl = (props) => {
    const {legibility, name,} = props;
    const exclude = ['id'];
    if (exclude.includes(name)) {
        return (<></>);
    }
    return (
        <>
            <tr valign={'middle'}>
                <th width={'50%'} className={'py-1 text-right'}>
                    <StartCase string={name}/>
                </th>
                <td className={'py-1'}>
                    <b>{legibility ?? ""}</b>
                </td>
            </tr>
        </>
    );
}

class LegibilityCheck extends Component {
    state = {
        loading: false,
        inputValue: "",
    }
    //
    handleOnChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value})
    }
    //
    handleSearchButton = async (e) => {
        this.setState({loading: true});
        const {inputValue} = this.state;
        const {checkLegibilityCheck} = this.props;
        if (inputValue !== "") {
            await checkLegibilityCheck(inputValue);
        }
        this.setState({loading: false});
    }

    //
    render() {
        const {inputValue, loading,} = this.state;
        const {legibility} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Check Legibility</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Check Legibility</h4>
                    </div>
                </div>
                <Card border="light" className="table-wrapper shadow-sm mb-4">
                    <Card.Body className="">
                        <Row className="">
                            <Col md={6} className={'offset-3'}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroup.Text>
                                    <Form.Control type="text"
                                                  name="inputValue"
                                                  onChange={this.handleOnChange}
                                                  value={inputValue}
                                                  placeholder="Search by MSISDN"/>
                                    <Button onClick={this.handleSearchButton} disabled={loading}>
                                        Search
                                        {
                                            loading ? (
                                                <>
                                                    &nbsp;<BeatLoader color="#FFF" size="6"/>
                                                </>
                                            ) : ('')
                                        }
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {
                    !loading && legibility ? (
                        <>
                            <Col md={8} className={'offset-2'}>
                                <Card border="light" className="table-wrapper shadow-sm">
                                    <Card.Body className="">
                                        <Table striped bordered>
                                            <tbody>
                                            {
                                                Object.keys(legibility).map((key, i) => (
                                                    <Trl legibility={legibility[key]} name={key} key={i}/>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </>
                    ) : (<></>)
                }
            </>
        );
    }
}

const mapStateToProps = (store) => {
    const {error, legibility} = store.clients;
    return {error, legibility};
}
export default connect(mapStateToProps, {
    checkLegibilityCheck,
})(LegibilityCheck);
