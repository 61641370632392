import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import {
    changeDeviceSIMNumber,
    extendClientDevice,
    getCustomerByMSISDN,
    lockClientDevice,
    openClientDevice,
    statusClientDevice,
    unlockClientDevice
} from "../../../actions/clients";
import DeviceActions from "./DeviceActions";

const TableRow = (props) => {
    //
    const {
        index, deviceType, username, store, msisdn, releasedOn, handleShow,
        devicePrice,
    } = props;
    //
    return (
        <tr>
            <td valign={'middle'}><Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link></td>
            <td className="fw-bold" valign={'middle'}>
                <a onClick={() => {
                    handleShow(props)
                }}>
                    <u>{deviceType.type_name}</u>
                </a>
            </td>
            <td valign={'middle'}>
                <div>Storage: {deviceType.internal_storage}</div>
                <div>OS UI: {deviceType.os_ui}</div>
                <div>Full Price <small>(current)</small>: {deviceType.full_price.toLocaleString() ?? '-'}</div>
            </td>
            <td className="fw-bold" valign={'middle'}>{msisdn}</td>
            <td valign={'middle'}>{(new Date(releasedOn)).toDateString() ?? '-'}</td>
            <td valign={'middle'}>{devicePrice.toLocaleString() ?? deviceType.full_price.toLocaleString() ?? "-"}</td>
            <td valign={'middle'}>
                <a href={`/partners/stores/${store.storeId}/details`} className={'btn btn-link btn-sm'}
                   target='_blank' rel="noreferrer"><u>{store.name}</u></a>
            </td>
            <td valign={'middle'}>{username ?? "-"}</td>
        </tr>
    );
};

class CustomerDevice extends Component {
    state = {
        loading: true,
        loadingActions: false,
        showModal: false,
        deviceData: null,
    }

    componentDidMount = async () => {
        this.setState({loading: true});
        const {getClientDevices, client} = this.props;
        //
        if (client && client.customer_id) {
            await getClientDevices(client.customer_id);
        }
        //
        this.setState({loading: false});
    }
    //
    lockDevice = async (imei, reason) => {
        this.setState({loadingActions: true});
        const {lockClientDevice, getClientDevices, client, user: {username}} = this.props;
        await lockClientDevice({imei, reason, username});
        if (client && client.customer_id) {
            await getClientDevices(client.customer_id);
        }
        this.setState({loadingActions: false});
    }
    //
    unlockDevice = async (imei, reason) => {
        this.setState({loadingActions: true});
        const {unlockClientDevice, getClientDevices, client, user: {username}} = this.props;
        await unlockClientDevice({imei, reason, username});
        if (client && client.customer_id) {
            await getClientDevices(client.customer_id);
        }
        this.setState({loadingActions: false});
    }
    //
    openDevice = async (imei, reason) => {
        this.setState({loadingActions: true});
        const {openClientDevice, user: {username}} = this.props;
        await openClientDevice({imei, reason, username});
        this.setState({loadingActions: false});
    }
    //
    statusDevice = async (imei, reason) => {
        this.setState({loadingActions: true});
        const {statusClientDevice} = this.props;
        await statusClientDevice(imei);
        this.setState({loadingActions: false});
    }
    //
    extendDevice = async (imei, msisdn, reason) => {
        this.setState({loadingActions: true});
        const {extendClientDevice, user: {username}} = this.props;
        await extendClientDevice({imei, msisdn, reason, username});
        this.setState({loadingActions: false});
    }
    //
    handleClose = () => {
        this.setState({showModal: false, deviceData: null,});
    };
    //
    handleShow = (data) => {
        this.setState({showModal: true, deviceData: data,});
    }

    render() {
        const {clientDevices} = this.props;
        const {loading, showModal, deviceData} = this.state;
        //
        return (
            <Card>
                <Card.Body>
                    <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                        <thead className="thead-light">
                        <tr>
                            <th className="">#</th>
                            <th className="">Device Type</th>
                            <th className="">Device Details</th>
                            <th className="">MSISDN</th>
                            <th className="">Released On</th>
                            <th className="">Price</th>
                            <th className="">Store</th>
                            <th className="">Representative</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loading ? (
                                <tr>
                                    <td colSpan="8" align="center"><BeatLoader color="#000" size="8"/></td>
                                </tr>
                            ) : clientDevices != null && clientDevices.length > 0 ?
                                clientDevices.map((device, i) => <TableRow {...device} index={++i}
                                                                           handleShow={this.handleShow}
                                                                           unlockDevice={this.unlockDevice}
                                                                           lockDevice={this.lockDevice}
                                                                           openDevice={this.openDevice}
                                                                           statusDevice={this.statusDevice}
                                                                           extendDevice={this.extendDevice}
                                                                           key={`page-visit-${i}`}/>) : (
                                    <tr>
                                        <td colSpan="8" align="center">No device available !</td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                    {
                        showModal ? (<DeviceActions show={showModal}
                                                    handleClose={this.handleClose}
                                                    loading={this.state.loadingActions}
                                                    device={deviceData}/>) : ""
                    }
                </Card.Body>
            </Card>
        );
    }
}

//
const mapStateProps = (state) => {
    const {clientDevices, error, deviceAction} = state.clients;
    const {user} = state.authentication;
    return {clientDevices, error, deviceAction, user};
}
//
export default connect(mapStateProps, {
    lockClientDevice,
    unlockClientDevice,
    openClientDevice,
    statusClientDevice,
    extendClientDevice,
    getCustomerByMSISDN,
    changeDeviceSIMNumber,
})(CustomerDevice);
