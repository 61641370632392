import {
    ERROR_ALLOCATED_LOANS, ERROR_LOAN_REPAYMENT,
    FETCH_CLOSURE_LOANS_ENDS,
    FETCH_CLOSURE_LOANS_START,
    FETCH_CLOSURE_LOANS_SUCCESS,
    GET_ALLOCATED_LOANS, GET_LOAN_REPAYMENT,
    INITIATE_LOAN_CANCELLATION_ENDS,
    INITIATE_LOAN_CANCELLATION_START, POST_LOAN_REPAYMENT,
    UPDATE_LOAN_CLOSURE_ENDS,
    UPDATE_LOAN_CLOSURE_START, UPDATE_LOAN_REPAYMENT,
} from "../../actions/types";

const initiate = {
    error: null,
    loading: false,
    initiatedLoans: null,
};

export const loansReducer = (state = initiate, action) => {
    switch (action.type) {
        case GET_ALLOCATED_LOANS:
            return {
                ...state,
                loans: action.payload,
            };
        case ERROR_ALLOCATED_LOANS:
            return {
                ...state,
                error: action.payload,
            };
        case INITIATE_LOAN_CANCELLATION_START:
            return {
                ...state,
                loading: true,

            };
        case INITIATE_LOAN_CANCELLATION_ENDS:
            return {
                ...state,
                loading: false,
            };

        case FETCH_CLOSURE_LOANS_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CLOSURE_LOANS_SUCCESS:
            console.log("state...", action);
            return {
                ...state,
                loading: false,
                initiatedLoans: action.payload,
            };

        case UPDATE_LOAN_CLOSURE_START:
            return {
                ...state,
                loading: true,

            };
        case UPDATE_LOAN_CLOSURE_ENDS:
            return {
                ...state,
                loading: false,
            };
        case POST_LOAN_REPAYMENT:
        case UPDATE_LOAN_REPAYMENT:
            return {
                ...state,
                loading: false,
                responses: action.payload,
            }
        case GET_LOAN_REPAYMENT:
            return {
                ...state,
                loading: false,
                loans: action.payload,
            }
        case ERROR_LOAN_REPAYMENT:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            };
    }
};
