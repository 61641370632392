import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Breadcrumb,
    Button,
    Card, Col,
    Dropdown,
    Form,
    FormControl,
    InputGroup,
    Nav, Pagination, Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faEye, faFilter, faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {BeatLoader} from "react-spinners";
import {getCustomersByType, getCustomerTypes} from "../../actions/clients";
import authorization from "../../helpers/authorization";
import {StartCase} from "react-lodash";
import {PagingFooter, PagingSize} from "../../components/Paging";

const TableRow = (props) => {
    const {
        index, customer_id, surname, name, msisdn, national_id_number,
        customerType,
    } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className="fw-bold">
                <a href={authorization("IC_READ") ? `/clients/${customer_id}/details` : "#"}
                   className={'btn btn-link btn-sm'}>
                    <StartCase string={`${name} ${surname}`.toLocaleLowerCase()}/>
                </a>
            </td>
            <td>{national_id_number}</td>
            <td>{msisdn}</td>
            <td>{customerType}</td>
            <td>
                <Button variant="light" size="sm"
                        href={`/clients/${customer_id}/details`}
                        disabled={!authorization("IC_READ")}>
                    <FontAwesomeIcon icon={faEye} className={`icon icon-xs text-success cursor-pointer`}/>
                </Button>
            </td>
        </tr>
    );
};
//
const CustomerTypeFilter = (props) => {
    const {type, types, onTypeChange} = props;
    //
    return (
        <>
            <div className="">
                <Dropdown style={{marginLeft: 1000, marginBottom: 30, marginTop: -50}}>
                    <Dropdown.Toggle as={Button} variant="primary">
                        <FontAwesomeIcon icon={faFilter} className="me-2"/> Customer Type
                        <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                        {types && types.length > 0 ? types.map(t => (
                            <Dropdown.Item onClick={() => onTypeChange(t.value)} active={t.value === type}>
                                {t.name}
                            </Dropdown.Item>
                        )) : (<></>)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
};

//
class Clients extends Component {
    state = {
        loading: true,
        loadingTypes: true,
        type: 'CUSTOMER',
        customers: null,
        pageSize: 25,
        currentPage: 0,
        searchQuery: null,
    };
    //
    componentDidMount = async () => {
        this.setState({loadingTypes: true});
        const {type, pageSize, currentPage: cPage} = this.state;
        const {getCustomerTypes, getCustomersByType} = this.props;
        await getCustomerTypes();
        // await getCustomersByType(type, {page: cPage, size: pageSize});
        const {currentPage} = this.props;
        this.setState({currentPage: currentPage});
        this.setState({loadingTypes: false, loading: false});
    }
    //
    onTypeChange = (type) => {
        this.setState({type});
    }
    //
    changePage = async (number) => {
        this.setState( {loading: true});
        const {getCustomersByType, size} = this.props;
        const {type} = this.state;
        this.setState({currentPage: number, pageSize: size});
        await getCustomersByType(type, {page: number, size: size});
        this.setState({loading: false});
    }
    onSelectPageSize = async (number) => {
        const {getCustomersByType,} = this.props;
        this.setState( {loading: true});
        const {pageSize, currentPage, type} = this.state;
        this.setState({pageSize: number,});
        await getCustomersByType(type, {page: currentPage, size: pageSize});
        this.setState( {loading: false});
    }
    //
    onChanges = async (e) => {
        e.preventDefault();
        if (e.target.value) {
            this.setState({searchQuery: e.target.value,});
        } else {
            this.setState({searchQuery: null,});
        }
    }
    //
    onSearch = async () => {
        const {type, searchQuery, pageSize} = this.state;
        const {getCustomersByType} = this.props;
        this.setState({loading: true});
        if (searchQuery) {
            await getCustomersByType(type, {page: 0, size: pageSize, searchQuery});
        } else {
            await getCustomersByType(type, {page: 0, size: pageSize});
        }
        this.setState({loading: false});
    }
    //
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const {type, pageSize,} = this.state;
        if (prevState.type !== type) {
            this.setState({loading: true});
            const {getCustomersByType} = this.props;
            await getCustomersByType(type, {page: 0, size: pageSize});
            this.setState({loading: false});
        }
    }

    //
    render() {
        const {loading, type, loadingTypes, customers,} = this.state;
        const {types, clients, size, currentPage, totalElements, totalPages, numberOfElements, last, first,} = this.props;
        //
        let _costomers = customers ?? clients;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>Clients</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>{type ? type : 'Clients'}</h4>
                    </div>
                </div>
                {
                    authorization("IC_CREATE") && false ? (
                        <Button variant="primary" href={Routes.CreateClient.path} className="m-1">Add Client</Button>
                    ) : (<></>)
                }
                {/* Filters */}
                <CustomerTypeFilter onTypeChange={this.onTypeChange} type={type} types={types}/>
                {/* List */}
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Form>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            name={'search'}
                                            onChange={this.onChanges}
                                            placeholder="Search by msisdn"
                                            aria-label="Search" />
                                        <Button type="button" onClick={this.onSearch}>Search</Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                        <Table responsive
                               striped
                               className="table-centered table-striped table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Name</th>
                                <th className="">National ID</th>
                                <th className="">MSISDN</th>
                                <th className="">Customer Type</th>
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading || loadingTypes ? (
                                    <tr>
                                        <td colSpan="10" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : _costomers != null && _costomers.length > 0 ?
                                    _costomers?.map(
                                        (client, index) => <TableRow {...client}
                                                                     index={++index}
                                                                     key={`page-visit-${client.customer_id}`}/>
                                    ) :
                                    (
                                        <tr>
                                            <td colSpan="10" align="center">No search made!</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {error, clients, types, size, currentPage, totalElements, totalPages, numberOfElements, last, first,} = state.clients;
    return {
        error, clients, types, size, currentPage, totalElements, totalPages,
        numberOfElements, last, first,
    };
}

export default connect(mapStateProps, {getCustomersByType, getCustomerTypes})(Clients);
