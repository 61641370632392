import {getUser,} from "./tokenHelper";

export default (neededRole) => {
    try {
        if (getUser() && getUser().roles) {
            const roles = getUser().roles;
            if (Array.isArray(neededRole)) {
                let _d = false;
                // console.log(neededRole);
                (neededRole ?? []).forEach((v, i) => {
                    _d = (_d || roles.includes(v)); 
                });
                return _d;
            } else {
                return roles.includes(neededRole);
            }
        }
    } catch (e) {
        console.log(e.message);
    }
    return false;
}