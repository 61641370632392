import {
    CREATE_REPRESENTATIVE_ERROR_USER,
    CREATE_REPRESENTATIVE_USER,
    GET_REPRESENTATIVE_USERS,
    GET_USERS_DEVICES
} from "../../actions/types";

const initials = {
    users: null,
    error: null,
};
const repUsersReducer = (state = initials, action) => {
    switch (action.type) {
        case GET_REPRESENTATIVE_USERS:
            return {
                ...state,
                users: action.payload,
                error: null,
            }
        case CREATE_REPRESENTATIVE_USER:
            return {
                ...state,
                representative: action.payload,
                error: null,
            }
        case CREATE_REPRESENTATIVE_ERROR_USER:
            return {
                ...state,
                representative: null,
                error: action.payload,
            }
        case GET_USERS_DEVICES:
            return {
                ...state,
                repDevices: action.payload,
                error: null,
            }
        default:
            return state;
    }
}
export default repUsersReducer;