import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumb, Button, Card, Form, FormControl, InputGroup, Table} from '@themesberg/react-bootstrap';
import {connect} from "react-redux";
import {BeatLoader} from "react-spinners";
import {getAllDevices} from "../../actions/devices";
import {Routes} from "../../routes";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import authorization from "../../helpers/authorization";
import Filters from "./components/Filters";
import {getURLSearchParams} from "../../helpers/url";

export class Devices extends Component {
    state = {
        loading: false,
        category: getURLSearchParams('category'),
        deviceTypes: null,
    };
    //
    onChanges = async (e) => {
        e.preventDefault();
        const {devices} = this.props;
        if (e.target.value) {
            const _devices = devices?.filter((item, i) => `${item?.type_name}`.toLowerCase().includes(`${e.target.value}`.toLowerCase()));
            this.setState({deviceTypes: _devices,});
        } else {
            this.setState({deviceTypes: devices,});
        }
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getAllDevices} = this.props;
        const {category} = this.state;
        await getAllDevices(category);
        this.setState({loading: false});
    }
    //
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const {category} = this.state;
        if (category !== prevState.category) {
            this.setState({loading: true});
            await this.props.getAllDevices(category);
            this.setState({loading: false});
        }
    }
    //
    setSelectedCategory = (category) => {
        const {history,} = this.props;
        history.push({
            pathname: Routes.Devices.path,
            search: category ? `?category=${category.id}` : ``,
            state: category ? {
                category: category.id,
            } : null,
        });
        this.setState({category: category?.id,});
    }

    render() {
        const {loading, deviceTypes,} = this.state;
        const {devices,} = this.props;
        //
        let _deviceTypes = deviceTypes ?? devices;
        //
        const TableRow = (props) => {
            //
            const {
                deviceTypeId, type_name, description, network, full_price, index,
            } = props;
            //
            return (
                <tr>
                    <td>
                        <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
                    </td>
                    <td className="fw-bold">
                        <Button variant="light" size="sm"
                                disabled={!authorization("DT_BROWSE")}
                                href={`/devices/${deviceTypeId}/details`}>
                            {type_name}
                        </Button>
                    </td>
                    <td>{description}</td>
                    <td>{network}</td>
                    <td>{full_price.toLocaleString() ?? "-"}</td>
                    <td>
                        <Button variant="light" size="sm"
                                disabled={!authorization("DT_BROWSE")}
                                href={`/devices/${deviceTypeId}/details`}>
                            <FontAwesomeIcon icon={faEye} className={`icon icon-xs text-success cursor-pointer`}/>
                        </Button>&nbsp;&nbsp;
                        {
                            authorization("DT_EDIT") ? (
                                <Button variant="light" size="sm" href={`/devices/${deviceTypeId}/edit`}>
                                    <FontAwesomeIcon icon={faEdit} className={`icon icon-xs text-info cursor-pointer`}/>
                                </Button>
                            ) : (<></>)
                        }
                    </td>
                </tr>
            );
        };
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all devices</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4> All devices</h4>
                    </div>
                </div>
                <div className={'d-flex justify-content-between'}>
                    {
                        authorization("DT_CREATE") ? (
                            <Button variant="primary" href={Routes.NewDevice.path} className="m-1">Add device</Button>
                        ) : (<></>)
                    }
                    <Filters setSelectedCategory={this.setSelectedCategory}/>
                </div>
                <Form>
                    <InputGroup className="mb-3">
                        <FormControl
                            name={'search'}
                            onChange={this.onChanges}
                            placeholder="Search by device type"
                            aria-label="Search by device type"/>
                    </InputGroup>
                </Form>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Device type</th>
                                <th className="">Description</th>
                                <th className="">Network</th>
                                <th className="">Price</th>
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="8" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : _deviceTypes != null && _deviceTypes.length > 0 ?
                                    _deviceTypes.map((device, i) => <TableRow {...device} index={++i}
                                                                              key={`page-visit-${device.deviceTypeId}`}/>) : (
                                        <tr>
                                            <td colSpan="8" align="center">No device available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapState = (state) => {
    const {devices, total, error} = state.device;
    return {devices, total, error};
}

export default connect(mapState, {getAllDevices,})(Devices);
