import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/Preloader";
import {BeatLoader} from "react-spinners";
import {getUserDetails, markUserAsRepresentative} from "../../actions/user";

export class MarkAsRepresentative extends Component {

    state = {
        loading: true,
        submitted: false,
        selectedUser: null,
        id: this.props.match.params.username,
        username: "",
        name: "",
        surname: "",
        email: "",
        msisdn: "",
        nid: "",
        residence: "",
        maritalStatus: "",
        gender: "",
        startingDate: "",
        repStatus: "",
    };
    //
    handleChange = async (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {id} = this.state;
        const {getUserDetails} = this.props;
        const user = await getUserDetails(id);
        //
        if (user) {
            this.setState({
                username: user.username,
                name: user.name,
                surname: user.surname,
                email: user.email,
                msisdn: user.msisdn,
            });
        }
        //
        this.setState({loading: false});
    }

    markAsRepresentative = async () => {
        this.setState({loading: true, submitted: true});
        const {markUserAsRepresentative, history} = this.props;
        const {
            username,
            name,
            surname,
            email,
            msisdn,
            nid,
            residence,
            maritalStatus,
            gender,
            startingDate,
            repStatus,
        } = this.state;
        //
        const markAsRep = await markUserAsRepresentative({
            username,
            name,
            surname,
            email,
            msisdn,
            nid,
            residence,
            maritalStatus,
            gender,
            startingDate,
            repStatus,
        });
        if (markAsRep) {
            history.push(Routes.Users.path);
        } else {
            // this.setState({submitted: false});
        }
        this.setState({loading: false});
    }

    //
    render() {
        const {user, error} = this.props;
        const {
            loading, submitted, nid, residence, maritalStatus,
            gender, startingDate,
        } = this.state;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Users.path}>List of all users</Breadcrumb.Item>
                            <Breadcrumb.Item active>Mark As Representative</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>{!loading && user ? (<>User<span>: <b><u>{user && user.name} {user && user.surname}</u></b></span></>) : ("")}</h3>
                    </div>
                </div>
                {
                    loading ? (
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <Preloader show={loading}/>
                                </Col>
                            </Row>
                        ) :
                        (
                            <Row className="">
                                <Col md="12">
                                    <Card border="light" className="custom-class">
                                        <Card.Body>
                                            <h5 className="mb-4">Mark user as representative</h5>
                                            {error ? (<div className="text-danger">{error}</div>) : ""}
                                            <Form onSubmit={this.markAsRepresentative}>
                                                <Row>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group id="username">
                                                            <Form.Label>Username</Form.Label>
                                                            <Form.Control required type="text" name="username"
                                                                          onChange={this.handleChange}
                                                                          value={user && user.username} id={'username'}
                                                                          readOnly={true}
                                                                          placeholder="Enter username"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id="password">
                                                            <Form.Label>Names</Form.Label>
                                                            <Form.Control required type="text" name="name"
                                                                          onChange={this.handleChange}
                                                                          id={'name'}
                                                                          readOnly={true}
                                                                          value={user && user.name}
                                                                          placeholder="Enter Name"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id="password">
                                                            <Form.Label>Surname</Form.Label>
                                                            <Form.Control required type="text" name="surname"
                                                                          onChange={this.handleChange}
                                                                          id={'surname'}
                                                                          readOnly={true}
                                                                          value={user && user.surname}
                                                                          placeholder="Enter Surname"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group id="email">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control required type="email" name="email"
                                                                          onChange={this.handleChange}
                                                                          value={user && user.email} id={'email'}
                                                                          readOnly={true}
                                                                          placeholder="name@intelligra.io"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group id="phone">
                                                            <Form.Label>MSISDN</Form.Label>
                                                            <Form.Control required type="number" name="msisdn"
                                                                          onChange={this.handleChange}
                                                                          value={user && user.msisdn} id={'msisdn'}
                                                                          readOnly={true}
                                                                          placeholder="+250 788 327 447"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id="nid">
                                                            <Form.Label>NID</Form.Label>
                                                            <Form.Control required type="text" name="nid" id={'nid'}
                                                                          onChange={this.handleChange}
                                                                          value={nid}
                                                                          readOnly={loading || submitted}
                                                                          placeholder="NID"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className={'mb-3'}>
                                                        <Form.Group id={'residence'}>
                                                            <Form.Label>Residence</Form.Label>
                                                            <Form.Control required type={'text'} name={'residence'}
                                                                          id={'residence'} value={residence}
                                                                          readOnly={loading || submitted}
                                                                          onChange={this.handleChange}
                                                                          placeholder={'Residence'}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className={''}>
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id={'maritalStatus'}>
                                                            <Form.Label>Marital Status</Form.Label>
                                                            <Form.Select required aria-label="Select marital status"
                                                                         name={"maritalStatus"}
                                                                         readOnly={loading || submitted}
                                                                         onChange={this.handleChange}>
                                                                <option value="" disabled={true}
                                                                        selected={true}>Select:.
                                                                </option>
                                                                <option value="M"
                                                                        selected={maritalStatus === 'M'}>Married
                                                                </option>
                                                                <option value="S"
                                                                        selected={maritalStatus === 'S'}>Single
                                                                </option>
                                                                <option value="W"
                                                                        selected={maritalStatus === 'W'}>Widower/Window
                                                                </option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className={'mb-3'}>
                                                        <Form.Group id={'gender'}>
                                                            <Form.Label>Gender</Form.Label>
                                                            <Form.Select required name={'gender'}
                                                                         readOnly={loading || submitted}
                                                                         onChange={this.handleChange}
                                                                         aria-label="Select gender">
                                                                <option value="" disabled={true}
                                                                        selected={true}>Select:.
                                                                </option>
                                                                <option value="M" selected={gender === 'M'}>Male
                                                                </option>
                                                                <option value="F" selected={gender === 'F'}>Female
                                                                </option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className={''}>
                                                    <Col md={6} className={'mb-3'}>
                                                        <Form.Group id={'startingDate'}>
                                                            <Form.Label>Starting Date</Form.Label>
                                                            <Form.Control name={'startingDate'}
                                                                          required readOnly={loading || submitted}
                                                                          type="date" value={startingDate}
                                                                          onChange={this.handleChange}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className={'mb-3'}>
                                                        <Form.Group id={'repStatus'}>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Check
                                                                type="switch"
                                                                id="repStatus"
                                                                name={'repStatus'}
                                                                defaultValue={'active'}
                                                                label="Select representative status"
                                                                value={"active"}
                                                                onChange={this.handleChange}
                                                                readOnly={loading || submitted}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="mt-3">
                                                    <Button variant="primary" type="submit"
                                                            disabled={loading || submitted}>
                                                        {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                }
            </>
        );
    }

}

const mapStateProps = (state) => {
    const {user, error, representative} = state.users;
    return {
        user, error, representative
    };
}
export default connect(mapStateProps, {getUserDetails, markUserAsRepresentative})(MarkAsRepresentative);
