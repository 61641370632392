import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import {createRoleGroup} from "../../actions/roles";
import authorization from "../../helpers/authorization";

class CreateRoleGroup extends Component {
    state = {
        loading: false,
        groupCode: null,
        groupName: null,
        description: null,
        groupstatus: 1,
    }

    componentDidMount = async () => {

    }

    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }

    submit = async (e) => {
        e.preventDefault();
        const {groupCode, groupName, description, groupstatus} = this.state;
        const {createRoleGroup, history} = this.props;
        this.setState({loading: true,});
        const create = await createRoleGroup({groupCode, groupName, description, groupstatus: parseInt(groupstatus),});
        if (create !== false) {
            history.push(`/users/roles/${create.groupId}/groups/`);
        }
        this.setState({loading: false,});
    }

    render() {
        const {groupCode, groupName, description, loading,} = this.state;
        const {error} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.GroupsRoles.path}>Groups</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Group</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Add Group</h4>
                    </div>
                </div>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">Add Group</h5>
                        {error ? (<div className="text-danger mb-2">{error}</div>) : ""}
                        <Form onSubmit={this.submit}>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="name">
                                        <Form.Label>Group Code</Form.Label>
                                        <Form.Control required type="text" name="groupCode" onChange={this.handleChange}
                                                      value={groupCode} placeholder="Group Code"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="surname">
                                        <Form.Label>Group Name</Form.Label>
                                        <Form.Control required type="text" name="groupName" onChange={this.handleChange}
                                                      value={groupName} placeholder="Group Name"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="name">
                                        <Form.Label>Group description</Form.Label>
                                        <Form.Control required as={'textarea'} type={'text'} name="description"
                                                      onChange={this.handleChange}
                                                      value={description} placeholder="Description"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className={'align-items-center col '}>
                                    <Form.Group id="groupstatus">
                                        <Form.Label>Group Status</Form.Label>
                                        <Form.Select name={'groupstatus'}
                                                     onChange={this.handleChange}
                                                     aria-label="Select group">
                                            <option value="" disabled={true} selected={true}>Select:.</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <div className="mt-3">
                                <Button variant="primary"
                                        type="submit"
                                        disabled={loading || !authorization("GR_CREATE")}>
                                    {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {roles, group, error} = state.roles;
    return {roles, group, error,};
}
//
export default connect(mapStateProps, {createRoleGroup})(CreateRoleGroup);
