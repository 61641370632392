const allocatedLoans = [
    {
        "id": "61a0e8c7e8c5a0cbf1fe4dcc",
        "bank": {
            "id": "61a0e8c750e649b822bc314b",
            "name": "GOGOL",
            "bankName": "XYLAR",
            "phone_number": "+250 (861) 586-2059",
            "email": "aguirrerush@xylar.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Lina",
            "customerSurname": "Bird",
            "msisdn": "+250(807) 534-2010",
            "address": "871 Billings Place, Ironton, Kentucky, 9008",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 9161325632695422,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 735275,
        "totalLoanAmount": 7308908,
        "graceTime": 2,
        "minScore": "R15",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 7923778,
        "paidAmount": 895466,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c7aba9a43febcd0ac1",
        "bank": {
            "id": "61a0e8c709ab95ab5bca1ba2",
            "name": "DIGIAL",
            "bankName": "MAGNEATO",
            "phone_number": "+250 (901) 550-3522",
            "email": "linabird@magneato.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Margret",
            "customerSurname": "Cantrell",
            "msisdn": "+250(960) 592-2466",
            "address": "333 Newel Street, Delco, Maryland, 2061",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 3412727694843112,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 477778,
        "totalLoanAmount": 4345438,
        "graceTime": 1,
        "minScore": "R6",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 2524864,
        "paidAmount": 23270,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c700d383283197353f",
        "bank": {
            "id": "61a0e8c735fb73e848d8d547",
            "name": "XUMONK",
            "bankName": "SILODYNE",
            "phone_number": "+250 (932) 573-2834",
            "email": "margretcantrell@silodyne.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Burke",
            "customerSurname": "Doyle",
            "msisdn": "+250(937) 542-2271",
            "address": "348 Bulwer Place, Rosedale, Oklahoma, 3041",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 4424006556343639,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 860990,
        "totalLoanAmount": 3318700,
        "graceTime": 5,
        "minScore": "R3",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 1433844,
        "paidAmount": 293956,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c7aee4ad3f5b6fc406",
        "bank": {
            "id": "61a0e8c7842d47ac37374abe",
            "name": "COMVOY",
            "bankName": "MAKINGWAY",
            "phone_number": "+250 (875) 499-2185",
            "email": "burkedoyle@makingway.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Katina",
            "customerSurname": "Fuller",
            "msisdn": "+250(892) 421-3682",
            "address": "693 Driggs Avenue, Calpine, Arizona, 3200",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 3146967524678277,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 362560,
        "totalLoanAmount": 1762358,
        "graceTime": 5,
        "minScore": "R8",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 4069817,
        "paidAmount": 574369,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c76611ff17e21180dc",
        "bank": {
            "id": "61a0e8c7e7670b4a1df2dd6b",
            "name": "GEEKWAGON",
            "bankName": "TYPHONICA",
            "phone_number": "+250 (839) 432-2768",
            "email": "katinafuller@typhonica.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Julia",
            "customerSurname": "Emerson",
            "msisdn": "+250(803) 578-3867",
            "address": "769 Apollo Street, Longoria, Michigan, 294",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 8133987664526934,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 363795,
        "totalLoanAmount": 2735937,
        "graceTime": 2,
        "minScore": "R6",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 6613149,
        "paidAmount": 808912,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c7644ae58b1865317e",
        "bank": {
            "id": "61a0e8c7639946763e60303a",
            "name": "BOILCAT",
            "bankName": "GOKO",
            "phone_number": "+250 (946) 599-2997",
            "email": "juliaemerson@goko.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Tamika",
            "customerSurname": "Patrick",
            "msisdn": "+250(906) 553-2091",
            "address": "181 Seeley Street, Sunriver, Wisconsin, 6536",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 1772254798815336,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 504319,
        "totalLoanAmount": 3379772,
        "graceTime": 4,
        "minScore": "R5",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 3642343,
        "paidAmount": 365018,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c7e1f9b25713abca38",
        "bank": {
            "id": "61a0e8c7bafc4529bca8c4c9",
            "name": "COMBOGEN",
            "bankName": "COMTRAK",
            "phone_number": "+250 (954) 491-3105",
            "email": "tamikapatrick@comtrak.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Schultz",
            "customerSurname": "Fields",
            "msisdn": "+250(826) 573-2192",
            "address": "519 Dahlgreen Place, Bascom, Connecticut, 3717",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 5587419263371942,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 462864,
        "totalLoanAmount": 1377720,
        "graceTime": 1,
        "minScore": "R1",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 882198,
        "paidAmount": 453464,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c72a07336a29effe45",
        "bank": {
            "id": "61a0e8c77edf599312178bf7",
            "name": "ZOLAVO",
            "bankName": "INSOURCE",
            "phone_number": "+250 (897) 548-2637",
            "email": "schultzfields@insource.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "May",
            "customerSurname": "Perez",
            "msisdn": "+250(803) 514-2285",
            "address": "291 Remsen Street, Brambleton, Virgin Islands, 6246",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 4406661635074110,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 542450,
        "totalLoanAmount": 4547132,
        "graceTime": 3,
        "minScore": "R12",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 3441818,
        "paidAmount": 321118,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c7129651f57f37a67c",
        "bank": {
            "id": "61a0e8c73a838922b4dec087",
            "name": "COMVEYER",
            "bankName": "NIXELT",
            "phone_number": "+250 (818) 526-2849",
            "email": "mayperez@nixelt.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Owen",
            "customerSurname": "Alston",
            "msisdn": "+250(824) 404-3192",
            "address": "824 Glendale Court, Ernstville, Federated States Of Micronesia, 5910",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 8563147668090628,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 787762,
        "totalLoanAmount": 8752842,
        "graceTime": 2,
        "minScore": "R10",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 8216294,
        "paidAmount": 895117,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    },
    {
        "id": "61a0e8c7d25d02fdb98b35ad",
        "bank": {
            "id": "61a0e8c7783f2e07fa1505df",
            "name": "PODUNK",
            "bankName": "CUIZINE",
            "phone_number": "+250 (840) 561-2968",
            "email": "owenalston@cuizine.com",
            "address": null,
            "interest_rate": null,
            "created_at": null,
            "modified_at": null
        },
        "customer": {
            "intelligraCustomerId": 25,
            "customerName": "Forbes",
            "customerSurname": "Hamilton",
            "msisdn": "+250(962) 428-2763",
            "address": "292 Strickland Avenue, Nipinnawasee, Washington, 4108",
            "maritalStatus": "M",
            "gender": "M",
            "nationalid": 7534457586332580,
            "typenationalId": "NID",
            "province": "Umujyi wa Kigali",
            "district": "Nyarugenge",
            "sector": "Kigali",
            "cell": "Mwendo",
            "village": "Amajyambere"
        },
        "downPayment": 460156,
        "totalLoanAmount": 5256660,
        "graceTime": 3,
        "minScore": "R1",
        "paymentFrequency": "MONTHLY",
        "devices": [
            {
                "deviceId": 23,
                "imei": 858685869366586,
                "universalDeviceId": null,
                "deviceType": {
                    "deviceTypeId": 17,
                    "type_name": "SAMSUNG A12",
                    "description": null,
                    "dimension": null,
                    "chipset": null,
                    "os_ui": null,
                    "network": null,
                    "display": null,
                    "screen_size": null,
                    "memory": null,
                    "internal_storage": "4GB/64GB",
                    "camera": null,
                    "battery": null,
                    "specs": "SAMSUNG A12",
                    "full_price": 144000,
                    "created_on": null,
                    "updated_on": null,
                    "devicetypecategory": 2,
                    "photos": []
                },
                "devicePlanId": 19,
                "customerId": 25,
                "username": "dominique",
                "store": {
                    "storeId": 1,
                    "name": "Tecno exclusive Rubavu shop",
                    "fulladress": "Musanze",
                    "location": null,
                    "owner_name": "Transsion",
                    "owner_tin": null,
                    "mobileMoneyAccount": null,
                    "msisdn": null,
                    "email": null
                },
                "msisdn": 250783540990,
                "releasedOn": "2021-11-01T14:11:54.000+00:00"
            }
        ],
        "offeringDate": "2021-11-01T14:11:54.000+00:00",
        "loanStatus": "APPLIED",
        "loanBalance": 4565927,
        "paidAmount": 651941,
        "paymentSchedules": [
            {
                "loanedId": 1,
                "loanId": 23,
                "startingDate": "2021-11-01T14:11:54.000+00:00",
                "endingDate": "2021-12-01T14:11:54.000+00:00",
                "dueDate": "2021-11-01T14:11:54.000+00:00",
                "paymentDate": "2021-11-01T14:11:54.000+00:00",
                "dueAmount": 18500,
                "paidAmount": 18500,
                "deferredAmount": 0,
                "scheduleStatus": "PAID"
            },
            {
                "loanedId": 2,
                "loanId": 23,
                "startingDate": "2021-12-01T14:11:54.000+00:00",
                "endingDate": "2022-01-01T14:11:54.000+00:00",
                "dueDate": "2021-12-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 3,
                "loanId": 23,
                "startingDate": "2022-01-01T14:11:54.000+00:00",
                "endingDate": "2022-02-01T14:11:54.000+00:00",
                "dueDate": "2022-01-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 4,
                "loanId": 23,
                "startingDate": "2022-02-01T14:11:54.000+00:00",
                "endingDate": "2022-03-01T14:11:54.000+00:00",
                "dueDate": "2022-02-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 5,
                "loanId": 23,
                "startingDate": "2022-03-01T14:11:54.000+00:00",
                "endingDate": "2022-04-01T14:11:54.000+00:00",
                "dueDate": "2022-03-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 6,
                "loanId": 23,
                "startingDate": "2022-04-01T14:11:54.000+00:00",
                "endingDate": "2022-05-01T14:11:54.000+00:00",
                "dueDate": "2022-04-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 7,
                "loanId": 23,
                "startingDate": "2022-05-01T14:11:54.000+00:00",
                "endingDate": "2022-06-01T14:11:54.000+00:00",
                "dueDate": "2022-05-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 8,
                "loanId": 23,
                "startingDate": "2022-06-01T14:11:54.000+00:00",
                "endingDate": "2022-07-01T14:11:54.000+00:00",
                "dueDate": "2022-06-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 9,
                "loanId": 23,
                "startingDate": "2022-07-01T14:11:54.000+00:00",
                "endingDate": "2022-08-01T14:11:54.000+00:00",
                "dueDate": "2022-07-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 10,
                "loanId": 23,
                "startingDate": "2022-08-01T14:11:54.000+00:00",
                "endingDate": "2022-09-01T14:11:54.000+00:00",
                "dueDate": "2022-08-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 11,
                "loanId": 23,
                "startingDate": "2022-09-01T14:11:54.000+00:00",
                "endingDate": "2022-10-01T14:11:54.000+00:00",
                "dueDate": "2022-09-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            },
            {
                "loanedId": 12,
                "loanId": 23,
                "startingDate": "2022-10-01T14:11:54.000+00:00",
                "endingDate": "2022-11-01T14:11:54.000+00:00",
                "dueDate": "2022-10-01T14:11:54.000+00:00",
                "paymentDate": null,
                "dueAmount": 18500,
                "paidAmount": 0,
                "deferredAmount": 0,
                "scheduleStatus": "UNPAID"
            }
        ]
    }
];

export {
    allocatedLoans,
}

