
export const showAlert = (message, variant, redirectUrl) => {
    return {
        type: "SHOW_ALERT",
        payload: {
            message,
            variant
        },
        redirectUrl
    }
}