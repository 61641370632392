import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import enUS from "date-fns/locale/en-US";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale('en-us', enUS);

//
class BankAccounts extends Component {
    state = {
        loading: false,
        startDate: new Date(),
        endDate: new Date(),
    }
    //
    componentDidMount = async () => {
    }
    setDate = (date, at = 's') => {
        if (at === 's') {
            this.setState({startDate: date,});
        } else if (at === 'e') {
            this.setDate({endDate: date,});
        }
    }

    //
    render() {
        const {endDate, startDate,} = this.state;
        //
        return (
            <>
                <Card>
                    <Card.Body>
                        <Form method={'GET'}>
                            <Row>
                                <Col md="4">
                                    <Form.Group className="mb-0" controlId="formSearch">
                                        <input className="form-control" type="text" placeholder="Search"
                                               name={'_q'}
                                               aria-label="Search"/>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-0" controlId="formStartDate">
                                        <DatePicker
                                            selected={startDate}
                                            className={'form-control'}
                                            name={'_s'}
                                            onChange={(date) => this.setDate(date, 's')}/>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="mb-0" controlId="formEndDate">
                                        <DatePicker
                                            className={'form-control'}
                                            name={'_e'}
                                            selected={endDate}
                                            onChange={(date) => this.setDate(date, 'e')}/>
                                    </Form.Group>
                                </Col>
                                <Col md="2">
                                    <Button className={'btn-block'} type={'submit'}>Transaction</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, accounts} = state.partners;
    return {error, accounts,};
}
//
export default connect(mapStateProps, {})(BankAccounts);
