import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Col, Form, InputGroup, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {changeDeviceSIMNumber, getClientDevices, getCustomerByMSISDN} from "../../actions/clients";
import DetailsInfo from "../push-notification/components/DetailsInfo";
import CustomerDevice from "../clients/components/CustomerDevice";

class SimSwap extends Component {
    state = {
        loading: false,
        inputValue: "",
    }

    componentDidMount = async () => {
        // this.setState({inputValue: ""});
    }
    //
    handleOnChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value})
    }
    //
    handleSearchButton = async (e) => {
        this.setState({loading: true});
        const {getCustomerByMSISDN} = this.props;
        const {inputValue} = this.state;
        if (inputValue !== "") {
            await getCustomerByMSISDN(inputValue);
        }
        this.setState({loading: false});
    }

    render() {
        const {inputValue, loading,} = this.state;
        const {client, getClientDevices} = this.props;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Device Search</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Device Search</h4>
                    </div>
                </div>
                <Card border="light" className="table-wrapper shadow-sm mb-4">
                    <Card.Body className="">
                        <Row className="">
                            <Col md={6} className={'offset-3'}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroup.Text>
                                    <Form.Control type="text"
                                                  name="inputValue"
                                                  onChange={this.handleOnChange}
                                                  value={inputValue}
                                                  placeholder="Search client by NATIONAL ID, EMAIL, MSISDN"/>
                                    <Button onClick={this.handleSearchButton} disabled={loading}>
                                        Search
                                        {
                                            loading ? (
                                                <>
                                                    &nbsp;<BeatLoader color="#FFF" size="6"/>
                                                </>
                                            ) : ('')
                                        }
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row>
                    <Col md={4}>
                        {
                            !loading && client ? (
                                <DetailsInfo client={client}/>
                            ) : ('')
                        }
                    </Col>
                    <Col md={8}>
                        {
                            !loading && client ? (
                                <CustomerDevice client={client}
                                                getClientDevices={getClientDevices}/>
                            ) : (<></>)
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

const mapPropsState = (state) => {
    const {error, client, clientDevices} = state.clients;
    return {error, client, clientDevices};
}

export default connect(mapPropsState, {getCustomerByMSISDN, changeDeviceSIMNumber, getClientDevices,})(SimSwap);
