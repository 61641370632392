import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Table} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {getDeviceTypeCategory} from "../../actions/devices";
import authorization from "../../helpers/authorization";

//
const TableRow = (props) => {
    //
    const {
        index, categoryName, description, id,
    } = props;
    //
    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className="fw-bold">{categoryName}</td>
            <td>{description}</td>
        </tr>
    );
};

//
class DeviceCategory extends Component {
    state = {
        loading: true,
    };

    componentDidMount = async () => {
        this.setState({loading: true});
        const {getDeviceTypeCategory} = this.props;
        await getDeviceTypeCategory();
        this.setState({loading: false});
    }

    render() {
        const {loading} = this.state;
        const {categories} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>List of categories</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>All categories</h4>
                    </div>
                </div>
                {
                    authorization("DC_CREATE") && false ? (
                        <Button variant="primary" href={Routes.NewDeviceCategory.path} className="m-1">Add
                            Category</Button>
                    ) : (<></>)
                }
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Category Name</th>
                                <th className="">Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="4" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : categories != null && categories.length > 0 ?
                                    categories.map((category, i) => <TableRow {...category}
                                                                              index={++i}
                                                                              key={`page-visit-${category.id}`}/>) : (
                                        <tr>
                                            <td colSpan="4" align="center">No device category available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }

}

//
const mapStateProps = (state) => {
    const {categories, error} = state.device;

    return {
        categories, error,
    };
}
//
export default connect(mapStateProps, {getDeviceTypeCategory})(DeviceCategory);