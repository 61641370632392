import {getUser} from "../../helpers/tokenHelper";
import {USER_DATA, USER_ERROR} from "../../actions/types";

const user = getUser();
//
const initState = {
    user,
    error: null,
}
//
const profile = (state = initState, action) => {
    switch (action.type) {
        case USER_DATA:
            return {
                ...state,
                user,
                error: null,
            };
        case USER_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default profile;