import {
    ASSIGN_DEVICE_TO_STORE,
    ATTACH_REPRESENTATIVE_TO_STORE,
    CREATE_STORES,
    ERROR_ASSIGN_DEVICE_TO_STORE,
    ERROR_REPRESENTATIVE_STORE,
    ERROR_STORES,
    GET_DEVICE_IN_STORES,
    GET_DEVICE_NOT_IN_STORE,
    GET_REPRESENTATIVE_IN_STORES,
    GET_STORES,
    GET_UNDEPLOYED_REPRESENTATIVE,
    REMOVE_DEVICE_FROM_STORE,
    REMOVE_REPRESENTATIVE_FROM_STORE,
    UPDATE_STORE, UPDATE_STORE_PRICE,
    VIEW_STORE_DETAILS
} from "../../actions/types";

const initiate = {
    data: null,
    error: null,
    total: 0,
};

const storeReducer = (state = initiate, action) => {
    switch (action.type) {
        case GET_STORES:
            return {
                ...state,
                data: action.payload,
                error: null,
                total: action.payload.length ?? 0,
            }
        case ERROR_STORES:
            return {
                ...state,
                data: null,
                error: action.payload,
                total: 0,
            };
        case CREATE_STORES:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case UPDATE_STORE:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case UPDATE_STORE_PRICE:
            return {
                ...state,
                updatedDevicePrice: action.payload,
                error: null,
            }
        case VIEW_STORE_DETAILS:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case GET_DEVICE_NOT_IN_STORE:
            return {
                ...state,
                devicesNotInTheStore: action.payload,
                error: null,
            }
        case ASSIGN_DEVICE_TO_STORE:
            return {
                ...state,
                assignDevice: action.payload,
                error: null,
            };
        case ERROR_ASSIGN_DEVICE_TO_STORE:
            return {
                ...state,
                assignDevice: null,
                error: action.payload,
            }
        case REMOVE_DEVICE_FROM_STORE:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case GET_DEVICE_IN_STORES:
            return {
                ...state,
                storeDevices: action.payload,
                error: null,
            };
        case GET_REPRESENTATIVE_IN_STORES:
            return {
                ...state,
                representatives: action.payload,
                error: null,
            }
        case GET_UNDEPLOYED_REPRESENTATIVE:
            return {
                ...state,
                repUsers: action.payload,
                error: null,
            }
        case ATTACH_REPRESENTATIVE_TO_STORE:
            return {
                ...state,
                repUser: action.payload,
                error: null,
            }
        case REMOVE_REPRESENTATIVE_FROM_STORE:
            return {
                ...state,
                repUser: action.payload,
                error: null,
            }
        case ERROR_REPRESENTATIVE_STORE:
            return {
                ...state,
                repUser: null,
                error: action.payload,
            }
        default:
            return state;
    }
}
//
export default storeReducer;