import {action} from "../index";
import {
    ADD_ROLE_TO_GROUP,
    ASSIGN_USER_TO_GROUP,
    CREATE_ROLE,
    GET_ROLES,
    GET_ROLES_IN_GROUP,
    GET_ROLES_NOT_IN_GROUP,
    REMOVE_ROLE_TO_GROUP,
    REMOVE_ROLES_GROUP,
    REVOKE_USER_FROM_GROUP,
    ROLE_ERROR,
    ROLE_GROUP,
    ROLE_GROUPS,
    ROLE_GROUPS_CREATE,
    USER_GROUPS
} from "../types";
import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";

/**
 * Get roles groups
 * @returns {Promise<void>}
 */
export const getRolesGroups = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/allgroups`, {
            headers,
        });
        //
        dispatch(action(ROLE_GROUPS, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error));
        } else {
            dispatch(action(ROLE_ERROR, e.message));
        }
    }
}

/**
 * Create role groups
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const createRoleGroup = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.post(`/intelligrapi/createditeusergroup`, data, {
            headers,
        });
        //
        if (req.data.groupCode) {
            dispatch(action(ROLE_GROUPS_CREATE, req.data));
            return req.data;
        } else {
            dispatch(action(ROLE_ERROR, req.data.error ?? "Error occurs !!"));
        }
        //
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error ?? "Error occurs !!"));
        } else {
            dispatch(action(ROLE_ERROR, e.message ?? "Error occurs !!"));
        }
    }
    return false;
}

/**
 * Get group by id
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getGroupById = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/groupbyid?groupCode=${id}`, {
            headers,
        });
        dispatch(action(ROLE_GROUP, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error));
        } else {
            dispatch(action(ROLE_ERROR, e.message));
        }
    }
}

/**
 * Get group by group code
 *
 * @param code
 * @returns {(function(*): Promise<void>)|*}
 */
export const getGroupByCode = (code) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/groupbycode?groupCode=${code}`, {
            headers,
        });
        dispatch(action(ROLE_GROUP, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error));
        } else {
            dispatch(action(ROLE_ERROR, e.message));
        }
    }
}
/**
 * Get roles in a group
 *
 * @param groupId
 * @returns {(function(*): Promise<void>)|*}
 */
export const getRolesInGroup = (groupId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/grouproles?groupCode=${groupId}`, {
            headers,
        });
        dispatch(action(GET_ROLES_IN_GROUP, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error));
        } else {
            dispatch(action(ROLE_ERROR, e.message));
        }
    }
}

/**
 * Get roles not in a group
 * @param groupId
 * @returns {(function(*): Promise<void>)|*}
 */
export const getRolesNotInGroup = (groupId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/rolesnotinagroup?groupCode=${groupId}`, {
            headers,
        });
        dispatch(action(GET_ROLES_NOT_IN_GROUP, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error));
        } else {
            dispatch(action(ROLE_ERROR, e.message));
        }
    }
}

/**
 * Add user to group
 *
 * @returns {(function(*): Promise<void>)|*}
 * @param data
 */
export const addUserIntoTheGroup = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/userinthegroup?groupCode=${data.groupCode}&userId=${data.userId}`, {
            headers,
        });
        //
        dispatch(action(ASSIGN_USER_TO_GROUP, req.data));
        return true;
    } catch (e) {
        dispatch(action(ROLE_ERROR, e.message));
    }
    return false;
}

/**
 * Remove user from a group
 *
 * @returns {(function(*): Promise<boolean>)|*}
 * @param username
 * @param groupCode
 */
export const removeUserFromGroup = (username, groupCode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/removeuserinthegroup?groupCode=${groupCode}&username=${username}`, {
            headers,
        });
        //
        dispatch(action(REVOKE_USER_FROM_GROUP, req.data));
        return true;
    } catch (e) {
        dispatch(action(ROLE_ERROR, e.message));
    }
    return false;
}

/**
 * Add role from group
 *
 * @returns {(function(*): Promise<boolean>)|*}
 * @param data
 */
export const addRoleToGroup = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/addroletogroup?roleCode=${data?.roleCode}&groupCode=${data?.groupCode}`, {
            headers,
        });
        //
        dispatch(action(ADD_ROLE_TO_GROUP, req.data));
        return true;
    } catch (e) {
        dispatch(action(ROLE_ERROR, e.message));
    }
    return false;
}

/**
 * Remove role from the group
 *
 * @returns {(function(*): Promise<boolean>)|*}
 * @param data
 */
export const removeRoleFromGroup = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/removerolefromthegroup?roleCode=${data?.roleCode}&groupCode=${data?.groupCode}`, {
            headers,
        });
        //
        dispatch(action(REMOVE_ROLE_TO_GROUP, req.data));
        return true;
    } catch (e) {
        dispatch(action(ROLE_ERROR, e.message));
    }
    return false;
}

/**
 * Get all permissions
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllPermissions = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/allsystemroles`, {
            headers,
        });
        //
        dispatch(action(GET_ROLES, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error));
        } else {
            dispatch(action(ROLE_ERROR, e.message));
        }
    }
}

/**
 * Create permission
 *
 * @param data
 * @returns {(function(*): Promise<{groupCode}|T|undefined>)|*}
 */
export const createPermission = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.post(`/intelligrapi/creatingarole`, data, {
            headers,
        });
        //
        if (req.data.groupCode) {
            dispatch(action(CREATE_ROLE, req.data));
            return req.data;
        } else {
            dispatch(action(ROLE_ERROR, req.data.error ?? "Error occurs !!"));
        }
        //
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error ?? "Error occurs !!"));
        } else {
            dispatch(action(ROLE_ERROR, e.message ?? "Error occurs !!"));
        }
    }
}

/**
 * Remove user groups
 *
 * @returns {(function(*): Promise<void>)|*}
 * @param groupCode
 */
export const removeUserGroup = (groupCode) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/removeausergroup?groupCode=${groupCode}`, {
            headers,
        });
        //
        dispatch(action(REMOVE_ROLES_GROUP, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error ?? "Error occurs !!"));
        } else {
            dispatch(action(ROLE_ERROR, e.message ?? "Error occurs !!"));
        }
    }
}

/**
 * Get groups by user
 *
 * @param user
 * @returns {(function(*): Promise<void>)|*}
 */
export const getGroupsByUser = (user) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        //
        const req = await apiCall.get(`/intelligrapi/groupsofauser?username=${user}`, {
            headers,
        });
        //
        dispatch(action(USER_GROUPS, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ROLE_ERROR, e.response.data.error ?? "Error occurs !!"));
        } else {
            dispatch(action(ROLE_ERROR, e.message ?? "Error occurs !!"));
        }
    }
}
