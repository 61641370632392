import React, {Component} from "react";
import {Card, Table} from "@themesberg/react-bootstrap";

class Details extends Component {
    state = {
        loading: false,
    }

    render() {
        const {
            name, tinNumber, description, email, msisdn, status,
        } = this.props;
        return (
            <>
                <Card>
                    <Card.Body className={'overflow-auto'}>
                        <Table striped bordered>
                            <tr>
                                <td>Name</td>
                                <td><b>{`${name ?? ''}`}</b></td>
                            </tr>
                            <tr>
                                <td>Tin Number</td>
                                <td><b>{`${tinNumber ?? ''}`}</b></td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td><b>{`${description ?? ''}`}</b></td>
                            </tr>
                            <tr>
                                <td>E-Mail</td>
                                <td><b>{`${email ?? ''}`}</b></td>
                            </tr>
                            <tr>
                                <td>MSISDN</td>
                                <td><b>{`${msisdn ?? ''}`}</b></td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td><b>{`${status === true ? 'Active' : "Inactive"}`}</b></td>
                            </tr>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default Details;
