import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import authorization from "../../../helpers/authorization";


const TableRow = (props) => {
    const {
        index, bank, downPayment, totalLoanAmount, minScore, paymentFrequency, devices,
        offeringDate, loanStatus, loanBalance, paidAmount, paymentSchedules, showDevices, setDevices,
        showSchedules, setSchedules, loanId, bankId, bankLoanAccount, bankLoanId
    } = props;

    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{(index + 1)}</Card.Link>
            </td>
            <td className="fw-bold">{loanStatus}</td>
            <td className="fw-bold">{loanId}</td>
            <td className="fw-bold">{bankId}</td>
            <td className="fw-bold">{bankLoanAccount}</td>
            <td className="fw-bold">{bankLoanId ?? '-'}</td>
            <td className="fw-bold">{bank ? bank.name ?? bank.bankName : ""}</td>
            <td className="fw-bold">{minScore}</td>
            <td className="fw-bold">
                <Button size={'sm'}
                        onClick={() => {
                            showDevices();
                            setDevices(devices);
                        }}
                        variant={'light'}>Device(s): {devices && devices.length}</Button>
            </td>
            <td className="fw-bold">
                <Button size={'sm'} variant={'light'}
                        disabled={!authorization("IC_SC_BROWSE")}
                        onClick={() => {
                            showSchedules();
                            setSchedules(paymentSchedules);
                        }}>Schedules</Button>
            </td>
            <td className="fw-bold">{paymentFrequency}</td>
            <td className="fw-bold">{downPayment.toLocaleString()}</td>
            <td className="fw-bold">{totalLoanAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanBalance.toLocaleString()}</td>
            <td className="fw-bold">{paidAmount.toLocaleString()}</td>
            <td className="fw-bold">{(new Date(offeringDate)).toLocaleString()}</td>
        </tr>
    );
};
//
const DevicesModelDialog = (props) => {
    const {show, close, devices} = props;
    const TableRow = (props) => {
        const {index, device} = props;
        //
        return (
            <tr>
                <td>{index}</td>
                <td>
                    <a
                        href={device.deviceType ? `/devices/${device.deviceType.deviceTypeId}/details` : "#"}>
                        {device.deviceType && device.deviceType.type_name}
                    </a>
                </td>
                <td>{device.deviceType && device.deviceType.full_price}</td>
                <td>{device && device.imei}</td>
                <td>
                    <a href={device.store ? `/stores/${device.store.storeId}/details` : "#"}>
                        {device.store && device.store.name}
                    </a>
                </td>
                <td>{device.user ? `${device.user.name} ${device.user.name}` : ''}</td>
            </tr>
        );
    }
    //
    return (
        <Modal show={show} onHide={close} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive className="table-centered table-bordered table-nowrap rounded">
                    <thead className="thead-light">
                    <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Device Type</th>
                        <th className="border-0">Full Price</th>
                        <th className="border-0">IMEI</th>
                        <th className="border-0">Store Name</th>
                        <th className="border-0">Representative</th>
                    </tr>
                    </thead>
                    <tbody>
                    {devices && devices.length ? (
                        devices.map((device, i) => <TableRow device={device} index={++i}/>)
                    ) : (
                        <></>
                    )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
//
const SchedulesModelDialog = (props) => {
    const {show, close, schedules} = props;
    const TableRow = (props) => {
        const {schedule, index,} = props;
        return (
            <tr>
                <td>{index}</td>
                <td>{schedule.scheduleStatus.toLocaleString()}</td>
                <td>{schedule.dueAmount.toLocaleString()}</td>
                <td>{schedule.paidAmount.toLocaleString()}</td>
                <td>{schedule.deferredAmount.toLocaleString()}</td>
                <td>{schedule.startingDate ? (new Date(schedule.startingDate)).toDateString() : '-'}</td>
                <td>{schedule.endingDate ? (new Date(schedule.endingDate)).toDateString() : '-'}</td>
                <td>{schedule.dueDate ? (new Date(schedule.dueDate)).toDateString() : '-'}</td>
                <td>{schedule.paymentDate ? (new Date(schedule.paymentDate)).toDateString() : '-'}</td>
            </tr>
        );
    }
    //
    return (
        <Modal show={show} onHide={close} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Schedules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive striped={true} className="table-centered table-bordered table-nowrap rounded">
                    <thead className="thead-light">
                    <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Schedule Status</th>
                        <th className="border-0">Due Amount</th>
                        <th className="border-0">Paid Amount</th>
                        <th className="border-0">Deferred Amount</th>
                        <th className="border-0">Starting Date</th>
                        <th className="border-0">Ending Date</th>
                        <th className="border-0">Due Date</th>
                        <th className="border-0">Payment Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {schedules && schedules.length ? (
                        schedules.map((schedule, i) => <TableRow schedule={schedule} index={++i}/>)
                    ) : (
                        <></>
                    )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

class CustomerLoan extends Component {
    state = {
        loading: true,
        showDevices: false,
        showSchedules: false,
        getDevices: [],
        getSchedules: [],
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getClientLoans, client} = this.props;
        if (client && client.customer_id) {
            await getClientLoans(client.customer_id);
        }
        this.setState({loading: false});
    }
    //
    showDevices = () => {
        this.setState({showDevices: true,});
    }
    //
    showSchedule = () => {
        this.setState({showSchedules: true});
    }
    //
    hideSchedule = () => {
        this.setState({showSchedules: false, getSchedules: [],});
    }
    //
    hideDevices = () => {
        this.setState({showDevices: false, getDevices: [],});
    }
    //
    setDevices = (devices) => {
        this.setState({
            getDevices: devices,
        });
    }
    //
    setPaymentSchedules = (schedules) => {
        this.setState({
            getSchedules: schedules,
        });
    }


    //
    render() {
        const {clientLoans} = this.props;
        const {loading, showDevices, getDevices, showSchedules, getSchedules} = this.state;
        return (
            <Card>
                <Card.Body className={'overflow-auto'}>
                    <Table responsive className="table-centered table-bordered table-nowrap rounded">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Loan Status</th>
                            <th className="border-0">Loan Id</th>
                            <th className="border-0">Bank Id</th>
                            <th className="border-0">Bank Loan Account</th>
                            <th className="border-0">Bank LoanID</th>
                            <th className="border-0">Lender</th>
                            <th className="border-0">Min Score</th>
                            <th className="border-0">Device(s)</th>
                            <th className="border-0">Schedules</th>
                            <th className="border-0">Payment Frequency</th>
                            <th className="border-0">Down Payment</th>
                            <th className="border-0">Total Loan Amount</th>
                            <th className="border-0">Loan Balance</th>
                            <th className="border-0">Paid Amount</th>
                            <th className="border-0">Offering Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loading ? (
                                <tr>
                                    <td colSpan="12" align="center"><BeatLoader color="#000" size="8"/></td>
                                </tr>
                            ) : clientLoans != null && clientLoans.length > 0 ?
                                clientLoans.map((loan, i) => <TableRow {...loan}
                                                                       index={i}
                                                                       showDevices={this.showDevices}
                                                                       showSchedules={this.showSchedule}
                                                                       hideDevices={this.hideDevices}
                                                                       openDevicesModel={showDevices}
                                                                       openSchedulesModel={showSchedules}
                                                                       setDevices={this.setDevices}
                                                                       setSchedules={this.setPaymentSchedules}
                                                                       key={`page-visit-${i}`}/>) : (
                                    <tr>
                                        <td colSpan="12" align="center">No loans yet !</td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                </Card.Body>
                <DevicesModelDialog show={showDevices} close={this.hideDevices} devices={getDevices}/>
                <SchedulesModelDialog show={showSchedules} close={this.hideSchedule} schedules={getSchedules}/>
            </Card>
        );
    }
}

const mapStateProps = (state) => {
    const {clientLoans, error,} = state.clients;
    return {clientLoans, error};
}

export default connect(mapStateProps, {})(CustomerLoan);
