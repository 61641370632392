import React, {Component} from "react";
import {connect} from "react-redux";
import {createCorporate} from "../../actions/corporates";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import authorization from "../../helpers/authorization";

class CreateCorporate extends Component {
    state = {
        loading: false,
        name: "",
        tinNumber: "",
        description: "",
        email: "",
        website: "",
        msisdn: "",
        status: "",
    }

    componentDidMount = async () => {
        //
    }

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }

    submit = async (e) => {
        e.preventDefault();
        this.setState({loading: true,});
        const {createCorporate, history} = this.props;
        const {name, tinNumber, description, email, website, msisdn, status} = this.state;
        const create = await createCorporate({name, tinNumber, description, email, website, msisdn, status});
        if (create) {
            history.push(Routes.Corporates.path);
        }
        this.setState({loading: false,});
    }

    render() {
        const {loading, name, tinNumber, description, email, website, msisdn, status} = this.state;
        const {error,} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Corporates.path}>List corporates</Breadcrumb.Item>
                            <Breadcrumb.Item active>Corporate Create</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Create Corporate</h4>
                    </div>
                </div>
                <div className={'mb-2'}>
                    <Button variant="primary" href={Routes.Corporates.path} className="m-1">All corporates</Button>
                </div>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">Add new corporate</h5>
                        {error ? (<div className="text-danger">{error}</div>) : ""}
                        <Form onSubmit={this.submit}>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="text" name="name" onChange={this.handleChange}
                                                      value={name} placeholder="Name"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="tinNumber">
                                        <Form.Label>Tin Number</Form.Label>
                                        <Form.Control required type="text" name="tinNumber" onChange={this.handleChange}
                                                      value={tinNumber} placeholder="Tin Number"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="email">
                                        <Form.Label>E-Mail</Form.Label>
                                        <Form.Control required type="email" name="email" onChange={this.handleChange}
                                                      value={email} placeholder="E-Mail"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="msisdn">
                                        <Form.Label>MSISDN</Form.Label>
                                        <Form.Control required type="text" name="msisdn" onChange={this.handleChange}
                                                      value={msisdn} placeholder="MSISDN"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow className={'mb-4'}>
                                <Row className="align-items-center col ">
                                    <Form.Group id="website">
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control required type="text" name="website" onChange={this.handleChange}
                                                      value={website} placeholder="Website"/>
                                    </Form.Group>
                                </Row>
                                <Row className="align-items-center col ">
                                    <Form.Group id="status">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Select marital status" name={"status"}
                                                     readOnly={loading} onChange={this.handleChange}>
                                            <option value="" disabled={true} selected={true}>Select:.</option>
                                            <option value="M" selected={status === true}>Active</option>
                                            <option value="S" selected={status === false}>Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <MDBRow>
                                <Row className="custom">
                                    <Form.Group id="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control required as="textarea" type="text" name="description"
                                                      onChange={this.handleChange}
                                                      value={description} placeholder="Description"/>
                                    </Form.Group>
                                </Row>
                            </MDBRow>
                            <div className="mt-3">
                                <Button variant="primary"
                                        type="submit"
                                        disabled={loading || !authorization("CC_CREATE")}>
                                    {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, corporate} = state.corporates;
    return {error, corporate};
}
//
export default connect(mapStateProps, {createCorporate})(CreateCorporate);
