import React, {Component} from "react";
import {connect} from "react-redux";

export class BrowseRepresentative extends Component {

    state = {
        loading: true,
    };

    componentDidMount() {
        //
    }

    render() {
        return (
            <></>
        );
    }
}

const mapStateProps = (state) => {
    return {};
}
export default connect(mapStateProps, {})(BrowseRepresentative);
