import {action} from "../index";
import {USER_CREATE_FAILED, USER_CREATED} from "../types";
import {apiCall} from "../../utils/apiCall";
import {getToken} from "../../helpers/tokenHelper";

/**
 * Create user action
 *
 * @param userData
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const createUserAction = (userData) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/userregister', userData, {
            headers,
        });
        if (req.data.userid !== undefined) {
            const user = req.data;
            dispatch(action(USER_CREATED, user));
            return true;
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(USER_CREATE_FAILED, e.response.data.error));
        } else {
            dispatch(action(USER_CREATE_FAILED, e.message));
        }
    }
    return false;
}

export default createUserAction;
