import React from "react";

import {GeneralInfoForm} from "../components/Forms";
import {connect} from "react-redux";
import {profileAction} from "../actions/user";


export const settings = (props) => {
    console.log(props);
    return (
        <>
            <div className=" align-items-center py-4">

            </div>
            <GeneralInfoForm/>
        </>
    );
};
//
const mapStateToProps = (state) => {
    const {isLoggedIn} = state.authentication;
    const {user} = state.profile;
    return {
        isLoggedIn,
        user,
    };
};
//
export default connect(mapStateToProps, {profileAction})(settings);
