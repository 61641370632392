import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode, faDesktop, faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {getRolesGroups} from "../../actions/roles";
import Groups from "./components/Groups";
import Roles from "./components/Roles";
import TabNavItem from "../../components/TabNavItem";
import authorization from "../../helpers/authorization";

class RolesGroups extends Component {
    state = {
        loading: true,
        selectedTab: this.props.history.state ? this.props.history.state.selectedTab ?? 'groups' : 'groups',
    };
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getRolesGroups} = this.props;
        await getRolesGroups();
        this.setState({loading: false});
    }
    //
    selectTab = (tab) => {
        this.setState({selectedTab: tab});
    }

    //
    render() {
        const {selectedTab} = this.state;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Roles Groups</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Roles & Permissions</h4>
                    </div>
                </div>
                <Row className={''}>
                    <Col md={12}>
                        <Tab.Container title={''} defaultActiveKey={selectedTab}>
                            <Row>
                                <Col md={12}>
                                    <Nav fill variant="pills" className="flex-column flex-sm-row">
                                        <TabNavItem title={'Groups'}
                                                    eventKey="groups"
                                                    icon={faDesktop}
                                                    role={'GR_BROWSE'}
                                                    onSelect={() => this.selectTab('groups')}/>
                                        <TabNavItem title={'Roles'}
                                                    eventKey={'roles'}
                                                    icon={faCode}
                                                    role={'RO_BROWSE'}
                                                    onSelect={() => this.selectTab('roles')}/>
                                    </Nav>
                                    <Tab.Content>
                                        {
                                            authorization("GR_BROWSE") ? (
                                                <>
                                                    <Tab.Pane eventKey="groups" className="py-4">
                                                        {
                                                            selectedTab === 'groups' ? (
                                                                <Groups/>
                                                            ) : (<></>)
                                                        }
                                                    </Tab.Pane>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            authorization("RO_BROWSE") ? (
                                                <>
                                                    <Tab.Pane eventKey="roles" className="py-4">
                                                        {
                                                            selectedTab === 'roles' ? (
                                                                <Roles/>
                                                            ) : (<></>)
                                                        }
                                                    </Tab.Pane>
                                                </>
                                            ) : (<></>)
                                        }
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {groups, roles, error} = state.roles;
    return {groups, roles, error};
}
export default connect(mapStateProps, {getRolesGroups})(RolesGroups);
