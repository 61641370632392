import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Col, Form, InputGroup, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import {getCustomerByMSISDN} from "../../actions/clients";
import DetailsInfo from "./components/DetailsInfo";
import DevicesNotification from "./components/DevicesNotification";
import {BeatLoader} from "react-spinners";

class PushNotification extends Component {
    state = {
        loading: false,
        inputValue: "",
    }
    componentDidMount = async () => {

    }
    //
    handleOnChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value})
    }
    //
    handleSearchButton = async (e) => {
        this.setState({loading: true});
        const {getCustomerByMSISDN} = this.props;
        const {inputValue} = this.state;
        console.log(inputValue);
        if (inputValue !== "") {
            await getCustomerByMSISDN(inputValue);
        }
        this.setState({loading: false});
    }

    //
    render() {
        const {inputValue, loading,} = this.state;
        const {client,} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Notification</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Notifications</h4>
                    </div>
                </div>
                <Card border="light" className="table-wrapper shadow-sm mb-4">
                    <Card.Body className="">
                        <Row className="">
                            <Col md={6} className={'offset-3'}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroup.Text>
                                    <Form.Control type="text"
                                                  name="inputValue"
                                                  onChange={this.handleOnChange}
                                                  value={inputValue}
                                                  placeholder="Search client by NATIONAL ID, EMAIL, MSISDN"/>
                                    <Button onClick={this.handleSearchButton} disabled={loading}>
                                        Search
                                        {
                                            loading ? (
                                                <>
                                                    &nbsp;<BeatLoader color="#FFF" size="6"/>
                                                </>
                                            ) : ('')
                                        }
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Row>
                    <Col md={4}>
                        {
                            !loading && client ? (
                                <DetailsInfo client={client}/>
                            ) : ('')
                        }
                    </Col>
                    <Col md={8}>
                        {
                            !loading && client ? (
                                <DevicesNotification client={client}/>
                            ) : ('')
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {error, devices, notification} = state.notification;
    const {client,} = state.clients;
    //
    return {error, devices, notification, client};
}
//
export default connect(mapStateProps, {getCustomerByMSISDN})(PushNotification);
