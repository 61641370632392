import {
    CLOSE_TICKETS,
    CREATE_TICKETS,
    DELETE_TICKETS,
    ERROR_TICKETS,
    GET_TICKETS,
    REPLY_TICKETS
} from "../../actions/types";

const initiate = {
    error: null,
};

export const ticketsReducer = (state = initiate, action) => {
    switch (action.type) {
        case GET_TICKETS:
            return {
                ...state,
                tickets: action.payload,
            }
        case ERROR_TICKETS:
            return {
                ...state,
                error: action.payload,
            }
        case CREATE_TICKETS:
        case CLOSE_TICKETS:
        case DELETE_TICKETS:
        case REPLY_TICKETS:
            return {
                ...state,
                ticker: action.payload,
                error: null,
            }
        default:
            return {
                ...state,
            }
    }
}