import {GET_CELL, GET_DISTRICT, GET_PROVINCES, GET_SECTOR, GET_VILLAGE, PLACES_ERROR} from "../../actions/types";

const init = {
    error: null,
}

const placesReducer = (state = init, action) => {
    switch (action.type) {
        case PLACES_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case GET_PROVINCES:
            return {
                ...state,
                provinces: action.payload,
            }
        case GET_DISTRICT:
            return {
                ...state,
                districts: action.payload,
            }
        case GET_SECTOR:
            return {
                ...state,
                sectors: action.payload,
            }
        case GET_CELL:
            return {
                ...state,
                cells: action.payload,
            }
        case GET_VILLAGE:
            return {
                ...state,
                villages: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default placesReducer;