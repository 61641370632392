import React, {Component} from "react";
import "../../css/index.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, FormCheck, InputGroup, Modal, Row} from '@themesberg/react-bootstrap';
import BgImage from "../../assets/img/illustrations/signin.svg";
import {BeatLoader} from "react-spinners";
import {Routes} from "../../routes";
import {connect} from "react-redux";
import login from "../../actions/auth";


export class Login extends Component {
    state = {
        username: "",
        password: "",
        error: false,
        showModal: false,
        loading: false,
    };

    handleClose = () => {
        this.setState({showModal: false,});
    };
    //
    handleShow = (data) => {
        this.setState({showModal: true,});
    }

    //
    static getDerivedStateFromProps(props, state) {
        const {user} = props;
        if (user) {
            window.location = Routes.DashboardOverview.path;
        }
        return state;
    }

    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    };

    //
    login = async (e) => {
        e.preventDefault();
        this.setState({loading: true});
        const {username, password} = this.state;
        const {login} = this.props;
        const loginSuccess = await login({username, password});
        // console.log("loginSuccess", loginSuccess)
        if (loginSuccess) {
            window.location = Routes.DashboardOverview.path;
        } else {
            this.setState(() => ({error: true}));
        }
        this.setState({loading: false});
    };

    //
    render() {
        const {username, password, error, loading, showModal, } = this.state;
        return (
            <main>
                <section className="d-flex align-items-center my-5 mt-lg-9 mb-lg-5 p-lg-5">
                    <Container>
                        <p className="text-center">
                            <img src="/intelligra-logo-f1.png" alt="a"/>
                        </p>
                        <Row className="justify-content-center form-bg-image"
                             style={{backgroundImage: `url(${BgImage})`}}>
                            <Col xs={12} className="d-flex align-items-center justify-content-center">
                                <div
                                    className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                    <div className="text-center text-md-center mb-4 mt-md-0">
                                        <h3 className="mb-0">Welcome to Finance 1</h3>
                                    </div>
                                    <Form className="mt-4" onSubmit={this.login}>
                                        <Form.Group id="email" className="mb-4">
                                            <Form.Label>Your username</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope}/>
                                                </InputGroup.Text>
                                                <Form.Control autoFocus required type="text" name="username"
                                                              onChange={this.handleChange} value={username}
                                                              placeholder="intelligra"/>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Group id="password" className="mb-4">
                                                <Form.Label>Your Password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt}/>
                                                    </InputGroup.Text>
                                                    <Form.Control required type="password" name="password"
                                                                  onChange={this.handleChange} value={password}
                                                                  placeholder="Password"/>
                                                </InputGroup>
                                            </Form.Group>
                                            {error && (
                                                <p className="text-danger">Incorrect username or password</p>
                                            )}
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <Form.Check type="checkbox">
                                                    <FormCheck.Input id="defaultCheck5" className="me-2"/>
                                                    <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember
                                                        me</FormCheck.Label>
                                                </Form.Check>
                                                <Card.Link onClick={this.handleShow} className="small text-end">Lost password?</Card.Link>
                                            </div>
                                        </Form.Group>
                                        <Button variant="primary" type="submit"
                                                disabled={!username || !password || loading} className="w-100">
                                            {loading ? <BeatLoader color="#FFF"/> : "Sign in"}
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Modal
                    show={showModal}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Lost password?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert alert-warning">
                            If you forget password, you have to contact administrator!
                        </div>
                    </Modal.Body>
                </Modal>
            </main>
        );
    }

}

const mapStateToProps = (state) => {
    const {user, authError, role} = state.authentication;
    return {user, authError, role}; 
};

export default connect(mapStateToProps, {login})(Login);
