import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome, faImages} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {getDeviceTypeCategoryById, getDeviceTypeDetails, uploadDeviceImage} from "../../actions/devices";
import Preloader from "../../components/Preloader";
import Details from "./components/Details";
import Images from "./components/Images";
import authorization from "../../helpers/authorization";

export class DeviceDetails extends Component {
    state = {
        loading: true,
        deviceId: this.props.match.params.id,
    };
    //
    componentDidMount = async () => {
        const {deviceId} = this.state;
        //
        const {getDeviceTypeDetails} = this.props;
        await getDeviceTypeDetails(deviceId);
        //
        this.setState({loading: false,});
    }
    //
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
    }

    //
    render() {
        const {data, getDeviceTypeCategoryById, categories} = this.props;
        const {loading, deviceId} = this.state;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Devices.path}>Devices</Breadcrumb.Item>
                            <Breadcrumb.Item active>Details</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Device Type: <b><u>{data && data.type_name}</u></b></h4>
                    </div>
                </div>
                <div className={'mb-2'}>
                    <Button variant="primary" href={Routes.Devices.path} className="m-1">All device</Button>
                    {
                        authorization("DT_EDIT") ? (
                            <Button variant="success" href={`/devices/${deviceId}/edit`} className="m-1">Edit
                                device</Button>
                        ) : (<></>)
                    }
                </div>
                {loading ? (
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Preloader show={loading}/>
                        </Col>
                    </Row>
                ) : (
                    <Row className="">
                        <Col md="12">
                            <Tab.Container defaultActiveKey="device_details">
                                <Row>
                                    <Col lg={12}>
                                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                                            <Nav.Item>
                                                <Nav.Link eventKey="device_details" className="mb-sm-3 mb-md-0">
                                                    <FontAwesomeIcon icon={faEye} className="me-2"/> Details
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="device_images" className="mb-sm-3 mb-md-0">
                                                    <FontAwesomeIcon icon={faImages} className="me-2"/> Images
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="device_details" className="py-4">
                                                <Details {...data}
                                                         getDeviceTypeCategoryById={getDeviceTypeCategoryById}
                                                         categories={categories}/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="device_images" className="py-4">
                                                <Images {...this.props}/>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {data, error, uploadError, categories} = state.device;
    return {
        data, error, uploadError, categories,
    }
};
//
export default connect(mapStateProps, {
    getDeviceTypeDetails,
    getDeviceTypeCategoryById,
    uploadDeviceImage
})(DeviceDetails);
