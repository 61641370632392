import React, {Component} from "react";
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Nav,
    Pagination,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCog, faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {BeatLoader} from "react-spinners";
import {connect} from "react-redux";
import {getAllocatedLoans} from "../../actions/loans";
import {DevicesModelDialog} from "./components/DevicesModelDialog";
import {SchedulesModelDialog} from "./components/SchedulesModelDialog";
import FilterLoanStatus from "./components/FilterLoanStatus";
import FilterPaymentFreq from "./components/FilterPaymentFreq";
import FilterRender from "./components/FilterRender";
import FilterApplicationStatus from "./components/FilterApplicationStatus";
import {StartCase} from 'react-lodash';
import authorization from "../../helpers/authorization";

const TableRow = (props) => {
    const {
        index, bank, downPayment, totalLoanAmount, minScore, paymentFrequency, devices,
        offeringDate, loanStatus, loanBalance, paidAmount, paymentSchedules, showDevices, setDevices,
        showSchedules, setSchedules, customer,
    } = props;

    return (
        <tr>
            <td>
                <a href={authorization("IC_READ") ? `/clients/${customer.intelligraCustomerId}/details` : "#"}>
                    <Card.Link href="#" className="text-primary fw-bold">{(index + 1)}</Card.Link>
                </a>
            </td>
            <td className="fw-bold">
                <a href={authorization("IC_READ") ? `/clients/${customer.intelligraCustomerId}/details` : "#"}>
                    <StartCase
                        string={`${customer.customerName} ${customer.customerSurname}`?.toLocaleLowerCase()}/>
                </a>
            </td>
            <td className="fw-bold">
                <a href={authorization("IC_READ") ? `/clients/${customer.intelligraCustomerId}/details` : "#"}>
                    {customer.msisdn}
                </a>
            </td>
            <td className="fw-bold">
                <a href={authorization("IC_READ") ? `/clients/${customer.intelligraCustomerId}/details` : "#"}>
                    {bank ? bank.name ?? bank.bankName : ""}
                </a>
            </td>
            <td className="fw-bold">
                <a href={authorization("IC_READ") ? `/clients/${customer.intelligraCustomerId}/details` : "#"}>
                    {minScore}
                </a>
            </td>
            <td className="fw-bold">
                <Button size={'sm'} onClick={() => {
                    showDevices();
                    setDevices(devices);
                }}
                        variant={'light'}>Device(s): {devices && devices.length}</Button>
            </td>
            <td className="fw-bold">
                <Button size={'sm'} variant={'light'} onClick={() => {
                    showSchedules();
                    setSchedules(paymentSchedules);
                }}>Schedules</Button>
            </td>
            <td className="fw-bold">{paymentFrequency}</td>
            <td className="fw-bold">{downPayment.toLocaleString()}</td>
            <td className="fw-bold">{totalLoanAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanBalance.toLocaleString()}</td>
            <td className="fw-bold">{paidAmount.toLocaleString()}</td>
            <td className="fw-bold">{loanStatus}</td>
            <td className="fw-bold">{
                (new Date(offeringDate)).toLocaleString()
            }</td>
        </tr>
    );
};

class AllocatedLoans extends Component {
    state = {
        loading: true,
        showDevices: false,
        showSchedules: false,
        status: null,
        selectedFreq: null,
        selectedRender: null,
        getDevices: [],
        getSchedules: [],
        loansList: null,
    };

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getAllocatedLoans} = this.props;
        // await getAllocatedLoans();
        this.setState({loading: false,});
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        const prevStatus = prevState.status;
        const {status} = this.state;
        if (prevStatus !== status) {
            //
        }
    }
    //
    setStatus = (status) => {
        const {loans} = this.props;
        const _loans = loans?.filter((item) => item.loanStatus.toLocaleLowerCase().includes(status?.toLocaleLowerCase()));
        this.setState({status: status, loansList: _loans,});
    }
    //
    selectedFreq = (selectedFreq) => {
        const {loans} = this.props;
        const _loans = loans?.filter((item) => item.paymentFrequency.toLocaleLowerCase().includes(selectedFreq?.toLocaleLowerCase()));
        this.setState({selectedFreq: selectedFreq, loansList: _loans,});
    }
    //
    setRender = (selectedRender) => {
        const {loans} = this.props;
        const _loans = loans?.filter((item) => item.bank.id.toLocaleLowerCase().includes(selectedRender?.id.toLocaleLowerCase()));
        this.setState({selectedRender: selectedRender, loansList: _loans,});
    }
    //
    search = (e) => {
        e.preventDefault();
        const _s = e.target.value
        const {loans} = this.props;
        if (_s) {
            const _loans = loans?.filter((item) => item.customer?.customerName.toLocaleLowerCase().includes(_s.toLocaleLowerCase()));
            this.setState({loansList: _loans,});
        } else {
            this.setState({loansList: null,});
        }
    }
    //
    showDevices = () => {
        this.setState({showDevices: true,});
    }
    //
    showSchedule = () => {
        this.setState({showSchedules: true});
    }
    //
    hideSchedule = () => {
        this.setState({showSchedules: false, getSchedules: [],});
    }
    //
    hideDevices = () => {
        this.setState({showDevices: false, getDevices: [],});
    }
    //
    setDevices = (devices) => {
        this.setState({
            getDevices: devices,
        });
    }
    //
    setPaymentSchedules = (schedules) => {
        this.setState({
            getSchedules: schedules,
        });
    }

    //
    render() {
        const {loading, showDevices, getDevices, showSchedules, getSchedules, loansList} = this.state;
        const {loans} = this.props;
        let _loans = loansList ?? loans;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Allocated loans</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Allocated loans.</h4>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <ButtonGroup>
                            <Button variant="outline-primary" size="sm">Share</Button>
                            <Button variant="outline-primary" size="sm">Export</Button>
                        </ButtonGroup>
                    </div>
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-between align-items-center">
                        <Col md={4}>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                                <Form.Control type="text" placeholder="Search" onChange={this.search}/>
                            </InputGroup>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={3}><FilterLoanStatus selectStatus={this.setStatus}/></Col>
                                <Col md={3}><FilterApplicationStatus selectStatus={this.setStatus}/></Col>
                                <Col md={3}><FilterRender selectRender={this.setRender}/></Col>
                                <Col md={3}><FilterPaymentFreq selectedFreq={this.selectedFreq}/></Col>
                            </Row>
                        </Col>
                        <Col md={2} className="ps-md-0 text-end">
                            <Dropdown as={ButtonGroup}>
                                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm icon-gray">
                                  <FontAwesomeIcon icon={faCog}/>
                                </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                    <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                    <Dropdown.Item className="d-flex fw-bold">
                                        10 <span className="icon icon-small ms-auto"><FontAwesomeIcon
                                        icon={faCheck}/></span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                    <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>

                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="">
                        <Table responsive striped bordered className="user-table align-items-center">
                            <thead>
                            <tr>
                                <th className="border-bottom">#</th>
                                <th className="border-bottom">Customer Name</th>
                                <th className="border-bottom">Customer Telephone</th>
                                <th className="border-bottom">Lender</th>
                                <th className="border-bottom">Min Score</th>
                                <th className="border-bottom">Device(s)</th>
                                <th className="border-bottom">Schedules</th>
                                <th className="border-bottom">Payment Frequency</th>
                                <th className="border-bottom">Down Payment</th>
                                <th className="border-bottom">Total Loan Amount</th>
                                <th className="border-bottom">Loan Balance</th>
                                <th className="border-bottom">Paid Amount</th>
                                <th className="border-bottom">Loan Status</th>
                                <th className="border-bottom">Offering Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="14" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : _loans != null && _loans.length > 0 ?
                                    _loans.map((loan, i) => <TableRow {...loan}
                                                                      index={i}
                                                                      showDevices={this.showDevices}
                                                                      showSchedules={this.showSchedule}
                                                                      hideDevices={this.hideDevices}
                                                                      openDevicesModel={showDevices}
                                                                      openSchedulesModel={showSchedules}
                                                                      setDevices={this.setDevices}
                                                                      setSchedules={this.setPaymentSchedules}
                                                                      key={`page-visit-${loan.id}`}/>) : (
                                        <tr>
                                            <td colSpan="14" align="center">Search By MSISDN</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                        <Nav>
                            <Pagination className="mb-2 mb-lg-0">
                                <Pagination.Prev>
                                    Previous
                                </Pagination.Prev>
                                <Pagination.Item active>1</Pagination.Item>
                                <Pagination.Next>
                                    Next
                                </Pagination.Next>
                            </Pagination>
                        </Nav>
                        <small className="fw-bold">
                            Showing <b>1</b> out of <b>25</b> entries
                        </small>
                    </Card.Footer>
                </Card>
                <DevicesModelDialog show={showDevices} close={this.hideDevices} devices={getDevices}/>
                <SchedulesModelDialog show={showSchedules} close={this.hideSchedule} schedules={getSchedules}/>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {loans, error,} = state.loans;
    return {loans, error,};
}

export default connect(mapStateProps, {getAllocatedLoans})(AllocatedLoans);
