const initialState = {
    alerts: [],
    redirectUrl: ""
}

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SHOW_ALERT":
            return {
                ...state,
                alerts: [...state.alerts, action.payload],
                redirectUrl: action.redirectUrl
            };
        case "HIDE_ALERT":
            return {
                ...state,
                alerts: state.alerts.filter(alert => alert !== action.payload)
            };
        default:
            return state;
    }
}

export default alertReducer;