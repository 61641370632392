import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import AddFreelancer from "./AddFreelancer";
import {getFreelancersPartner} from "../../../../actions/partners";

const TableRow = ({index, name, surname, nationalId, msisdn, createdon, membershipstatus, memberemail, partnertypecode, partnercode, username,}) => {
    return (
        <>
            <tr>
                <td>{index}</td>
                <td>{`${name} ${surname}`}</td>
                <td>{`${nationalId}`}</td>
                <td>{`${msisdn}`}</td>
                <td>{`${memberemail}`}</td>
                <td>{`${membershipstatus}`}</td>
                <td>{`${createdon}`}</td>
                <td>&nbsp;</td>
            </tr>
        </>
    );
}

class Freelancers extends Component {
    state = {
        show: false,
    }
    //
    componentDidMount = async () => {
        const {getFreelancersPartner, bank: partner} = this.props;
        await getFreelancersPartner(partner.id);
    }

    //
    handleModal = (state) => {
        this.setState({show: state});
    }
    //
    render() {
        const {show,} = this.state;
        const {bank, freelancers, error, freelancerLoading} = this.props;
        return (
            <>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Button variant="primary" className={'mb-4'} onClick={() => {
                            this.handleModal(true)
                        }}>
                            Add Freelancer
                        </Button>
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className={'text-center'}>#</th>
                                <th className={'text-center'}>Name</th>
                                <th className={'text-center'}>NationalId</th>
                                <th className={'text-center'}>MSISDN</th>
                                <th className={'text-center'}>Email</th>
                                <th className={'text-center'}>Status</th>
                                <th className={'text-center'}>CreatedOn</th>
                                <th className={'text-center'}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !freelancerLoading && (freelancers ?? []).length > 0 ?  freelancers.map((freelancer, i) => <TableRow index={(i + 1)} {...freelancer} />) : (
                                    <>
                                        <tr><td colSpan={8} className="text-center">{freelancerLoading ? `Loading ...` : `No freelancers available yet!`}</td></tr>
                                    </>
                                )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <AddFreelancer
                    partner={bank}
                    show={show}
                    handleModal={this.handleModal}
                    handleSubmit={this.handleSubmit}/>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    const {freelancers, error, freelancerLoading} = store.partners;
    return {freelancers, error, freelancerLoading};
}
export default connect(mapStateToProps, {getFreelancersPartner})(Freelancers);
