import {action} from "../index";
import {GET_USERS, USER_ERROR} from "../types";
import {apiCall} from "../../utils/apiCall";
import {getToken} from "../../helpers/tokenHelper";

export const getAllUsersAction = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get('/intelligrapi/allusers', {
            headers,
        });
        dispatch(action(GET_USERS, req.data));
    } catch (e) {
        dispatch(action(USER_ERROR, e));
    }
    return false;
}

/**
 * Get all reps
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllRepUsers = () => async dispatch => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        }
        const req = await apiCall.get('/intelligrapi/reps/allrepresentativeusers', {
            headers,
        });
        dispatch(action(GET_USERS, req.data));
    } catch (e) {
        dispatch(action(USER_ERROR, e.message));
    }
}

export default getAllUsersAction;
