import {action} from "../index";
import {
    CREATE_DEVICE,
    ERROR_DEVICES,
    ERROR_DEVICES_CATEGORY,
    GET_DEVICE_DETAILS,
    GET_DEVICES,
    GET_DEVICES_CATEGORY,
    UPDATE_DEVICE,
    UPLOAD_DEVICE_IMAGE,
    UPLOAD_DEVICE_IMAGE_ERROR
} from "../types";
import {apiCall} from "../../utils/apiCall";
import {getToken} from "../../helpers/tokenHelper";

/**
 * Get all devices type from the backend
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllDevices = (category = null) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const url = category === null ? `/intelligrapi/alldevicetypes` : `/intelligrapi/devicetypesbycategory?categoryid=${category}`;
        const req = await apiCall.get(url, {
            headers,
        });
        dispatch(action(GET_DEVICES, req.data));
    } catch (e) {
        dispatch(action(ERROR_DEVICES, e.message));
    }
}

/**
 * Get categories of devices type
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getDeviceTypeCategory = () => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get('/intelligrapi/alldevicetypecategories', {
            headers,
        });
        dispatch(action(GET_DEVICES_CATEGORY, req.data));
    } catch (e) {
        dispatch(action(ERROR_DEVICES_CATEGORY, e.message));
    }
}
/**
 * Get device type category by id
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getDeviceTypeCategoryById = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.get(`/intelligrapi/devicetypesbycategory?category=${parseInt(id)}`, {
            headers,
        });
        dispatch(action(GET_DEVICES_CATEGORY, req.data));
    } catch (e) {
        dispatch(action(ERROR_DEVICES_CATEGORY, e.message));
    }
}

/**
 * Create a new device type
 *
 * @param data
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const createDevice = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/adddevicespecs', data, {
            headers
        });
        //
        if (req.data && req.data.deviceTypeId) {
            dispatch(action(CREATE_DEVICE, req.data));
            return true;
        } else {
            dispatch(action(ERROR_DEVICES, req.data.error));
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_DEVICES, e.response.data.error));
        } else {
            dispatch(action(ERROR_DEVICES, e.message));
        }
    }
    return false;
}

/**
 * Update Device
 *
 * @param data
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const updateDevice = (data) => async (dispatch) => {
    try {
        console.log(data);
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/reviewdevicespecs', data, {
            headers
        });
        //
        if (req.data && req.data.deviceTypeId) {
            dispatch(action(UPDATE_DEVICE, req.data));
            return true;
        } else {
            dispatch(action(ERROR_DEVICES, req.data.error));
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_DEVICES, e.response.data.error));
        } else {
            dispatch(action(ERROR_DEVICES, e.message));
        }
    }
    return false;
}

/**
 * Get device details
 *
 * @param deviceId
 * @returns {(function(*): Promise<*>)|*}
 */
export const getDeviceTypeDetails = (deviceId) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/devicetypedetails?devicetypeid=${deviceId}`, {
            headers,
        });
        //
        dispatch(action(GET_DEVICE_DETAILS, req.data));
    } catch (e) {
        dispatch(action(ERROR_DEVICES, e.message));
    }
}

/**
 * Upload image for a device type
 *
 * @param data
 * @returns {(function(*): Promise<*>)|*}
 */
export const uploadDeviceImage = (data) => async (dispatch) => {
    try {

        const token = getToken();
        const headers = {
            "Authorization": "Bearer ".concat(token),
            "Content-Type": "multipart/form-data",
        };
        const req = await apiCall.post(`/intelligrapi/devicetypephotos`, data, {
            headers,
        });
        //
        if (req.data.information || req.data.error) {
            dispatch(action(UPLOAD_DEVICE_IMAGE, req.data));
            return true;
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(UPLOAD_DEVICE_IMAGE_ERROR, (e.response.data.error ?? e.response.data)));
        } else {
            dispatch(action(UPLOAD_DEVICE_IMAGE_ERROR, e.message));
        }
    }
    return false;
}

