import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import createUserAction from "../../actions/user/createUserAction";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import authorization from "../../helpers/authorization";
import {getPartnerData, getPartnerTypes} from "../../actions/partners";

export class CreateUser extends Component {
    state = {
        hasError: false,
        loading: false,
        loadingPartnerData: false,
        usernameError: null,
        username: '',
        password: '',
        names: '',
        email: '',
        phone: '',
        userpartnertyype: '',
        userpartnertyypeId: '',
    };

    usernameOnChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        const pattern = /\s/g.test(value);
        if (!pattern) {
            this.setState(() => ({
                [name]: value,
                usernameError: null,
                hasError: false,
            }));
        } else {
            this.setState(() => ({
                usernameError: "Please remove spaces !!",
                hasError: true,
            }));
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    };

    handlePartnerChange = async (e) => {
        e.preventDefault();
        const {value} = e.target;
        const {getPartnerData,} = this.props;
        const splitValue = value.toString().split(":");
        if (splitValue.length > 0) {
            const _name = splitValue[0];
            const _code = splitValue[1];
            this.setState({loadingPartnerData: true, loading: true, userpartnertyype: _code,});
            await getPartnerData(_code);
            this.setState({loadingPartnerData: false, loading: false,});
        }
    }

    componentDidMount = async () => {
        const {getPartnerTypes} = this.props;
        this.setState({loading: true});
        await getPartnerTypes();
        this.setState({loading: false});
    }

    createUser = async (e) => {
        this.setState({loading: true});
        e.preventDefault();
        const {username, password, names, email, phone, userpartnertyype, userpartnertyypeId} = this.state;
        const splitNames = names.split(" ");
        const name = splitNames[0] ?? "";
        const surname = splitNames.slice(1).join(" ");
        const {createUserAction} = this.props;
        const create = await createUserAction({
            username, password, name, surname, email, msisdn: phone, userpartnertyype, userpartnertyypeId,
        });
        if (create === true) {
            const {history} = this.props;
            history.push(Routes.Users.path);
        }
        this.setState({loading: false});
    }

    render() {
        const {
            username,
            names,
            email,
            loading,
            password,
            phone,
            userpartnertyype,
            loadingPartnerData,
            userpartnertyypeId,
            usernameError,
            hasError,
        } = this.state;
        const {error, partnerTypes, partnerData,} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Users.path}>List of all users</Breadcrumb.Item>
                            <Breadcrumb.Item active>New User</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4> Create New User</h4>
                    </div>
                </div>
                <div className={''}>
                    <Card border={'light'} className={'shadow-sm mb-4'}>
                        <Card.Header>
                            <h5 className="mb-0">User information</h5>
                        </Card.Header>
                        <Card.Body>
                            {error ? (<div className="text-danger">{error}</div>) : ""}
                            <Form onSubmit={this.createUser} aria-invalid={hasError}>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="username" className={''}>
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control required type="text" name="username"
                                                          onChange={this.usernameOnChange}
                                                          isInvalid={(usernameError !== null)}
                                                          value={username} placeholder="Enter username"/>
                                            {
                                                (usernameError !== null) ? (
                                                    <>
                                                        <Form.Control.Feedback type="invalid">
                                                            {usernameError}
                                                        </Form.Control.Feedback>
                                                    </>
                                                ) : (<></>)
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="password">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control required type="text" name="password"
                                                          onChange={this.handleChange} value={password}
                                                          placeholder="Enter password"/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="password">
                                            <Form.Label>Names</Form.Label>
                                            <Form.Control required type="text" name="names" onChange={this.handleChange}
                                                          value={names} placeholder="Enter Names"/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required type="email" name="email"
                                                          onChange={this.handleChange}
                                                          value={email} placeholder="name@intelligra.io"/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group id="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control required type="number" name="phone"
                                                          onChange={this.handleChange}
                                                          value={phone} placeholder="+250 788 327 447"/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={partnerData && userpartnertyype !== 'I' ? 3 : 6} className="mb-3">
                                        <Form.Group id="email">
                                            <Form.Label>User Types</Form.Label>
                                            <Form.Select name={'userpartnertyype'}
                                                         onChange={this.handlePartnerChange}
                                                         aria-label="Select user type"
                                                         required={true}>
                                                <option value={""}>Types :.</option>
                                                <option value={"I"}>INTELLIGRA</option>
                                                {
                                                    (partnerTypes ?? []).map((item, i) => (
                                                        <option
                                                            value={`${item.typeName}:${item.typeCode}`}
                                                            selected={userpartnertyype === item.typeCode}>{item.typeName}</option>)
                                                    )
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {
                                        partnerData && !loadingPartnerData && userpartnertyype !== 'I' ? (
                                            <>
                                                <Col md={3} className="mb-3">
                                                    <Form.Group id="userpartnertyypeId">
                                                        <Form.Label>Partners</Form.Label>
                                                        <Form.Select name={'userpartnertyypeId'}
                                                                     onChange={this.handleChange}
                                                                     aria-label="Select partner data">
                                                            <option value={""}>Partner List :.</option>
                                                            {
                                                                (partnerData ?? []).map((item, i) => (
                                                                    <option
                                                                        value={item.id}
                                                                        selected={userpartnertyypeId === item.id}>{item.description}</option>)
                                                                )
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        ) : (<></>)
                                    }

                                </Row>
                                <div className="mt-3">
                                    <Button variant="primary"
                                            type="submit"
                                            disabled={loading || !authorization('US_CREATE') || hasError}>
                                        {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}

//
const mapStateToProps = (state) => {
    const {data, error,} = state.createUser;
    const {partnerTypes, partnerData} = state.partners;
    return {
        data, error, partnerTypes, partnerData,
    };
};
//
export default connect(mapStateToProps, {
    createUserAction,
    getPartnerTypes,
    getPartnerData,
})(CreateUser);
