import {connect, useDispatch} from "react-redux";
import {Alert, Button, Col, Form, Image, Modal, Row} from "@themesberg/react-bootstrap";
import React, {useEffect, useState} from "react";
import {createPartnerFreelancer} from "../../../../actions/partners";
import ReactLogo from "../../../../assets/loader/Loader.svg";

const AddFreelancer = ({show, handleModal, handleSubmit, error, partner, freelancerLoading}) => {
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(null)
    const dispatch = useDispatch();
    //
    useEffect(() => {
        setLoading(freelancerLoading);
        if (error) {
            setValidated(false);
            setHasError(error);
        }
    }, [freelancerLoading, error,])
    //
    const _handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            event.stopPropagation();
            dispatch(createPartnerFreelancer({
                ...data,
                partnertypecode: partner.partnerTypeCode,
                partnerid: partner.id
            }));
        }
        setValidated(true);
    };
    const handleOnChange = (e) => {
        e.preventDefault();
        if (e.target.value) {
            setHasError(null);
            const _data = data;
            _data[e.target.name] = e.target.value;
            setData(_data);
        }
    }

    const handleModalClose = () => {
        setValidated(false);
        handleModal(false);
    }

    return (
        <>
            <Modal show={show}
                   backdrop='static'
                   keyboard={false}
                   onHide={handleModalClose}
                   size={'lg'}
                   dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Freelancer</Modal.Title>
                </Modal.Header>
                <Form noValidate
                      validated={validated}
                      onSubmit={_handleSubmit}
                      className="w-100 overflow-hidden">
                    <Modal.Body>
                        {hasError && (
                            <Alert key={'danger'} variant={'danger'}>
                                {(hasError ?? []).map(error => (<li>{error}</li>))}
                            </Alert>
                        )}
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="validationCustom01" id='create-freelancer-form'>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First name"
                                    name={'name'}
                                    onChange={handleOnChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last name"
                                    name={'surname'}
                                    onChange={handleOnChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    name={'username'}
                                    onChange={handleOnChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="5" controlId="validationCustom03">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                              placeholder="Email"
                                              name={'memberemail'}
                                              onChange={handleOnChange}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationCustom04">
                                <Form.Label>MSISDN</Form.Label>
                                <Form.Control type="text"
                                              placeholder="msisdn"
                                              required
                                              name={'msisdn'}
                                              onChange={handleOnChange}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationCustom05">
                                <Form.Label>Secret</Form.Label>
                                <Form.Control type="text"
                                              placeholder="Secret"
                                              name={'secret'}
                                              required
                                              onChange={handleOnChange}/>
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" key={'inline-radio'}>
                            <Form.Label>Freelance Status</Form.Label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Form.Check
                                required
                                type={'radio'}
                                label="Active"
                                name='membershipstatus'
                                value={'active'}
                                feedback="Check status"
                                inline={true}
                                id={`inline-radio-1`}
                                onChange={handleOnChange}
                            />
                            <Form.Check
                                required
                                type={'radio'}
                                inline={true}
                                label="Suspended"
                                name='membershipstatus'
                                value={'suspended'}
                                feedback="Check status"
                                id={`inline-radio-2`}
                                onChange={handleOnChange}
                            />
                            <Form.Check
                                required
                                type={'radio'}
                                inline={true}
                                label="Closed"
                                name='membershipstatus'
                                value={'closed'}
                                feedback="Check status"
                                id={`inline-radio-3`}
                                onChange={handleOnChange}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type='button'
                                onClick={handleModalClose}
                                disabled={loading}
                        >
                            Close
                        </Button>
                        <Button variant="primary"
                                type={'submit'}
                                disabled={loading}
                                onClick={handleSubmit}>
                            Save Changes
                            {
                                loading && (<Image
                                    className="loader-element animate__animated animate__jackInTheBox"
                                    src={ReactLogo} height={30} width={30}/>)
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
const mapStateToProps = (store) => {
    const {freelancerLoading, freelancer, error,} = store.partners;
    return {freelancerLoading, freelancer, error,}
}
//
export default connect(mapStateToProps, {})(AddFreelancer);