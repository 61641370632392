import {REPORT_ERROR, REPORT_GET_TELCO_LOANS, REPORT_LOADING} from "../../actions/types";

const initiate = {
    error: null,
    reports: [],
};

export const reportsReducer = (state = initiate, action) => {
    switch (action.type) {
        case REPORT_GET_TELCO_LOANS:
            return {
                ...state,
                reports: action.payload,
            };
        case REPORT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}
