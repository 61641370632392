import { action } from "../index";
import { getToken } from "../../helpers/tokenHelper";
import { apiCall2 } from "../../utils/apiCall";
import {
    GET_CUSTOMER_DETAILS_START,
    GET_CUSTOMER_DETAILS_SUCCESS,
    GET_CUSTOMER_DETAILS_ENDS,

    PROCESS_CUSTOMER_GRADUATION_START,
    PROCESS_CUSTOMER_GRADUATION_SUCCESS,
    PROCESS_CUSTOMER_GRADUATION_ENDS,

    FETCH_GRADUATING_CUSTOMER_START,
    FETCH_GRADUATING_CUSTOMER_SUCCESS,
    FETCH_GRADUATING_CUSTOMER_ENDS,

    VERIFY_GRADUATE_CUSTOMER_START,
    VERIFY_GRADUATE_CUSTOMER_SUCCESS,
    VERIFY_GRADUATE_CUSTOMER_ENDS
} from "../types";
import { showAlert } from "../apiresponse/AlertAction";


// Fetch details of a single customer  
export const getCustomerDetails = (msisdn) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        console.log("customerDetails-payload", msisdn);
        dispatch(action(GET_CUSTOMER_DETAILS_START));

        const req = await apiCall2.get(`Customers/search_customer_with_msisdn?msisdn=${msisdn}`, {
            headers,
        });

        console.log('fetchDetails-res', req)

        const code = req.status;
        if (code === 200) {
            console.log('Fetch customer payment logs success...')
            dispatch(action(GET_CUSTOMER_DETAILS_SUCCESS, {payload: req.data?.data[0]}))
            dispatch(showAlert(req?.data?.message || "Customer details successfully fecthed!", "success"))
        } else {
            console.log('Fetch customer payment logs failure...')
            dispatch(action(GET_CUSTOMER_DETAILS_ENDS)) 
            dispatch(showAlert(req?.data?.message || "Error fetching customer details!", "error"))
        }

        return req;
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(GET_CUSTOMER_DETAILS_ENDS))
        dispatch(showAlert("Error fetching customer details!", "error"))
    }
}

// Process customer for graduation
export const processCustomerGraduation = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const data = payload;

        console.log("processCustomer-payload", payload)
        dispatch(action(PROCESS_CUSTOMER_GRADUATION_START))

        const req = await apiCall2.post(`Customers/proccess_customer_for_graduation`, data, {
            headers,
        });

        console.log('process-customer-res', req)

        const code = req.status;
        if (code === 200) {
            console.log('Process custommer graduation success...')
            dispatch(showAlert(req?.data?.message, "success"))
            dispatch(action(PROCESS_CUSTOMER_GRADUATION_SUCCESS))
        } else {
            console.log('Process custommer graduation failure...')
            dispatch(showAlert("Operation Failed", "error"))
            dispatch(action(PROCESS_CUSTOMER_GRADUATION_ENDS))
        }
        return req;
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(PROCESS_CUSTOMER_GRADUATION_ENDS))
        dispatch(showAlert("Operation Failed", "error"))
    }
}

// Fetch graduating customers
export const fetchGraduatingCustomers = (statusType) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        dispatch(action(FETCH_GRADUATING_CUSTOMER_START))

        const req = await apiCall2.get(`Customers/fetch_graduating_customers?filters=status==${statusType}`, {
            headers,
        });

        console.log('fetch-grad-customer-res', req)

        const code = req.status;
        if (code === 200) {
            console.log('Fetch graduating customer success...')
            // dispatch(showAlert(req?.data?.message, "success"))
            dispatch(action(FETCH_GRADUATING_CUSTOMER_SUCCESS, {payload: req.data?.data}))
        } else {
            console.log('Fetch graduating customer failure...')
            dispatch(showAlert("Operation Failed", "error"));
            dispatch(action(FETCH_GRADUATING_CUSTOMER_ENDS));
        }
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(FETCH_GRADUATING_CUSTOMER_ENDS))
        dispatch(showAlert("Operation Failed", "error"))
    }
}

// Verify/Graduate customers
export const verifyGraduateCustomer = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const data = payload;

        console.log("verifyGradCustomer-payload", payload)
        dispatch(action(VERIFY_GRADUATE_CUSTOMER_START))

        const req = await apiCall2.post(`Customers/verify_and_graduate_customer`, data, {
            headers,
        });

        console.log('VerifyGrad-customer-res', req)

        const code = req.status;
        if (code === 200 && req.data.message !== "Customer not found") {
            console.log('Verify/graduate customer success...')
            dispatch(showAlert(req?.data?.message, "success"))
            dispatch(action(VERIFY_GRADUATE_CUSTOMER_SUCCESS))
        } else {
            console.log('Verify/graduatecustomer failure...')
            dispatch(showAlert(req?.data?.message || "Operation Failed", "error"))
            dispatch(action(VERIFY_GRADUATE_CUSTOMER_ENDS))
        }

        return req;
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(VERIFY_GRADUATE_CUSTOMER_ENDS))
        dispatch(showAlert("Operation Failed", "error"))
    }
}
