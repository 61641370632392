import React, {Fragment} from "react";
import {connect} from "react-redux";
import {getCells, getDistricts, getProvinces, getSectors, getVillages} from "../../../actions/places";
import {Form, Row} from "@themesberg/react-bootstrap";
import {MDBRow} from "mdb-react-ui-kit";
import {GET_CELL, GET_DISTRICT, GET_SECTOR, GET_VILLAGE} from "../../../actions/types";

const SelectOptions = (props) => {
    const {required, name, onChange, ariaLabel, defaultValue, items, title, isLoading} = props;
    return (
        <Fragment>
            <Form.Select required={required === true} name={name}
                         onChange={onChange}
                         aria-label={ariaLabel} aria-disabled={isLoading} disabled={isLoading}
                         defaultValue={defaultValue}>
                <option value={''} selected={defaultValue == null}>:. {title}</option>
                {
                    items?.map((item) => (
                        <>
                            <option value={`${item.vcode}:${item.vname}`}
                                    selected={defaultValue === item.vcode}>
                                {item.vname}
                            </option>
                        </>
                    ))
                }
            </Form.Select>
        </Fragment>
    );
}

class RwandaBorders extends React.Component {
    state = {
        loading: false,
        province: null,
        district: null,
        sector: null,
        cell: null,
        village: null,
        provinceId: null,
        districtId: null,
        sectorId: null,
        cellId: null,
        villageId: null,
    }

    componentDidMount = async () => {
        this.setState({loading: true});
        const {getProvinces, provinceId, districtId, sectorId, cellId,} = this.props;
        await getProvinces();

        if (provinceId && this.state.provinceId === null) {
            await this.handleLoadingNewBorders({type: GET_DISTRICT, value: provinceId});
        }
        if (districtId && this.state.districtId === null) {
            await this.handleLoadingNewBorders({type: GET_SECTOR, value: districtId});
        }
        if (sectorId && this.state?.sectorId === null) {
            await this.handleLoadingNewBorders({type: GET_CELL, value: sectorId});
        }
        if (cellId && this.state?.cellId === null) {
            await this.handleLoadingNewBorders({type: GET_VILLAGE, value: cellId});
        }
        this.setState({loading: false});
    }

    handleChange = (e) => {
        const {handleChange} = this.props;
        handleChange(e);
        //
        const {name, value} = e.target;
        const _value = value.toString().split(':');
        let _name = value;
        let _id = null;
        if (_value.length > 0) {
            _name = _value[1] ?? null;
            _id = _value[0] ?? value;
        }
        this.setState(() => ({
            [name]: _name,
            [`${name}Id`]: _id,
        }));
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevState?.province !== this.state?.province) {
            await this.handleLoadingNewBorders({type: GET_DISTRICT, value: this.state.provinceId});
        }
        if (prevState?.district !== this.state?.district) {
            await this.handleLoadingNewBorders({type: GET_SECTOR, value: this.state.districtId});
        }
        if (prevState?.sector !== this.state?.sector) {
            await this.handleLoadingNewBorders({type: GET_CELL, value: this.state.sectorId});
        }
        if (prevState?.cell !== this.state?.cell) {
            await this.handleLoadingNewBorders({type: GET_VILLAGE, value: this.state.cellId});
        }
    }

    handleLoadingNewBorders = async (_target = {type: null, value: null}) => {
        this.setState({loading: true});
        if (_target.type === GET_DISTRICT && _target.value !== null) {
            await this.props.getDistricts(_target.value);
        }
        if (_target.type === GET_SECTOR && _target.value !== null) {
            await this.props.getSectors(_target.value);
        }
        if (_target.type === GET_CELL && _target.value !== null) {
            await this.props.getCells(_target.value);
        }
        if (_target.type === GET_VILLAGE && _target.value !== null) {
            await this.props.getVillages(_target.value);
        }
        this.setState({loading: false});
    }

    render() {
        const {
            province, district, sector, cell, village,
            provinces, districts, sectors, cells, villages,
        } = this.props;
        return (
            <Fragment>
                <MDBRow className={'mb-4'}>
                    <Row className="align-items-center col ">
                        <Form.Group id="province" aria-disabled={true}>
                            <Form.Label>Province</Form.Label>
                            <SelectOptions required={true}
                                           name="province"
                                           isLoading={this.state.loading}
                                           onChange={this.handleChange}
                                           targetedChild={GET_DISTRICT}
                                           ariaLabel="Default select province"
                                           defaultValue={province}
                                           ariaDisabled={this.state.loading}
                                           items={provinces} title={'Provinces'}/>
                        </Form.Group>
                    </Row>
                    <Row className="align-items-center col ">
                        <Form.Group id="district">
                            <Form.Label>District</Form.Label>
                            <SelectOptions required={true}
                                           name="district"
                                           isLoading={this.state.loading}
                                           onChange={this.handleChange}
                                           targetedChild={GET_SECTOR}
                                           ariaLabel="Default select District"
                                           defaultValue={district}
                                           ariaDisabled={this.state.loading}
                                           items={districts} title={'District'}/>
                        </Form.Group>
                    </Row>
                    <Row className="align-items-center col ">
                        <Form.Group id="sector">
                            <Form.Label>Sector</Form.Label>
                            <SelectOptions required={true}
                                           name="sector"
                                           isLoading={this.state.loading}
                                           onChange={this.handleChange}
                                           targetedChild={GET_CELL}
                                           ariaLabel="Default select sector"
                                           defaultValue={sector}
                                           ariaDisabled={this.state.loading}
                                           items={sectors} title={'Sectors'}/>
                        </Form.Group>
                    </Row>
                    <Row className="align-items-center col ">
                        <Form.Group id="cell">
                            <Form.Label>Cell</Form.Label>
                            <SelectOptions required={true}
                                           name="cell"
                                           isLoading={this.state.loading}
                                           onChange={this.handleChange}
                                           targetedChild={GET_VILLAGE}
                                           ariaLabel="Default select cell"
                                           defaultValue={cell}
                                           ariaDisabled={this.state.loading}
                                           items={cells} title={'Cells'}/>
                        </Form.Group>
                    </Row>
                    <Row className="align-items-center col ">
                        <Form.Group id="village">
                            <Form.Label>Village</Form.Label>
                            <SelectOptions required={true}
                                           name="village"
                                           isLoading={this.state.loading}
                                           onChange={this.handleChange}
                                           ariaLabel="Default select village"
                                           defaultValue={village}
                                           ariaDisabled={this.state.loading}
                                           items={villages} title={'Villages'}/>
                        </Form.Group>
                    </Row>
                </MDBRow>
            </Fragment>
        );
    }
}

const mapStateProps = (store) => {
    const {error, provinces, districts, sectors, cells, villages,} = store.places;
    return {error, provinces, districts, sectors, cells, villages,};
}
export default connect(mapStateProps, {
    getProvinces,
    getDistricts,
    getSectors,
    getCells,
    getVillages,
})(RwandaBorders);