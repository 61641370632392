import React, {Component} from "react";
import {Breadcrumb, Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {MDBRow} from "mdb-react-ui-kit";
import {BeatLoader} from "react-spinners";
import {connect} from "react-redux";
import {PARTNER_TYPES} from "../../data/tables";
import {createBank, updateBank, viewBank} from "../../actions/partners";
import Preloader from "../../components/Preloader";
import authorization from "../../helpers/authorization";
import useRoute from "../../helpers/useRoute";

class AddPartner extends Component {
    state = {
        isSubmitting: false, loading: false, types: [], bankCode: "", address: "", bankName: "", bankShort: "",
        tinNumber: "", phoneNumber: "", customerServicePhone: "", email: "", website: "",
        hasError: false, bankId: this.props.match.params.id ?? null, partnerType: "",
    };
    //
    componentDidMount = async () => {
        const param = this.props.match.params;
        this.setState({partnerType: param['code']});
        this.setState({types: PARTNER_TYPES});
        this.setState({loading: true,});
        const {bankId,} = this.state;
        const {viewBank,} = this.props;
        if (bankId) {
            await viewBank(bankId);
        }
        this.setState({loading: false,});

    }//
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {bankId, bankCode,} = prevState;
        const {bank,} = this.props;
        if (bankId && bank && bankCode === '') {
            this.setState({...bank,});
        }
    }

    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    submit = async (e) => {
        let save = false;
        e.preventDefault();
        const {
            bankCode, address, bankName, bankShort, tinNumber, phoneNumber,
            customerServicePhone, email, website, bankId,
        } = this.state;
        const {createBank, updateBank, history} = this.props;
        this.setState({isSubmitting: true,});
        if (bankId) {
            save = await updateBank({
                bankCode: parseInt(bankCode), address, bankName, bankShort, tinNumber, phoneNumber,
                customerServicePhone, email, website, id: parseInt(bankId),
            });
        } else {
            save = await createBank({
                bankCode: parseInt(bankCode),
                address,
                bankName,
                bankShort,
                tinNumber,
                phoneNumber,
                customerServicePhone,
                email,
                website,
            });
        }
        if (save) {
            history.push(Routes.Partners.path);
        }
        this.setState({isSubmitting: false,});
    }

    render() {
        const {
            loading, bankCode, bankName, address, bankShort, website, email, tinNumber, customerServicePhone,
            phoneNumber, bankId, isSubmitting, partnerType,
        } = this.state;
        //
        const {error, bank,} = this.props;
        const linkTo = useRoute;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={linkTo(Routes.Partners.path, {code: partnerType})}>{`${partnerType}`.toUpperCase()}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{!loading && bankId ? 'Update' : 'New'} Bank</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>{!loading && bankId ? `Update ${bank ? bank.bankName : partnerType}` : `Add New ${partnerType}`}</h4>
                    </div>
                </div>
                <Button variant="primary" href={linkTo(Routes.Partners.path, {code: partnerType})}
                        className="m-1">All {`${partnerType}`}</Button>
                <Card border="light" className="custom-class">
                    <Card.Header className={'py-3'}>
                        <h5 className="my-0 py-0">{!loading && bankId ? 'Update' : 'Add'} {partnerType} information</h5>
                    </Card.Header>
                    <Card.Body>
                        {error ? (<div className="text-danger pb-3">{error ?? "Error occur"}</div>) : ""}
                        {
                            loading ? (
                                <>
                                    <Row className="justify-content-md-center">
                                        <Col md="auto">
                                            <Preloader show={loading}/>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Form onSubmit={this.submit}>
                                        <MDBRow className={'mb-2'}>
                                            <Row className="col">
                                                <Form.Group id="bankName">
                                                    <Form.Label>Bank Name</Form.Label>
                                                    <Form.Control required type="text" name="bankName"
                                                                  onChange={this.handleChange}
                                                                  value={bankName}
                                                                  placeholder="Bank Name"/>
                                                </Form.Group>
                                            </Row>
                                        </MDBRow>
                                        <MDBRow className={'mb-2'}>
                                            <Row className="col">
                                                <Form.Group id="bankCode">
                                                    <Form.Label>Bank Code</Form.Label>
                                                    <Form.Control required type="text" name="bankCode"
                                                                  onChange={this.handleChange}
                                                                  value={bankCode}
                                                                  placeholder="Bank Code"/>
                                                </Form.Group>
                                            </Row>
                                            <Row className="col">
                                                <Form.Group id="bankShort">
                                                    <Form.Label>Bank Short</Form.Label>
                                                    <Form.Control required type="text" name="bankShort"
                                                                  onChange={this.handleChange}
                                                                  maxlength="10"
                                                                  value={bankShort}
                                                                  placeholder="Bank Short"/>
                                                </Form.Group>
                                            </Row>
                                        </MDBRow>
                                        <MDBRow className={'mb-2'}>
                                            <Row className="col">
                                                <Form.Group id="tinNumber">
                                                    <Form.Label>Tin Number</Form.Label>
                                                    <Form.Control required type="text" name="tinNumber"
                                                                  onChange={this.handleChange}
                                                                  value={tinNumber} placeholder="Tin Number"/>
                                                </Form.Group>
                                            </Row>
                                            <Row className="col">
                                                <Form.Group id="phoneNumber">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control required type="text" name="phoneNumber"
                                                                  onChange={this.handleChange}
                                                                  value={phoneNumber} placeholder="Phone Number"/>
                                                </Form.Group>
                                            </Row>
                                            <Row className="col">
                                                <Form.Group id="customerServicePhone">
                                                    <Form.Label>Customer Service Phone</Form.Label>
                                                    <Form.Control type="text" name="customerServicePhone"
                                                                  onChange={this.handleChange}
                                                                  value={customerServicePhone}
                                                                  placeholder="Customer Service Phone"/>
                                                </Form.Group>
                                            </Row>
                                        </MDBRow>
                                        <MDBRow className={'mb-2'}>
                                            <Row className="col">
                                                <Form.Group id="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control required type="text" name="email"
                                                                  onChange={this.handleChange}
                                                                  value={email} placeholder="Email"/>
                                                </Form.Group>
                                            </Row>
                                            <Row className="col">
                                                <Form.Group id="website">
                                                    <Form.Label>Website</Form.Label>
                                                    <Form.Control required type="text" name="website"
                                                                  onChange={this.handleChange}
                                                                  value={website} placeholder="website"/>
                                                </Form.Group>
                                            </Row>
                                        </MDBRow>
                                        <MDBRow className={'mb-2'}>
                                            <Row className="custom">
                                                <Form.Group id="address">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control required type="text" as="textarea" name="address"
                                                                  onChange={this.handleChange}
                                                                  value={address} placeholder="Address"/>
                                                </Form.Group>
                                            </Row>
                                        </MDBRow>

                                        <div className="mt-3">
                                            <Button variant="primary"
                                                    type="submit"
                                                    disabled={isSubmitting || !authorization("PA_CREATE")}>
                                                {isSubmitting ?
                                                    <BeatLoader color="#fff"/> : `${bankId ? 'Update' : 'Submit'}`}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            )
                        }
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {bank, error,} = state.partners;
    return {bank, error,};
}
export default connect(mapStateProps, {createBank, viewBank, updateBank,})(AddPartner);
