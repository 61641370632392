import React, {Component} from "react";
import {connect} from "react-redux";
import {getUserDetails, updateUserAction} from "../../actions/user";
import {Breadcrumb, Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/Preloader";
import {BeatLoader} from "react-spinners";
import authorization from "../../helpers/authorization";
import {getPartnerTypes} from "../../actions/partners";

class EditUser extends Component {
    state = {
        userId: this.props.match.params.id,
        hasError: false,
        loading: true,
        submitted: false,
        username: '',
        password: '',
        name: '',
        email: '',
        msisdn: '',
        userStatus: '',
        userpartnertyype: '',
        userid: '',
    };
    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    };
    //
    editUser = async (e) => {
        e.preventDefault();
        this.setState({submitted: true,});
        const {updateUserAction} = this.props;
        const {userid, userStatus, username, password, name, email, msisdn, userpartnertyype} = this.state;
        const update = await updateUserAction({
            userid,
            userStatus,
            username,
            password,
            name,
            email,
            msisdn,
            userpartnertyype,
        });
        if (update) {
            window.location = Routes.Users.path;
        }
        this.setState({loading: false});
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {userId} = this.state;
        const {getUserDetails, getPartnerTypes,} = this.props;
        await getPartnerTypes();
        const user = await getUserDetails(userId);
        if (user) {
            this.setState({
                userid: user.userid,
                username: user.username,
                name: user.name,
                surname: user.surname,
                email: user.email,
                msisdn: user.msisdn,
                userStatus: user.userStatus,
                userpartnertyype: user.userpartnertyype,
            });
        } else {
            this.setState({hasError: true});
        }
        this.setState({loading: false});
    }

    //
    render() {
        const {user, error, partnerTypes} = this.props;
        const {
            loading, submitted,
        } = this.state;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.Users.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Users.path}>List of all users</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit user</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>{!loading && user ? (<>User<span>: <b><u>{user && user.name} {user && user.surname}</u></b></span></>) : ("")}</h3>
                    </div>
                </div>
                {
                    loading ? (
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <Preloader show={loading}/>
                                </Col>
                            </Row>
                        ) :
                        (
                            <Row className="">
                                <Col md="12">
                                    <Card border="light" className="custom-class">
                                        <Card.Body>
                                            <h5 className="mb-4">Update users information</h5>
                                            {error ? (<div className="text-danger">{error}</div>) : ""}
                                            <Form onSubmit={this.editUser}>
                                                <Row>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group id="username">
                                                            <Form.Label>Username</Form.Label>
                                                            <Form.Control required type="text" name="username"
                                                                          onChange={this.handleChange} readOnly={true}
                                                                          value={user && user.username} id={'username'}
                                                                          placeholder="Enter username"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id="password">
                                                            <Form.Label>Names</Form.Label>
                                                            <Form.Control required type="text" name="name"
                                                                          onChange={this.handleChange}
                                                                          id={'name'} readOnly={submitted}
                                                                          defaultValue={user && user.name}
                                                                          placeholder="Enter Name"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id="password">
                                                            <Form.Label>Surname</Form.Label>
                                                            <Form.Control required type="text" name="surname"
                                                                          onChange={this.handleChange}
                                                                          id={'surname'} readOnly={submitted}
                                                                          defaultValue={user && user.surname}
                                                                          placeholder="Enter Surname"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group id="email">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control required type="email" name="email"
                                                                          onChange={this.handleChange}
                                                                          readOnly={submitted}
                                                                          defaultValue={user && user.email} id={'email'}
                                                                          placeholder="name@intelligra.io"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group id="phone">
                                                            <Form.Label>MSISDN</Form.Label>
                                                            <Form.Control required type="number" name="msisdn"
                                                                          onChange={this.handleChange}
                                                                          defaultValue={user && user.msisdn}
                                                                          id={'msisdn'} readOnly={submitted}
                                                                          placeholder="+250 788 327 447"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className={''}>
                                                    <Col md={6} className={'mb-3'}>
                                                        <Form.Group id={'userStatus'}>
                                                            <Form.Label>User Status</Form.Label>
                                                            <Form.Select required name={'userStatus'}
                                                                         onChange={this.handleChange}
                                                                         aria-label="Select user status">
                                                                <option value="" disabled={true}
                                                                        selected={true}>Select:.
                                                                </option>
                                                                <option value="active"
                                                                        selected={user && user.userStatus === 'active'}>Active
                                                                </option>
                                                                <option value="blocked"
                                                                        selected={user && user.userStatus === 'blocked'}>Blocker
                                                                </option>
                                                                <option value="onleave"
                                                                        selected={user && user.userStatus === 'onleave'}>On
                                                                    Leave
                                                                </option>
                                                                <option value="suspended"
                                                                        selected={user && user.userStatus === 'suspended'}>Suspended
                                                                </option>
                                                                <option value="sick"
                                                                        selected={user && user.userStatus === 'sick'}>Sick
                                                                </option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className={"mb-3"}>
                                                        <Form.Group id="password">
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control type="password" name="password"
                                                                          id={'password'} readOnly={submitted}
                                                                          onChange={this.handleChange} defaultValue=""
                                                                          placeholder="Enter password or leave it blank."/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className={''}>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group id="email">
                                                            <Form.Label>User Types</Form.Label>
                                                            <Form.Select name={'userpartnertyype'}
                                                                         onChange={this.handleChange}
                                                                         aria-label="Select user type"
                                                                         required={true}>
                                                                <option value={""}>Types :.</option>
                                                                {
                                                                    (user.userpartnertyype === 'representative') ? (
                                                                        <>
                                                                            <option value={user.userpartnertyype}
                                                                                    selected>{user.userpartnertyype}</option>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <option value={"I"}
                                                                                    selected={user.userpartnertyype === "I"}>INTELLIGRA
                                                                            </option>
                                                                            {
                                                                                (partnerTypes ?? []).map((item) => (
                                                                                    <option
                                                                                        value={`${item.typeCode}`}
                                                                                        selected={user.userpartnertyype === item.typeCode}>
                                                                                        {item.typeName}
                                                                                    </option>)
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="mt-3">
                                                    <Button variant="primary" type="submit"
                                                            disabled={loading || submitted || !authorization('US_EDIT')}>
                                                        {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                }
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {user,} = state.users;
    const {data, error,} = state.createUser;
    const {partnerTypes,} = state.partners;
    //
    return {
        user, error, data, partnerTypes,
    }
}
export default connect(mapStateProps, {getUserDetails, updateUserAction, getPartnerTypes,})(EditUser);
