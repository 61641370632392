import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Form, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {BeatLoader} from "react-spinners";
import authorization from "../../helpers/authorization";

class ImportDeviceTypes extends Component {
    state = {}
    //
    componentDidMount = async () => {
    }
    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    submit = async () => {

    }

    //
    render() {
        const {loading} = this.state;
        const {error} = this.props;

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Devices.path}>Devices</Breadcrumb.Item>
                            <Breadcrumb.Item active>Import</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Import Device List</h4>
                    </div>
                </div>
                <Button variant="primary" href={Routes.Devices.path} className="m-1">All device</Button>
                <Card border="light" className="custom-class">
                    <Card.Body>
                        <h5 className="mb-4">Upload Devices Type</h5>
                        {error ? (<div className="text-danger">{error}</div>) : ""}
                        <Form onSubmit={this.submit}>
                            <Row className="align-items-center col ">
                                <Form.Group id="file">
                                    <Form.Label>Excel File</Form.Label>
                                    <Form.Control required type="file" name="file" onChange={this.handleChange}
                                                  placeholder="Upload excel file"/>
                                </Form.Group>
                            </Row>
                            <div className="mt-3">
                                <Button variant="primary" type="submit"
                                        disabled={loading || !authorization("DI_CREATE")}>
                                    {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    return {};
};

export default connect(mapStateProps, {})(ImportDeviceTypes);
