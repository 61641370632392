import React, {Component} from "react";
import {
    Alert,
    Breadcrumb,
    Button,
    Card,
    Col,
    Form,
    InputGroup,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import authorization from "../../helpers/authorization";
import {BeatLoader} from "react-spinners";
import {connect} from "react-redux";
import {getLoanRepayments, submitLoanRepayment, updateLoanRepayment} from "../../actions/loans";

const TableRow = (props) => {
    const {msisdn, loanId, bankloanId, bankloanAccount, amount, status, index, isAuthorized, onClick} = props;
    return (
        <tr>
            <td>{index}</td>
            <td>{msisdn}</td>
            <td>{loanId}</td>
            <td>{bankloanId}</td>
            <td>{bankloanAccount}</td>
            <td>{amount?.toLocaleString()} FRW</td>
            <td>{status}</td>
            {isAuthorized && (<td>
                <Button className={"btn btn-sm"} onClick={onClick}>Approve</Button>
            </td>)}
        </tr>
    )
}


class LoanRepayment extends Component {
    state = {
        loading: true,
        msisdn: null,
        amount: null,
        isSubmit: false,
        searchQuery: '',
    };

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getLoanRepayments} = this.props;
        await getLoanRepayments();
        this.setState({loading: false,});
    }

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }

    onSubmitLoanRepayment = async (e) => {
        e.preventDefault();
        this.setState({loading: true,});
        const {submitLoanRepayment, getLoanRepayments} = this.props;
        if (this.state.msisdn !== null && this.state.amount !== null) {
            await submitLoanRepayment({
                msisdn: this.state.msisdn,
                amount: this.state.amount,
            });
            await getLoanRepayments();
            this.setState({msisdn: null, amount: null, isSubmit: true});
        }
        this.setState({loading: false,});
    }

    onUpdateLoanRepayment = async (msisdn) => {
        const {updateLoanRepayment, getLoanRepayments} = this.props;
        this.setState({loading: true,});
        if (window.confirm("Are you sure?") === true) {
            await updateLoanRepayment({
                msisdn,
            });
            await getLoanRepayments();
        }
        this.setState({loading: false, isSubmit: true});
    }

    render() {
        const {loading, amount, msisdn, isSubmit, searchQuery} = this.state;
        const {loans, error, response} = this.props;
        let filteredLoans = [];
        if (loans && loans.length > 0) {
            filteredLoans = loans.filter((loan) =>
                loan.msisdn.toLowerCase().includes(searchQuery.toLowerCase()) ||
                loan.loanId.toString().includes(searchQuery) ||
                loan.bankloanId.toString().includes(searchQuery) ||
                loan.bankloanAccount.toString().includes(searchQuery)
            );
        }

        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.LoanRepayment.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Loan Repayment</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Loan Repayment</h4>
                    </div>
                </div>
                {authorization("REPAYMENT_CREATE") && (
                    <Card border="light" className="custom-class able-wrapper shadow-sm">
                        <Card.Body className="">
                            <h5 className="mb-4">Repayment Form</h5>
                            <Form onSubmit={this.onSubmitLoanRepayment}>
                                <Row className="align-items-center col ">
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="msisdn"
                                            onChange={this.handleChange}
                                            defaultValue={msisdn ?? ""}
                                            placeholder="Type Msisdn..."
                                            required
                                        />
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                name="amount"
                                                onChange={this.handleChange}
                                                defaultValue={amount ?? ""}
                                                placeholder="Enter Amount"
                                                required
                                            />
                                            <Button variant="primary"
                                                    type="submit"
                                                    disabled={loading || !authorization("CC_CREATE")}>
                                                {loading ? <BeatLoader color="#fff"/> : "Submit"}
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className={"mt-2"}>
                                    <Col>
                                        {(isSubmit && error) && <Alert variant="danger" className="p-2">{error}</Alert>}
                                        {(isSubmit && response) &&
                                            <Alert variant="success" className="p-2">{response}</Alert>}
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                )}

                <Card border="light" className="custom-class able-wrapper table-responsive shadow-sm">
                    <Card.Body className="">
                        <h5 className="mb-4">Pending Loan Repayment List</h5>
                        <Row className="align-items-center col mb-4">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="searchQuery"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.searchQuery}
                                    placeholder="Search..."
                                    disabled={loading}
                                />
                            </Col>
                        </Row>
                        <Table responsive striped className="table-centered table-bordered table-wrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Msisdn</th>
                                <th className="">LoanId</th>
                                <th className="">BankLoanId</th>
                                <th className="">BankLoanAccount</th>
                                <th className="">amount</th>
                                <th className="">Status</th>
                                {authorization("REPAYMENT_UPDATE") && (<th className="">Action</th>)}
                            </tr>
                            </thead>
                            <tbody>

                            {loading && (<tr>
                                <td colSpan="8" align="center"><BeatLoader color="#000" size="8"/></td>
                            </tr>)}
                            {(!loading && filteredLoans?.length > 0) && (
                                filteredLoans?.map((loan, i) => (
                                    <TableRow
                                        key={i}
                                        {...loan}
                                        index={i + 1}
                                        isAuthorized={authorization("REPAYMENT_UPDATE")}
                                        onClick={() => {
                                            this.onUpdateLoanRepayment(loan?.msisdn);
                                        }}
                                    />
                                ))
                            )}
                            {(!loading && (filteredLoans === undefined || filteredLoans?.length <= 0)) && (<tr>
                                <td colSpan={8}>No Pending loan repayment</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {loans, error, responses} = state.loans;
    return {loans, error, responses};
}

export default connect(mapStateProps, {
    getLoanRepayments,
    submitLoanRepayment,
    updateLoanRepayment,
})(LoanRepayment);