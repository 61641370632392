import { action } from "../index";
import { apiCall2 } from "../../utils/apiCall";
import {
  READ_PENDING_TRANSACTIONS_ENDS,
  READ_PENDING_TRANSACTIONS_START,
  READ_TRANSACTION_STATUS_ENDS,
  READ_TRANSACTION_STATUS_START,
  READ_TRANSACTION_STATUS_SUCCESS,
  RESOLVE_COMMON_ISSUES_ENDS,
  RESOLVE_COMMON_ISSUES_START,
  RESOLVE_COMMON_ISSUES_SUCCESS,
  RESOLVE_MISSING_RECORDS_ENDS,
  RESOLVE_MISSING_RECORDS_START,
  RESOLVE_MISSING_RECORDS_SUCCESS,
  RESOLVE_PAYMENT_FETCH_ENDS,
  RESOLVE_PAYMENT_FETCH_START,
  RESOLVE_PAYMENT_FETCH_SUCCESS,
  RESOLVE_DEVICE_DETAILS_START,
  RESOLVE_DEVICE_DETAILS_SUCCESS,
  RESOLVE_DEVICE_DETAILS_ENDS,
} from "../types";
import { showAlert } from "../apiresponse/AlertAction";
import { getToken, getUser } from "../../helpers/tokenHelper";
import { formatDate } from "../../utils/format";

// Common Issues
export const ResolveCommonFix = (payload) => async (dispatch) => {

  try {
    const token = getToken();
    const user = getUser();
    const headers = {
      Authorization: "Bearer ".concat(token),
    };



    dispatch(action(RESOLVE_COMMON_ISSUES_START));

    let req;

    switch (payload?.operation) {
      case "Send Popup":
        req = await apiCall2.get(
          `Customers/fix_send_popup?msisdn=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      case "Active Plan":
        console.log("user", user);
        req = await apiCall2.get(
          `Misc/ResolveActivationPlan?phoneNumber=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      case "Insurance Contract":
        req = await apiCall2.get(
          `Customers/get_insurance_contract?msisdn=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      case "Initial Payment - Sideloading":
        req = await apiCall2.get(
          `Customers/fix_first_payment_sideloading?msisdn=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      case "Update National ID":
        req = await apiCall2.get(
          `Customers/clear_customer?msisdn=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      
        case "Temporary Extension":
          req = await apiCall2.post(
            `Customers/temporary_extension`, {msisdn: payload?.msisdn, username: user?.username},
            {
              headers,
            }
          );
          break;
    
      
        default:
        req = "Looking forward to the Weekend";
    }

    dispatch(action(RESOLVE_COMMON_ISSUES_SUCCESS));
    // dispatch(
    //   showAlert(
    //     req?.data?.message ||
    //       "Looks like this issue has been fixed. Try re-onboard to confirm",
    //     "success"
    //   )
    // );

    dispatch(action(RESOLVE_COMMON_ISSUES_ENDS));

    return req;
  } catch (e) {
    console.log("Error occurred...");
    dispatch(action(RESOLVE_COMMON_ISSUES_ENDS));
    dispatch(showAlert("Error while resolving issue!", "error"));
  }
};

export const ReadPendingTransactions = (payload) => async (dispatch) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: "Bearer ".concat(token),
    };


    dispatch(action(READ_PENDING_TRANSACTIONS_START));

    let req;

    switch (payload?.operation) {
      case "BK":
        req = await apiCall2.get(
          `Transaction/get_pending_bank_transaction?start=${formatDate(
            payload?.startDate
          )}&end=${formatDate(payload?.endDate)}`,
          {
            headers,
          }
        );
        break;
      case "INTELLIGRA":
        req = await apiCall2.get(
          `Transaction/get_pending_intelligra_transaction?start=${formatDate(
            payload?.startDate
          )}&end=${formatDate(payload?.endDate)}`,
          { phoneNumber: payload?.msisdn },
          {
            headers,
          }
        );
        break;
      default:
        req = "";
    }

    dispatch(action(READ_TRANSACTION_STATUS_SUCCESS));
    dispatch(action(READ_PENDING_TRANSACTIONS_ENDS));

    return req;
  } catch (e) {
    console.log("Error occurred...");
    dispatch(action(READ_PENDING_TRANSACTIONS_ENDS));
    dispatch(showAlert("Error while fetching pending transactions!", "error"));
  }
};

//Fetch Transaction Status
export const ReadTransactionStatus = (payload) => async (dispatch) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: "Bearer ".concat(token),
    };



    dispatch(action(READ_TRANSACTION_STATUS_START));

    const req = await apiCall2.get(
      `Transaction/get_transaction_status_analysis?start=${formatDate(
        payload?.startDate
      )}&end=${formatDate(payload?.endDate)}`,
      {
        headers,
      }
    );

    dispatch(action(READ_TRANSACTION_STATUS_SUCCESS));

    dispatch(action(READ_TRANSACTION_STATUS_ENDS));

    return req;
  } catch (e) {
    console.log("Error occurred...");
    dispatch(action(READ_TRANSACTION_STATUS_ENDS));
    dispatch(showAlert("Error while fetching transaction status", "error"));
  }
};

//Missing Customer Records
export const ResolveMissingRecords = (payload) => async (dispatch) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: "Bearer ".concat(token),
    };

    console.log(payload);

    dispatch(action(RESOLVE_MISSING_RECORDS_START));
    console.log("starting...");

    const req = await apiCall2.get(
      `Transaction/update_missing_customer_record?start=${formatDate(
        payload?.startDate
      )}&end=${formatDate(payload?.endDate)}`,
      {
        headers,
      }
    );

    dispatch(action(RESOLVE_MISSING_RECORDS_SUCCESS));

    dispatch(action(RESOLVE_MISSING_RECORDS_ENDS));

    return req;
  } catch (e) {
    console.log("Error occurred...");
    dispatch(action(RESOLVE_MISSING_RECORDS_ENDS));
    dispatch(
      showAlert("Error while waiting to update missing records", "error")
    );
  }
};

export const ResolvePaymentFetch = (payload) => async (dispatch) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: "Bearer ".concat(token),
    };

    console.log("check-payload", payload);
    dispatch(action(RESOLVE_PAYMENT_FETCH_START));
    console.log("starting...");
    let req;

    switch (payload?.operation) {
      case "Total Loan Payments":
        req = await apiCall2.get(
          `Customers/total_loan_payment?msisdn=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      case "Total Transactions":
        req = await apiCall2.get(
          `Customers/total_transaction?msisdn=${payload?.msisdn}`,
          {
            headers,
          }
        );
        break;
      default:
        req = "Looking forward to the Weekend";
    }

    dispatch(action(RESOLVE_PAYMENT_FETCH_SUCCESS));
    // dispatch(
    //   showAlert(
    //     req?.data?.message ||
    //       "Looks like this issue has been fixed. Try re-onboard to confirm",
    //     "success"
    //   )
    // );

    dispatch(action(RESOLVE_PAYMENT_FETCH_ENDS));

    return req;
  } catch (e) {
    console.log("Error occurred...");
    dispatch(action(RESOLVE_PAYMENT_FETCH_ENDS));
    dispatch(showAlert("Error while resolving issue!", "error"));
  }
};

export const ResolveDeviceDetails = (payload) => async (dispatch) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: "Bearer ".concat(token),
    };

    console.log("check-payload", payload);
    dispatch(action(RESOLVE_DEVICE_DETAILS_START));
    console.log("starting...");

    const req = await apiCall2.get(
      `Customers/device_detail?imei=${payload?.imei}`,
      {
        headers,
      }
    );

    dispatch(action(RESOLVE_DEVICE_DETAILS_SUCCESS));

    dispatch(action(RESOLVE_DEVICE_DETAILS_ENDS));

    return req;
  } catch (e) {
    console.log("Error occurred...");
    dispatch(action(RESOLVE_DEVICE_DETAILS_ENDS));
    dispatch(showAlert("Error while resolving issue!", "error"));
  }
};
