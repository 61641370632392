export const dateNow = () => {
    return new Date();
}

export const addDaysToNow = (days = 0) => {
    let myFutureDate = new Date(dateNow());
    myFutureDate.setDate(myFutureDate.getDate() + days);
    return myFutureDate;
}

export const getCurrentMonth = () => {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return [firstDay, lastDay];
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getStringMonthFromDate(date, current = 0) {
    const inputDate = new Date(date);
    const startYear = inputDate.getFullYear();
    const startMonth = inputDate.getMonth();
    const months = [];
    for (let month = 0; month < startMonth + 12; month++) {
        const currentYear = Math.floor((startMonth + month) / 12) + startYear;
        const currentMonth = (startMonth + month) % 12;
        const currentDate = new Date(currentYear, currentMonth, 1);
        const monthName = currentDate.toLocaleString('en-US', {month: 'long'});
        months.push(`${monthName} ${currentYear}`);
    }
    return months[current];
}

export function isDueDate(subscription = ""){
    const dueDate = new Date(subscription);
    const currentDate = new Date();
    // Check if today is the due date
    return currentDate >= dueDate;
}

