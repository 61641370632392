import React, {Component} from "react";
import {Badge, Card, Col, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import {Capitalize, Split} from "react-lodash";

const StatusBadge = (props) => {
    const {status} = props;
    let bg = "light";
    switch (status.toLowerCase()) {
        case 'pending':
            bg = "secondary";
            break;
        case 'open':
            bg = 'info'
            break;
        case 'closed':
            bg = 'success'
            break;
        case 'attended':
            bg = 'primary'
            break;

    }
    return (
        <>
            <Badge pill bg={bg} className={'py-1'}>{status}</Badge>
        </>
    );
}

class TicketCard extends Component {
    state = {
        loading: true,
    }
    //
    componentDidMount = async () => {
    }

    render() {
        const {ticket,} = this.props;
        //
        return (
            <>
                <Col md={6} className={'mb-4'} onClick={this.props.onClick}>
                    <Card className={'cursor-pointer'} onClick={() => {
                    }}>
                        <Card.Header className={'py-2'}>
                            <Row>
                                <Col className={'text-md'}>
                                    <StatusBadge status={ticket.status.toLocaleString()}/>
                                    &emsp;<span>Ticket: #<Split string={ticket.id} separator={'-'} limit={1}/></span>
                                </Col>
                                <Col md={4} className={'pull-right text-right text-md'}>
                                    <small>{(new Date(ticket.createdAt)).toLocaleString()}</small>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Subtitle className="mb-2"><Capitalize string={ticket.title}/></Card.Subtitle>
                            <Card.Text>{ticket.description}</Card.Text>
                        </Card.Body>
                        <Card.Footer className={'py-2'}>
                            <Row>
                                <Col
                                    className={'text-md'}>
                                    <Capitalize
                                        string={ticket.customer ? `${ticket.customer.firstName} ${ticket.customer.lastName}` : ""}/>
                                </Col>
                                <Col md={4} className={'pull-right text-right text-md'}>
                                    <span><FontAwesomeIcon
                                        icon={faComments}/> {ticket.messages ? ticket.messages.length : [].length}</span>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </>
        );
    }
}

export default TicketCard;