import React, {Component} from "react";
import {connect} from "react-redux";
import {Routes} from "../../../routes";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import {getAllPermissions} from "../../../actions/roles";
import authorization from "../../../helpers/authorization";

const TableRow = (props) => {
    const {
        roleCode, roleName, description, rolestatus, index,
    } = props;

    return (
        <tr valign={'middle'}>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className="fw-bold">{roleName}</td>
            <td className="fw-bold">{roleCode}</td>
            <td>{description}</td>
            <td>{rolestatus ? "Active" : "Inactive"}</td>
        </tr>
    );
};

class Roles extends Component {
    state = {
        loading: false,
    }
    //
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getAllPermissions} = this.props;
        await getAllPermissions();
        this.setState({loading: false});
    }

    //
    render() {
        const {roles,} = this.props;
        const {loading,} = this.state;
        return (
            <>
                {
                    authorization("RO_CREATE") && false ? (
                        <>
                            <Button variant="primary" href={Routes.CreateRole.path} className="m-1">New Role</Button>
                        </>
                    ) : (<></>)
                }
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive
                               striped
                               className="table-centered table-striped table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Role Name</th>
                                <th className="">Role Code</th>
                                <th className="">Description</th>
                                <th className="">Role Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="5" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : roles != null && roles.length > 0 ?
                                    roles.map((role, i) => <TableRow {...role} key={`page-visit-${i}`}
                                                                     index={++i}/>) : (
                                        <tr>
                                            <td colSpan="5" align="center">No roles available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {roles, error,} = state.roles;
    return {roles, error,};
}
//
export default connect(mapStateProps, {getAllPermissions})(Roles);
