import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFilter} from "@fortawesome/free-solid-svg-icons";
import {getDeviceTypeCategory} from "../../../actions/devices";

class Filters extends React.Component {

    state = {
        loading: false,
        selectedCategory: null,
    }

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {getDeviceTypeCategory,} = this.props;
        await getDeviceTypeCategory();
        this.setState({loading: false,});
    }

    render() {
        const {loading, selectedCategory,} = this.state;
        const {categories, setSelectedCategory,} = this.props;
        //
        return (
            <Fragment>
                <Dropdown>
                    <Dropdown.Toggle as={Button} variant="primary">
                        <FontAwesomeIcon icon={faFilter}
                                         className="me-2"/> {selectedCategory?.categoryName ?? `Categories`}
                        <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                        <Dropdown.Item onClick={() => {
                            this.setState({selectedCategory: null,})
                            setSelectedCategory(null)
                        }}>
                            Reset
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        {
                            !loading && categories?.length ? (
                                categories.map((category, i) => (
                                    <Dropdown.Item onClick={() => {
                                        this.setState({selectedCategory: category,});
                                        setSelectedCategory(category);
                                    }}>
                                        {category.categoryName}
                                    </Dropdown.Item>
                                ))
                            ) : (<></>)
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </Fragment>
        );
    }
}

const mapStateProps = (store) => {
    const {categories, error,} = store.device;
    return {categories, error,};
}
export default connect(mapStateProps, {getDeviceTypeCategory})(Filters);