import React, {Component} from "react";
import {Button, Card, Col, Row, Table} from "@themesberg/react-bootstrap";
import {connect} from "react-redux";
import ModalRep from "./ModalRep";
import {getRepresentativesInStore, removeRepFromStore} from "../../../actions/stores";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import authorization from "../../../helpers/authorization";

const TableRow = (props) => {
    //
    const {
        index, username, name, surname, email, msisdn, nid, gender, startingDate,
        detach, repId,
    } = props;
    //
    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className="fw-bold">
                {`${name} ${surname}`}
            </td>
            <td>{`${username}`}</td>
            <td>{`${email}`}</td>
            <td>{`${gender}`}</td>
            <td>{`${msisdn}`}</td>
            <td>{`${nid}`}</td>
            <td>{`${startingDate}`}</td>
            <td>
                {
                    authorization("SR_DELETE") ? (
                        <>
                            <Button variant="light" size="sm"
                                    onClick={() => detach(repId)}>
                                <FontAwesomeIcon icon={faMinusCircle}
                                                 className={`icon icon-xs text-warning cursor-pointer`}/>
                            </Button>
                        </>
                    ) : (<></>)
                }
            </td>
        </tr>
    );
};

export class StoreRepresentative extends Component {
    state = {
        loading: true,
        showModal: false,
    };

    //
    handleClose = () => {
        this.setState({showModal: false,});
    }
    //
    handleShow = () => {
        this.setState({showModal: true,});
    }

    //
    removeRepFromStore = async (rep) => {
        if (window.confirm("Are you sure?") === true) {
            this.setState({loading: true});
            const {removeRepFromStore, getRepresentativesInStore, handleClose, storeId} = this.props;
            const detach = await removeRepFromStore(storeId, rep);
            if (detach) {
                await getRepresentativesInStore(storeId);
                this.setState({loading: false});
                handleClose();
            }
            this.setState({loading: false});
        }
    }

    componentDidMount = async () => {
        this.setState({loading: true});
        const {getRepresentativesInStore, storeId} = this.props;
        await getRepresentativesInStore(storeId);
        this.setState({loading: false});
    }

    render() {
        const {loading, showModal} = this.state;
        const {representatives, storeId} = this.props;
        //
        return (
            <>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body>
                        <Row className={''}>
                            <Col md={12}>
                                <div className="mb-4">
                                    {
                                        authorization("SR_CREATE") ? (
                                            <>
                                                <Button variant="primary" onClick={this.handleShow} size="sm">
                                                    Attach Representative
                                                </Button>
                                                {
                                                    showModal ? (<ModalRep storeId={storeId} show={showModal}
                                                                           handleClose={this.handleClose}/>) : ("")
                                                }
                                            </>
                                        ) : (<></>)
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className={''}>
                            <Col md={12}>
                                <Table striped className="table-centered table-bordered table-nowrap rounded">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="">#</th>
                                        <th className="">Name</th>
                                        <th className="">Username</th>
                                        <th className="">Email</th>
                                        <th className="">Gender</th>
                                        <th className="">MSISDN</th>
                                        <th className="">NID</th>
                                        <th className="">Starting Date</th>
                                        <th className="">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="9" align="center"><BeatLoader color="#000" size="8"/></td>
                                        </tr>
                                    ) : (
                                        representatives && representatives.length > 0 ? (
                                            representatives.map((representative, index) =>
                                                <TableRow {...representative} index={++index}
                                                          detach={this.removeRepFromStore}/>)
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className={"text-center"}>
                                                    There is no representative in this store !
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            </>
        );
    }
}

/**
 * @param state
 * @returns {{representatives, error}}
 */
const mapStateProps = (state) => {
    const {representatives, error} = state.store;
    return {representatives, error};
}

export default connect(mapStateProps, {getRepresentativesInStore, removeRepFromStore})(StoreRepresentative);
