import {
    SINGLE_MANUAL_REPAYMENT_STARTS,
    SINGLE_MANUAL_REPAYMENT_ENDS,
    MULTIPLE_MANUAL_REPAYMENT_STARTS,
    MULTIPLE_MANUAL_REPAYMENT_ENDS,
    CHECK_MANUAL_REPAYMENT_STATUS_STARTS,
    CHECK_MANUAL_REPAYMENT_STATUS_ENDS,

    SINGLE_CUSTOMER_PAYMENT_STARTS,
    SINGLE_CUSTOMER_PAYMENT_ENDS,
    MULTIPLE_CUSTOMER_PAYMENT_STARTS,
    MULTIPLE_CUSTOMER_PAYMENT_ENDS,
    FETCH_CUSTOMER_PAYMENT_LOGS_STARTS,
    FETCH_CUSTOMER_PAYMENT_LOGS_ENDS,
    FETCH_CUSTOMER_PAYMENT_LOGS_SUCCESS,

    UPDATE_CUSTOMER_PAYMENT_LOGS_STARTS,
    UPDATE_CUSTOMER_PAYMENT_LOGS_SUCCESS,
    UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS,

    CHECK_CUSTOMER_PAYMENT_LOG_STARTS,
    CHECK_CUSTOMER_PAYMENT_LOG_SUCCESS,
    CHECK_CUSTOMER_PAYMENT_LOG_ENDS
} from "../../actions/types";

const initiate = {
    error: null,
    loading: false,
    customerPaymentLogs: [],
    singleCustomerPaylog: {}
}

export const ManualRepaymentReducer = (state = initiate, action) => {
    console.log('reducer-action', action)
    switch (action.type) {
        // Single Manual Repayment 
        case SINGLE_MANUAL_REPAYMENT_STARTS:
            return {
                ...state,
                loading: true
            }
        case SINGLE_MANUAL_REPAYMENT_ENDS:
            return {
                ...state,
                loading: false
            }

        // Multiple manual repayment 
        case MULTIPLE_MANUAL_REPAYMENT_STARTS:
            return {
                ...state,
                loading: true
            }
        case MULTIPLE_MANUAL_REPAYMENT_ENDS:
            return {
                ...state,
                loading: false
            }

        // Check manual repayment status 
        case CHECK_MANUAL_REPAYMENT_STATUS_STARTS:
            return {
                ...state,
                loading: true
            }
        case CHECK_MANUAL_REPAYMENT_STATUS_ENDS:
            return {
                ...state,
                loading: false
            }

        // Single customer Paylog 
        case SINGLE_CUSTOMER_PAYMENT_STARTS:
            return {
                ...state,
                loading: true
            }
        case SINGLE_CUSTOMER_PAYMENT_ENDS:
            return {
                ...state,
                loading: false,
            }

        // Multiple customer Paylog 
        case MULTIPLE_CUSTOMER_PAYMENT_STARTS:
            return {
                ...state,
                loading: true
            }
        case MULTIPLE_CUSTOMER_PAYMENT_ENDS:
            return {
                ...state,
                loading: false,
            }

        // Fetch customer paylog 
        case FETCH_CUSTOMER_PAYMENT_LOGS_STARTS:
            return {
                ...state,
                loading: true
            }
        case FETCH_CUSTOMER_PAYMENT_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                customerPaymentLogs: action.payload
            }
        case FETCH_CUSTOMER_PAYMENT_LOGS_ENDS:
            return {
                ...state,
                loading: false,
                customerPaymentLogs: []
            }

        // Update customer paylog 
        case UPDATE_CUSTOMER_PAYMENT_LOGS_STARTS:
            return {
                ...state,
                loading: true
            }
        case UPDATE_CUSTOMER_PAYMENT_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS:
            return {
                ...state,
                loading: false,
            }

        // Check customer paylog status 
        case CHECK_CUSTOMER_PAYMENT_LOG_STARTS:
            return {
                ...state,
                loading: true
            }
        case CHECK_CUSTOMER_PAYMENT_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                singleCustomerPaylog: action.payload
            }
        case CHECK_CUSTOMER_PAYMENT_LOG_ENDS:
            return {
                ...state,
                loading: false,
                singleCustomerPaylog: {}
            }
        // Customer Paylog  - ends

        default:
            return {
                ...state,
            }
    }
}
