import React, {Component} from "react";
import {Button, Card, Col, Form, Image, Modal, Row} from "@themesberg/react-bootstrap";
import Profile3 from "../../../assets/img/team/profile-picture-10.png";
import {Split} from "react-lodash";

const Message = (props) => {
    const {content, createdAt, author,} = props;
    const justify = author ? author.role !== "CUSTOMER" ? "end" : "start" : 'start'; // end
    return (
        <>
            <Row className={`justify-content-${justify} mb-2`}>
                <Col md={8}>
                    <Card>
                        <Card.Body className={'py-2 px-2'}>
                            <Row className={''}>
                                <Col md={'2'} className={'d-flex justify-content-start align-items-center pl-1 py-0'}>
                                    <Image src={Profile3} roundedCircle={true} className={''} loading={'lazy'}
                                           width={50}/>
                                </Col>
                                <Col md={'10'} className={''}>
                                    <p>{content}</p>
                                    <div
                                        className={'text-xsm text-right'}>{(new Date(createdAt)).toLocaleString()}</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

class ViewTicket extends Component {
    state = {
        show: false,
        loading: false,
        messages: this.props.ticket ? (this.props.ticket.messages ?? []) : [],
        comment: "",
    }
    //
    componentDidMount = () => {

    }
    //
    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState(() => ({
            [name]: value,
        }));
    }
    //
    submit = async (e) => {
        e.preventDefault();
        this.setState({loading: true,})
        const {replyTickets, ticket, user} = this.props;
        const {messages, comment} = this.state;
        const send = await replyTickets({
            content: comment,
            authorId: user.id,
            ticketId: ticket.id,
        });
        if (send) {
            this.setState({messages: messages.push(send), loading: true,});
        }
        this.setState({loading: false,})
    }

    //
    render() {
        const {messages, loading} = this.state;
        const {ticket, handleClose, show} = this.props;
        //
        return (
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size={'lg'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title><span>Ticket: #<Split string={ticket.id} separator={'-'}
                                                           limit={1}/></span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            messages.map((message, i) => <Message {...message}/>)
                        }
                    </Modal.Body>
                    <Modal.Footer className={'content-left'}>
                        <Form className={'w-100'}>
                            <Row>
                                <Col md={10}>
                                    <Form.Group>
                                        <Form.Control as="textarea" placeholder={'Message ...'}
                                                      onChange={this.handleChange} name={'comment'}
                                                      className={'w-100'} required
                                                      size={'md'}/>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className={'d-flex justify-content-center align-items-center'}>
                                    <Button variant="primary"
                                            className={'btn-block'}
                                            onClick={this.submit}
                                            disabled={loading}>Send</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ViewTicket;
