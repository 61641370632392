import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Card, Form, FormControl, InputGroup, Table } from '@themesberg/react-bootstrap';
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Routes } from "../../routes";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import authorization from "../../helpers/authorization";
// import Filters from "./components/Filters";
import { fecthCustomerPaymentLogs } from '../../actions/manual_repayments';
import {
    FcDeleteDatabase
} from "react-icons/fc"
import ModalComponent from '../../components/modal/ModalComponent';
import ConfirmModalCustomerLog from './ConfirmModalCustomerLog';


const TableRow = ({ customer, setShowModal, setActionType, setActiveRow, index }) => {


    return (
        <tr>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td>
                {customer?.fromMsisdn || "msisdn"}
            </td>
            <td>{customer?.customer?.loanid}</td>
            <td>{customer?.totalAmount}</td>
            <td>{customer?.usedAmount}</td>
            <td>{customer?.status === "REJECT" ? "REJECTED" : customer?.status}</td>
            <td>{customer?.datePaid}</td>
            <td>{customer?.reference}</td>
            <td>{customer?.paymentMethod}</td>
            <td>{customer?.userID}</td>
            <td>
                {
                    authorization("DT_EDIT") && customer?.status === "PENDING" ? (

                        <div>
                            <span
                                title='APPROVE'
                                onClick={() => {
                                    setActiveRow(customer)
                                    setShowModal(true)
                                    setActionType("approve")
                                }}
                            >
                                <FontAwesomeIcon icon={faEdit} className={`icon icon-xs text-info cursor-pointer color-white`} />
                            </span>
                            <span
                                style={{ marginLeft: "10px", color: 'red !important' }}
                                title='REJECT'
                                onClick={() => {
                                    setActiveRow(customer)
                                    setShowModal(true)
                                    setActionType("reject")
                                }}
                                type="button"
                            >
                                <FcDeleteDatabase size={18} />
                            </span>
                        </div>
                    ) : (<></>)
                }
            </td>
        </tr>
    );
};

function FetchPaymentLogStatus({ getCustomerPaymentLogs, customerPaymentLogs, loading }) {
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState("approve");
    const [activeRow, setActiveRow] = useState(null);
    const [searchValue, setSearchValue] = useState("")


    // To handle search onChange 
    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    // To handle filter based on search Value 
    function filterArrayBySearchValue(arr, searchValue) {
        // Convert the search value to lowercase for case-insensitive matching
        const lowerSearchValue = searchValue.toLowerCase();

        // Use the filter method to create a new array with matching elements
        const filteredArray = arr.filter(item => {

            // Check if item.fromMsisdn is null or undefined
            if (item.fromMsisdn == null || item.status == null) {
                return false; 
            }

            // Convert each item to lowercase and check if it includes the search value
            const lowerItem = item.fromMsisdn?.toLowerCase();
            const lowerItem2 = item.status?.toLowerCase();
            return lowerItem.includes(lowerSearchValue) || lowerItem2.includes(lowerSearchValue);
        });

        return filteredArray;
    }

    useEffect(() => {
        // Get All The Customer Logs Once Component Mounts 
        getCustomerPaymentLogs()
    }, [])


    return (
        <div className='py-4'>
            {/* Header - starts  */}
            <Breadcrumb className="d-none d-md-inline-block"
                listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                    icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item active>Customer Payments Logs</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ marginTop: "2%" }}>
                <h5 style={{ marginBottom: "2%" }}>Fetch payment log</h5>

                {/* Search box  */}
                <Form>
                    <InputGroup className="mb-3">
                        <FormControl
                            name={'search'}
                            onChange={handleChange}
                            placeholder="Search by msisdn or status"
                            aria-label="Search by msisdn"
                        />
                    </InputGroup>
                </Form>

                {/* Table section  */}
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive striped className="table-centered table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                                <tr>
                                    <th className="">#</th>
                                    <th className="">Msisdn</th>
                                    <th className="">Loan ID</th>
                                    <th className="">Total Amount</th>
                                    <th className="">Used Amount</th>
                                    <th className="">Status</th>
                                    <th className="">Date Paid</th>
                                    <th className="">Reference</th>
                                    <th className="">Payment Method</th>
                                    <th className="">UserID</th>
                                    <th className="">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? (
                                        <tr>
                                            <td colSpan="11" align="center"><BeatLoader color="#000" size="8" /></td>
                                        </tr>
                                    ) :
                                        customerPaymentLogs?.payload?.length > 0 ?
                                            filterArrayBySearchValue(customerPaymentLogs?.payload, searchValue).map((customer, i) =>
                                            (
                                                <TableRow
                                                    index={++i}
                                                    key={`page-visit-${customer?.id}`}
                                                    setShowModal={setShowModal}
                                                    setActionType={setActionType}
                                                    setActiveRow={setActiveRow}
                                                    customer={customer}
                                                />
                                            ))
                                            : (
                                                <tr>
                                                    <td colSpan="8" align="center">No payment log available !</td>
                                                </tr>
                                            )
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>

            {
                showModal &&
                <ModalComponent
                    show={showModal}
                    setShow={setShowModal}
                    title={actionType === "approve" ? "APPROVE" : "REJECT"}
                    modalContent={
                        <ConfirmModalCustomerLog
                            type={actionType}
                            activeRow={activeRow}
                            setShow={setShowModal}
                        />
                    }
                />
            }
        </div>
    )
}


const mapDispatchToProps = dispatch => ({
    getCustomerPaymentLogs: (payload) => dispatch(fecthCustomerPaymentLogs(payload))
})

const mapStateToProps = (store) => {
    const { loading, customerPaymentLogs } = store?.manualRepayments;
    return { loading, customerPaymentLogs };
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchPaymentLogStatus);