import axios from "axios";

export const apiCall = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        appKey: "F1",
    }
});
//
export const apiCallT = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_TICKET,
});
//
export const apiCallF = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_FINANCE,
});

export const apiCall2 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_SHARED,
    headers: {
        appKey: "F1",
    }
});

export const apiCallRwUtils = axios.create({
    baseURL: process.env.REACT_APP_URL_RW_UTILS + "/rw-utils",
    headers: {
        appKey: "F1",
    }
});

