import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Button, Card, Col, Image, ListGroup, Row} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {
    addRoleToGroup,
    getGroupByCode,
    getRolesInGroup,
    getRolesNotInGroup,
    removeRoleFromGroup
} from "../../actions/roles";
import Preloader from "../../components/Preloader";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import ReactLogo from "../../assets/loader/Loader.svg";
import authorization from "../../helpers/authorization";

class Roles extends Component {
    REMOVE_ROLE = 'REMOVE_ROLE';
    ADD_ROLE = 'ADD_ROLE';
    //
    state = {
        loading: true,
        loadingRoles: true,
        loadingNotInRoles: true,
        groupId: this.props.match.params.id,
        selectedRole: null,
        selectedRoleId: null,
        selectedAction: null,
        role_in_group_form: "",
        role_not_group_form: "",
        resultsRolesInGroup: [],
        resultsRolesNotGroup: [],
    }

    componentDidMount = async () => {
        this.setState({loading: true, loadingRoles: true, loadingNotInRoles: true,});
        const {groupId} = this.state;
        const {getGroupByCode, getRolesInGroup, getRolesNotInGroup} = this.props;
        await getGroupByCode(groupId);
        this.setState({loading: false,});
        getRolesInGroup(groupId).then(() => {
            this.setState({loadingRoles: false,});
        });
        getRolesNotInGroup(groupId).then(() => {
            this.setState({loadingNotInRoles: false,});
        });
    }
    //
    selectRole = (role, action) => {
        this.setState({selectedRole: role.roleCode, selectedRoleId: role.roleId, selectedAction: action,});
    }
    //
    handleActionOnRoleToGroup = async () => {
        const {selectedRole, selectedAction, groupId,} = this.state;
        if (!selectedRole || !groupId) return;
        this.setState({loadingRoles: true, loadingNotInRoles: true,});
        const {addRoleToGroup, removeRoleFromGroup, getRolesInGroup, getRolesNotInGroup, group} = this.props;
        const data = {
            roleCode: selectedRole,
            groupCode: group.groupCode,
        };
        this.setState({selectedRole: null, selectedRoleId: null, selectedAction: null,});
        if (selectedAction === this.ADD_ROLE) {
            await addRoleToGroup(data);
        } else if (selectedAction === this.REMOVE_ROLE) {
            await removeRoleFromGroup(data);
        }
        getRolesInGroup(groupId).then(() => {
            this.setState({loadingRoles: false,});
        });
        getRolesNotInGroup(groupId).then(() => {
            this.setState({loadingNotInRoles: false,});
        });
    }
    //
    handleChangeRoleInGroupForm = (e) => {
        e.preventDefault();
        const {value} = e.target;
        const {rolesInGroup,} = this.props;
        if (rolesInGroup && rolesInGroup.length) {
            const results = rolesInGroup.filter(e => {
                if (`${e.roleName}`.toLocaleLowerCase().includes(`${value}`.toLocaleLowerCase())) {
                    return e;
                }
            });
            this.setState((state, props) => {
                return {
                    resultsRolesInGroup: results,
                }
            });
        }
    }
    //
    handleChangeRoleNotInGroupForm = (e) => {
        e.preventDefault();
        const {value} = e.target;
        const {rolesNotInGroup,} = this.props;
        if (rolesNotInGroup && rolesNotInGroup.length) {
            const results = rolesNotInGroup.filter(e => {
                if (`${e.roleName}`.toLocaleLowerCase().includes(`${value}`.toLocaleLowerCase())) {
                    return e;
                }
            });
            this.setState((state, props) => {
                return {
                    resultsRolesNotGroup: results,
                }
            });
        }
    }

    //
    render() {
        const {rolesInGroup, rolesNotInGroup, group,} = this.props;
        const {
            loading, selectedRole, selectedAction,
            loadingRoles, loadingNotInRoles, role_in_group_form, role_not_group_form,
            resultsRolesInGroup, resultsRolesNotGroup,
        } = this.state;
        //
        let dataRoleInGroup = resultsRolesInGroup.length > 0 ? resultsRolesInGroup : rolesInGroup;
        let dataRoleNotInGroup = resultsRolesNotGroup.length > 0 ? resultsRolesNotGroup : rolesNotInGroup;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>Roles Groups</Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all groups</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4> Group : {!loading && group && group.groupName} </h4>
                    </div>
                </div>
                <p>
                    {!loading && group && group.description}
                </p>
                {loading ? (
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Preloader show={loading}/>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col md={4}>
                                <Card>
                                    <Card.Header className={'py-2'}>
                                        <Card.Title className={'mb-0'}>Roles In The Group</Card.Title>
                                    </Card.Header>
                                    <Card.Body
                                        style={{
                                            height: '80vh',
                                            overflowY: 'scroll',
                                        }}
                                        className={loadingRoles ? 'flex-column justify-content-center align-items-center' : ''}>
                                        <div className={'mb-4'}>
                                            <input type={'text'} name={'role_in_group'}
                                                   defaultValue={role_in_group_form}
                                                   className={'form-control'}
                                                   onChange={this.handleChangeRoleInGroupForm}
                                                   disabled={loadingRoles && (!rolesInGroup)}
                                                   placeholder={'Search roles'}/>
                                        </div>
                                        {loadingRoles ? (
                                            <center>
                                                <Image
                                                    className="loader-element animate__animated animate__jackInTheBox"
                                                    src={ReactLogo} height={100} width={100}/>
                                            </center>
                                        ) : (
                                            <>
                                                <ListGroup>
                                                    {
                                                        dataRoleInGroup && dataRoleInGroup.map((role, i) => (
                                                                <ListGroup.Item active={selectedRole === role.roleCode}
                                                                                onClick={() => {
                                                                                    this.selectRole(role, this.REMOVE_ROLE)
                                                                                }}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                className={'border my-1 shadow-1 px-2 py-2'}>
                                                                    {role.roleName}
                                                                </ListGroup.Item>
                                                            )
                                                        )
                                                    }
                                                </ListGroup>
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={1} className={'pt-5'}>
                                <div className={"mb-3"}>
                                    {
                                        authorization("RO_EDIT") ? (
                                            <>
                                                <Button className={"btn-block"}
                                                        disabled={selectedAction !== this.ADD_ROLE}
                                                        onClick={this.handleActionOnRoleToGroup}
                                                        size={"sm"}><FontAwesomeIcon icon={faArrowLeft}/></Button>
                                            </>
                                        ) : (<></>)
                                    }
                                </div>
                                <div className={""}>
                                    {
                                        authorization("RO_EDIT") ? (
                                            <>
                                                <Button className={"btn-block"}
                                                        disabled={selectedAction !== this.REMOVE_ROLE}
                                                        onClick={this.handleActionOnRoleToGroup}
                                                        size={"sm"}><FontAwesomeIcon icon={faArrowRight}/></Button>
                                            </>
                                        ) : (<></>)
                                    }
                                </div>
                            </Col>
                            <Col md={4}>
                                <Card>
                                    <Card.Header className={'py-2'}>
                                        <Card.Title className={'mb-0'}>Roles Not In The Group</Card.Title>
                                    </Card.Header>
                                    <Card.Body
                                        style={{
                                            height: '80vh',
                                            overflowY: 'scroll',
                                        }}
                                        className={loadingNotInRoles ? 'flex-column justify-content-center align-items-center' : ''}>
                                        <div className={'mb-4'}>
                                            <input name={'role_in_group'} defaultValue={role_not_group_form}
                                                   className={'form-control'}
                                                   onChange={this.handleChangeRoleNotInGroupForm}
                                                   disabled={loadingNotInRoles && (!rolesNotInGroup)}
                                                   placeholder={'Search roles'}/>
                                        </div>
                                        {loadingNotInRoles ? (
                                            <center>
                                                <Image
                                                    className="loader-element animate__animated animate__jackInTheBox"
                                                    src={ReactLogo} height={100} width={100}/>
                                            </center>
                                        ) : (
                                            <>
                                                <ListGroup>
                                                    {
                                                        dataRoleNotInGroup && dataRoleNotInGroup.map((role, i) => (
                                                                <ListGroup.Item active={selectedRole === role.roleCode}
                                                                                onClick={() => {
                                                                                    this.selectRole(role, this.ADD_ROLE)
                                                                                }}
                                                                                className={'border my-1 shadow-1 px-2 py-2'}
                                                                                style={{cursor: 'pointer'}}>
                                                                    {role.roleName}
                                                                </ListGroup.Item>
                                                            )
                                                        )
                                                    }
                                                </ListGroup>
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {rolesInGroup, rolesNotInGroup, group, error} = state.roles;
    const {user} = state.authentication;
    return {rolesInGroup, rolesNotInGroup, group, error, user,};
}
//
export default connect(mapStateProps, {
    getGroupByCode,
    getRolesInGroup,
    getRolesNotInGroup,
    removeRoleFromGroup,
    addRoleToGroup,
})(Roles);
