import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Table} from "@themesberg/react-bootstrap";
import {BeatLoader} from "react-spinners";
import {getStringMonthFromDate, isDueDate} from "../../../helpers/dateHelper";

const TableWithTransactions = ({client, transactions: data}) => {
    const transactions = data?.transactions ?? [];

    return (
        <Table hover bordered className="user-table align-items-center">
            <thead>
            <tr>
                <th className="border-bottom py-1">#</th>
                <th className="border-bottom py-1">Transaction ID</th>
                <th className="border-bottom py-1">MSISDN</th>
                <th className="border-bottom py-1">Paid Date</th>
                <th className="border-bottom py-1">Paid Amount</th>
                <th className="border-bottom py-1">Type</th>
            </tr>
            </thead>
            <tbody>
            {
                transactions.map((trans, index) => {
                    const isRefund = trans.transactionType.includes("Refund");
                    const isBNumber = client.msisdn !== trans.msisdn && !isRefund;
                    return (<tr className={'py-1'} style={{backgroundColor: !isRefund ? '' : '#fef6e0'}}>
                        <td className={'py-1'}>{index + 1}</td>
                        <td className={'py-1'}>{trans.financialTransactionId ?? ''}</td>
                        <td className={'py-1'} style={{ backgroundColor: isBNumber ? '#eef9e9': '' }}>{trans.msisdn ?? ''}</td>
                        <td className={'py-1'}>{(new Date(trans.date)).toLocaleDateString()}</td>
                        <td className={'py-1'}>{trans.amount.toLocaleString()}</td>
                        <td className={'py-1'}>{trans.transactionType}</td>
                    </tr>);
                })
            }
            </tbody>
        </Table>
    );
};

const TableWithInfo = ({transactions}) => {
    return (
        <Table striped bordered>
            <tbody>
            <tr className={'py-1'}>
                <td className={'py-1'}>Total Loan To Be Paid</td>
                <td className={'py-1'}>
                    <b>{transactions && `${transactions.totalLoanToBePaid.toLocaleString()}`}</b></td>
            </tr>
            <tr className={'py-1'}>
                <td className={'py-1'}>Total Paid Amount</td>
                <td className={'py-1'}>
                    <b>{transactions && `${transactions.totalPaidAmount.toLocaleString()}`}</b>
                </td>
            </tr>
            <tr className={'py-1'}>
                <td className={'py-1'}>Balance</td>
                <td className={'py-1'}><b>{transactions && `${transactions.balance.toLocaleString()}`}</b></td>
            </tr>
            </tbody>
        </Table>
    );
}

const TableWithInstallments = ({ installments, date, totalToBePaid, firstPayment }) => {
    const [dueAmount, setDueAmount] = useState(0);

    const firstInstallment = firstPayment ?? totalToBePaid / 12;
    const offeredDate = new Date(date);

    useEffect(() => {
        let updatedDueAmount = 0;

        (installments ?? []).forEach((installment) => {
            const isNotPaid = !installment?.amount;
            const isDue = isDueDate(`${offeredDate.getDate()}, ${getStringMonthFromDate(date, installment?.month - 1)}`);

            if (isNotPaid && isDue) {
                updatedDueAmount += firstPayment ?? 0;
            }
            if (isDue && installment?.amount > 0 && installment?.amount < firstInstallment) {
                const _remainAmount = firstPayment - installment?.amount;
                updatedDueAmount += _remainAmount;
            }
        });

        setDueAmount(updatedDueAmount);
    }, [installments, date, firstPayment, offeredDate]);

    return (
        <Table bordered>
            <tbody>
            <tr className={`py-1`}>
                <td>Joined: {offeredDate.toDateString()}</td>
                <td className="text-sm">
                    <div>First Payment: {firstPayment.toLocaleString()}</div>
                    <div>Due: <span className="text-danger" style={{ textAlign: "right" }}>{dueAmount.toLocaleString()}</span></div>
                </td>
            </tr>
            {(installments ?? []).map((installment) => {
                const isNotFull = installment?.amount > 0 && installment?.amount < firstInstallment;
                const isNotPaid = !installment?.amount;
                const _date = `${offeredDate.getDate()}, ${getStringMonthFromDate(date, installment?.month - 1)}`;
                const isDue = isDueDate(_date);
                let color = "";

                if (isNotFull) {
                    color = "#fef9eb";
                } else if (isNotPaid && isDue) {
                    color = "#fee3ea";
                }

                return (
                    <tr key={installment?.month} className={`py-1`} style={{ backgroundColor: color }}>
                        <td className={"py-1"}>{_date}</td>
                        <td className={"py-1"}>{installment?.amount?.toLocaleString()}</td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
};

class CustomerTransactions extends Component {

    state = {
        loading: true,
        totalPaidAmount: null,
        totalTransactionCount: null,
        balance: null,
        totalLoanToBePaid: null,
        transactions: [],
    }
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getClientTransactions, client} = this.props;
        if (client && client.msisdn) {
            await getClientTransactions(client.msisdn);
        }
        this.setState({loading: false});
    }

    render() {
        const {loading} = this.state;
        const {transactions, client, error} = this.props;
        return (
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="">
                    <Row>
                        {
                            (loading && !error) && (<Col md={12}><center><BeatLoader color="#000" size="9"/></center></Col>)
                        }
                        {
                            (!loading && error) && (<Col md={12}><center><p>{error}</p></center></Col>)
                        }
                        <Col md={4}>
                            <div className="col-md-12">
                                {
                                    (!loading && !error) && (<TableWithInfo transactions={transactions}/>)
                                }
                            </div>
                            <div className="col-md-12 mt-4">
                                {
                                    (!loading && !error) && (<TableWithInstallments
                                        installments={transactions?.calculatedLoanInstallments ?? []}
                                        date={transactions?.offeringDate ?? new Date().toLocaleString()}
                                        totalToBePaid = {transactions?.totalLoanToBePaid ?? 12}
                                        firstPayment = {transactions?.firstPayment}
                                    />)
                                }
                            </div>
                        </Col>
                        <Col md={8}>
                            {(!loading && !error) && (<TableWithTransactions transactions={transactions} client={client} />)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

//
const mapStateProps = (state) => {
    const {transactions, clientLoans, error} = state.clients;
    return {transactions, clientLoans, error};
}
//
export default connect(mapStateProps, {})(CustomerTransactions);
