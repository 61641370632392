import React, { useState } from "react";
import { Routes } from "../../routes";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Alert,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import { connect } from "react-redux";
import { ResolveLoanInitiation } from "../../actions/loans";


const LoanInitiation = ({ initiateLoanCancellation, loading }) => {
    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);
    const onClose = (alertId) => {
      const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
      setHiddenAlerts(hiddenAlertsUpdated);
    };
    const shouldShowAlert = (alertId) => hiddenAlerts.indexOf(alertId) === -1;
  
    const [msisdn, setMsisdn] = useState("");
    const [reason, setReason] = useState("");
    const [response, setResponse] = useState(null);

    const intiateCancellation = async (e) => {
        e.preventDefault();
        setResponse(null);
        const res = await initiateLoanCancellation(reason, msisdn);
        console.log(res);
        setResponse(res?.data);
      };
    
      console.log("response", response);
  
    return (
        <div>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
            >
              <Breadcrumb.Item href={Routes.DashboardOverview.path}>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Loan Cancellation</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Loans - Initiate Loan Cancellation</h5>
  
            <Card border="light" className="table-wrapper shadow-sm mb-4">
              <Card.Body className="">
                <Form onSubmit={intiateCancellation}>
                  <Row className="">
                    <Col>
                    <Form.Control
                          type="text"
                          name="reason"
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}
                          value={reason}
                          placeholder="Enter Reason"
                          required
                        />
                    </Col>
                    <Col>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="msisdn"
                          onChange={(e) => {
                            setMsisdn(e.target.value);
                            setResponse(null);
                          }}
                          value={msisdn}
                          placeholder="Type Msisdn..."
                          required
                        />
  
                        <Button type="submit">
                          {loading ? <BeatLoader color="#fff" /> : "Initiate"}
                        </Button>
                      </InputGroup>
                    </Col>
  
                   {response && (
                      <Col md={12} className="mt-3">
                        <Alert
                          variant="primary"
                          show={shouldShowAlert("primary")}
                          onClose={() => onClose("primary")}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>
                                <strong>
                                  Loan Cancellation Initiation - {msisdn}
                                </strong>
                              </p>
                              <p>{response?.message}</p>
                            </div>
                            <Button
                              variant="close"
                              size="xs"
                              onClick={() => onClose("primary")}
                            />
                          </div>
                        </Alert>
                      </Col>
                    )} 
  
                  
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    initiateLoanCancellation: (reason, msisdn) =>
      dispatch(ResolveLoanInitiation({ reason, msisdn })),
  });
  
  const mapStateToProps = (store) => {
    const { loading } = store?.loans;
    return { loading };
  };

export default connect(mapStateToProps, mapDispatchToProps)(LoanInitiation);