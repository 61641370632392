import React, { useState } from "react";
import { Routes } from "../../routes";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Alert,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import { connect } from "react-redux";
import { ResolveCommonFix } from "../../actions/supports";
import pdfImg from "../../assets/img/pdf.png";

const CommonFix = ({ fixIssue, loading }) => {
  const [hiddenAlerts, setHiddenAlerts] = React.useState([]);
  const onClose = (alertId) => {
    const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
    setHiddenAlerts(hiddenAlertsUpdated);
  };
  const shouldShowAlert = (alertId) => hiddenAlerts.indexOf(alertId) === -1;

  const [msisdn, setMsisdn] = useState("");
  const [operation, setOperation] = useState("");
  const [response, setResponse] = useState(null);

  const handleFix = async (e) => {
    e.preventDefault();
    setResponse(null);
    const res = await fixIssue(operation, msisdn);
    setResponse(res?.data);
  };

  console.log("response", response);

  return (
    <div>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href={Routes.DashboardOverview.path}>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Common Fixes</Breadcrumb.Item>
          </Breadcrumb>
          <h5>Support Issues - Common Issues</h5>

          <Card border="light" className="table-wrapper shadow-sm mb-4">
            <Card.Body className="">
              <Form onSubmit={handleFix}>
                <Row className="">
                  <Col>
                    <Form.Select
                      onChange={(e) => {
                        setOperation(e.target.value);
                        setResponse(null);
                      }}
                      required
                    >
                      <option value="">Select Operation</option>
                      <option value="Send Popup">Send Popup</option>
                      <option value="Active Plan">Active Plan</option>
                      <option value="Insurance Contract">
                        Insurance Contract
                      </option>
                      <option value="Initial Payment - Sideloading">
                        Initial Payment - Sideloading
                      </option>
                      <option value="Update National ID">
                        Update National ID
                      </option>
                      <option value="Temporary Extension">
                        Temporary Extension
                      </option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="msisdn"
                        onChange={(e) => {
                          setMsisdn(e.target.value);
                          setResponse(null);
                        }}
                        value={msisdn}
                        placeholder="Type Msisdn..."
                        required
                      />

                      <Button type="submit">
                        {loading ? <BeatLoader color="#fff" /> : "Resolve"}
                      </Button>
                    </InputGroup>
                  </Col>

                  {response && (
                    <Col md={12} className="mt-3">
                      <Alert
                        variant="primary"
                        show={shouldShowAlert("primary")}
                        onClose={() => onClose("primary")}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <p>
                              <strong>
                                {msisdn} - {operation}
                              </strong>
                            </p>
                            <p>{response?.message}</p>
                          </div>
                          <Button
                            variant="close"
                            size="xs"
                            onClick={() => onClose("primary")}
                          />
                        </div>
                      </Alert>
                    </Col>
                  )}

                  {response &&
                    response?.data &&
                    response?.status &&
                    operation === "Insurance Contract" && (
                      <a
                        href={`data:application/pdf;base64,${response?.data}`}
                        download="file.pdf"
                      >
                        <img alt="pdf-rep" src={pdfImg} width="200px" />
                        {/* <div>
                        <Document
                          file={`data:application/pdf;base64,${response?.data}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                        <p>
                          Page {pageNumber} of {numPages}
                        </p>
                      </div> */}
                      </a>
                    )}
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fixIssue: (operation, msisdn) =>
    dispatch(ResolveCommonFix({ operation, msisdn })),
});

const mapStateToProps = (store) => {
  const { loading } = store?.supports;
  return { loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonFix);
