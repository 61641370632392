import React, {Component} from "react";
import {connect} from "react-redux";
import {getRolesGroups, removeUserGroup} from "../../../actions/roles";
import {Button, Card, Table} from "@themesberg/react-bootstrap";
import {Routes} from "../../../routes";
import {BeatLoader} from "react-spinners";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import authorization from "../../../helpers/authorization";

const TableRow = (props) => {
    const {
        groupCode, groupName, description, groupstatus, roles, index,
        handleRemoveGroup,
    } = props;

    return (
        <tr valign={'middle'}>
            <td>
                <Card.Link href="#" className="text-primary fw-bold">{index}</Card.Link>
            </td>
            <td className="fw-bold">
                <Button variant={'light'}
                        size={'sm'}
                        disabled={!authorization("GR_READ")}
                        href={`/users/roles/${groupCode}/groups/`}>
                    {groupName}
                </Button>
            </td>
            <td>{description}</td>
            <td>{groupstatus ? "Active" : "Inactive"}</td>
            <td>
                <Button variant={'light'}
                        size={'sm'}
                        disabled={!authorization("GR_READ")}
                        href={`/users/roles/${groupCode}/groups/`}>Roles: {(roles && roles.length) ?? 0}</Button>
            </td>
            <td>
                <Button variant="light"
                        size="sm"
                        disabled={!authorization("GR_DELETE")}
                        onClick={() => {
                            handleRemoveGroup(groupCode)
                        }}>
                    <FontAwesomeIcon icon={faMinusCircle}
                                     className={`icon icon-xs text-danger cursor-pointer`}/>
                </Button>
            </td>
        </tr>
    );
};

class Groups extends Component {
    state = {
        loading: false,
    }
    componentDidMount = async () => {
        this.setState({loading: true});
        const {getRolesGroups} = this.props;
        await getRolesGroups();
        this.setState({loading: false});
    }

    handleRemoveGroup = async (group) => {
        if (window.confirm("Are you sure ?") !== true) return;
        this.setState({loading: true});
        const {removeUserGroup, getRolesGroups,} = this.props;
        await removeUserGroup(group);
        await getRolesGroups();
        this.setState({loading: false});
    }

    render() {
        const {groups,} = this.props;
        const {loading,} = this.state;
        //
        return (
            <>
                {
                    authorization("GR_CREATE") ? (
                        <>
                            <Button variant="primary"
                                    href={Routes.AddGroupRoles.path}
                                    className="m-1">New Group</Button>
                        </>
                    ) : (<></>)
                }
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="">
                        <Table responsive
                               striped
                               className="table-centered table-striped table-bordered table-nowrap rounded">
                            <thead className="thead-light">
                            <tr>
                                <th className="">#</th>
                                <th className="">Group Name</th>
                                <th className="">Description</th>
                                <th className="">Group Status</th>
                                <th className="">Roles</th>
                                <th className="">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ? (
                                    <tr>
                                        <td colSpan="6" align="center"><BeatLoader color="#000" size="8"/></td>
                                    </tr>
                                ) : groups != null && groups.length > 0 ?
                                    groups.map((group, i) => <TableRow {...group}
                                                                       handleRemoveGroup={this.handleRemoveGroup}
                                                                       key={`page-visit-${i}`}
                                                                       index={++i}/>) : (
                                        <tr>
                                            <td colSpan="6" align="center">No groups available !</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

//
const mapPropState = (state) => {
    const {groups, roles, error} = state.roles;
    return {groups, roles, error};
}
//
export default connect(mapPropState, {getRolesGroups, removeUserGroup,})(Groups);
