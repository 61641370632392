import {EDIT_USER, ERROR_EDITING_USER, USER_CREATE_FAILED, USER_CREATED} from "../../actions/types";

const initials = {
    data: null,
    error: null,
};
const createUserReducer = (state = initials, action) => {
    switch (action.type) {
        case USER_CREATED:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        case USER_CREATE_FAILED:
            return {
                ...state,
                data: null,
                error: action.payload,
            }
        case ERROR_EDITING_USER:
            return {
                ...state,
                data: null,
                error: action.payload,
            }
        case EDIT_USER:
            return {
                ...state,
                data: action.payload,
                error: null,
            }
        default:
            return state;
    }
}

export default createUserReducer;