import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faFilter} from "@fortawesome/free-solid-svg-icons";
import {StartCase} from "react-lodash";

class FilterPaymentFreq extends Component {
    state = {
        loading: false,
        selectedItem: null,
        items: [
            'weekly', 'monthly', 'daily',
        ],
    }
    //
    selectStatus = (value) => {
        const {selectedFreq} = this.props;
        selectedFreq(value);
        this.setState({selectedItem: value,});
    }

    //
    render() {
        const {items, selectedItem,} = this.state;
        //
        return (
            <>
                <div className="">
                    <Dropdown>
                        <Dropdown.Toggle as={Button} variant="primary" className={'py-1'}>
                            <FontAwesomeIcon icon={faFilter} className="me-2"/><StartCase
                            string={selectedItem ?? 'Payment Frequency'}/>
                            <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown}/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                            {
                                items && items.length > 0 ? items.map((t, i) => (
                                    <Dropdown.Item onClick={() => this.selectStatus(t)} active={t === selectedItem}>
                                        <StartCase string={t}/>
                                    </Dropdown.Item>
                                )) : (<></>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }
}

const mapPropsState = (state) => {
    return {};
}
export default connect(mapPropsState, {})(FilterPaymentFreq);
