import {getToken} from "../../helpers/tokenHelper";
import {apiCall} from "../../utils/apiCall";
import {action} from "../index";
import {
    CHANGE_DEVICE,
    CHANGE_DEVICE_SIM_NUMBER,
    CREATE_CLIENT,
    ERROR_CHANGE_DEVICE,
    ERROR_CHANGE_SIM_NUMBER,
    ERROR_CLIENTS,
    EXTEND_CLIENT_DEVICE,
    GET_CLIENT,
    GET_CLIENT_TRANSACTION_LOG,
    GET_CLIENT_DEVICES,
    GET_CLIENT_FILE,
    GET_CLIENT_LOANS,
    GET_CLIENTS,
    GET_CLIENTS_TYPES,
    LEGIBILITY_CHECK,
    LOCK_CLIENT_DEVICE,
    OPEN_CLIENT_DEVICE, PROVISIONING,
    STATUS_CLIENT_DEVICE,
    UNLOCK_CLIENT_DEVICE
} from "../types";
import clientLogs from "../../data/clientLogs";

/**
 * Create a new client
 *
 * @param data
 * @returns {(function(*): Promise<boolean>)|*}
 */
export const createClientAction = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post('/intelligrapi/registercustomer', data, {
            headers,
        });
        if (req.data && req.data.customer_id) {
            dispatch(action(CREATE_CLIENT, req.data));
            return true;
        } else {
            dispatch(action(ERROR_CLIENTS, req.data.error));
        }
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
    return false;
}

/**
 * Get customer type
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCustomerTypes = () => async (dispatch) => {
    try {
        // const token = getToken();
        //
        // const req = await apiCall.get(`/intelligrapi/customertypes`, {
        //     headers
        // });
        //
        dispatch(action(GET_CLIENTS_TYPES, [
            {
                'name': 'CUSTOMER',
                'value': 'CUSTOMER',
            },
            {
                'name': 'PRE-CUSTOMER',
                'value': 'PRE-CUSTOMER',
            }
        ]));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Get clients according to their types
 *
 * @param type
 * @param pagination
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCustomersByType = (type, pagination) => async (dispatch) => {
    try {
        const token = getToken();
        const {size, page, sort, searchQuery} = pagination;
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/aboardedcustomerspage?customertype=${type}&size=${size}&page=${page}${searchQuery ? '&msisdn=' + searchQuery : ''}`, {
            headers,
        });
        //
        dispatch(action(GET_CLIENTS, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Get customer by msidn
 * @param msisdn
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCustomerByMSISDN = (msisdn) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/findcustomerbymsisdn?msisdn=${msisdn}`, {
            headers,
        });
        //
        dispatch(action(GET_CLIENT, req.data));
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message));
    }
}

/**
 * Check legibility for a customer
 *
 * @param msisdn
 * @returns {(function(*): Promise<void>)|*}
 */
export const checkLegibilityCheck = (msisdn) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/scoring/mtnscore?msisdn=${msisdn}`, {
            headers,
        });
        //
        const code = req.data?.responseCode;
        if (code === 200) {
            dispatch(action(LEGIBILITY_CHECK, req.data.data));
        } else {
            dispatch(action(ERROR_CLIENTS, "Not eligible !!"));
        }
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message));
    }
}

export const provisioning = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.post(`/intelligrapi/provisioning`, data,{
            headers,
        });
        const code = req.data?.responseCode;
        if (code === 200) {
            dispatch(action(PROVISIONING, req.data));
        } else {
            dispatch(action(ERROR_CLIENTS, "Error occurs!"));
        }
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message));
    }
}

/**
 * Get customer profile
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getClientProfile = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/findcustomerbyid?customerId=${id}`, {
            headers,
        });
        //
        dispatch(action(GET_CLIENT, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Get client file
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getClientFile = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/findcustomerpictures?customerId=${id}`, {
            headers,
        });
        dispatch(action(GET_CLIENT_FILE, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Get client loans
 *
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const getClientLoans = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/findcustomerloans?customerId=${id}`, {
            headers,
        });
        dispatch(action(GET_CLIENT_LOANS, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Get client devices
 *
 * @param id
 * @returns {(function(*): Promise<*>)|*}
 */
export const getClientDevices = (id) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/customerdevices?customerid=${id}`, {
            headers,
        });
        //
        dispatch(action(GET_CLIENT_DEVICES, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Change the phone of a client
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const changeClientDevice = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post(`/intelligrapi/changephoneaion`, data, {
            headers,
        });
        //
        dispatch(action(CHANGE_DEVICE, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CHANGE_DEVICE, e.response.data.error));
        } else {
            dispatch(action(ERROR_CHANGE_DEVICE, e.message));
        }
    }
}

/**
 * Change device sim number
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const changeDeviceSIMNumber = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const req = await apiCall.post(`/intelligrapi/changephoneaion`, data, {
            headers,
        });
        //
        dispatch(action(CHANGE_DEVICE_SIM_NUMBER, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CHANGE_SIM_NUMBER, e.response.data.error));
        } else {
            dispatch(action(ERROR_CHANGE_SIM_NUMBER, e.message));
        }
    }
}

/**
 * Lock client device
 *
 * @returns {(function(*): Promise<void>)|*}
 * @param data
 */
export const lockClientDevice = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.post(`/intelligrapi/devicelockaion`, data, {
            headers,
        });
        //
        dispatch(action(LOCK_CLIENT_DEVICE, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Unlock client device
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const unlockClientDevice = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.post(`/intelligrapi/deviceunlockaion`, data, {
            headers,
        });
        //
        dispatch(action(UNLOCK_CLIENT_DEVICE, req.data));
    } catch (e) {
        if (e.response) {
            dispatch(action(ERROR_CLIENTS, e.response.data.error));
        } else {
            dispatch(action(ERROR_CLIENTS, e.message));
        }
    }
}

/**
 * Open client device
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const openClientDevice = (data) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.post(`/intelligrapi/deviceopenaion`, data, {
            headers,
        });
        //
        dispatch(action(OPEN_CLIENT_DEVICE, req.data));
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message));
    }
}

/**
 * Check status client device
 *
 * @param imei
 * @returns {(function(*): Promise<void>)|*}
 */
export const statusClientDevice = (imei) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/devicestatusaion?imei=${imei}`, {
            headers,
        });
        //
        dispatch(action(STATUS_CLIENT_DEVICE, req.data));
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message));
    }
}

/**
 * Extend client device
 *
 * @param imei
 * @param msisdn
 * @returns {(function(*): Promise<void>)|*}
 */
export const extendClientDevice = (imei, msisdn) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        //
        const req = await apiCall.get(`/intelligrapi/deviceextendingaion?imei=${imei}&msisdn=${msisdn}`, {
            headers,
        });
        //
        dispatch(action(EXTEND_CLIENT_DEVICE, req.data));
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message));
    }
}

/**
 * Activity logs for a client
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const getClientTransactions = (msisdn) => async (dispatch) => {
    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        const req = await apiCall.get(`/intelligrapi/getcustomertransactions?msisdn=${msisdn}`, {
            headers,
        });

        dispatch(action(GET_CLIENT_TRANSACTION_LOG, req.data));
    } catch (e) {
        dispatch(action(ERROR_CLIENTS, e.message))
    }
}
