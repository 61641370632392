import { action } from "../index";
import { getToken } from "../../helpers/tokenHelper";
import { apiCall2 } from "../../utils/apiCall";
import {
    SINGLE_MANUAL_REPAYMENT_STARTS,
    SINGLE_MANUAL_REPAYMENT_ENDS,

    SINGLE_CUSTOMER_PAYMENT_STARTS,
    SINGLE_CUSTOMER_PAYMENT_ENDS,

    MULTIPLE_CUSTOMER_PAYMENT_STARTS,
    MULTIPLE_CUSTOMER_PAYMENT_ENDS,

    FETCH_CUSTOMER_PAYMENT_LOGS_STARTS,
    FETCH_CUSTOMER_PAYMENT_LOGS_SUCCESS,
    FETCH_CUSTOMER_PAYMENT_LOGS_ENDS,

    UPDATE_CUSTOMER_PAYMENT_LOGS_STARTS,
    UPDATE_CUSTOMER_PAYMENT_LOGS_SUCCESS,
    UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS,

    CHECK_CUSTOMER_PAYMENT_LOG_STARTS,
    CHECK_CUSTOMER_PAYMENT_LOG_SUCCESS,
    CHECK_CUSTOMER_PAYMENT_LOG_ENDS
} from "../types";
import { showAlert } from "../apiresponse/AlertAction";


// Insert single and multiple manual repayments 
export const manualRepayment = (msisdn) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        console.log("redux-payload", msisdn)
        dispatch(action(SINGLE_MANUAL_REPAYMENT_STARTS))

        const req = await apiCall2.get(`scoring/danny?msisdn=${msisdn}`, {
            headers,
        });
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(SINGLE_MANUAL_REPAYMENT_ENDS))
    }
}

// Insert single manual repayments 
export const InsertSingleManualPaynent = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };
        const data = payload;

        console.log("addSingle-payload", payload)
        dispatch(action(SINGLE_CUSTOMER_PAYMENT_STARTS))

        const req = await apiCall2.post(`Customers/add_single_payment_recognition`, data, {
            headers,
        });

        console.log('insert-single-res', req)

        const code = req.status;
        if (code === 200) {
            console.log('Single manual repayment upload success...')
            dispatch(showAlert(req?.data?.result?.message, "success"))
            dispatch(action(SINGLE_CUSTOMER_PAYMENT_ENDS))
        } else {
            console.log('Single manual repayment upload failure...')
            dispatch(showAlert("Operation Failed", "error"))
            dispatch(action(SINGLE_CUSTOMER_PAYMENT_ENDS))
        }
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(SINGLE_CUSTOMER_PAYMENT_ENDS))
        dispatch(showAlert("Operation Failed", "error"))
    }
}

// Insert multiple manual repayments(File Uploads) 
export const InsertMultipleManualPaynent = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            "Authorization": "Bearer ".concat(token),
            "Content-Type": "multipart/form-data"
        };
        const data = payload;

        console.log("mutiple-recog-payload", data)
        dispatch(action(MULTIPLE_CUSTOMER_PAYMENT_STARTS))

        // const req = await apiCall2.post(`Customers/add_manual_payment_recognition_bulk_upload`, data, {
        const req = await apiCall2.post(`Customers/add_manual_payment_recognition_bulk_upload?userId=${data?.AdminUserID}`, data?.formData, {
            headers,
        });

        const code = req?.status;
        const statusText = req?.statusText;
        const dataStatus = req?.data?.result?.status;
        const successMessage = req?.data?.result?.message;

        console.log('insert-multiple-res', req)
        console.log('insert-multiple-res-statusText', statusText)
        console.log('insert-multiple-res-dataStatus', dataStatus)
        console.log('insert-multiple-res-successMessage', successMessage)

        if (code === 200 && statusText === "OK") {
            console.log('Fetch customer payment logs success...')
            dispatch(showAlert(successMessage || "Excel Data Successfully uploaded...!!!", "success"))
            dispatch(action(MULTIPLE_CUSTOMER_PAYMENT_ENDS))
        } else {
            console.log('Upload multiple customer paylog failure...')
            dispatch(showAlert("Operation Failed", "error"))
            dispatch(action(MULTIPLE_CUSTOMER_PAYMENT_ENDS))
        }
        
    } catch (e) {
        console.log("Error occurred...")
        dispatch(showAlert("Failed To Upload File...!!!", "error"))
        dispatch(action(MULTIPLE_CUSTOMER_PAYMENT_ENDS))
    }
}

// Fetch customer payment logs
export const fecthCustomerPaymentLogs = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        console.log("redux-payload", payload)
        dispatch(action(FETCH_CUSTOMER_PAYMENT_LOGS_STARTS))

        const req = await apiCall2.get(`Customers/Fetch_customer_paylog`, {
            headers,
        });

        console.log('fetch-records-res', req)

        const code = req.status;
        if (code === 200) {
            console.log('Fetch customer payment logs success...')
            // dispatch(showAlert(req?.data?.data?.message, "success"))
            dispatch(action(FETCH_CUSTOMER_PAYMENT_LOGS_SUCCESS, {payload: req?.data?.data}))
        } else {
            console.log('Fetch customer payment logs failure...')
            dispatch(showAlert("Could not fetch customer payment logs...", "error"))
            dispatch(action(SINGLE_CUSTOMER_PAYMENT_ENDS))
        }
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(FETCH_CUSTOMER_PAYMENT_LOGS_ENDS))
    }
}

// Accept/Reject customer payment logs
export const UpdateCustomerPaymentLogs = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        console.log("acceptReject-payload", payload)
        dispatch(action(UPDATE_CUSTOMER_PAYMENT_LOGS_STARTS))

        const req = await apiCall2.get(`Customers/Update_customer_paylog?msisdn=${payload?.fromMsisdn}&userID=${payload?.id}&status=${payload?.status}`, {
            headers,
        });

        console.log('update-record-res', req)

        const code = req.status;
        if (code === 200) {
            console.log('Accept/Reject customer payment logs success...')
            dispatch(showAlert(req?.data?.message || "Customer paylog successfully upddated!", "success"))
            dispatch(action(UPDATE_CUSTOMER_PAYMENT_LOGS_SUCCESS))
        } else {
            console.log('Fetch customer payment logs failure...')
            dispatch(action(UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS))
            dispatch(showAlert(req?.data?.message || "Error updating customer record!", "error"))
        }

        return req;
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(UPDATE_CUSTOMER_PAYMENT_LOGS_ENDS))
        dispatch(showAlert("Error updating customer record!", "error"))
    }
}

// Check customer payment logs
export const CheckCustomerPaymentLogs = (payload) => async (dispatch) => {

    try {
        const token = getToken();
        const headers = {
            Authorization: "Bearer ".concat(token),
        };

        console.log("check-payload", payload)
        dispatch(action(CHECK_CUSTOMER_PAYMENT_LOG_STARTS))

        const req = await apiCall2.get(`Customers/check_customer_paylog?msisdn=${payload}`,{
            headers,
        });
        console.log('check-record-res', req)

        const code = req.status;
        if (code === 200 && req?.data?.data.length > 0) {
            console.log('Fetch customer payment logs success...')
            dispatch(action(CHECK_CUSTOMER_PAYMENT_LOG_SUCCESS))
            dispatch(showAlert(req?.data?.message || "Customer paylog successfully upddated!", "success"))
        } else {
            console.log('Fetch customer payment logs failure...')
            dispatch(action(CHECK_CUSTOMER_PAYMENT_LOG_ENDS))
            dispatch(showAlert( req?.data?.data?.length === 0 ? "No record found for the msisdn" : req?.data?.message || "Error fetching customer record!", "error"))
        }

        return req;
    } catch (e) {
        console.log("Error occurred...")
        dispatch(action(FETCH_CUSTOMER_PAYMENT_LOGS_ENDS))
        dispatch(showAlert("Error fetching customer record!", "error"))
    }
}