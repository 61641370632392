import React from 'react';
import { Button } from "@themesberg/react-bootstrap";
import { BeatLoader } from "react-spinners";
import { connect } from 'react-redux';
import { useState } from 'react';


function ConfirmModalContent({ type, content, action, actionPayload, actionTxt, loading }) {

    console.log('loading-state', loading) 

    return (
        <>
            <>
                {
                    type === "single" ?
                        <>
                            <h5>Are you sure you want to trigger single manual repayment for this msisdn?</h5>
                            <p>
                                <b>Msisdn:</b>
                                <span style={{ marginLeft: "10px" }}>{content || ""}</span>
                            </p>
                        </> :
                        <>
                            <h5>Are you sure you want to trigger multiple manual repayment?</h5>
                            <p style={{ color: "brown", fontWeight: "700" }}>Note that this process can't be reversed.</p>
                        </>
                }
                {/* Action buttons  */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "30px"
                    }}
                >
                    <Button
                        variant={"dark"}
                        size={"sm"}
                        color="white"
                        onClick={
                            action ?
                                () => {
                                    action(actionPayload)
                                    console.log('loading', loading)
                                } :
                                () => {
                                    console.log("Proceeding...")
                                }}
                        disabled={loading}
                    >
                        {loading ? <BeatLoader color="#fff" /> : (actionTxt || "Proceed")}
                    </Button>
                </div>
            </>
        </>
    )
}


const mapDispatchToProps = dispatch => ({
    
})

const mapStateToProps = (store) => {
    const {loading} = store?.manualRepayments;
    return {loading};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalContent);