import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faEye, faHome, faMobile} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import Preloader from "../../components/Preloader";
import CustomerDetails from "./components/CustomerDetails";
import CustomerDevice from "./components/CustomerDevice";
import CustomerLoan from "./components/CustomerLoan";
import CustomerTransactions from "./components/CustomerTransactions";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {
    getClientDevices,
    getClientFile,
    getClientLoans,
    getClientProfile,
    getClientTransactions
} from "../../actions/clients";
import authorization from "../../helpers/authorization";

class ClientDetails extends Component {
    state = {
        loading: false,
        loadingImage: true,
        selectedTab: 'customer_details',
        customerId: this.props.match.params.id,
    }

    componentDidMount = async () => {
        const {getClientProfile, getClientFile} = this.props;
        const {customerId} = this.state;
        this.setState({loading: true, loadingImage: true,});
        getClientProfile(customerId).then(() => {
            this.setState({loading: false,});
        });
        getClientFile(customerId).then(() => {
            this.setState({loadingImage: false,});
        });
    }

    selectTab = (tab) => {
        this.setState({selectedTab: tab});
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
    }

    render() {
        const {loading, selectedTab, loadingImage} = this.state;
        const {client, clientFile, getClientLoans, getClientDevices, getClientTransactions} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Clients.path}>Clients</Breadcrumb.Item>
                            <Breadcrumb.Item active>List of all clients</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>Client {!loading && client ? (
                            <span>: <b>{client.name ?? ""} {client.surname ?? ""}</b></span>) : ("")}</h3>
                    </div>
                </div>
                {loading ? (
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Preloader show={loading}/>
                        </Col>
                    </Row>
                ) : (
                    <Row className={''}>
                        <Col md={12}>
                            <Tab.Container title={''} defaultActiveKey={'customer_details'}>
                                <Row>
                                    <Col md={12}>
                                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                                            {
                                                authorization("IC_KYC_BROWSE") ? (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="customer_details"
                                                                  className="mb-sm-3 mb-md-0"
                                                                  onSelect={() => this.selectTab('customer_details')}>
                                                            <FontAwesomeIcon icon={faEye} className="me-2"/> KYC
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("IC_D_BROWSE") ? (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="customer_device"
                                                                  className="mb-sm-3 mb-md-0"
                                                                  onSelect={() => this.selectTab('customer_device')}>
                                                            <FontAwesomeIcon icon={faMobile}
                                                                             className="me-2"/> Devices
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("IC_L_BROWSE") ? (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="customer_loan"
                                                                  className="mb-sm-3 mb-md-0"
                                                                  onSelect={() => this.selectTab('customer_loan')}>
                                                            <FontAwesomeIcon icon={faMoneyBill}
                                                                             className="me-2"/> Loans
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("IC_T_BROWSE") ? (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="customer_transactions"
                                                                  className="mb-sm-3 mb-md-0"
                                                                  onSelect={() => this.selectTab('customer_transactions')}>
                                                            <FontAwesomeIcon icon={faList}
                                                                             className="me-2"/> Transactions
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ) : (<></>)
                                            }
                                        </Nav>
                                        <Tab.Content>
                                            {authorization("IC_A_BROWSE") }
                                            {
                                                authorization("IC_KYC_BROWSE") ? (
                                                    <Tab.Pane eventKey="customer_details" className="py-4">
                                                        {
                                                            selectedTab === 'customer_details' ? (
                                                                <CustomerDetails client={client}
                                                                                 imageLoading={loadingImage}
                                                                                 images={clientFile}/>
                                                            ) : (<></>)
                                                        }
                                                    </Tab.Pane>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("IC_D_BROWSE") ? (
                                                    <Tab.Pane eventKey="customer_device" className="py-4">
                                                        {
                                                            selectedTab === 'customer_device' ? (
                                                                <CustomerDevice client={client}
                                                                                getClientDevices={getClientDevices}/>
                                                            ) : (<></>)
                                                        }
                                                    </Tab.Pane>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("IC_L_BROWSE") ? (
                                                    <Tab.Pane eventKey="customer_loan" className="py-4">
                                                        {
                                                            selectedTab === 'customer_loan' ? (
                                                                <CustomerLoan client={client}
                                                                              getClientLoans={getClientLoans}/>
                                                            ) : (<></>)
                                                        }
                                                    </Tab.Pane>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("IC_T_BROWSE") ? (
                                                    <Tab.Pane eventKey="customer_transactions" className="py-4">
                                                        {
                                                            selectedTab === 'customer_transactions' ? (
                                                                <CustomerTransactions
                                                                    client={client}
                                                                    getClientTransactions={getClientTransactions}/>
                                                            ) : (<></>)
                                                        }
                                                    </Tab.Pane>
                                                ) : (<></>)
                                            }
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, client, clientFile} = state.clients;
    return {error, client, clientFile};
}
//
export default connect(mapStateProps, {
    getClientProfile,
    getClientFile,
    getClientLoans,
    getClientDevices,
    getClientTransactions,
})(ClientDetails);
