import React, {Component} from "react";
import {connect} from "react-redux";
import {checkLegibilityCheck, provisioning} from "../../actions/clients";
import {Breadcrumb, Button, Card, Col, Form, InputGroup, Row, Table} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import {BeatLoader} from "react-spinners";
import {StartCase} from "react-lodash";
import {isMtnNumber} from "../../helpers/client";

const Trl = (props) => {
    const {provisioning, name,} = props;
    const exclude = ['id'];
    if (exclude.includes(name)) {
        return (<></>);
    }
    return (
        <>
            <tr valign={'middle'}>
                <th width={'50%'} className={'py-1 text-right'}>
                    <StartCase string={name}/>
                </th>
                <td className={'py-1'}>
                    <b>{provisioning ?? ""}</b>
                </td>
            </tr>
        </>
    );
}

class Provisioning extends Component {
    state = {
        loading: false,
        inputValue: "",
        error: null,
    }
    //
    handleOnChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value})
    }
    //
    handleSubmitButton = async (e) => {
        this.setState({loading: true});
        const {inputValue} = this.state;
        const {provisioning} = this.props;
        if (inputValue !== "" && isMtnNumber(inputValue)) {
            const data = {
                amount: 0,
                msisdnNumb: inputValue,
                processingNumber: (new Date()).getTime(),
                productName: "EntryLevelSP",
            };
            await provisioning(data);
        } else if (!isMtnNumber(inputValue)) {
            this.setState({error: "Invalid MTN number"});
        }
        this.setState({loading: false});
    }

    //
    render() {
        const {inputValue, loading, error} = this.state;
        const {provisioningStore, responseError} = this.props;
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Provisioning</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Provisioning</h4>
                    </div>
                </div>
                <Card border="light" className="table-wrapper shadow-sm mb-4">
                    <Card.Body className="">
                        <Row className="">
                            <Col md={6} className={'offset-3'}>
                                <InputGroup>
                                    <Form.Control type="text"
                                                  name="inputValue"
                                                  onChange={this.handleOnChange}
                                                  value={inputValue}
                                                  isInvalid={error !== null}
                                                  placeholder="MSISDN"/>
                                    <Button onClick={this.handleSubmitButton} disabled={loading}>
                                        Submit
                                        {
                                            loading ? (
                                                <>
                                                    &nbsp;<BeatLoader color="#FFF" size="6"/>
                                                </>
                                            ) : ('')
                                        }
                                    </Button>
                                </InputGroup>
                                <div className="text-center">
                                    { error !== null || responseError ? (<p className="text-muted text-warning">{error || responseError}</p>) : ""}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {
                    !loading && provisioningStore ? (
                        <>
                            <Col md={8} className={'offset-2'}>
                                <Card border="light" className="table-wrapper shadow-sm">
                                    <Card.Body className="">
                                        <Table striped bordered>
                                            <tbody>
                                            {
                                                Object.keys(provisioningStore).map((key, i) => (
                                                    <Trl provisioning={provisioningStore[key]} name={key} key={i}/>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </>
                    ) : (<></>)
                }
            </>
        );
    }
}

const mapStateToProps = (store) => {
    const {error: responseError, provisioning: provisioningStore} = store.clients;
    return {responseError, provisioningStore};
}
export default connect(mapStateToProps, {
    provisioning,
})(Provisioning);