import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Table} from "@themesberg/react-bootstrap";

class BankDetails extends Component {
    state = {
        loading: false,
    }
    //
    componentDidMount = async () => {
    }

    //
    render() {
        const {bank} = this.props;
        //
        return (
            <>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col className={'md-6'}>
                                <Table bordered striped className={'table-compacted'}>
                                    <tbody>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Bank Name</td>
                                        <td className={'py-1'}><b>{bank ? bank.bankName : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Bank Code</td>
                                        <td className={'py-1'}><b>{bank ? bank.bankCode : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Bank Short</td>
                                        <td className={'py-1'}><b>{bank ? bank.bankShort : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Tin Number</td>
                                        <td className={'py-1'}><b>{bank ? bank.tinNumber : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Phone Number</td>
                                        <td className={'py-1'}><b>{bank ? bank.phoneNumber : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Email</td>
                                        <td className={'py-1'}><b>{bank ? bank.email : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Customer Service Phone</td>
                                        <td className={'py-1'}><b>{bank ? bank.customerServicePhone : ''}</b></td>
                                    </tr>
                                    <tr className={'py-1'}>
                                        <td className={'py-1'}>Website</td>
                                        <td className={'py-1'}><b>{bank ? bank.website : ''}</b></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateProps = (state) => {
    const {bank, error,} = state.partners;
    return {bank, error};
}
export default connect(mapStateProps, {})(BankDetails);
