import React, { useState } from "react";
import { Routes } from "../../routes";
import { Breadcrumb, Button, Card, Col, Form, InputGroup, Row, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import authorization from "../../helpers/authorization";
import { CheckCustomerPaymentLogs } from "../../actions/manual_repayments";
import { connect } from "react-redux";
import PaylogDetails from "./PaylogDetails";


const CheckPaymentLogStatus = ({ tiggerCheckPaymentLog, loading, singleCustomerPaylog }) => {
  const [inputValue, setInputValue] = useState("");
  const [customerPaylogs, setCustomerPaylogs] = useState({});

  const handleOnChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleFetchPaymentLog = async (e) => {
    e.preventDefault();
    console.log("inputValue", inputValue)
    const response = await tiggerCheckPaymentLog(inputValue)
    console.log('fetch-single-response', response?.data?.data)
    setCustomerPaylogs((response?.data?.data && response?.data?.data) || [])
  }


  return (
    <div>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item href={Routes.DashboardOverview.path}><FontAwesomeIcon
              icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Customer Payment Log</Breadcrumb.Item>
          </Breadcrumb>
          <h5>Fetch Payment Log</h5>

          <Card border="light" className="table-wrapper shadow-sm mb-4">
            <Card.Body className="">
              <Row className="">
                <Col md={12}>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="inputValue"
                      onChange={handleOnChange}
                      value={inputValue}
                      placeholder="Type Msisdn..."
                      required
                    />
                    <Button onClick={handleFetchPaymentLog}>
                      {loading ? <BeatLoader color="#fff" /> : "Fetch"}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* Details Below  */}
          {
            (customerPaylogs !== undefined && customerPaylogs?.length > 0) ?
              customerPaylogs.map((data, index) => (
                <div key={index}>
                  <PaylogDetails paylogData={data} index={index} />
                </div>
              ))
              : ''

          }
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  tiggerCheckPaymentLog: (msisdn) => dispatch(CheckCustomerPaymentLogs(msisdn)),
})

const mapStateToProps = (store) => {
  const { loading, singleCustomerPaylog } = store?.manualRepayments;
  return { loading, singleCustomerPaylog };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckPaymentLogStatus);
