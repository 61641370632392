import {Button, Modal, Table} from "@themesberg/react-bootstrap";
import React from "react";

export const DevicesModelDialog = (props) => {
    const {show, close, devices} = props;
    const TableRow = (props) => {
        const {index, device} = props;
        //
        return (
            <tr>
                <td>{index}</td>
                <td>
                    <a
                        href={device.deviceType ? `/devices/${device.deviceType.deviceTypeId}/details` : "#"}>
                        {device.deviceType && device.deviceType.type_name}
                    </a>
                </td>
                <td>{device.deviceType && device.deviceType.full_price}</td>
                <td>{device && device.imei}</td>
                <td>
                    <a href={device.store ? `/stores/${device.store.storeId}/details` : "#"}>
                        {device.store && device.store.name}
                    </a>
                </td>
                <td>{device.user ? `${device.user.name} ${device.user.name}` : ''}</td>
            </tr>
        );
    }
    //
    return (
        <Modal show={show} onHide={close} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive className="table-centered table-bordered table-nowrap rounded">
                    <thead className="thead-light">
                    <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Device Type</th>
                        <th className="border-0">Full Price</th>
                        <th className="border-0">IMEI</th>
                        <th className="border-0">Store Name</th>
                        <th className="border-0">Representative</th>
                    </tr>
                    </thead>
                    <tbody>
                    {devices && devices.length ? (
                        devices.map((device, i) => <TableRow device={device} index={++i}/>)
                    ) : (
                        <></>
                    )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}