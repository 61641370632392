import React, {Component} from "react";
import {Breadcrumb, Button, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faHome} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import Preloader from "../../components/Preloader";
import {connect} from "react-redux";
import {getCorporate, getCorporateMembers, getCorporateTransactions, getCorporateUsers} from "../../actions/corporates";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faListOl} from "@fortawesome/free-solid-svg-icons/faListOl";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons/faUserFriends";
import Details from "./components/Details";
import Members from "./components/Members";
import Transactions from "./components/Transactions";
import Users from "./components/Users";
import authorization from "../../helpers/authorization";
import TabNavItem from "../../components/TabNavItem";

class DetailCorporate extends Component {
    state = {
        loading: true,
        selectedTab: 'corporate_details',
        corporateId: this.props.match.params.id,
    }

    componentDidMount = async () => {
        this.setState({loading: true,});
        const {corporateId} = this.state;
        const {getCorporate} = this.props;
        await getCorporate(corporateId);
        this.setState({loading: false,});
    }
    //
    selectTab = (tab) => {
        this.setState({selectedTab: tab,});
    }

    //
    render() {
        const {loading, corporateId, selectedTab} = this.state;
        const {corporate, getCorporateMembers, getCorporateUsers, getCorporateTransactions} = this.props;
        //
        return (
            <>
                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item href={Routes.Corporates.path}>List corporates</Breadcrumb.Item>
                            <Breadcrumb.Item active>Corporate Detail</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Corporate: <b><u>{corporate && corporate.name}</u></b></h4>
                    </div>
                </div>
                <div className={'mb-2'}>
                    <Button variant="primary"
                            href={Routes.Corporates.path}
                            className="m-1">All corporates</Button>
                    {
                        authorization("CC_EDIT") ? (
                            <>
                                <Button variant="success"
                                        href={`/corporates/${corporateId}/edit`} className="m-1">
                                    Edit Corporate
                                </Button>
                            </>
                        ) : (<></>)
                    }
                </div>
                {loading ? (
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Preloader show={loading}/>
                        </Col>
                    </Row>
                ) : (
                    <Row className={''}>
                        <Col md={12}>
                            <Tab.Container title={''} defaultActiveKey={'corporate_details'}>
                                <Row>
                                    <Col md={12}>
                                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                                            <TabNavItem title={"Details"}
                                                        eventKey="corporate_details"
                                                        onSelect={() => this.selectTab('corporate_details')}
                                                        icon={faEye}
                                                        role={"CC_BROWSE"}/>
                                            <TabNavItem title={"Members"}
                                                        eventKey="corporate_members"
                                                        onSelect={() => this.selectTab('corporate_members')}
                                                        icon={faUsers}
                                                        role={"CC_M_BROWSE"}/>
                                            <TabNavItem title={"Transactions"}
                                                        eventKey="corporate_transactions"
                                                        onSelect={() => this.selectTab('corporate_transactions')}
                                                        icon={faListOl}
                                                        role={"CC_T_BROWSE"}/>
                                            <TabNavItem title={"Users"}
                                                        eventKey="corporate_users"
                                                        onSelect={() => this.selectTab('corporate_users')}
                                                        icon={faUserFriends}
                                                        role={"CC_U_BROWSE"}/>
                                        </Nav>
                                        <Tab.Content>
                                            {
                                                authorization("CC_BROWSE") ? (
                                                    <>
                                                        <Tab.Pane eventKey="corporate_details" className="py-4">
                                                            {
                                                                selectedTab === 'corporate_details' ? (
                                                                    <Details {...corporate} corporateId={corporateId}/>
                                                                ) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("CC_M_BROWSE") ? (
                                                    <>
                                                        <Tab.Pane eventKey="corporate_members" className="py-4">
                                                            {
                                                                selectedTab === 'corporate_members' ? (
                                                                    <Members corporate={corporate}
                                                                             corporateId={corporateId}
                                                                             getCorporateMembers={getCorporateMembers}/>
                                                                ) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("CC_T_BROWSE") ? (
                                                    <>
                                                        <Tab.Pane eventKey="corporate_transactions" className="py-4">
                                                            {
                                                                selectedTab === 'corporate_transactions' ? (
                                                                    <Transactions corporate={corporate}
                                                                                  corporateId={corporateId}
                                                                                  getCorporateTransactions={getCorporateTransactions}/>
                                                                ) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                authorization("CC_U_BROWSE") ? (
                                                    <>
                                                        <Tab.Pane eventKey="corporate_users" className="py-4">
                                                            {
                                                                selectedTab === 'corporate_users' ? (
                                                                    <Users corporate={corporate}
                                                                           corporateId={corporateId}
                                                                           getCorporateUsers={getCorporateUsers}/>
                                                                ) : (<></>)
                                                            }
                                                        </Tab.Pane>
                                                    </>
                                                ) : (<></>)
                                            }
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                )
                }
            </>
        );
    }
}

//
const mapStateProps = (state) => {
    const {error, corporate} = state.corporates;
    return {error, corporate};
}
//
export default connect(mapStateProps, {
    getCorporate,
    getCorporateMembers,
    getCorporateUsers,
    getCorporateTransactions,
})(DetailCorporate);
