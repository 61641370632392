import React, {useEffect, useState} from 'react';
import {Redirect, Route} from "react-router-dom";
import {isLoggedIn} from "../helpers/tokenHelper";
import Preloader from "./Preloader";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {Routes} from "../routes";
import {connect} from "react-redux";
import {profileAction} from "../actions/user";
import authorization from "../helpers/authorization";
import {setCurrentPage, setLoadingSate} from "../actions/app";

export const ProtectedRoute = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000); 
        return () => clearTimeout(timer);
    }, []);
    const {role, path,} = rest;
    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }
    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    const isAuthenticated = isLoggedIn();
    if (!isAuthenticated) {
        window.location = Routes.Login.path;
    }
    if (rest.currentPage !== path) {
        rest.setCurrentPage(path);
    }

    return (
        <Route {...rest} render={(props) => {
            if (!authorization(role)) {
                return (
                    <Redirect
                        to={{
                            pathname: Routes.NotAuthorized.path,
                            state: {from: props.location},
                        }}
                    />
                )
            }

            return (
                isAuthenticated ? (
                    <>
                        <Preloader show={loaded ? false : true}/>
                        <Sidebar user={rest.user}/>
                        <main className="content">
                            <Navbar/>
                            <Component {...props} />
                            <Footer toggleSettings={toggleSettings} showSettings={showSettings}/>
                        </main>
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.Login.path,
                            state: {from: props.location},
                        }}
                    />
                )
            );
        }}
        />
    );
};
//
const mapStateToProps = (state) => {
    const {isLoggedIn} = state.authentication;
    const {user} = state.profile;
    const {currentPage, loadingState,} = state.app;
    //
    return {
        isLoggedIn, user, currentPage, loadingState,
    };
};
//
export default connect(mapStateToProps, {
    profileAction,
    setCurrentPage,
    setLoadingSate,
})(ProtectedRoute);